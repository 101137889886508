import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/LoginDashboard";
// import TryalLoginDashboard from "../Navbar/TryalLoginDashboard";
import Footer from "../Footer/Footer";
import Sidbar from "./Sidbar";
// import TryalSidbar from "./TryalSidbar";
import "./dashboard.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Moment from "react-moment";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import TryalChallengeoftheday from "./TryalChallengeoftheday";
import axios from "axios";
import moment from "moment";
import { decode as atob, encode as btoa } from "base-64";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from '@mui/material/Tooltip';

const TryalDashboard = () => {
  let navigate = useNavigate();
  const dateToFormat = new Date();
  const [user, setUser] = useState("");
  const [classid, setClassid] = useState();
  const [Job, setJob] = useState("");
  const [insaidtv, setInsaidtv] = useState("");
  const [coures, setCoures] = useState(localStorage.getItem("courseDetails")?JSON.parse(localStorage.getItem('courseDetails')):"");
  const [today, setToday] = useState();
  const [mylink, setMylink] = useState();
  const [curtimeseconds, setCs] = useState();
  const [eventdate, setEd] = useState();
  const [starttimeseconds, setSts] = useState();
  const [endtimeseconds, setEts] = useState();
  const [isShown, setIsShown] = useState(false);
  const [insaidtvpop, setInsaidtvpop] = useState();
  const [info, setinfo] = useState(false);
  const [showcoins, setShowcoins] = useState(false);
  const [open, setOpen] = useState(false);
  const [openup, setOpenup] = useState(false);
  const [age, setAge] = useState("");
  const [term, setTerm] = useState("");
  const [baner, setBanerData] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpenref = () => {
    setOpen(true);
  };
  
  const handleCloseref = () => {
    setOpen(false);
  };
  const handleClickOpenup = () => {
    setOpenup(true);
  };
  
  const handleCloseup = () => {
    setOpenup(false);
  };
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const handleClose = () => {
    setInsaidtvpop(false);
  };
  const handleClickOpen = (indtv) => {
    setInsaidtvpop(true);
    setinfo(indtv);
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    category: localStorage.getItem("category"),
  };
  const CourseData = {
    enrol_id: localStorage.getItem("enrol_id"),
  };


  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUser(user_id);

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchaccrediantv`,
        JSON.stringify(sendData),
        options
      )
      .then((result) => {
        setInsaidtv(result.data);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchhomejob`,
        JSON.stringify(sendData),
        options
      )
      .then((result) => {
        setJob(result.data);
      });
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchcoursedetails`,
    //     JSON.stringify(CourseData),
    //     options
    //   )
    //   .then((result) => {
    //     setCoures(result.data);
     
    //   });
    if(!localStorage.getItem("courseDetails")){
      axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchcoursedetails`,
      JSON.stringify(CourseData),
      options
    )
    .then((result) => {
      localStorage.setItem("courseDetails",JSON.stringify(result.data))
      setCoures(result.data);
      // LoaderClose();
    });
    }

 
    
  }, []);

 
  if (user == null) {
    navigate(`/Login`);
  }

  return (
    <>
      <LoginDashboard showcoins={showcoins} />
    
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5, px: { xs: 2 } }}>
        <Box sx={{ pt: { lg: 8, xs: 2 } }}>
       
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12} sm={12} md={12}>

              <Grid container sx={{ mb: 4, pt: { lg: 2, xs: 5 } }}>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={4}
                  sm={12}
                  sx={{
                    maxWidth: { lg: "30.333333% !important" },
                    mb: { xs: 2 },
                  }}
                >
                  <Paper
                    sx={{ pt: 3, pb: 1, borderRadius: "16px" }}
                    elevation={3}
                  >
                    <CardMedia
                      component="img"
                      // image="https://www.insaid.co/wp-content/uploads/2022/06/WhatsApp_Image_2022-06-07_at_4.21.32_PM-removebg-preview.png"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/1.webp"
                      alt="Goole"
                      sx={{
                        height: "126px",
                        width: "190px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Box sx={{ mx: 2, mt: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          mb: 2,
                          background: "#002D60",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                          height: "32px",
                        }}
                        className="start-learning-button"
                      >
                        Start Learning{" "}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={4}
                  sm={12}
                  sx={{
                    maxWidth: { lg: "30.333333% !important" },
                    ml: { lg: 2 },
                    mb: { xs: 2 },
                  }}
                >
                       <Box sx={{background:"#D9D9D9CC", borderRadius: "16px",opacity:1,height:224}}>
          
          <Paper
            sx={{ pt: 3, pb: 1, borderRadius: "16px",opacity:0.2 }}
            elevation={3}
          >
            <CardMedia
              component="img"
              // image="https://www.insaid.co/wp-content/uploads/2022/06/counselling1.png"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/2.webp"
              alt="Goole"
              sx={{
                height: "126px",
                width: "190px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Box sx={{ mx: 2, mt: 2 }}>
              <Button
                variant="contained"
                sx={{
                  mb: 2,
                  background: "#002D60",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "100%",
                  height: "32px",
                  "&:hover": {
                    background: "#002D60",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "8px",
                    textTransform: "none",
                    width: "100%",
                    height: "32px",
                  },
                }}
              >
                Event calendar{" "}
              </Button>
            </Box>
          </Paper>
         <center> <Button
                variant="contained"
             
          
                sx={{
                  mt:-32,
                  background: "#35709D",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "40%",
                 
                  "&:hover": {
                    background: "#35709D",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "8px",
                    textTransform: "none",
                    width: "40%",
                   
                  },
                }}
              >
               Unlock{" "}
              </Button>
              </center>
          </Box>
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={4}
                  sm={12}
                  sx={{
                    maxWidth: { lg: "30.333333% !important" },
                    ml: { lg: 2 },
                    mb: { xs: 2 },
                  }}
                >
                                     
                          <Paper
                            sx={{ pt: 3, pb: 1, borderRadius: "16px" }}
                            elevation={3}
                          >
                            <CardMedia
                              component="img"
                              // image="https://www.insaid.co/wp-content/uploads/2022/06/counselling1.png"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/3.webp"
                              alt="Goole"
                              sx={{
                                height: "126px",
                                width: "190px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                            <Box sx={{ mx: 2, mt: 2 }}>
                            <Link to='/CareerCounselling'> <Button
                                variant="contained"
                                sx={{
                                  mb: 2,
                                  background: "#002D60",
                                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                  borderRadius: "8px",
                                  textTransform: "none",
                                  width: "100%",
                                  height: "32px",
                                  "&:hover": {
                                    background: "#002D60",
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    width: "100%",
                                    height: "32px",
                                  },
                                }}
                              >
                                Talk to an Admission Counselor {" "}
                              </Button>
                              </Link> 
                            </Box>
                          </Paper>
                      
                       

                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={7.5} xs={12} sm={12} md={7.5}>
                  <TryalChallengeoftheday setShowcoins={setShowcoins} />
                </Grid>
                <Grid item lg={3.7} xs={12} md={4} sm={12} sx={{ ml: { lg: 2,md:2 } }}>
                  <Paper
                    sx={{
                      py: 3,
                      borderRadius: "15px",
                      
                    }}
                  >
                    <Box display="flex" sx={{ mb: 1, px: 2 }}>
                      <LiveTvIcon />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.1rem",
                          marginBottom: "0rem !important",
                          ml: 2,
                        }}
                      >
                        Accredian TV
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        px: 2,
                        pt: 2,
                        height:{lg:"420px",sm:"420px",md:"490px",xs:"420px"} ,
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      {insaidtv &&
                        insaidtv.map((indtv) => (
                          <Box
                            className="containers"
                            onClick={() => handleClickOpen(indtv)}
                          >
                            <CardMedia
                              component="img"
                              image={indtv.thumbnails}
                              alt="Goole"
                              sx={{
                                mb: 4,
                                width:{lg: "280px",md: "300px",sm: "280px",xs: "320px"},
                                height: "160px",
                              }}
                              className="neimage"
                            />
                            <Box className="cr">
                              <Typography
                                sx={{
                                  mt: -13,
                                  width: "250px",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: "0.95rem",
                                }}
                              >
                                {indtv.title}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3 }}>
                <Grid item lg={7.5} xs={12} sm={12} md={7.5} sx={{ mb: { xs: 2 } }}>
                <Box sx={{background:"#D9D9D9CC", borderRadius: "16px",opacity:1,height:520}}>
                  <Paper
                    sx={{
                      py: 3,
                      borderRadius: "15px",
                      opacity:0.2,
                    }}
                  >
                    <Box display="flex" sx={{ mb: 3, px: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.1rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        Job Portal
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "420px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      {Job &&
                        Job.map((jb) => (
                          <a
                            href={jb.job_link}
                            target="_blank"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <Box
                              sx={{
                                boxShadow:
                                  "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px",
                                mx: 2,
                                p: 2,
                                borderRadius: "5px",
                                mb: 1,
                              }}
                              key={jb.id}
                            >
                              <Grid container>
                                <Grid item lg={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      width: { lg: 220, xs: 80, sm: 80 },
                                    }}
                                  >
                                    {jb.job_title}
                                  </Typography>
                                </Grid>
                                <Grid item lg={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.9rem",
                                      marginBottom: "0rem !important",
                                      color: "#055FA0",
                                      textAlign: "center",
                                      ml: { xs: 1 },
                                      width: { lg: 220, xs: 80, sm: 80 },
                                    }}
                                  >
                                    {jb.company_name}
                                  </Typography>
                                </Grid>
                                <Grid item lg={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      textAlign: "center",
                                      ml: { xs: 1 },
                                      width: { lg: 220, xs: 80, sm: 80 },
                                    }}
                                  >
                                    {jb.level} level
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </a>
                        ))}
                      <Box sx={{ py: 2 }}>
                        <center>
                      
                            <Button
                              variant="contained"
                              sx={{
                                background: "#009ef7",
                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: "50px",
                                textTransform: "none",
                                height: "35px",

                                mt: -1,
                                "&:hover": {
                                  background: "#009ef7",
                                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                  borderRadius: "50px",
                                  textTransform: "none",
                                  height: "35px",
                                },
                              }}
                            >
                              View more jobs{" "}
                            </Button>
                      
                        </center>
                      </Box>
                    </Box>
                  </Paper>
                  <center> <Button
                variant="contained"
             
          
                sx={{
                  mt:-72,
                  background: "#35709D",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "40%",
                 
                  "&:hover": {
                    background: "#35709D",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "8px",
                    textTransform: "none",
                    width: "40%",
                   
                  },
                }}
              >
               Unlock{" "}
              </Button>
              </center>
                  </Box>
                </Grid>
                <Grid item lg={3.7} xs={12} sm={12} md={4} sx={{ ml: { lg: 2,md:2 } }}>
                <Box sx={{background:"#D9D9D9CC", borderRadius: "16px",opacity:1,height:520}}>
                    <Paper
                      sx={{
                        py: 3,
                        borderRadius: "15px",
                        opacity:0.2,
                      }}
                    >
                      <Box display="flex" sx={{ mb: 1, px: 2 }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "1.1rem",
                            marginBottom: "0rem !important",
                          }}
                        >
                          Course:
                        </Typography>
                        <Typography
                          sx={{
                            background: "#046582",
                            color: "#fff",
                            fontWeight: 600,
                            width: "25%",
                            pl: 1.2,
                            fontSize: "0.95rem",
                            borderRadius: 1,
                            height: "24px",
                            ml: 2,
                            mt: 0.5,
                            paddingTop: "1px",
                            marginBottom: "0.4rem !important",
                            width: "117px",
                          }}
                        >
                          {coures.program_code}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          px: 2,
                          height: "428px",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            width: "0.2em",
                          },
                          "&::-webkit-scrollbar-track": {
                            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(0,0,0,0)",
                            outline: "1px solid #fff",
                          },
                        }}
                      >
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              marginRight: "-40px",
                            },
                          }}
                        >
                          {coures.terms &&
                            coures.terms.map((cd, index) => (
                              <TimelineItem
                                sx={{ minHeight: "16px" }}
                                key={cd.term_id}
                              >
                                <>
                                  <TimelineSeparator>
                                    {(() => {
                                      if (coures.active_term > cd.term_id) {
                                        return (
                                          <>
                                            <TimelineDot
                                              sx={{
                                                margin: "7.5px 0",
                                                padding: "4px",
                                                backgroundColor: "#50cd89",
                                              }}
                                            />
                                          </>
                                        );
                                      } else if (
                                        coures.active_term < cd.term_id
                                      ) {
                                        return (
                                          <>
                                            <TimelineDot
                                              sx={{
                                                margin: "7.5px 0",
                                                padding: "4px",
                                              }}
                                              variant="outlined"
                                            />
                                          </>
                                        );
                                      } else if (
                                        coures.active_term == cd.term_id
                                      ) {
                                        return (
                                          <>
                                            <TimelineDot
                                              sx={{
                                                margin: "7.5px 0",
                                                padding: "4px",
                                                borderColor: "#50cd89",
                                              }}
                                              variant="outlined"
                                            />
                                          </>
                                        );
                                      }
                                    })()}
                                    <TimelineConnector
                                      sx={{ height: "10px!important" }}
                                    />
                                  </TimelineSeparator>
                                  <TimelineContent
                                    sx={{
                                      fontSize: "0.7rem",
                                      width: "250px!important",
                                    }}
                                  >
                                    {cd.term_name}
                                  </TimelineContent>
                                </>
                              </TimelineItem>
                            ))}
                        </Timeline>
                      </Box>
                    </Paper>
                    <center> <Button
                variant="contained"
             
          
                sx={{
                  mt:-72,
                  background: "#35709D",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "40%",
                 
                  "&:hover": {
                    background: "#35709D",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "8px",
                    textTransform: "none",
                    width: "40%",
                   
                  },
                }}
              >
               Unlock{" "}
              </Button>
              </center>
                    </Box>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
   
    </>
               
  );
};
export default TryalDashboard;
// export default TryalDashboard;
