import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  sesction: {
   
    "@media (max-width: 700px)": {
   marginBottom:"10px",

    },
  },
  boxsesction: {
    marginBottom:"20px !important",
    "@media (max-width: 700px)": {
   marginBottom:"10px",
   marginLeft: "45px !important",


    },
  },
  lastimag:{
    marginTop: "-9px !important",
  
    "@media (max-width: 700px)": {
    
      marginLeft: "99px !important",
      marginTop: "10px !important",
      marginBottom:"10px",
   
       },
  },
  sectiononfont: {
    fontSize: "42px !important",
    "@media (max-width: 700px)": {
      fontSize: "25px !important",
    }
  },
  suhedulebutton: {
    marginLeft: "-44px!important",
    "@media (max-width: 700px)": {
      marginLeft: "21% !important",
    },
  },
  sussbutton: {
    width: "206px",
    "@media (max-width: 700px)": {
      marginLeft: "21% !important",
      width: "232px",
    },
  },
  sussbuttontwo: {
    width: "206px",
    "@media (max-width: 700px)": {
      marginLeft: "0% !important",
      width: "232px",
    },
  },
  reasons: {
    fontSize: "25px!important",
  },
  tbalehadding: {
    fontSize: "20px!important",
  },
  carbs: {
    color: "#008ae6",
  },
  infobox: {
    backgroundColor: "#f2f2f2",
  },
  sussesstroyimag: {
    width: "360px !important",
    height: "225px !important",
    "@media (max-width: 700px)": {
      width: "380px !important",
    },
  },
  seconbuttonprv: {
    marginTop: "-283px !important",
    marginLeft: "-66px !important",
    "@media (max-width: 700px)": {
      marginTop: "-97px !important",
      marginLeft: "42px !important",
    },
  },
  seconbuttonnext: {
    float: "right",
    marginTop: "-144px !important",
    marginRight: "-44px !important",
    "@media (max-width: 700px)": {
      marginRight: "31px !important",
      marginTop: "-54px !important",
    },
  },
  coreimage: {
    width: "58px !important",
    height: "64px",
    "@media (max-width: 700px)": {
      width: "78px !important",
      height: "64px",
    },
  },
  coretwoimage: {
    width: "58px  !important",
    height: "57px",
    "@media (max-width: 700px)": {
      width: "78px !important",
      height: "64px",
    },
  },
  coretext: {
    "@media (max-width: 700px)": {
      fontSize: "25px !important",
      textAlign: "center",
    },
  },
  coresection: {
    "@media (max-width: 700px)": {
      marginLeft: "20% !important",
    },
  },
  infocurriculm: {
    marginLeft: "122px !important",
    marginRight: "122px !important",
    "@media (max-width: 700px)": {
      marginLeft: "40px !important",
      marginRight: "40px !important",
      fontSize: "20px !important",
    },
  },
  infocurriculmtwos: {
    marginLeft: "80px !important",
    marginRight: "80px !important",
    "@media (max-width: 700px)": {
      marginLeft: "10px !important",
      marginRight: "10px !important",
      fontSize: "20px !important",
    },
  },

  curriclumimg: {
    height: "64px",
  },
  infocurriculmtext: {
    marginLeft: "56px !important",
    marginRight: "56px !important",
    fontSize: "14px !important",
    width: "373px !important",
    "@media (max-width: 700px)": {
      marginLeft: "20px !important",
      marginRight: "20px !important",
      width: "280px !important",
    },
  },
  infocurriculmtexttwo: {
    marginLeft: "32px !important",
    marginRight: "32px !important",
    "@media (max-width: 700px)": {
      marginLeft: "0px !important",
      marginRight: "0px !important",
    },
  },
  getredytext: {
    "@media (max-width: 700px)": {
      fontSize: "35px !important",
    },
  },
}));
