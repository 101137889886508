import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Dashborednav from "../Navbar/Dashborednav";
import Withoutenrollment from "./Withoutenrollment";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Dashboard from "../Dashboard/Dashboard";
const Myaccount = () => {
  const [user, setUser] = useState("");
  const [enrooll, setEnrooll] = useState("");
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const [program, setData] = useState("");
  const programcount = localStorage.getItem("count");

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const sendData = {
    user_id: localStorage.getItem("user_id"),
  };

  useEffect(() => {
    LoaderOpen();
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
    var enrooll = localStorage.getItem("count");
    setEnrooll(enrooll);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fetchenrolments`,
        JSON.stringify(sendData),
        options,
        { withCredentials: true }
      )
      .then((result) => {
        setData(result.data);
        LoaderClose();
      });
  }, []);
  const handleClickOpen = (
    enrol_id,
    category,
    program_id,
    term,
    program_name,
    program_code
  ) => {
    //console.log(enrol_id, category);
    window.localStorage.setItem("enrol_id", enrol_id);
    window.localStorage.setItem("category", category);
    window.localStorage.setItem("program_id", program_id);
    window.localStorage.setItem("term", term);
    window.localStorage.setItem("program_name", program_name);
    window.localStorage.setItem("program_code", program_code);
    navigate(`/Dashboard`);
  };
  if (user == null) {
    navigate(`/Login`);
  }
  const LoaderOpen = () => {
    setOpen(true);
  };
  const LoaderClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dashborednav />
      {(() => {
        if (programcount == "2" || programcount == "3") {
          return (
            <>
              <Box sx={{ backgroundColor: "#f3f6f9", pb: 10 }}>
                <Box sx={{ pt: 8 }}>
                  <Container fixed>
                    <Box>
                      <Typography
                        sx={{ textAlign: "center", fontSize: "1.5rem" }}
                      >
                        CHOOSE YOUR DASHBOARD
                      </Typography>
                    </Box>
                    <Grid item lg={12} container sx={{ ml: { lg: 2 } }}>
                      {program &&
                        program.map(
                          ({
                            enrol_id,
                            program_id,
                            category,
                            term,
                            program_name,
                            program_code,
                          }) => {
                            return (
                              <Grid
                                item
                                lg={6}
                                xs={12}
                                sx={{
                                  maxWidth: { lg: "46.333333% !important " },
                                  mx: { lg: 2 },
                                  mb: { xs: 3 },
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundImage:
                                      "linear-gradient(0deg, rgba(21,101,192,0.8), rgba(21,101,192,0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                                    backgroundSize: "cover",
                                    height: "362px",
                                  }}
                                >
                                  <Box sx={{ pt: 18 }}>
                                    <Typography
                                      sx={{
                                        fontSize: "1.1rem",
                                        color: "#fff",
                                        textAlign: "center",
                                        mx: { lg: 10 },
                                        fontWeight: "bold",
                                        marginBottom: "0.5rem !important",
                                        height: "62px",
                                      }}
                                    >
                                      {program_name}
                                    </Typography>
                                    <center>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          textTransform: "none",
                                          background: "#f8f9fa",
                                          color: "#000",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textTransform: "none",
                                            background: "#f8f9fa",
                                            color: "#000",
                                            cursor: "pointer",
                                          },
                                        }}
                                        onClick={() =>
                                          handleClickOpen(
                                            enrol_id,
                                            category,
                                            program_id,
                                            term,
                                            program_name,
                                            program_code
                                          )
                                        }
                                      >
                                        Explore Now
                                      </Button>
                                    </center>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          }
                        )}
                    </Grid>
                  </Container>
                </Box>
              </Box>
            </>
          );
        } else {
          return (
            <>
              <Box sx={{ backgroundColor: "#f3f6f9", pb: 10, mt: -4 }}>
                {" "}
                <Dashboard />
              </Box>
            </>
          );
        }
      })()}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Myaccount;
