import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box ,Paper} from "@mui/material";
import "../../../../Assets/css/Term-project/quiz.css"
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useState,useEffect } from "react";

function Output(props){
  const {id}=useParams();
  const [counter,setCounter]=useState(10)
  useEffect(()=>{
    const intervalId = setInterval(() => {
      setCounter(count => count - 1); // Decrement count by 1
    }, 1000); // Update count every 1000ms (1 second)

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
   
  },[])
  const[notClicked,setClicked]=useState(true)
  // //console.log(window.location.pathname,"urlllll")
  
    setTimeout(()=>{
      if(window.location.pathname=="/Term-projects-quiz"){
        window.location=`/Term-projects/${localStorage.getItem("backUrl")}`
      }
     

    },10000)
  
    
    
    return(
        <>
        
        <Box sx={{
            display:"grid",
            placeItems:"center",

        }}>
           <Paper
           elevation={2}
           sx={{
            width:{lg:"60vw",xs:"80%",sm:"60vw",md:"60vw"},
            mr:6,
           
           }}           
           >
            
           <TableContainer 
           sx={{ height: "400px",
           overflowY: "scroll",
           "&::-webkit-scrollbar": {
             width: "0.2em",
           },
           "&::-webkit-scrollbar-track": {
             boxShadow: "inset 0 0 6px hsl(206, 92%, 94%)",
             webkitBoxShadow: "inset 0 0 6px hsl(206, 92%, 94%)",
           },
           "&::-webkit-scrollbar-thumb": {
             backgroundColor: "hsl(206, 92%, 94%)",
             outline: "1px solid hsl(206, 92%, 94%)",
           },}}
           
           >
           <Table   aria-label="simple table">
             <TableHead  sx={{

                position: "sticky",
                backgroundColor: "#002e60",
                
                top: 0,
             }}>
               <TableRow  >
               <TableCell sx={{color: "#fff !important"}} >S.No</TableCell>
                 <TableCell align="center" sx={{fontSize:"15px",color: "#fff !important"}}>Question</TableCell>
                 <TableCell sx={{color: "#fff !important"}} >Your Answer</TableCell>
                 <TableCell sx={{color: "#fff !important"}} >Correct Answer</TableCell>
                 <TableCell sx={{color: "#fff !important"}} >Result</TableCell>
               </TableRow>
             </TableHead>
             
               
             <TableBody>
             {props.question.map((row,i) => (
               <TableRow
               sx={{
                backgroundColor:  "hsl(206, 92%, 94%)",
               }}
                 
                 
               >
                 <TableCell  >
                   {i+1}
                 </TableCell>
                 <TableCell width={300}  >
                   {row.question_txt}
                 </TableCell>
                 <TableCell  >{row.your_answer}</TableCell>
                 <TableCell >{row.correct_answer}</TableCell>
                 <TableCell >{row.result}</TableCell>
               </TableRow>
             ))}
           </TableBody>
             
           </Table>
         </TableContainer>
            </Paper> 
           
        </Box>
        <Box sx={{mt:1,mb:1,ml:8}} >
              
               You will automatically get to the home page in {counter} sec 
              

        </Box>
        <Box sx={{mt:1,mb:2,ml:8}} >
               <Link  to={`/Term-projects/${localStorage.getItem("backUrl")}`} style={{textDecoration:"none"}}>
               Click here to go the the home page 
               </Link>

        </Box>
        
        </>
    )
}

export default Output