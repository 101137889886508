
import React from "react";
// import Competitioncorner from '../Components/Dashboard/Competition-corner/competition-corner';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from 'react';
import SkeletonL from "../Components/Skeleton/Withlogin";

const Competitioncorner = lazy(() => import('../Components/Dashboard/Competition-corner/competition-corner')); 
const AuthCompetitioncorner = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL/>}>
  <Competitioncorner/>
</Suspense> : <Navigate to="/Login" />;
}
export default AuthCompetitioncorner;
