
import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BatchTransfer from "../Components/Dashboard/Manage-My-Program/BatchTransfer";
const AuthBatchTransfer = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <BatchTransfer /> : <Navigate to="/Login" />;
}
export default AuthBatchTransfer;
