
import React from "react";
// import { Suspense, lazy } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import CareerCounselling from "../Components/Dashboard/CareerCounselling";

// const CareerCounselling = lazy(() => import('../Components/Dashboard/CareerCounselling'));
const AuthCareercounselling = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <CareerCounselling /> : <Navigate to="/Login" />;
}
export default AuthCareercounselling;
