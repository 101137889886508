import Carousel from "react-spring-3d-carousel-2";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import Styles from "./Card.module.css";
import { Style } from "@mui/icons-material";
export default function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(6);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [autoPlay] = useState(true);
  const [interval] = useState(4);
const[goToSlideDelay] = useState(200);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
    className={Styles.cardbox}
    
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        goToSlideDelay={goToSlideDelay}
        autoPlay={autoPlay}
        interval={interval}
        animationConfig={config.gentle}
      />
    </div>
  );
}
