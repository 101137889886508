import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  programcard:{
    display:"flex",
    flexDirection:"column",
    width:"358px",
    boxShadow:"0 0 20px 4px rgb(0 0 0 / 10%)",
    borderRadius:"10px",
    overflow: "hidden",
    margin: "15px",
    "@media (max-width: 1100px)": {
    
      flexDirection:"column",
      width:"95%",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius:"10px",
      overflow: "hidden",
      margin: "46px 1px",
    },    

  },
  progtitle:{
    fontSize:"22px",
    lineHeight:"35px",
    fontWeight: "bold",

    marginBottom:"15px",
    marginTop:"15px",
    "@media (max-width: 700px)": {
      fontSize:"17px",
      lineHeight:"35px",
      fontWeight: "400",

      marginBottom:"15px",
      marginTop:"15px",
      height:"48px",
    },
},
}));
