import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from 'react';
// import CareerLaunchpad from "../Components/Dashboard/CareerLaunchpad";
import SkeletonL from "../Components/Skeleton/Withlogin";
const CareerLaunchpad = lazy(() => import('../Components/Dashboard/CareerLaunchpad'));
const AuthCareerlaunchpad = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL/>}>
  <CareerLaunchpad/>
</Suspense> : <Navigate to="/Login" />;
}
export default AuthCareerlaunchpad;
