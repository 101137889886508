import React, { useState, useEffect } from "react";
import { Typography, Button, Box, Paper, Grid } from "@mui/material";
import { Widget } from "@typeform/embed-react";
import LoginDashboard from "../Navbar/LoginDashboard";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Sidbar from "./Sidbar";
const SuccessStory = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    //console.log(user_id, enrol_id, category);
  }, []);
  if (category == 2) {
    return (
      <>
        <LoginDashboard />
        <Box sx={{ backgroundColor: "#f3f6f9", pb: 7,px:{xs:2} }}>
          <Box sx={{ pt: 8 }}>
            <Grid container>
            <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
                <Sidbar />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Link
                  to="/Dashboard"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Box display="flex">
                    <ArrowBackIcon sx={{ fontWeight: "bold" }} />
                    <Typography
                      sx={{ ml: 2, fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {" "}
                      Back to Dashboard
                    </Typography>
                  </Box>
                </Link>
                <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.6rem" }}>
                    Success Story
                  </Typography>
                  <Typography sx={{ fontSize: "1.3rem" }}>
                    Let's feature your successs story in front of world
                  </Typography>
                </Box>
                <Box>
                  <Widget
                    id="GPjBDGcC"
                    style={{ width:{lg:"90%",xs:"100%"} , height: "517px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <LoginDashboard />
        <Box sx={{ backgroundColor: "#f3f6f9", pb: 7,px:{xs:2} }}>
          <Box sx={{ pt: 8 }}>
            <Grid container>
            <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
                <Sidbar />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Link
                  to="/Dashboard"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Box display="flex">
                    <ArrowBackIcon sx={{ fontWeight: "bold" }} />
                    <Typography
                      sx={{ ml: 2, fontWeight: "bold", fontSize: "1rem" }}
                    >
                      {" "}
                      Back to Dashboard
                    </Typography>
                  </Box>
                </Link>
                <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.6rem" }}>
                    Success Story
                  </Typography>
                  <Typography sx={{ fontSize: "1.3rem" }}>
                    Let's feature your successs story in front of world
                  </Typography>
                </Box>
                <Box>
                  <Widget
                    id="sNt7RWWj"
                    style={{ width:{lg:"90%",xs:"100%"} , height: "517px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
};
export default SuccessStory;
