import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  Card,
  CardContent,
  Backdrop,
  FormControl,
} from "@mui/material";

import Testingnavbar from "../Components/Navbar/testinnav";
import "../Assets/css/resource.css";
import Footer from "../Components/Footer/Footer";

function Resourcescthankyou() {
  const app = localStorage.getItem("user_id");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

},[]);
  return (
    <>
      <Testingnavbar />
      <Box
        sx={{
          pt: app
            ? { lg: 2, sm: 2, md: 2, xs: 3 }
            : { lg: 8, sm: 5, md: 10, xs: 11 },
          mt: app
            ? { lg: 0, sm: 0, md: 0, xs: 0 }
            : { lg: 0, sm: 0, md: 0, xs: 0 },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mb: 2,
            height:{lg: "510px",md: "85vh",sm:"850px",xs:"100vh"},
          }}
        >
          <Container fixed>
            <Grid lg={12} xl={12} sm={12} md={12} container sx={{ pt:{lg:5} }}>
              <Grid xl={6} lg={6} sm={12} md={12} xs={12} sx={{}}>
                <Box sx={{ my: 2, display: "flex",
           
            justifyContent: "center", }}>
                  <Box
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      background: "#fff",
                      p: 3,
                      width:{lg: "380px",md: "580px"},
                      ml:{lg:0,md:0,sm:0,xs:0},
                   
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "60px",
                          fontFamily: "Poppins !important",
                          fontWeight: "bold",
                          color: "#000",
                          marginBottom:"0.3rem !important",
                          mt: 2,
                        }}
                      >
                        Awesome!
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "41px",
                          fontFamily: "Poppins !important",
                          fontWeight: "bold",
                          color: "#000",
                      lineHeight:"1.1",
                        }}
                      >
                        You have taken a step in the RIGHT DIRECTION.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontFamily: "Poppins !important",

                          color: "#000",
                          mt: 2,
                        }}
                      >
                        Here’s how you can explore & download more resources on
                        our page.
                      </Typography>
                    </Box>
                    <Box>
                    <Button
                          variant="contained"
                          type="submit"
                          sx={{ textTransform: "none" }}
                          href={localStorage.getItem("pagelink")}
                        >
                          {" "}
                          Click here
                        </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xl={6} lg={6} sm={12} md={12} xs={12}>
                <Box sx={{ mt:{lg:10,md:10,sm:0,xs:0} }}  className="videofrem">
                  <iframe src={localStorage.getItem("youtublink")} width="560" height="305"></iframe>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Footer />
    </>
  );
}
export default Resourcescthankyou;
