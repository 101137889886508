import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
} from "@mui/material";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Iitprogram } from "../../data/Iitprogram";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import "../../Assets/css/ProgramPage/style.css";
import useStyles from "../../Assets/js/ProgramPage/style";
import AOS from "aos";
import "aos/dist/aos.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Rcta from "../../Components/cta/request_a_call_back";
const currencies = [
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Business Management",
    label: "Business Management",
  },
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "789px",
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CcbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));
const McbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,

  onClose: PropTypes.func.isRequired,
};

function CcDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 1,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

CcDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function McDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 1,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

McDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function ProgramSectionTwo() {
  const { id } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState();
  const [program, setProgram] = useState("");
  const [applynow, setApplynow] = useState(false);

  const handleApplyOpen = () => {
    setApplynow(true);
  };
  const handleApplyclose = () => {
    setApplynow(false);
  };
  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  useEffect(() => {
    let ans = Iitprogram.filter((obj) => {
      return obj.id == id;
    });
    //console.log(ans);
    setData(ans);
    AOS.init({ duration: 2000 });
  }, [id]);
  const [open, setOpen] = useState(false);
  const [info, setinfo] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClickOpen = (projectinfo) => {
    //console.log(projectinfo);
    setinfo(projectinfo);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  const classes = useStyles();
  const sliderRef = useRef();
  const wpsliderRef = useRef();
  const wpgotoNext = () => {
    wpsliderRef.current.slickNext();
  };
  const wpgotoPrev = () => {
    wpsliderRef.current.slickPrev();
  };
  const fssliderRef = useRef();
  const fcgotoNext = () => {
    fssliderRef.current.slickNext();
  };
  const fcgotoPrev = () => {
    fssliderRef.current.slickPrev();
  };
  const deskgotoNext = () => {
    sliderRef.current.slickNext();
  };
  const deskgotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1124,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };

  const [Ccbopen, CcsetOpen] = useState(false);
  const CchandleClickOpen = () => {
    CcsetOpen(true);
    setOpen(false);
  };
  const CcbhandleClose = () => {
    CcsetOpen(false);
  };
  const [Mcopen, McsetOpen] = React.useState(false);
  const MchandleClickOpen = () => {
    McsetOpen(true);
  };
  const McbhandleClose = () => {
    McsetOpen(false);
  };

  return (
    <>
      {data &&
        data.map((Iitprogram) => (
          <Box sx={{ pt: 5 }}>
            <Container fixed>
              <Box sx={{}} >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    fontSize: {
                      lg: "38px",
                      sm: "38px",
                      md: "38px",
                      xs: "35px",
                    },
                    fontWeight: "bold",
                    pb: 6,
                    textAlign: {
                      lg: "left",
                      md: "center",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  Faculty & Mentors
                </Typography>

                <Grid
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  sx={{ mb: { lg: 5, sm: 3, md: 3, xs: 3 }, mr: 3 }}
                >
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    style={{ overflowX: "hidden" }}
                  >
                    {Iitprogram.faculty &&
                      Iitprogram.faculty.map((facultyinfo) => (
                        <Grid
                          lg={4}
                          xs={4}
                          sm={4}
                          md={4}
                          sx={{ ml: { lg: 0, sm: 15, md: 4, xs: 0 } }}
                        >
                          <Box
                            sx={{
                              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.65) 70%, #000 89%), url(${facultyinfo.facultyimag})`,
                              backgroundSize: "350px",
                              height: "390px",
                              width: "350px",
                            }}
                          >
                            <Box sx={{ paddingTop: "87%" }}>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{ fontSize: "25px", color: "#fff" }}
                              >
                                {facultyinfo.name}
                              </Typography>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{ fontSize: "25px", color: "#fff" }}
                              >
                                {facultyinfo.college}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Slider>
                </Grid>
                <Button
                  onClick={() => deskgotoPrev()}
                  sx={{
                    mt: { lg: -25, md: -64, sm: -64, xs: -64 },
                    ml: { lg: -7.5, md: -6, sm: -2, xs: -4 },
                    display: { lg: "none" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <ArrowBackIosIcon></ArrowBackIosIcon>
                </Button>
                <Button
                  onClick={() => deskgotoNext()}
                  sx={{
                    float: "right",
                    mt: { lg: -25, md: -34, sm: -33, xs: -33 },
                    mr: { lg: -5.4, md: -4, sm: -5.4, xs: -5 },
                    display: { lg: "none" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <ArrowForwardIosIcon></ArrowForwardIosIcon>
                </Button>
              </Box>
              {/*  Mobile version For Faculty section */}

              {/* End Mobile version For Faculty section */}
              {/* <center>
                <Button
                  variant="contained"
                  sx={{
                    mb: 5,
                    mt: { lg: 4, sm: 1, md: 1, xs: 1 },
                    ml: { lg: 0, md: 0, xs: 0, sm: 2 },
                  }}
                  className={classes.DownloadBrochureone}
                  onClick={handleApplyOpen}
                >
                  Get more info
                </Button>
              </center> */}
              <Box
                sx={{
                  display: { xs: "none", lg: "block", sm: "none", md: "none" },
                }}
                
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ fontSize: "38px", fontWeight: "bold", pb: 6 }}
                >
                  {Iitprogram.projectsectionhadding}
                </Typography>
                <Box sx={{ mx: 3 }}>
                  <Grid item lg={12} container>
                    {Iitprogram.project &&
                      Iitprogram.project.map((projectinfo) => (
                        <>
                          <Grid item lg={3}>
                            <Box sx={{ height: "55px" }}>
                              <center>
                                {" "}
                                <CardMedia
                                  component="img"
                                  image={projectinfo.projectimg}
                                  alt="Goole"
                                  sx={{
                                    maxWidth: "20%",
                                    height: "35px",
                                    my: "auto",
                                    objectFit: "unset",
                                  }}
                                 
                                />
                              </center>
                            </Box>
                            <Box>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "17px",
                                  color: "#09142f",
                                  height: "42px",
                                  mb: 4,
                                }}
                              >
                                {projectinfo.projecthadding}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#6c757d",
                                  height: "57px",
                                  mb: 4,
                                  px: 3,
                                }}
                              >
                                {projectinfo.sortdescription}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h4"
                                component="div"
                                align="center"
                                sx={{
                                  fontSize: "14px",
                                  color: "#007bff",
                                  mb: 4,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleClickOpen(projectinfo)}
                              >
                                Learn More
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Box>
              </Box>
              {/*  Mobile version For Faculty section */}
              <Box
                sx={{
                  display: {
                    xs: "block",
                    lg: "none",
                    sm: "block",
                    md: "block",
                  },
                }}
                
              >
                <Typography
                  variant="h4"
                  component="div"
                  textAlign="center"
                  sx={{
                    fontSize: { sm: "38px", md: "38px", xs: "35px" },
                    fontWeight: "bold",
                    pb: 6,
                    mt: { sm: 3, md: 3, xs: 2 },
                  }}
                >
                  {Iitprogram.projectsectionhadding}
                </Typography>
                <Box>
                  <Grid xs={12} md={12} sm={12} container>
                    <Slider
                      ref={wpsliderRef}
                      {...settings}
                      style={{ overflowX: "hidden" }}
                    >
                      {Iitprogram.project &&
                        Iitprogram.project.map((projectinfo) => (
                          <>
                            <Grid xs={12} sm={12} md={12} sx={{ ml: 1 }}>
                              <Box>
                                <center>
                                  {" "}
                                  <CardMedia
                                    component="img"
                                    image={projectinfo.projectimg}
                                    alt="Goole"
                                    sx={{
                                      maxWidth: "20%",
                                      height: "55px",
                                      mb: 4,
                                      objectFit: "unset",
                                    }}
                                  />
                                </center>
                              </Box>
                              <Box>
                                <Typography
                                  variant="h4"
                                  component="div"
                                  align="center"
                                  sx={{
                                    fontSize: "25px",
                                    color: "#09142f",
                                    height: "42px",
                                    mb: 4,
                                  }}
                                >
                                  {projectinfo.projecthadding}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="h4"
                                  component="div"
                                  align="center"
                                  sx={{
                                    fontSize: "18px",
                                    color: "#6c757d",
                                    height: "57px",
                                    mb: 4,
                                    px: 3,
                                  }}
                                >
                                  {projectinfo.sortdescription}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="h4"
                                  component="div"
                                  align="center"
                                  sx={{
                                    fontSize: "18px",
                                    color: "#007bff",
                                    mb: 4,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleClickOpen(projectinfo)}
                                >
                                  Learn More
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ))}
                    </Slider>
                  </Grid>
                  <Button
                    sx={{
                      mt: { md: -47, sm: -40, xs: -40 },
                      ml: { lg: -7.5, md: -6, sm: -5, xs: -4 },
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    onClick={() => wpgotoPrev()}
                  >
                    <ArrowBackIosIcon></ArrowBackIosIcon>
                  </Button>
                  <Button
                    sx={{
                      float: "right",
                      mt: { md: -24, sm: -21, xs: -21 },
                      mr: { md: -8, sm: -5.4, xs: -5 },
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    onClick={() => wpgotoNext()}
                  >
                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </Button>
                </Box>
              </Box>

              {/* End Mobile version For Faculty section */}

              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby={info.id}
                open={open}
                info={info}
              >
                <BootstrapDialogTitle
                  id={info.id}
                  onClose={handleClose}
                ></BootstrapDialogTitle>

                <DialogContent sx={{ p: 0 }}>
                  <Grid item lg={12} container>
                    <Grid item lg={6} xs={12} sx={{ my: "auto" }}>
                      <CardMedia
                        component="img"
                        image={info.projectimg}
                        alt="Goole"
                        sx={{
                          maxHeight: "305px",
                          width: "90%",
                          objectFit: "unset",
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Box sx={{ py: 10 }}>
                        <Box>
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              mb: 2,
                            }}
                          >
                            {info.projecthadding}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "14px",
                              color: "#09142f",
                              mb: 2,
                            }}
                          >
                            {info.sortdescription}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "14px",
                              color: "#09142f",
                              mb: 2,
                            }}
                          >
                            {info.longdescription}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
              </BootstrapDialog>
            </Container>
            <Box
              sx={{
                display: { xs: "none", lg: "block", sm: "block", md: "block" },
              }}
            >
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  height: { lg: "575px", sm: "755px", md: "400px" },
                  mb: 6,
                }}
              >
                <Container fixed>
                  <Box sx={{ ml: -1 }} >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontSize: "34px",
                        color: "#fff",
                        mb: 1,
                        fontWeight: "bold",
                        pt: 6,
                      }}
                    >
                      Share your achievements
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontSize: "22px",
                        color: "#fff",
                        mb: 2,
                        fontWeight: 400,
                      }}
                    >
                      with your professional network
                    </Typography>
                  </Box>
                  <Box>
                    <Grid lg={12} md={12} sm={12} container sx={{}}>
                      <Grid
                        lg={6}
                        md={6}
                        sm={12}
                        sx={{ mt: 6, ml: { xs: -1, md: -1, lg: -1 } }}
                        
                      >
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "22px",
                              color: "#fff",
                              mb: 4,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Globally recognized certifications
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "22px",
                              color: "#fff",
                              mb: 4,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Unique certification ID
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "22px",
                              color: "#fff",
                              mb: 3,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Lifetime validity
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid lg={5} sm={12} md={6} sx={{ ml: { lg: 0, md: 1 } }}>
                        {/* <Box display="flex">
                          <Box sx={{ position: "relative" }}>
                            <CropFreeIcon />
                          </Box>
                          <Box sx={{ position: "relative", ml: 36 }}>
                            <CropFreeIcon />
                          </Box>
                        </Box> */}
                        <Box sx={{ mt: { lg: -6, md: -14 } }}>
                          <Box className="container">
                            <CardMedia
                              component="img"
                              image={Iitprogram.cc}
                              alt="Goole"
                              sx={{
                                height: "350px",
                                objectFit: "unset",
                                width: { lg: "100%", md: "100%", sm: "100%" },
                                cursor: "zoom-in",
                                objectFit: "unset",

                                cursor: "zoom-in",
                              }}
                              onClick={CchandleClickOpen}
                            />
                            <Box className="overlay">
                              <Typography
                                className="text"
                                onClick={CchandleClickOpen}
                                sx={{ cursor: "pointer" }}
                              >
                                Click to Enlarge
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </Box>
            {/* End Mobile version For certifcte section */}
            <Box
              sx={{
                display: { xs: "block", lg: "none", sm: "none", md: "none" },
              }}
            >
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  height: "630px",
                  mb: 6,
                }}
              >
                <Container fixed>
                  <Box sx={{ mx: 3 }} >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontSize: "25px",
                        color: "#fff",
                        mb: 1,
                        fontWeight: "bold",
                        pt: 6,
                      }}
                    >
                      Share your achievements
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontSize: "18px",
                        color: "#fff",
                        mb: 2,
                        fontWeight: 400,
                      }}
                    >
                      with your professional network
                    </Typography>
                  </Box>
                  <Box>
                    <Grid item lg={12} container sx={{ mx: 3 }}>
                      <Grid item xs={12} sx={{ mt: 6 }}>
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "18px",
                              color: "#fff",
                              mb: 4,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Globally recognized certifications
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "18px",
                              color: "#fff",
                              mb: 4,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Unique certification ID
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <CheckIcon
                            sx={{ color: "#fff", fontWeight: "blod" }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              fontSize: "18px",
                              color: "#fff",
                              mb: 3,
                              fontWeight: "bold",
                              ml: 3,
                            }}
                          >
                            Lifetime validity
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Box sx={{ position: "relative" }}>
                            <CropFreeIcon />
                          </Box>
                        </Box>
                        <Box>
                          <CardMedia
                            component="img"
                            image={Iitprogram.cc}
                            alt="Goole"
                            sx={{
                              width: "312px",
                              height: "250px",
                              objectFit: "unset",
                              marginTop: "-26px",
                              mb: 6,
                              cursor: "zoom-in",
                            }}
                            onClick={CchandleClickOpen}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </Box>

            {/* End Mobile version For certifcte section */}

            <Box
              sx={{
                mt: -16,
                mb: 3,
                display: { xs: "none", lg: "block", sm: "none", md: "none" },
              }}
            >
              <Container fixed>
                <Grid item lg={12} container>
                  <Grid item lg={1.5}></Grid>
                  <Grid
                    item
                    lg={3}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      maxWidth: "21%!important",
                      ml: 5,
                    }}
                   
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "55px",
                          color: "#09142f",
                          mb: 6,
                          pt: 5,
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        500+
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "13.5px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        {" "}
                        {Iitprogram.certifictetstate}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      maxWidth: "21%!important",
                      ml: 3,
                    }}
                   
                  >
                    <Box>
                      <Box display="flex" sx={{ px: 4 }}>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            fontSize: "55px",
                            color: "#00487f",
                            mb: 6,
                            pt: 5,
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          {Iitprogram.growt}
                        </Typography>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/growth-graph-1.png"
                          alt="Goole"
                          sx={{
                            objectFit: "unset",
                            height: "32px",
                            width: "32px",
                            pt: 6,
                            pl: 2,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "13.5px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        Average <b>salary hike</b> after career transition.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      maxWidth: "21%!important",
                      ml: 3,
                    }}
                   
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "55px",
                          color: "#09142f",
                          mb: 6,
                          pt: 5,
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        2-in-1
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "13.5px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        Get certified from both{" "}
                        <b>Accredian and IIT Guwahati</b>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={1.5}></Grid>
                </Grid>
              </Container>
            </Box>
            {/*  Mobile version For certifcte sectiontwo */}
            <Box
              sx={{
                mb: 3,
                display: { xs: "block", lg: "none", sm: "block", md: "block" },
              }}
            >
              <Container fixed>
                <Grid xs={12} sm={12} md={12} container>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      mb: 4,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "55px",
                          color: "#09142f",
                          mb: 6,
                          pt: 5,
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        500+
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "25px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        {Iitprogram.certifictetstate}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      mb: 4,
                    }}
                  >
                    <Box>
                      <Box display="flex" sx={{ justifyContent: "center" }}>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            fontSize: "55px",
                            color: "#00487f",
                            mb: 6,
                            pt: 5,
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          {Iitprogram.growt}
                        </Typography>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/growth-graph-1.png"
                          alt="Goole"
                          sx={{
                            objectFit: "unset",
                            height: "32px",
                            width: "32px",
                            pt: 6,
                            pl: 2,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "25px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        Average <b>salary hike</b> after career transition.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #007bff",
                      borderRadius: "0.5rem",
                      mb: 4,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "55px",
                          color: "#09142f",
                          mb: 6,
                          pt: 5,
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        2-in-1
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{
                          fontSize: "25px",
                          color: "#09142f",
                          mb: 9,
                          fontWeight: 400,
                          textAlign: "center",
                          mx: 4.6,
                        }}
                      >
                        Get two Certifications - <b>Completion & Merit</b>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>

            {/* End Mobile version For certifcte section */}

            <CcbDialog
              onClose={CcbhandleClose}
              aria-labelledby="customized-dialog-title"
              open={Ccbopen}
            >
              <CcDialogTitle
                id="customized-dialog-title"
                onClose={CcbhandleClose}
              ></CcDialogTitle>
              <CardMedia
                component="img"
                image={Iitprogram.cc}
                alt="Goole"
                sx={{
                  objectFit: "unset",
                }}
              />
            </CcbDialog>

            <Rcta open={applynow} handleClose={handleApplyclose} />
          </Box>
        ))}
    </>
  );
}
