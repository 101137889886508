
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import Moment from "react-moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CachedIcon from '@mui/icons-material/Cached';
import Swal from 'sweetalert2';


import {
  faRandom,
  faLightbulb,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
const TryalChallengeoftheday = (props) => {
  let navigate = useNavigate();
  const dateToFormat = new Date();
  const [user, setUser] = useState("");
  const [datas, setData] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [ansisShown, setIsShownans] = useState(false);
  const [insaidtvpop, setInsaidtvpop] = useState();
  const [info, setinfo] = useState(false);
  const [isShownbtn ,setIsShownbtn] = useState(true);
  const [isShownrbtn ,setIsShownrbtn] = useState(false);
  const [btndisabled ,setBtndisabled] = useState(false);
  const [coin, setCoindata] = useState("");
  const [cod ,setCod] = useState({cod_option: ""});
  const newcategory = localStorage.getItem("category");

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const anshandleClick = () => {
    setIsShownans(false);
    setIsShownbtn(true);
    setIsShownrbtn(false);
    setCod({cod_option: ""});
  };
  const handleClose = () => {
    setInsaidtvpop(false);
  };
  const handalChnage = (e) => {
    setCod({ ...cod, [e.target.name]: e.target.value });
  };

  const fetchdata = async () => {

    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchcod`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        category: localStorage.getItem("category"),
      },
    }).then((res) => {
      setData(res.data[0]);
      //console.log(res.data[0].shuffle);

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const sufflefetchdata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/codshuffle`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        category: localStorage.getItem("category"),
        scount:datas.shuffle,
      },
    }).then((res) => {
      setData(res.data[0]);

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const fetchcoindata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchstudentcoins`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      setCoindata(res.data);

    });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    category: localStorage.getItem("category"),
  };
  const CourseData = {
    enrol_id: localStorage.getItem("enrol_id"),
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    fetchdata();
    fetchcoindata();
    setUser(user_id);
  }, []);
  const shufflebtn = () => {
    sufflefetchdata();
    setCod({cod_option: ""});
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if(cod.cod_option == ""){
      Swal.fire({
        title: "Warning",
        text: "Plese select at least one option",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }else {
    setIsShownans((current) => !current);
    setIsShownbtn(false);
    setIsShownrbtn((current) => !current);
    setBtndisabled(true);
       const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        };
        if(datas.answer!=parseInt(cod.cod_option)){
          var point =0;
    
        }
        else{
          if(datas.difficulty==="Easy"){
            var  point = 5;
           }
           else if(datas.difficulty==="Medium"){
             var  point = 10;
           }
           else if(datas.difficulty==="Hard"){
             var  point = 15;
             }
        }
        const sandData = {
          question_id:datas.qid,
          cod_option: parseInt(cod.cod_option),
          difficulty:datas.difficulty,
          attempt:datas.attempt,
          answer:datas.answer,
          enrol_id: localStorage.getItem("enrol_id"),
          coins:coin,
          point:point,

        };
        axios
        .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/submitdata`, JSON.stringify(sandData), options,)
        .then((res) => {
         
          props.setShowcoins(true);
          //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
        });
      }
  }
  return (
    <>
     <form onSubmit={submitForm}>
      <Paper sx={{ py: 3, borderRadius: "15px", height:{lg:"504px",xs:"580px"}, mb:{xs:2}  }}>
        <Box  sx={{ mb: 1, px: 2,display:{lg:"flex",sm:"flex",md:"flex"} }}>
          <Box sx={{display:{xs:"flex",sm:"flex",md:"flex"}}}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.1rem",
              marginBottom: "0rem !important",
              width:{xs:180},
            }}
          >
            Challenge of the day
          </Typography>
          <Typography
            sx={{
              background: "#046582",
              color: "#fff",
              fontWeight: 600,
              width: "16%",
              pl: 1,
              fontSize: "0.95rem",
              borderRadius: 1,
              height: "24px",
              ml: 1.2,
              mt: 0.5,
              width:{xs:100},
            }}
          >
            <Moment date={dateToFormat} format="D-M-YYYY" />
          </Typography>
          </Box>

          <LightTooltip
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                         
          <Button
            variant="contained"
            sx={{
              background: "#00cc99",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
              borderRadius: "50px",
              textTransform: "none",
              height: "40px",
              ml:{lg:18,sm:25,md:10},
              mt:{lg:-1 ,xs:1,sm:-1} ,
              "&:hover": {
                background: "#00cc99",
                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                borderRadius: "50px",
                textTransform: "none",
                height: "40px",
              },
            }}
          >
            Click to compete{" "}
          </Button>
                      </LightTooltip>
        </Box>
        <Box
          sx={{
            boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
          }}
        >
          <Box sx={{ background: "#b3ccff", p: 1 }}>
            <Box sx={{ px: 1 }}>
              <Grid container sx={{ py: 2 }}>
                <Grid item lg={10} sm={10} md={10} xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize:{lg:"0.95rem",sm:"1rem",md:"1rem",xs:"0.95rem"},
                      marginBottom: "0rem !important",
                      height:{xs:"80px",lg:"70px"} ,
                      width:{xs:320,lg:520,sm:620,md:529} ,
                    }}
                  >
                    Q.{datas.question}
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} sm={2} md={2}>
                  <Badge
                    sx={{
                      background: "#50cd89",
                      color: "#fff",
                      width: "60px",
                      px: 1.2,
                      py: 0.4,
                      borderRadius: "50px",
                      ml:{lg:6,sm:6,md:6},
                    }}
                  >
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                      alt="Goole"
                      sx={{
                        width: "20px",
                        height: "20px",
                        objectFit: "unset",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "0.8rem",
                        marginBottom: "0rem !important",
                      }}
                    >
                      {" "}
                      {(() => {
                        if (datas.difficulty === "Easy") {
                          return <>+5</>;
                        } else if (datas.difficulty === "Medium") {
                          return <>+10</>;
                        } else if (datas.difficulty === "Hard") {
                          return <>+15</>;
                        }
                      })()}
                    </Typography>
                  </Badge>
                </Grid>
              </Grid>
            </Box>
          </Box>
          
          <Box sx={{ px: 2, py: 2 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="cod_option"
              onChange={handalChnage}
              value={cod.cod_option}
            >
                <Grid container >
                <Grid item lg={10} sm={10} md={10} xs={12}>
                
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize:{lg: "0.85rem",sm: "1rem",md: "1rem",xs:"0.85rem"},
                          marginBottom: "0rem ! important",
                        }}
                      >
                        {datas.option1}
                      </Typography>
                    }
                  />
                  </Grid>
                  <Grid item lg={2} sm={2} md={2} xs={12}>
                  {ansisShown && (
                    <>
                      <Box>
                        {(() => {
                          if (datas.answer === 1 && datas.attempt== 0) {
                            return (
                              <>
                               <Box display="flex">
                             <CheckCircleOutlineIcon sx={{color:"#50cd89"}}/>
                            
                                <Badge
                                  sx={{
                                    background: "#50cd89",
                                    color: "#fff",
                                    width: "60px",
                                    px: 1.2,
                                    py: 0.4,
                                    borderRadius: "50px",
                                    ml:3,
                                    
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                    alt="Goole"
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "unset",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                    }}
                                  >
                                    {(() => {
                                      if (datas.difficulty === "Easy") {
                                        return <>+5</>;
                                      } else if (
                                        datas.difficulty === "Medium"
                                      ) {
                                        return <>+10</>;
                                      } else if (datas.difficulty === "Hard") {
                                        return <>+15</>;
                                      }
                                    })()}
                                  </Typography>
                                </Badge>
                                </Box>
                              </>
                            );
                          } else if(datas.attempt==0) {
                            return (
                                <Box display ="flex">
                                    <HighlightOffIcon sx={{color:"red"}}/>
                              <Badge
                                sx={{
                                  background: "red",
                                  color: "#fff",
                                  width: "60px",
                                  px: 1.2,
                                  py: 0.4,
                                  borderRadius: "50px",
                                  ml:3,
                                  
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                  alt="Goole"
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    objectFit: "unset",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "0.8rem",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  +0
                                </Typography>
                              </Badge>
                              </Box>
                            );
                          }
                        })()}
                     </Box>

                    </>
                  )}
                  </Grid>
              </Grid>
              <Grid container >
                <Grid item lg={10} sm={10} md={10} xs={12}>
              
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize:{lg: "0.85rem",sm: "1rem",md: "1rem",xs:"0.85rem"},
                          marginBottom: "0rem ! important",
                        }}
                      >
                        {datas.option2}
                      </Typography>
                    }
                  />
                  </Grid>
                  <Grid item lg={2} sm={2} md={2} xs={12}>
                  {ansisShown && (
                    <>
                      <Box>
                        {(() => {
                          if (datas.answer === 2 && datas.attempt==0) {
                            return (
                              <>
                           <Box display="flex">
                              <CheckCircleOutlineIcon sx={{color:"#50cd89"}}/>
                                <Badge
                                  sx={{
                                    background: "#50cd89",
                                    color: "#fff",
                                    width: "60px",
                                    px: 1.2,
                                    py: 0.4,
                                    borderRadius: "50px",
                                    ml:3,
                                    
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                    alt="Goole"
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "unset",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                    }}
                                  >
                                    {(() => {
                                      if (datas.difficulty === "Easy") {
                                        return <>+5</>;
                                      } else if (
                                        datas.difficulty === "Medium"
                                      ) {
                                        return <>+10</>;
                                      } else if (datas.difficulty === "Hard") {
                                        return <>+15</>;
                                      }
                                    })()}
                                  </Typography>
                                </Badge>
                                </Box>
                              </>
                            );
                          } else if(datas.attempt==0){
                            return (
                                <Box display ="flex">
                                <HighlightOffIcon sx={{color:"red"}}/>
                              <Badge
                                sx={{
                                  background: "red",
                                  color: "#fff",
                                  width: "60px",
                                  px: 1.2,
                                  py: 0.4,
                                  borderRadius: "50px",
                                  ml:3,
                                  
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                  alt="Goole"
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    objectFit: "unset",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "0.8rem",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  +0
                                </Typography>
                              </Badge>
                              </Box>
                            );
                          }
                        })()}
                      </Box>
                    </>
                  )}
              
                </Grid>
                </Grid>
                <Grid container>
                <Grid item lg={10} sm={10} md={10} xs={12}>
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize:{lg: "0.85rem",sm: "1rem",md: "1rem",xs:"0.85rem"},
                          marginBottom: "0rem ! important",
                        }}
                      >
                        {datas.option3}
                      </Typography>
                    }
                  />
                  </Grid>
                  <Grid item lg={2} sm={2} md={2} xs={12}>
                  {ansisShown && (
                    <>
                      <Box>
                        {(() => {
                          if (datas.answer === 3 && datas.attempt==0) {
                            return (
                              <>
                              <Box display="flex">
                           <CheckCircleOutlineIcon sx={{color:"#50cd89"}}/>
                                <Badge
                                  sx={{
                                    background: "#50cd89",
                                    color: "#fff",
                                    width: "60px",
                                    px: 1.2,
                                    py: 0.4,
                                    borderRadius: "50px",
                                    ml:3,
                                    
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                    alt="Goole"
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "unset",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                    }}
                                  >
                                    {(() => {
                                      if (datas.difficulty === "Easy") {
                                        return <>+5</>;
                                      } else if (
                                        datas.difficulty === "Medium"
                                      ) {
                                        return <>+10</>;
                                      } else if (datas.difficulty === "Hard") {
                                        return <>+15</>;
                                      }
                                    })()}
                                  </Typography>
                                </Badge>
                                </Box>
                              </>
                            );
                          } else if(datas.attempt==0) {
                            return (
                                <Box display ="flex">
                                <HighlightOffIcon sx={{color:"red"}}/>
                              <Badge
                                sx={{
                                  background: "red",
                                  color: "#fff",
                                  width: "60px",
                                  px: 1.2,
                                  py: 0.4,
                                  borderRadius: "50px",
                                  ml:3,
                                  
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                  alt="Goole"
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    objectFit: "unset",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "0.8rem",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  +0
                                </Typography>
                              </Badge>
                              </Box>
                            );
                          }
                        })()}
                      </Box>
                    </>
                  )}
          </Grid>
          </Grid>
          <Grid container>
                <Grid item lg={10} sm={10} md={10} xs={12}>
                  <FormControlLabel
                    value="4"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize:{lg: "0.85rem",sm: "1rem",md: "1rem",xs:"0.85rem"},
                          marginBottom: "0rem ! important",
                        }}
                      >
                        {datas.option4}
                      </Typography>
                    }
                  />
                  </Grid>
                  <Grid item lg={2} sm={2} md={2} xs={12}>
                  {ansisShown && (
                    <>
                      <Box>
                        {(() => {
                          if (datas.answer === 4 && datas.attempt === 0) {
                            return (
                              <>
                                 <Box display="flex">
                           <CheckCircleOutlineIcon sx={{color:"#50cd89"}}/>
                                <Badge
                                  sx={{
                                    background: "#50cd89",
                                    color: "#fff",
                                    width: "60px",
                                    px: 1.2,
                                    py: 0.4,
                                    borderRadius: "50px",
                                    ml:3,
                                    
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                    alt="Goole"
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "unset",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                    }}
                                  >
                                    {(() => {
                                      if (datas.difficulty === "Easy") {
                                        return <>+5</>;
                                      } else if (
                                        datas.difficulty === "Medium"
                                      ) {
                                        return <>+10</>;
                                      } else if (datas.difficulty === "Hard") {
                                        return <>+15</>;
                                      }
                                    })()}
                                  </Typography>
                                </Badge>
                                </Box>
                              </>
                            );
                          } else if(datas.attempt === 0) {
                            return (
                                <Box display ="flex">
                                <HighlightOffIcon sx={{color:"red"}}/>
                              <Badge
                                sx={{
                                  background: "red",
                                  color: "#fff",
                                  width: "60px",
                                  px: 1.2,
                                  py: 0.4,
                                  borderRadius: "50px",
                                  ml:3,
                                  
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                                  alt="Goole"
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    objectFit: "unset",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "0.8rem",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  +0
                                </Typography>
                              </Badge>
                              </Box>
                            );
                          }
                        })()}
                      </Box>
                    </>
                  )}
            </Grid>
            </Grid>
            </RadioGroup>
          </Box>
        </Box>
        <Box sx={{ height: "40px" }}>
          {isShown && (
            <Typography
              sx={{
                p: 2,
                marginBottom: "0rem! important",
                fontSize: "0.85rem",
              }}
            >
              Hint:{datas.hint_text}
            </Typography>
          )}
        </Box>
        <Box sx={{ px:{lg:2,xs:1}}}>
          <Grid container sx={{ pt: 5, ml:{lg:5,sm:5,md:5} }}>
            <Grid item lg={4} sm={4} md={4} xs={3.5} >
            {(() => {
                if (datas.shuffle >= 3) {
                  return (
              <Button
                variant="contained"
                sx={{
                  background: "#8080803d",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "50px",
                  textTransform: "none",
                  color: "#000",
                  width: "100px",
                  "&:hover": {
                    background: "#8080803d",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "50px",
                    textTransform: "none",
                    color: "#000",
                    width: "100px",
                  },
                }}
                disabled
              >
                <FontAwesomeIcon icon={faRandom} />{" "}
                <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                  Shuffle
                </Typography>
              </Button>
                  );
                }
                else if(datas.attempt > 0) {
                  return (
                  <Button
                  variant="contained"
                  sx={{
                    background: "#8080803d",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "50px",
                    textTransform: "none",
                    color: "#000",
                    width: "100px",
                    "&:hover": {
                      background: "#8080803d",
                      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                      borderRadius: "50px",
                      textTransform: "none",
                      color: "#000",
                      width: "100px",
                    },
                  }}
                
                  disabled
                >
                  <FontAwesomeIcon icon={faRandom} />{" "}
                  <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                    Shuffle
                  </Typography>
                </Button>
                  );
                }
                else  {
                  return (
                  <Button
                  variant="contained"
                  sx={{
                    background: "#8080803d",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "50px",
                    textTransform: "none",
                    color: "#000",
                    width: "100px",
                    "&:hover": {
                      background: "#8080803d",
                      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                      borderRadius: "50px",
                      textTransform: "none",
                      color: "#000",
                      width: "100px",
                    },
                  }}
                  onClick={shufflebtn}
                  disabled={btndisabled}
                >
                  <FontAwesomeIcon icon={faRandom} />{" "}
                  <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                    Shuffle
                  </Typography>
                </Button>
                  );
                }
                })()}
            </Grid>
            <Grid item lg={4} sm={4} md={4}  xs={3.5} sx={{ml:{xs:2,lg:0,sm:0,md:0}}}>
              <Button
                variant="contained"
                sx={{
                  background: "#8080803d",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "50px",
                  textTransform: "none",
                  color: "#000",
                  width:{lg:100,xs:90},
              
                  "&:hover": {
                    background: "#8080803d",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "50px",
                    textTransform: "none",
                    color: "#000",
                    width:{lg:100,xs:90},
                  },
                }}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faLightbulb} />{" "}
                <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                  Hint
                </Typography>
              </Button>
            </Grid>
            <Grid item lg={4}  sm={4} md={4} xs={3.5} sx={{ml:{xs:1,lg:0,sm:0,md:0}}}>
            {isShownbtn && (  
              <Button
                variant="contained"
                type="submit"
                sx={{
                  background: "#8080803d",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                  borderRadius: "50px",
                  textTransform: "none",
                  color: "#000",
                  width:{lg:100,xs:90},
           
                  "&:hover": {
                    background: "#8080803d",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "50px",
                    textTransform: "none",
                    color: "#000",
                    width: "100px",
                    width:{lg:100,xs:90},
                  },
                }}
                // onClick={anshandleClick}
              >
                <FontAwesomeIcon icon={faEdit} />{" "}
                <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                  Submit
                </Typography>
              </Button>
            )}
              {isShownrbtn && ( 
                        <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          background: "#8080803d",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "50px",
                          textTransform: "none",
                          color: "#000",
                          width:{lg:120,xs:110},
                      
                          "&:hover": {
                            background: "#8080803d",
                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                            borderRadius: "50px",
                            textTransform: "none",
                            color: "#000",
                            width:{lg:120,xs:110}
                          },
                        }}
                        onClick={anshandleClick}
                      >
                      <CachedIcon/>
                        <Typography sx={{ marginBottom: "0rem !important", ml: 1 }}>
                        Reattempt
                        </Typography>
                      </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
       </form>
    </>
  );
};
export default TryalChallengeoftheday;
