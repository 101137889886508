import React, { useRef } from "react";
import "../../Assets/css/Home/HomeSectionOne.css";
import { Grid, Box, CardMedia,Container } from "@mui/material";
import { Typography, Button } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useStyles from "../../Assets/js/Home/Banner";

export default function HomeSectionOne() {
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", lg: "block", md: "block", sm: "none" },
          mt: 4,
          mb: 4,
          backgroundColor: "rgba(0, 145, 255, 0.05)",
        }}
      >
        <Container fixed> 
        <Typography
          variant="h4"
          component="div"
          textAlign="center"
          className={classes.sectiononfont}
          sx={{ py: 3 }}
        >
          {" "}
          Our alumni work at
        </Typography>

        <Grid
          lg={12}
          sm={12}
          md={12}
          xl={12}
          container
          className={classes.boxsesction}
          sx={{ pt: 3, pb: 6 }}
        >
              <Grid lg={1} sm={1} md={1} xl={1}></Grid>
          <Grid
            lg={2}
            sm={2}
            md={2}
            xl={2}
            className={classes.sesction}
            sx={{ my: "auto" }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/google.webp"
              alt=""
              sx={{ width: "127px" }}
            />
          </Grid>
          <Grid
            lg={2}
            sm={2}
            md={2}
            xl={2}
            className={classes.sesction}
            sx={{ my: "auto" }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/mircrosoft.webp"
              alt=""
              sx={{ width: "127px", mt: 1 }}
            />
          </Grid>
          <Grid
            item
            lg={2}
            sm={2}
            md={2}
            xl={2}
            className={classes.sesction}
            sx={{ my: "auto" }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/flipkart.webp"
              alt=""
              sx={{ width: "127px", mt: 1 }}
            />
          </Grid>
          <Grid
            lg={2}
            sm={2}
            md={2}
            xl={2}
            className={classes.sesction}
            sx={{ my: "auto" }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/amazon.webp"
              alt=""
              sx={{ width: "127px", mt: 1 }}
            />
          </Grid>
          <Grid
            lg={2}
            sm={2}
            md={2}
            xl={2}
            className={classes.lastimag}
            sx={{ my: "auto" }}
          >
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/accenture.webp"
              alt=""
              sx={{ width: "127px", mt: 1 }}
            />
          </Grid>
          <Grid lg={1} sm={1} md={1} xl={1}></Grid>
        </Grid>
        </Container>  
      </Box>
      <Box
        sx={{
          display: { xs: "block", lg: "none", md: "none", sm: "block" },
          backgroundColor: "#f1f2f6",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          textAlign="center"
          className={classes.sectiononfont}
          sx={{ py: 3 }}
        >
          {" "}
          Our alumni work at
        </Typography>

        <Box
          sx={{ display: { xs: "block", lg: "none", md: "none", sm: "block" } }}
        >
          <Slider ref={sliderRef} {...settings}>
            <Grid xs={12} sm={2}>
              <center>
                {" "}
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/google.webp"
                  alt=""
                  sx={{ objectFit: "contain", width: "200px", height: "79px" }}
                />
              </center>
            </Grid>
            <Grid xs={12} sm={2}>
              <center>
                {" "}
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/mircrosoft.webp"
                  alt=""
                  sx={{ objectFit: "contain", width: "200px", height: "79px" }}
                />
              </center>
            </Grid>
            <Grid xs={12} sm={2}>
              <center>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/flipkart.webp"
                  alt=""
                  sx={{ objectFit: "contain", width: "200px", height: "79px" }}
                />
              </center>
            </Grid>
            <Grid xs={12} sm={2}>
              <center>
                {" "}
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/amazon.webp"
                  alt=""
                  sx={{ objectFit: "contain", width: "200px", height: "79px" }}
                />
              </center>
            </Grid>
            <Grid xs={12} sm={2}>
              <center>
                {" "}
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/accenture.webp"
                  alt=""
                  sx={{ objectFit: "contain", width: "200px", height: "79px" }}
                />
              </center>
            </Grid>
          </Slider>
        </Box>
      </Box>

    </>
  );
}
