import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  homsection:{

    paddingTop:"100px!important",
    
    "@media (max-width: 700px)": {
      paddingTop:"0px !important",
      marginTop:"-48px",
     
     }
  },
  homsections:{

    paddingTop:"60px!important",
    
    "@media (max-width: 700px)": {
      paddingTop:"0px !important",
      marginTop:"-48px",
     
     }
  },
}));
