
import React from "react";
import Bookpanel from '../Components/Dashboard/Resource-Center/Bookpanel';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
const AuthBookpanel = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Bookpanel /> : <Navigate to="/Login" />;
}
export default AuthBookpanel;
