
import React from "react";

import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SuccessStory from "../Components/Dashboard/SuccessStory";
const AuthSuccesstory = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <SuccessStory /> : <Navigate to="/Login" />;
}
export default AuthSuccesstory;
