import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from '@mui/material';
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import {

  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,

  Backdrop,
} from "@mui/material";
export default function HowTo() {
  const [state, setState] = useState({ right: false });
  const [data,setData]=useState("")
  const [insaidtvpop, setInsaidtvpop] = useState();
  const [info, setinfo] = useState(false);
  const handleClose = () => {
    setInsaidtvpop(false);
  };
  const handleClickOpen = (val) => {
    setInsaidtvpop(true);
    setinfo(val);
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
// React.useEffect(()=>{
  
// },[])
const fetchHowto=()=>{
  axios
  .post(
    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchhowto`,
    options
  )
  .then((result) => {
   
    setData(result.data);
  });
}
// console.log(data,"kk")
  const toggleDrawer = (anchor, open) => (event) => {
    if(open==true){
      fetchHowto()
    }
    
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : {lg:500,xs:350}}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{p:2,display:"flex",justifyContent:"space-between"}}>
      <Typography sx={{fontWeight:"bold",color:"#626378"}}>Accredian Guide</Typography>
      <CloseIcon sx={{cursor:"pointer"}} onClick={toggleDrawer('right', false)}/>
      </Box>
      <hr style={{borderCollapse:"collapse",background:"#f2f4f7"}}/>
      {/* <Box>hello</Box> */}
      {data&&data.map((val)=>(
        <Box sx={{py:1}}>
          <Box sx={{display:"flex",cursor:"pointer",mb:2}}    onClick={() => handleClickOpen(val)}>
         <img width="150px" height="100px" style={{marginLeft:"20px"}} src={val.thumbnails} alt="thumbnail"></img>
         <Typography sx={{ml:3,mt:2}}>{val.title}</Typography>
       </Box>
       <hr style={{borderCollapse:"collapse",background:"#f2f4f7"}}/>
        </Box>
      )
         
       )} 
     
      
    </Box>
  );

  return (
    <>
    <div>
      <>
      <div style={{ display: 'flex', justifyContent: 'flex-end',width:"100%" }}>
      <div style={{ position: 'fixed', top: '50%',  transform: 'translateY(-50%) rotate(90deg)' }}>
        <Button sx={{background:"#ff6f00", color:"#fff",marginLeft:"-44px","&:hover":{background:"#ff6f00", color:"#fff",marginLeft:"-44px"}}} onClick={toggleDrawer('right', true)}>
          HOW TO
        </Button>
      </div>
        {/* <Button onClick={toggleDrawer('right', true)} style={{ position: 'fixed', top: '50%', right: '10px', transform: 'translateY(-50%)',background:"#ff6f00",color:"#fff" }}>HOW TO</Button> */}
        <Drawer
          anchor={'right'}
          open={state['right']}
          // onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
        </div>
      </>
    </div>
     <Dialog
     open={insaidtvpop}
     onClose={handleClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
     sx={{}}
   >
     <DialogTitle id="shreefeddbackopen" sx={{ width: "580px" }}>
       {info.title}
       <ClearIcon
         onClick={handleClose}
         sx={{ float: "right", mt: 0.5, ml: 2, cursor: "pointer" }}
       />
     </DialogTitle>
     <DialogContent>
       <iframe
         width="100%"
         height="315"
         frameborder="0"
         allowfullscreen="1"
         allow="autoplay; encrypted-media;"
         src={
           `https://www.youtube-nocookie.com/embed/` +
           info.videoId +
           `?autoplay=1&cc_load_policy=1rel=0&showinfo=0`
         }
       ></iframe>
     </DialogContent>
   </Dialog>
   </>
  );
}
