import React, { useRef, useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import jsPDF from 'jspdf';
import logo from '../../images/Accrediancc.png'
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Link,
} from "@mui/material";
import axios from "axios";
import font from '../../Assets/fonts/fonts/Lato-Bold.ttf'
// import IconButton from '@material-ui/core/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
// import logo from '../../images/completioncertificate.png'


const Certificate = ({ full_name,program_name,from_date,to_date,index,id,batch_id,cert_id }) => {
  const [suchitDesigntion,setSD] = useState("Chief Data Mentor, Accredian")
	const [manavDesignation,setMD] = useState("CEO, Accredian")
  const[cid,setCid]=useState(id>1000?"INDM"+id:"INDM00"+id)
  const[imgurl,setUrl]=useState('');
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  
  const Downloadterm=()=>{
    const canvas = canvasRef.current;
    var downloadCanvas = document.createElement('canvas');
  downloadCanvas.width = 1755; // Set desired fixed width for download
  downloadCanvas.height = 1240; // Set desired fixed height for download
  var downloadCtx = downloadCanvas.getContext('2d');
  
  downloadCtx.drawImage(canvas, 0, 0, downloadCanvas.width, downloadCanvas.height);
  
  var downloadLink = document.createElement('a');
  downloadLink.href = downloadCanvas.toDataURL();
  downloadLink.download = 'Completion Certificate'; // Set desired filename for download
  downloadLink.click();
  }
  // ${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}
  // useEffect(()=>{
  //   axios.post(`http://localhost:8000/certificate/url`, { user_id: localStorage.getItem("user_id"),cert_id:cert_id },options)
  //   .then((res)=>{
  //     if(res.data[0].cert_url){
  //       //console.log("insaide")
  //       //console.log(document.getElementsByClassName("show"))
  //       setUrl(res.data[0].cert_url)
  //       document.getElementsByClassName("show")[index].style.display="block"
  //     }
  //   })
  // },[id,index])
  useEffect(()=>{
    // //console.log(index,"iiii")
    // ${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}
    axios.post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certificate/getallcompletion`, { user_id: localStorage.getItem("user_id"),cert_id:cert_id },options)
    .then((res)=>{
      if(res.data.status!=300){
        if(res.data[index].cert_url!=null){
          // //console.log(document.getElementsByClassName("show","showww"))
          setUrl(res.data[index].cert_url)
          document.getElementsByClassName("show")[index].style.display="block"
          // document.getElementsByClassName("lock-screen-overlay").style.display="block"
        }
      }
     
    })
  },[id,index])
  const dataUrlToBlob = (dataUrl) => {
    const parts = dataUrl.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const base64 = parts[1];
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
  
    return new Blob([new Uint8Array(byteArrays)], { type: contentType });
  };
  const canvasRef = useRef(null);
  var token= window.btoa(`${localStorage.getItem("user_id")}&${localStorage.getItem("category")}&${batch_id}&${cert_id}`);
  // //console.log(token,"see")
  var url="https://accredian.com/student_certificate/new-student-certificate.php?token="+token;



  const handleDownload = () => {
  
    
    document.getElementsByClassName("show")[index].style.display="block"
    axios.post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certificate/url`, { user_id: localStorage.getItem("user_id"),cert_id:cert_id },options)
    .then((resonse)=>{
      if(resonse.data[0].cert_url){
        setUrl(resonse.data[0].cert_url)
        const downloadLink = document.createElement('a'); // Create download link
        downloadLink.href = resonse.data[0].cert_url; // Set data URL as the href of the download link
        downloadLink.download = 'canvas_image.png';
        downloadLink.target="_blank" // Set desired filename for the download
        downloadLink.click();
      }
      else{
        const canvas = canvasRef.current; // Access the canvas element using the ref
        const dataURL = canvas.toDataURL('image/png');
        const blob = dataUrlToBlob(dataURL);
        const formData = new FormData();
      formData.append('image', blob);
      formData.append('user_id',localStorage.getItem("user_id"))
      formData.append('batch_id',batch_id)
      formData.append('cert_id',cert_id)
      formData.append('id',id)
      axios.post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certificate/uploadcertificate`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response)=>{
            
            if(response.data.code==200){
              axios.post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certificate/url`, { user_id: localStorage.getItem("user_id"),id:id },options)
        .then(response => {
          // Handle API response
          Downloadterm()
        })
            }
          
        })
      }
    })

    
    // axios.post('${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}/certificate/uploadcertificate', { imageData: dataURL })
    // .then(response => {
    //   // Handle API response
    //   //console.log(response.data);
    // })
    // .catch(error => {
    //   // Handle API error
    //   console.error(error);
    // }); // Convert canvas to data URL
    
  };
  
  
  
  useEffect(() => {

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if(localStorage.getItem('category') == 2 || localStorage.getItem('category') == 1){
      setSD("Chief Data Mentor, Accredian");
  }else if(localStorage.getItem('category') == 4 || localStorage.getItem('category') == 3){
    setSD("Chief Product Mentor, Accredian");
  }

 
  
   
    // const canvas2 = canvas2Ref.current; // Access the second canvas element using the ref
    // const context2 = canvas2.getContext('2d');
    
    // Load the template image
    const templateImage = new Image();
    templateImage.src = logo; // URL to the certificate template image
    
    // canvas.width=templateImage.width
    // canvas.height=templateImage.height
   
      // const imageData = context2.getImageData(0, 0, canvas2.width, canvas2.height);

      // Put the image data onto the second canvas at the desired position
      // ctx.putImageData(imageData, 400, 300);
    // QRCode.toCanvas(canvas, qrData, (error) => {
    //   if (error) console.error(error);
    // });
    // Draw the template image onto the canvas once it's loaded
    

    templateImage.onload = () => {
      // ctx.imageSmoothingEnabled = false;
      var foreground = new Image();
    foreground.crossOrigin="anonymous";
    foreground.src = "https://chart.googleapis.com/chart?chf=bg,s,FFFFFF00&chs=270x270&cht=qr&chs=100x100&chl="+ encodeURIComponent(url)
    foreground.onload = function() {
      ctx.drawImage(foreground,1450,1020,210,210);
      };
      //440 240
      ctx.drawImage(templateImage, 0, 0, canvas.width, canvas.height);

      // Draw the dynamic data onto the canvas
      ctx.fillStyle = '#0066a1';
      ctx.font = 'bold 75px Swiss721';
      ctx.fillText(`${full_name}`.toUpperCase(), 105, 570);
      ctx.font = 'bold 40px Lato-Bold';
      ctx.fillText(`${program_name}`.toUpperCase(), 525, 690);
      ctx.font = ' bold 35px Lato-Bold';
      ctx.fillText(`${from_date}`, 410, 750);
      ctx.font = 'bold 35px Lato-Bold';
      ctx.fillText(`${to_date}`, 700, 750);
      ctx.fillStyle = '#737373'
      ctx.font = '30px Lato';
		ctx.fillText("Certification ID",1450,980 );
    ctx.font = '25px Lato';
		ctx.fillText(cid, 1490,1020); 
		ctx.fillStyle = '#000'
    ctx.font = '30px Lato'
		ctx.fillText(manavDesignation,140, 1068)
    ctx.font = '30px Lato'
		ctx.fillText(suchitDesigntion, 588 , 1068)
   
      
    };
  }, [full_name, program_name,from_date,to_date,id]);
  const shareOnLinkedIn = () => {
    const imageUrl = "https://res.cloudinary.com/djxxtwy8k/image/upload/v1681468410/student_certificate/f3ippwz6chhrhm1whhiv.png";
    const redirectUri = window.location.href;
   
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(imgurl)}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`,
      "_blank"
    );
  };

  const shareOnFacebook = () => {
    const fbShareUrl = "https://www.facebook.com/dialog/share";
    const imageUrl = "https://res.cloudinary.com/djxxtwy8k/image/upload/v1681468410/student_certificate/f3ippwz6chhrhm1whhiv.png";
    const caption = "Your Image Caption";
    const description = "Your Image Description";
    const redirectUri = window.location.href;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imgurl)}`,
      "_blank"
    );
  };

  return (
    <>
<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <canvas  style={{display:"none"}} ref={canvasRef} width={1755} height={1240} />
    
    </div>
     <Box sx={{p:2,display:"flex",justifyContent:"space-between"}}>
                            {/* {certificate_lits.map((val)=>( */}
                                <Typography onClick={handleDownload} sx={{color:"#24acf8",cursor:"pointer",marginBottom:"0rem !important",width:{xs:"200px",lg:"auto",sm:"auto"}}}>{program_name}</Typography>
                                <Box className="show" sx={{display:"none",width:{lg:"auto",xs:"100px",sm:"auto"}}}>
                                <Button variant='contained' onClick={shareOnLinkedIn} color="primary" size='small' sx={{mr:1,textTransform:"none",mb:{lg:0,xs:1,sm:0},width:30,height:25}}>
                                  <LinkedInIcon sx={{fontSize:"15px"}}/>
                                  Share
                                </Button>
                                {/* <Button variant='conatined' onClick={shareOnLinkedIn}>Linkedin Share</Button> */}
                                {/* <Button variant='contained' onClick={shareOnFacebook}>Facebook Share</Button> */}
                                <Button variant='contained' onClick={shareOnFacebook} color="primary" size='small' sx={{textTransform:"none",width:30,height:25}}>
                                  <FacebookIcon sx={{fontSize:"15px"}}/>
                                  Share
                                </Button>
                                </Box>
                                
                            {/* ))} */}
                            </Box>
    </>
    
  );
};

export default Certificate;
