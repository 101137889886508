import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import PolicyIcon from "@mui/icons-material/Policy";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArticleIcon from '@mui/icons-material/Article';
import "../Dashboard/dashboard.css";
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import { decode as atob, encode as btoa } from "base-64";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Stack,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
  Popper,
  Collapse,
  ListItemIcon,
  ListItemButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Backdrop,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CottageIcon from "@mui/icons-material/Cottage";
import TaskIcon from "@mui/icons-material/Task";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DonutSmallRoundedIcon from "@mui/icons-material/DonutSmallRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
const drawerWidth = 320;

const Category = [
  {
    value: "faculty",
    label: "Faculty",
  },
  {
    value: "material",
    label: "Material",
  },
  {
    value: "quizzes_assignments",
    label: "Quizzes/Assignments",
  },
  {
    value: "projects",
    label: "Projects",
  },
  {
    value: "others",
    label: "Others",
  },
];
function LoginDashboard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imagerespons, setImagerespons] = useState();
  const [usercount, setUsercount] = useState("");
  const [usernames, setUsername] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [coin, setCoindata] = useState(localStorage.getItem("coins")?localStorage.getItem("coins"):"");
  const [notifiction, setNotifictiondata] = useState("");
  const [countnoti, setCountnoti] = useState("");
  const [noanchorEl, setNoanchorEl] = useState(null);
  const [mopen, setMopen] = useState(false);
  const [sharefeedback, setSharefeedback] = useState("");
  const [otherfeedbackdes, setOtherfeedbackdes] = useState("");
  const [feedbackdes, setFeedbackdes] = useState("");
  const [openloder, setOpenloder] = useState("");
  const { pathname } = useLocation();
  const [newopen, setOpen] = useState(false);
  const [feddbackopen, setFeddbackopen] = useState();
  const [shreefeddbackopen, setFeddbackopenshree] = useState();
  const [openrc, setOpenrc] = useState(false);
  const [openpp, setOpenpp] = useState(false);
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [issudeon,setIssudon]= useState("");
  const [term, setTerm] = useState("");
  const [filepath,setFilepath]=useState(localStorage.getItem("profileURL")?localStorage.getItem("profileURL"):"");
  const newemail = btoa(localStorage.getItem("email"));

  const { window } = props;
  const LoaderClose = () => {
    setOpenloder(false);
  };
  const LoaderOpen = () => {
    setOpenloder(true);
  };
  const submitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      enrol_id: localStorage.getItem("enrol_id"),
      message: feedbackdes,
      category:localStorage.getItem("category")
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/facultyFeedback/faculty-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }

        LoaderClose();
        handleClose();
      });
    //console.log(sandData);
  };
  const ShareFeedbacksubmitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      user_id : localStorage.getItem("user_id"),
      program_id : localStorage.getItem("program_id"),
      batch_id : localStorage.getItem("batch_id"),
      term_id : localStorage.getItem("term"),
      feedback_for: isShown,
      feedback : feedbackdes,
      faculty_id : sharefeedback.faculty_id,
      faculty_email : sharefeedback.faculty_email,
      faculty_name : sharefeedback.faculty_name,
      username : sharefeedback.username,
      program_name : sharefeedback.program_name,
      batch_name : sharefeedback.batch_name,
      term_name : sharefeedback.term_name,
      user_email : sharefeedback.user_email,
      other_feedback : otherfeedbackdes,
   
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/feedback/studentnew-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }
        setFeedbackdes("");
        LoaderClose();
        handleClose();
        setIsShown(false);
        setOtherfeedbackdes("");
      });
    
  };
  const feedbackdeschnage = (event) => {
    setFeedbackdes(event.target.value);
  };
  const otherfeedbackdeschnage = (event) => {
    setOtherfeedbackdes(event.target.value);
  };
  const sharefeedbackdetails = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetch_share_feedback_details`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),

      },
    }).then((res) => {
      setSharefeedback(res.data);
    });
  };
  const notifictionClick = (event) => {
    // console.log(event.currentTarget,"ll")
    fetchnotifictiondata()
    setNoanchorEl(noanchorEl ? null : event.currentTarget);
  };
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const opens = Boolean(noanchorEl);
  const id = opens ? "simple-popper" : undefined;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();


  const handleClicknew = () => {
    setOpen(!open);
  };
  const mhandleClick = () => {
    setMopen(!mopen);
  };
  const handleRcClick = () => {
    setOpenrc(!openrc);
  };
  const handlePpClick = () => {
    setOpenpp(!openpp);
  };
  const handleClickOpen = () => {
    setFeddbackopen(true);
  };

  const handleClosenew = () => {
    setFeddbackopen(false);
    setFeddbackopenshree(false);
  };
  const shreehandleClickOpen = () => {
    setFeddbackopenshree(true);
  };
  const [isShown, setIsShown] = useState(false);
  const otherClick = (e) => {
    setIsShown(e.target.value);
  };
  const LogOut = () => {
    localStorage.removeItem("user_id");
    localStorage.clear();
    navigate(`/Login`);
  };
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if(res.data.status == "300"){
        localStorage.setItem("profileURL","https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp")
        setFilepath('https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp')
      }else{
        localStorage.setItem("profileURL",res.data[0].filename)
        setFilepath(res.data[0].filename)
      }
    });
  };
  useEffect(()=>{
    if(!localStorage.getItem("profileURL")){
      fetchData()
    }
  },[localStorage.getItem("profile")])
  // const certificationExam = async () => {
  //   await axios({
  //     method: "post",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json",
  //     },
  //     url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/certificationExam`,
  //     data: {
  //       enrol_id: localStorage.getItem("enrol_id"),
  //       program_id: localStorage.getItem("program_id"),
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   }).then((res) => {
  //     setIssudon(res.data.certificateRow.eschedule);
  //       localStorage.setItem("issude_date", res.data.certificateRow.issued_on);
  //      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
  //   });
  // };
  const checkdclick = async (id) => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/updatenotification_status`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        id: id,
      },
    }).then((res) => {
      setCountnoti(res.data.unchecked_count);
    });
  };
  const fetchcoindata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchstudentcoins`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      if(res.data.status==300){
        setCoindata(0);
      }
      else{
        localStorage.setItem("coins",res.data)
        setCoindata(res.data);
      }
      
    });
  };
  const fetchnotifictiondata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchnotification`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      setNotifictiondata(res.data.notification);
      setCountnoti(res.data.unchecked_count);
    });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_count = localStorage.getItem("count");
    var usernamest = localStorage.getItem("username");
    var lastname = localStorage.getItem("lastname");
    var email = localStorage.getItem("email");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUsercount(user_count);
    setUsername(usernamest);
    setLastname(lastname);
    setEmail(email);
    if(!localStorage.getItem("coins")){
      fetchcoindata();
    }
   
    // fetchnotifictiondata();
    // certificationExam();
    sharefeedbackdetails();
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
  }, [props.showcoins]);
const newpolicy =localStorage.getItem("category");
const newpolicyprogram =localStorage.getItem("program_id");
if(newpolicy=="2" || newpolicy=="4" || newpolicy=="1" || newpolicy=="3" || newpolicy=="16"){
  var policylink="admissions-policy";
}
else if(newpolicy=="9" || newpolicy=="13" || newpolicy=="14"){
  var policylink="xlri-admissions-policy";
}
else if(newpolicyprogram=="29" || newpolicyprogram=="33"|| newpolicyprogram=="34"|| newpolicyprogram=="35"|| newpolicyprogram=="41"|| newpolicyprogram=="42"|| newpolicyprogram=="43"|| newpolicyprogram=="44"|| newpolicyprogram=="46"|| newpolicyprogram=="47"){
  var policylink="iitg-admissions-policy";
}
else if(newpolicy=="12" || newpolicy=="10"){
  var policylink="imi-admissions-policy";
}
else if(newpolicy=="15"){
  var policylink="spjain-admissions-policy";
}

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box display="flex">
        <Link to="/" style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
            alt="green iguana"
            sx={{
              display: { xs: "block", lg: "block" },
              width: 120,
              py: 1,
              ml: 2.5,
            }}
          />
        </Link>
        <ClearIcon sx={{ mt: 3, ml: 18 }} onClick={handleDrawerToggle} />
      </Box>
      <Divider />
      <>
        {(() => {
           if (category == "2" || category == "1") {
          return (
            <>
              <Box>

             
                  <Box sx={{ p: 1 }}>
                    <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Dashboard" ? "dashactive" : ""
                        }
                      >
                        <CottageIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        My Dashboard
                      </Typography>
                    </Link>
                    {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback



</Typography>
          ):(<></>)}
                    {/* <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      onClick={shreehandleClickOpen}
                    >
                      <TaskIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Share Feedback
                    </Typography> */}
                    <ListItemButton
                      onClick={mhandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "1rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width: "287px",
                            }}
                          >
                            {" "}
                            <ToggleOffIcon
                              sx={{
                                fontSize: "1rem",
                                mr: 2,
                                "&:hover": {
                                  background: "transparent",
                                  color: "#000",
                                },
                              }}
                            />
                            Manage My Program
                          </Typography>
                        }
                      />
                      {mopen ? <ExpandMoreIcon sx={{ml:"269px"}} /> : <ChevronRightIcon sx={{ml:"269px"}} />}
                    </ListItemButton>
                    <Collapse in={mopen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Duefee" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Duefee"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Duefee"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  {" "}
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Due Fee Payment
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Receipt" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Receipt"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Receipt"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Fee Receipt
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                      
                     <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                    
                      </List>
                    </Collapse>
                    {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                   <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }} target="_blanck">
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Calendar" ? "dashactive" : ""}
                      >
                        <CalendarMonthIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Event Calendar
                      </Typography>
                    </a>
                    <>
                      {(() => {
                        if (term > 3) {
                          return (
                            <>
                              <Link
                                to="/CareerCounselling"
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "1rem !important",
                                    width: "300px",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/CareerCounselling"
                                      ? "dashactive"
                                      : ""
                                  }
                                >
                                  <ForumIcon
                                    sx={{ fontSize: "1rem", mr: 2 }}
                                  />
                                  Career Counselling
                                </Typography>
                              </Link>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "1rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "1rem !important",
                                  width: "300px",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/CareerCounselling"
                                    ? "dashactive"
                                    : ""
                                }
                              >
                                <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                                Career Counselling{" "}
                                <LockPersonIcon
                                  sx={{ fontSize: "1rem", ml: 1, mt: 0.5 }}
                                />
                              </Typography>
                            </>
                          );
                        }
                      })()}
                    </>
                    <Link
                                to="/career-transition"
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.9rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "1rem !important",
                                    width:{lg:"230px"},
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/career-transition"
                                      ? "dashactive"
                                      : ""
                                  }
                                >
                                  <WifiProtectedSetupIcon
                                    sx={{ fontSize: "1.3rem", mr: 2 }}
                                  />
                                  Career Transition Manual
                                </Typography>
                              </Link>
                              {(() => {
                        if (term > 3) {
                          return (
                            <>
                             <Link to="/projects" style={{ textDecoration: "none" }}>
                      <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <NoteAltIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Term Projects
                      {/* <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip> */}
                    </Typography>
                        </Link>
                            </>
                          );
                        } 
                      })()}

                    {/* <Link to="/Projects" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Projects" ? "dashactive" : ""}
                      >
                        <NoteAltIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Term Projects
                      </Typography>
                    </Link> */}
                    <Link to="/Gallery" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Gallery" ? "dashactive" : ""}
                      >
                        <CollectionsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Project Gallery
                      </Typography>
                    </Link>
                    <Link
                      to="/Resume-toolkit"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Resume-toolkit" ? "dashactive" : ""
                        }
                      >
                        <DescriptionIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Resume Toolkit
                      </Typography>
                    </Link>
                    <Link
                      to="/Job-guarantee"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Job-guarantee" ? "dashactive" : ""
                        }
                      >
                        <BusinessCenterIcon
                          sx={{ fontSize: "1rem", mr: 2 }}
                        />
                        Job Guarantee
                      </Typography>
                    </Link>
                    <Link to="/Certificates" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certificates" ? "dashactive" : ""
                        }
                      >
                        <EmojiEventsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Certificates & Awards
                      </Typography>
                    </Link>

                    <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Certification Exam
                      </Typography>
                    </Link>


                    <Link
                      to="/Career-Launchpad"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Career-Launchpad" ? "dashactive" : ""
                        }
                      >
                        <SignalCellularAltIcon
                          sx={{ fontSize: "1rem", mr: 2 }}
                        />
                        Career Launchpad
                      </Typography>
                    </Link>
                    <ListItemButton
                      onClick={handleRcClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "1rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:"287px",
                            }}
                          >
                            {" "}
                            <DonutSmallRoundedIcon
                              sx={{ fontSize: "1rem", mr: 2 }}
                            />
                            Resource Center
                          </Typography>
                        }
                      />
                      {openrc ? <ExpandMoreIcon sx={{ml:"269px"}} /> : <ChevronRightIcon sx={{ml:"269px"}} />}
                    </ListItemButton>
                    <Collapse in={openrc} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Article" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Article"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Article"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Article
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Books" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Books" ? "mangmyprogramactive" : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Books" ? "myprogrgamnav" : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Books
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Case-Studies"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Case-Studies"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Case-Studies"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Case Studies
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Cheat-Sheet"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Cheat-Sheet"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Cheat-Sheet"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  CheatSheet
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Guides" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Guides"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Guides"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Guides
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Infographics"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Infographics"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Infographics"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Infographics
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Interview-Questions"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Interview-Questions"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Interview-Questions"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Interview Questions
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Research-Paper"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              pt: 0.5,
                              pb: 1,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Research-Paper"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Research-Paper"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Research Paper
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/SuccessStory" ? "dashactive" : ""
                        }
                      >
                        <StarRateRoundedIcon
                          sx={{ fontSize: "1rem", mr: 2 }}
                        />
                        Success Story
                      </Typography>
                    </Link>
                    <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                  </Box>
              
                <Box>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "183px",

                      mt: 1,
                      ml: 20,
                    }}
                    onClick={handleClickOpen}
                  >
                    <AnnouncementIcon
                      sx={{ fontSize: "1rem", color: "#ff6666", mr: 1 }}
                    />
                    Feedback
                  </Typography>
                </Box>
              </Box>
            </>
          );
        } else if (category == "7") {
          return (
            <>
              <Box>

            
                  <Box sx={{ p: 1 }}>
                    <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Dashboard" ? "dashactive" : ""
                        }
                      >
                        <CottageIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        My Dashboard
                      </Typography>
                    </Link>
                    <Link to="/By-Program" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/By-Program" ? "dashactive" : ""
                        }
                      >
                        <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Buy Full Course
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Event Calendar{" "}
                      <Tooltip 
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6.5,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip >
                    </Typography>
                    <Link
                      to="/CareerCounselling"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/CareerCounselling" ? "dashactive" : ""
                        }
                      >
                        <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Admission Counselor
                      </Typography>
                    </Link>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <NoteAltIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Term Projects{" "}
                      <Tooltip 
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 7.7,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip >
                    </Typography>

                    <Link to="/Gallery" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Gallery" ? "dashactive" : ""}
                      >
                        <CollectionsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Project Gallery
                      </Typography>
                    </Link>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <EmojiEventsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Certificates & Awards{" "}
                      <Tooltip 
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 1,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip >
                    </Typography>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <SignalCellularAltIcon
                        sx={{ fontSize: "1rem", mr: 2 }}
                      />
                      Career Launchpad{" "}
                      <Tooltip 
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 4,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip >
                    </Typography>

                    <ListItemButton
                      onClick={handleRcClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "1rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:"287px",
                            }}
                          >
                            {" "}
                            <DonutSmallRoundedIcon
                              sx={{ fontSize: "1rem", mr: 2 }}
                            />
                            Resource Center
                          </Typography>
                        }
                      />
                      {openrc ? <ExpandMoreIcon sx={{ml:"269px"}}/> : <ChevronRightIcon sx={{ml:"269px"}}/>}
                    </ListItemButton>
                    <Collapse in={openrc} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Article" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Article"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Article"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Article
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Books{" "}
                                <Tooltip 
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 14,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </Tooltip >
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <Link
                          to="/Case-Studies"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Case-Studies"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Case-Studies"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Case Studies
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Cheat-Sheet"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Cheat-Sheet"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Cheat-Sheet"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  CheatSheet
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Guides" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Guides"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Guides"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Guides
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Infographics{" "}
                                <Tooltip 
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 9,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </Tooltip >
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  width: 355,
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions{" "}
                                <Tooltip 
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 2.5,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </Tooltip >
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  width: 355,
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper{" "}
                                <Tooltip 
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 6,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </Tooltip >
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </Box>
            
                <Box>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "183px",

                      mt: 1,
                      ml: 20,
                    }}
                    onClick={handleClickOpen}
                  >
                    <AnnouncementIcon
                      sx={{ fontSize: "1rem", color: "#ff6666", mr: 1 }}
                    />
                    Feedback
                  </Typography>
                </Box>
              </Box>
            </>
          );
        } else if (category == "8") {
          return (
            <Box>

             
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  <Link to="/By-Program" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/By-Program" ? "dashactive" : ""}
                    >
                      <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Buy Full Course
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <CalendarMonthIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Event Calendar{" "}
                    <Tooltip 
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{
                          fontSize: "1rem",
                          ml: 6.5,
                          mt: 0.5,
                          color: "#000",
                        }}
                      />
                    </Tooltip >
                  </Typography>

                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Certification Exam{" "}
                    <Tooltip 
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 4, mt: 0.5, color: "#000" }}
                      />
                    </Tooltip >
                  </Typography>

                  <Link
                    to="/CareerCounselling"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/CareerCounselling" ? "dashactive" : ""
                      }
                    >
                      <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Admission Counselor
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Certificates & Awards{" "}
                    <Tooltip 
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 1, mt: 0.5, color: "#000" }}
                      />
                    </Tooltip >
                  </Typography>
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width: "300px",
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}
<Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <DescriptionIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Product Portfolio
                      <Tooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 14,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width:"287px",
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc ? <ExpandMoreIcon sx={{ml:"269px"}} /> : <ChevronRightIcon sx={{ml:"269px"}}/>}
                  </ListItemButton>
                  <Collapse in={openrc} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>

                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <StarRateRoundedIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Success Story{" "}
                    <Tooltip 
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 7, mt: 0.5, color: "#000" }}
                      />
                    </Tooltip >
                  </Typography>
                </Box>
             
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "1rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        } else if (category == "10" || category == "11" || category == "12" || category == "13" || category == "14" || category =='18' || category == "19" || category == "21") {
          return (
            <Box>
 
            
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback

</Typography>
          ):(<></>)}
                  <ListItemButton
                    onClick={mhandleClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width: "287px",
                          }}
                        >
                          {" "}
                          <ToggleOffIcon
                            sx={{
                              fontSize: "1rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Manage My Program
                        </Typography>
                      }
                    />
                    {mopen ? <ExpandMoreIcon sx={{ml:"269px"}} /> : <ChevronRightIcon sx={{ml:"269px"}} />}
                  </ListItemButton>
                  <Collapse in={mopen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/Duefee" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Duefee" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Duefee" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Due Fee Payment
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Receipt" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Receipt" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Receipt" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Fee Receipt
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      {/* <Link
                        to="/Batch-Transfer"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Batch-Transfer"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Batch-Transfer"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  className={
                                    pathname === "/Batch-Transfer"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                />
                                Batch Transfer
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
                      {/* <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
{/* 
                      <Link
                        to="/Pause-Program"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Pause-Program"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Pause-Program"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Pause My Program
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
                    </List>
                  </Collapse>
                  {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }} target="_blanck">
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Calendar" ? "dashactive" : ""}
                      >
                        <CalendarMonthIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Event Calendar
                      </Typography>
                    </a>
                  <Link
                    to="/CareerCounselling"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/CareerCounselling" ? "dashactive" : ""
                      }
                    >
                      <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Career Counselling
                    </Typography>
                  </Link>
                  <Link to="/Certificates" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Certificates & Awards
                  </Typography>
                  </Link>
                  <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Certification Exam
                      </Typography>
                    </Link>
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width: "300px",
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}

                  {/* <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openrc} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}
                  <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/SuccessStory" ? "dashactive" : ""
                      }
                    >
                      <StarRateRoundedIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Success Story
                    </Typography>
                  </Link>
                  <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                </Box>
        
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "1rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box>

            
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback



</Typography>
          ):(<></>)}
                  <ListItemButton
                    onClick={mhandleClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width: "287px",
                          }}
                        >
                          {" "}
                          <ToggleOffIcon
                            sx={{
                              fontSize: "1rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Manage My Program
                        </Typography>
                      }
                    />
                    {mopen ? <ExpandMoreIcon sx={{ml:"269px"}} /> : <ChevronRightIcon sx={{ml:"269px"}} />}
                  </ListItemButton>
                  <Collapse in={mopen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/Duefee" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Duefee" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Duefee" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Due Fee Payment
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Receipt" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Receipt" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Receipt" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Fee Receipt
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                  
                    </List>
                  </Collapse>
                  {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "1rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width: "300px",
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/Calendar" ? "dashactive" : ""}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Event Calendar
                    </Typography>
                  </a>
                  <Link
                    to="/CareerCounselling"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/CareerCounselling" ? "dashactive" : ""
                      }
                    >
                      <ForumIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Career Counselling
                    </Typography>
                  </Link>
                  <Link to="/Certificates" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "1rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "300px",
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1rem", mr: 2 }} />
                    Certificates & Awards
                  </Typography>
                  </Link>
                  <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Certification Exam
                      </Typography>
                    </Link>
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width: "300px",
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}
<Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <DescriptionIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Product Portfolio
                      <Tooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 14,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "1rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width:"287px",
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc ? <ExpandMoreIcon sx={{ml:"269px"}}/> : <ChevronRightIcon sx={{ml:"269px"}}/>}
                  </ListItemButton>
                  <Collapse in={openrc} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                  <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "1rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width: "300px",
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/SuccessStory" ? "dashactive" : ""
                      }
                    >
                      <StarRateRoundedIcon sx={{ fontSize: "1rem", mr: 2 }} />
                      Success Story
                    </Typography>
                  </Link>
                  <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                   
                </Box>
         
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "1rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        }
        })()}
      </>
    </Box>
  );
  if (user == null) {
    navigate(`/Login`);
  }
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar
        sx={{
          background: "#000",
          paddingRight: "0px!important",
          height: { xs: "60px", lg: "65px",sm:"65px",md:"65px" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none", sm: "block" }, color: "#fff" }}
          >
            <MenuIcon />
          </IconButton>
          <Grid item lg={12} container>
            <Grid item lg={2}>
              <Box display="flex">
                <>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                    alt="green iguana"
                    sx={{
                      display: { xs: "none", lg: "block" },
                      width: 100,
                      py:"11px",
                      objectFit: "unset",
                    }}
                  />

                  {(() => {
                    if (usercount == "1") {
                      return (
                        <Box
                          display="flex"
                          sx={{
                            float: "right",
                            border: "1px solid #fff",
                            borderRadius: "8px",
                            py: 0.4,
                            px: 1,
                            ml: { lg: 3, xs: 0 },
                            mt: 0.7,
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              marginBottom: "0.5rem !important",
                              mx: 2,
                              pt: 1,
                              fontWeight: "bold",
                              color: "#fff",
                            }}
                          >
                            Home
                          </Typography>
                        </Box>
                      );
                    } else {
                      return (
                        <Link to="/Myaccount">
                          <Box
                          onClick={()=>{localStorage.removeItem("codData");localStorage.removeItem("showCOD");localStorage.removeItem("coins");localStorage.removeItem("courseDetails")}}
                            display="flex"
                            sx={{
                              float: "right",
                              border: "1px solid #fff",
                              borderRadius: "8px",
                              py: 0.4,
                              px: 1,
                              ml: { lg: 3, xs: 0 },
                              mt: 0.7,
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                marginBottom: "0.5rem !important",
                                mx: 2,
                                pt: 1,
                                fontWeight: "bold",
                                color: "#fff",
                              }}
                            >
                              Home
                            </Typography>
                          </Box>
                        </Link>
                      );
                    }
                  })()}
                </>
              </Box>
            </Grid>
            <Grid item lg={10}>
              <Box>
                <Box
                  display="flex"
                  sx={{
                    float: "right",
                    border: "1px solid #fff",
                    borderRadius: "8px",
                    py: 0.8,
                    mt: 0.6,
                  }}
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      marginBottom: "0.5rem !important",
                      mx: 2,
                      pt: 1,
                    }}
                  >
                    {usernames}
                  </Typography>
      
                   
                        <CardMedia
                          component="img"
                          image={filepath}
                          alt="green iguana"
                          sx={{ width: 30, height: 30, borderRadius: "8px" }}
                        />
                 

                  <ArrowDropDownIcon sx={{ mt: 0.5 }} />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem sx={{ color: "#000" }}>
              
                       
                            <CardMedia
                              component="img"
                              image={filepath}
                              alt="green iguana"
                              sx={{
                                width: 30,
                                height: 30,
                                borderRadius: "8px",
                              }}
                            />
                       
                     

                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          ml: 1,
                          marginBottom: "0rem!important",
                          mt: -2,
                          display: "inline-block",
                        }}
                      >
                        {usernames} {lastname}
                      </Typography>
                    </MenuItem>
                  </Link>
                  <MenuItem sx={{ mt: -4 }}>
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        ml: 5,
                        marginBottom: "0.0rem!important",
                        display: "inline-block",
                      }}
                    >
                      {email}
                    </Typography>
                  </MenuItem>

                  <Divider />
                  <Link
                    to="/Profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      My Profile
                    </MenuItem>
                  </Link>
                  <Link
                    to={`/Policy/${policylink}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <PolicyIcon fontSize="small" />
                      </ListItemIcon>
                      Student Policy
                    </MenuItem>
                  </Link>
                  <Divider />
                  <Link
                    to="/ChangePassword"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem sx={{ color: "#000" }}>
                      <ListItemIcon>
                        <SyncLockIcon fontSize="small" />
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={LogOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                <Box
                  sx={{
                    float: "right",
                    mr: 5,
                    display: { xs: "none", lg: "block",sm:"block",md:"block" },
                  }}
                >
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/star.png"
                        alt="green iguana"
                        sx={{ width: 17, height: 17, borderRadius: "8px" }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "#fea116",
                          fontWeight: "bold",
                          fontSize: "13px",
                          ml: 1,
                        }}
                      >
                        {coin}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    float: "right",
                    mr: 4,
                    display: { xs: "none", lg: "block", lg: "block",sm:"block",md:"block" },
                  }}
                >
                  {(() => {
                    if (category == "7" || category == "8") {
                      return (
                        <>
                         <Link to='/By-Program'>
                          <Button
                            variant="contained"
                            sx={{
                              background: "#1572A1",
                              boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                              borderRadius: "50px",
                              textTransform: "none",
                              height: "40px",
                              mt: 0.9,
                              "&:hover": {
                                background: "#1572A1",
                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: "50px",
                                textTransform: "none",
                                height: "40px",
                              },
                            }}
                          >
                            Purchase Full Course
                          </Button>
                          </Link>
                        </>
                      );
                    } else  if (category == "2" || category == "4" || category == "1"|| category == "3") {
                      return (
                        <Link to='/Program-Upgrade'>
                        <Button
                          variant="contained"
                          sx={{
                            background: "#1572A1",
                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                            borderRadius: "50px",
                            textTransform: "none",
                            height: "40px",
                            mt: 0.9,
                            ml:{sm:5,md:5},
                            "&:hover": {
                              background: "#1572A1",
                              boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                              borderRadius: "50px",
                              textTransform: "none",
                              height: "40px",
                            },
                          }}
                        >
                          Program Upgrade{" "}
                        </Button>
                        </Link>
                      );
                    }
                  })()}
                </Box>
                <Box
                  sx={{ float: "right", mr: { lg: 5, xs: 2 }, ml: { xs: 2,sm:5,md:35 } }}
                >
                  <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                    <Badge
                      badgeContent={countnoti}
                      color="error"
                      sx={{cursor:"pointer"}}
                      onClick={notifictionClick}
                    >
                      <NotificationsNoneIcon sx={{ color: "#a1a5b7" }} />
                    </Badge>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />

      <Popper id={id} open={opens} anchorEl={noanchorEl}>
        <Box sx={{ mt: 4 }}>
          <Box>
            <Box>
              <Typography
                sx={{
                  bgcolor: "background.paper",
                  borderTopLeftRadius: ".475rem",
                  borderTopRightRadius: ".475rem",
                  pl: 2,
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "40px",
                  py: 2,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                Notifications
              </Typography>
            </Box>
            <List
              sx={{
                width: "368px",

                bgcolor: "background.paper",
                maxHeight: 260,
                mt: -3,
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px grey",
                  webkitBoxShadow: "inset 0 0 6px grey",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "grey",
                  outline: "1px solid grey",
                  borderRadius: "10px",
                },
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                mb: 2,
              }}
            >
              {notifiction &&
                notifiction.map((newnotiction) => (
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      borderBottom: "1px solid #e6e6e6",

                      "&:hover": {
                        background: "#eff2f5",
                      },
                    }}
                    component="a"
                    href={newnotiction.notification_url}
                    target="_blank"
                    onClick={() => checkdclick(newnotiction.id)}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  fontWeight: "bold",
                                  lineHeight: "1.6",
                                  marginBottom: "0.8rem!important",
                                  width: "320px",
                                  color: "#000",
                                }}
                              >
                                {newnotiction.heading_text}
                              </Typography>
                            </Box>
                            {(() => {
                              if (
                                newnotiction.notification_url &&
                                newnotiction.checked != 1
                              ) {
                                return (
                                  <>
                                    <Box sx={{}}>
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#009ef7",
                                        }}
                                      />
                                    </Box>
                                  </>
                                );
                              }
                            })()}
                          </Box>
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  marginBottom: "0rem!important",
                                }}
                                color="#a1a5b7"
                              >
                                {newnotiction.notification_text}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "0.7rem",
                                  fontWeight: "bold",
                                  marginBottom: "0rem!important",
                                }}
                                color="#1572A1"
                              >
                                {newnotiction.duration} ago
                              </Typography>
                            </Box>
                            <Box></Box>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Popper>
      <Dialog
        open={feddbackopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
     
        <DialogTitle id="feedback">
          {"Feedback"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
        <form onSubmit={submitForm}>
          <DialogContent>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              Any suggestions or feedback on your dashboard experience?
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="Comments or suggestions"
              multiline
              rows={3}
              placeholder="Comments or suggestions"
              value={feedbackdes}
              onChange={feedbackdeschnage}
              fullWidth
            />
          </DialogContent>
          <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                mb: 0.3,

                mr: 3,
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",

                "&:hover": {
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",
                },
              }}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                mb: 0.3,
                mr: 3,
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",

                "&:hover": {
                  background: "#f5f8fa",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#000",
                },
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
        <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openloder}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Dialog
        open={shreefeddbackopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "600px",
          },
        }}
      >
         <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openloder}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
     
          <form onSubmit={ShareFeedbacksubmitForm}>
        <DialogTitle id="shreefeddbackopen">
          {"Share Feedback"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
      
        <DialogContent>
       
          <Box>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
            Teram {sharefeedback.term_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
              Instructor: {sharefeedback.faculty_name}
            </Typography>
          </Box>
          <TextField
            id="Category"
            select
            label={isShown === false ? "Category" : ""}
            // label="Select Program"
            required={true}
            InputLabelProps={{ shrink: false }}
            fullWidth
            onChange={otherClick}
            sx={{ mb: 2, mt: 2 }}
          >
            {Category.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {isShown === "others" && (
            <TextField
              id="other_feedback"
              variant="outlined"
              placeholder="Other Feedback For"
              sx={{ mb: 2 }}
              fullWidth
              value={otherfeedbackdes}
              onChange={otherfeedbackdeschnage}
            />
          )}

          <TextField
            id="outlined-multiline-static"
            label="Feedback"
            multiline
            rows={3}
            placeholder="Feedback"
            value={feedbackdes}
            onChange={feedbackdeschnage}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              mr: 3,
              background: "#009ef7",

              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",
              },
            }}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mb: 0.3,
              mr: 3,
              background: "#f5f8fa",
              borderRadius: "8px",
              textTransform: "none",
              color: "#000",

              "&:hover": {
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
              },
            }}
          >
            Discard
          </Button>
         
        </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
LoginDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default LoginDashboard;
