import React, { useState} from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  TextField,

} from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useLocation,useSearchParams } from "react-router-dom";
import axios from "axios";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import Rcta from "../cta/request_a_call_back";
import "./top.css";
const currencies = [

  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Business Management",
    label: "Business Management",
  },
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function RcbDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RcbDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function Topbar(props) {

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [program, setProgram] = useState("");
  const [Rcbopen, RcbsetOpen] = useState(false);
  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  // const handleClickOpenRcb = () => {
  //   RcbsetOpen(true);
  // };
  // const RcbhandleClose = () => {
  //   RcbsetOpen(false);
  // };

  
  const [token, setToken] = useState('')
  const [cta, setCta] = useState(false);
  const handleClickOpenRcb = () => {
    setCta(true);
  };
  const RcbhandleClose = () => {
    setCta(false);
  };
  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token)
  }, []);
  const [open, setOpen] = useState(false);
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  const user = localStorage.getItem("user_id");
  if (user) {
    return true;
  } else {
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#0029ff",
            color: "#fff",
            position: "fixed",
            zIndex: "100",
            width: "100%",
            p: "10px 10px",
            display: { xs: "none", lg: "block",sm:"block",md:"block" },
          }}
        >
          <Container fixed>
            <Box display="flex" justifyContent="center">
              <Typography
                sx={{
                  my: "auto",
                  fontSize: "14px",
                  marginBottom: "0.2rem !important",
                }}
              >
               Navigate your ideal career path with tailored expert advice!
              </Typography>
              <Button
                variant="contained"
                sx={{
                  color: "#0029ff ",
                  backgroundColor: "#fff ",
                  borderColor: "#fff ",
                  borderRadius: "8px ",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "bold ",
                  textTransform: "none ",
                  ml: 1,
                  "&:hover": {
                    color: "#0029ff ",
                    backgroundColor: "#fff ",
                    borderColor: "#fff ",
                    borderRadius: "8px ",
                    fontSize: "12px",
                    height: "30px",
                    fontWeight: "bold ",
                    textTransform: "none ",
                    ml: 1,
                  },
                }}
                onClick={handleClickOpenRcb}
              >
                  Contact Program Expert
              </Button>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "#0029ff",
            color: "#fff",
            position: "fixed",
            zIndex: "100",
            width: "100%",
            p: "10px 10px",
            display: { xs: "none", lg: "none" },
          }}
        >
          <Container fixed>
            <Box justifyContent="center">
              <Typography
                sx={{
                  my: "auto",
                  fontSize: "14px",
                  marginBottom: "0.2rem !important",
                  textAlign: "center",
                  mx: 5,
                }}
              >
            Navigate your ideal career path with tailored expert advice!
              </Typography>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    color: "#0029ff ",
                    backgroundColor: "#fff ",
                    borderColor: "#fff ",
                    borderRadius: "8px ",
                    fontSize: "12px",
                    height: "30px",
                    fontWeight: "bold ",
                    textTransform: "none ",
                    ml: 1,
                  }}
                     onClick={handleClickOpenRcb}
                >
                  Contact Program Expert
                </Button>
              </center>
            </Box>
          </Container>
        </Box>
      
        <Rcta open={cta} handleClose={RcbhandleClose} program={props.program} />
      </>
    );
  }
}
