import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Container,
  CardMedia,
  TextField,
  Backdrop,
} from "@mui/material";
import Testingnavbar from "../Navbar/testinnav";
import Footer from "../Footer/Footer";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Lostpassword = () => {
const [email,setEmail] = useState("");
const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
    
  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
  
      email: email,

    };
    await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/password/forgot-password`,
      JSON.stringify(sandData),
      options
    )
    .then((result) => {
      if (result.data.status == "200") {
        toast.success("Password reset email has been sent. ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        e.target.reset();
      } else if(result.data.status == "300") {
        toast.warn("This email id not registered ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        e.target.reset();
      }else{
        toast.error("check once again email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
       
      }
      LoaderClose();
      setEmail("");
    });
   
  };
  return (
    <>
      <Testingnavbar />
      <Box sx={{ pt: 15, pb: 5 }}>
        <Container>
          <Grid container spacing={4} justifyContent="center">
            <Grid item lg={4} sx={{ pb: 4 }}>
              <Box
                sx={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  p: 3,
                }}
              >
                <Box>
                  <Typography
                    mb={2}
                    fontWeight="bold"
                    fontSize={25}
                    sx={{ textAlign: "center" }}
                  >
                    Lost your password?
                  </Typography>
                  <Typography mb={2} fontSize={15}>
                    Please enter your username or email address. You will
                    receive a link to create a new password via email.
                  </Typography>
                  <form onSubmit={submitForm}>
                    <Box mb={2}>
                      {" "}
                      <TextField
                        id="email"
                        label="Email"
                        variant="standard"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        fullWidth
                        sx={{ mb: 2 }}
                      />
                    </Box>

                    <Box>
                      {" "}
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ textTransform: "none" }}
                      >
                        {" "}
                        Reset password
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer/>
    </>
  );
};
export default Lostpassword;
