import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/LoginDashboard";
import CottageIcon from "@mui/icons-material/Cottage";
import Sidbar from "../Sidbar";
import ProfileDetails from "./ProfileDetails";
import WorkEducation from "./WorkEducation";
import Sociallinks from "./Sociallinks";
import StudentPolicy from "./StudentPolicy";
import VerifiedIcon from "@mui/icons-material/Verified";
import MailIcon from "@mui/icons-material/Mail";
import Cropper from "react-easy-crop";
import { styled } from "@mui/material/styles";
import getCroppedImg from "./cropImage";
import "./profile.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resizer from "react-image-file-resizer";
import ClearIcon from "@mui/icons-material/Clear";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "350px",
  },
}));

// https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp
const Profile = () => {
  const [user, setUser] = useState("");
  const [imagerespons, setImagerespons] = useState();
  const [filePath, setFilepath] = useState(localStorage.getItem("profileURL")?localStorage.getItem("profileURL"):"");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [fristname, setFristname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [uploaded,setUploaded]=useState(false)
  // const [datas, setData] = useState("");
  //console.log(uploaded,"seeupload")
  const [backopen, setBackopen] = useState(false);
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  const handleClickOpen = (e) => {
    setTimeout(() => {
      setOpen(true);
      LoaderClose()
    }, 1000);
  
    
    
    let fileInput = false;
    if (e.target.files[0]) {
      // //console.log(e.target.files[0]);
      fileInput = true;
      LoaderOpen();
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          "webp",
          100,
          0,
          (uri) => {
            // //console.log(uri);
            setImageUrl(uri);
          },
          "base64",
          300,
          300
        );
      } catch (err) {
        //console.log(err);
      }
    }
    // let value = URL.createObjectURL(event.target.files[0]);

    // setImageUrl(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  var count=localStorage.getItem("profile")?localStorage.getItem("profile"):0;

  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if(res.data.status == "300"){
        localStorage.setItem("profileURL","https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp")
        setFilepath('https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp')
      }else{
        localStorage.setItem("profileURL",res.data[0].filename)
        setFilepath(res.data[0].filename)
       
        setUploaded(true)
      }
    });
  };
  useEffect(()=>{
    if(!localStorage.getItem("profileURL")){
      fetchData()
    }
  },[])
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var fristname = localStorage.getItem("username");
    var lastname = localStorage.getItem("lastname");
    var email = localStorage.getItem("email");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    setFristname(fristname);
    setLastname(lastname);
    setEmail(email);

    //console.log(user_id, enrol_id, category);
  }, []);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    LoaderOpen()
    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
      // const imageName = file?.name?.split('.')?.pop();

      //console.log("donee", croppedImage);

      setCroppedImage(croppedImage);

      const options = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("fileName", croppedImage);
      formData.append("user_id", user);
      formData.append("prev_upload", uploaded);
      const res = await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/uploadimage/`, formData, options)
        .then((result) => {
          LoaderClose()
          if (result.data.status == "200") {
            toast.success("Your Profile Pic has been sucessfully uploaded", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setOpen();
            setFilepath(result.data.link);
            localStorage.setItem("profileURL",result.data.link)
           localStorage.setItem("profile",count+1)
            // setTimeout(() => {
            //   setFilepath(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/images/${localStorage.getItem("user_id")}.webp`);
            //   //console.log("Hello, World!");
            // }, 500);
          } else {
            toast.error("🦄 Error", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels,imageUrl]);
  if (user == null) {
    navigate(`/Login`);
  }


  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backopen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
          {enrol_id=="null"?(
             <Paper
             sx={{
               ml: 2,
               position: "fixed",
               height: "350px",
               borderRadius: "8px",
               width: "245px",
             }}
           >
             <Box sx={{ p: 2 }}>
               <Typography sx={{ fontSize: "0.7rem", color: "#a1a5b7" }}>
                 DASHBOARD
               </Typography>
               <Typography
                 sx={{ fontSize: "1rem", marginBottom: "0.5rem !important" }}
               >
                 <CottageIcon sx={{ fontSize: "1rem", mx: 1 }} />
                 My Dashboard
               </Typography>
             </Box>
           </Paper>
          ):( <Sidbar />)}
             
            </Grid>
            <Grid item lg={9} sm={12} md={12} xs={12}>
              <Paper
                sx={{
                  ml: 3,
                  mr:{lg:10,sm:3,md:3,xs:2},
                  borderRadius: "8px",
                  boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                  mb: 4,
                }}
              >
                <Grid container sx={{ p: 2 }}>
                  <Grid item lg={2} sm={2} md={2}>
                    <Box sx={{ mt: 0.7 }}>
                
                       
                        
                            <CardMedia
                              component="img"
                              image={filePath}
                              alt="name"
                              sx={{
                                height: "102px",
                                width: "102px",
                                borderRadius: "5px",
                                objectFit:"unset",
                              }}
                            />
                          
                       
                    
                    </Box>
                  </Grid>
                  <Grid item lg={10} sm={10} md={10} >
                    <Box display="flex" sx={{ml:{sm:2,md:2,lg:0,xs:2},pt:{sm:2,md:2,lg:2,xs:3}}}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          marginBottom: "0.1rem!important",
                        }}
                      >
                        {fristname}{lastname}
                      </Typography>
                      <VerifiedIcon sx={{ ml: 1, color: "#009ef7", mt: 0.5 }} />
                    </Box>
                    <Box display="flex" sx={{ mb: 0.4,ml:{sm:2,md:2,lg:0,xs:2} }}>
                      <MailIcon
                        sx={{ color: "#b5b5c3", fontSize: "0.9rem", mt: 0.3 }}
                      />
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0.1rem!important",
                          color: "#b5b5c3",
                          ml: 1,
                        }}
                      >
                        {email}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          mb: 0.3,
                          background: "#009ef7",

                          borderRadius: "8px",
                          textTransform: "none",

                          "&:hover": {
                            background: "#009ef7",

                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                      >
                        Upload new photo
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          // id="profilepicture"
                          name="fileName"
                          onChange={handleClickOpen}
                        />
                      </Button>
                 
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.7rem",
                          marginBottom: "0.1rem!important",
                          color: "#b5b5c3",
                        }}
                      >
                        Allowed JPG, JPEG or PNG.
                      </Typography>
                    </Box>
                    <Box></Box>
                  </Grid>
                </Grid>
              </Paper>
              <Box sx={{ mb: 4 }}>
                <ProfileDetails />
              </Box>
              <Box sx={{ mb: 4 }}>
                <WorkEducation />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Sociallinks />
              </Box>
              <Box sx={{ mb: 4 }}>
                <StudentPolicy />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Profile Image"}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <Slider
              size="small"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-label="Small"
              valueLabelDisplay="auto"
              s
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              sx={{ mt: 25 }}
            />
            {/* <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        /> */}
          </Box>
          <Box>
            <CardMedia
              component="img"
              image={croppedImage}
              sx={{
                height: "102px",
                width: "112px",
                borderRadius: "5px",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              background: "#009ef7",
              textTransform: "none",
              "&:hover": {
                background: "#009ef7",
                textTransform: "none",
              },
            }}
            onClick={showCroppedImage}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default Profile;
