import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/LoginDashboard";
import Sidbar from "./Sidbar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  var CryptoJS = require("crypto-js");
  let navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [messagecolor, setMessagecolor] = useState();
  const [user, setUser] = useState();
  const [npassword, setPassword] = useState();

  var pass, conPass;
  const fetchVal = (e) => {
    if (e.target.id == "password") {
      pass = e.target.value;
      comparePassword(pass, conPass);
    }
    if (e.target.id == "confirmPassword") {
      conPass = e.target.value;
      comparePassword(pass, conPass);
    }
  };
  const comparePassword = (pass, conPass) => {
    if (pass == "" && conPass == "") {
      setBtnDisabled(true);
      setMessage("");
    } else if (pass == conPass) {
      setBtnDisabled(false);
      setMessage("New Password and Confirm Password are matched");
      setMessagecolor("#248f24");
      setPassword(pass);
    } else if (pass != conPass) {
      setBtnDisabled(true);
      setMessage("New Password and Confirm Password are not matched");
      setMessagecolor("#ff6666");
    }
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const sandData = {
      user_id : localStorage.getItem("user_id"),
      password: CryptoJS.AES.encrypt(
        npassword,
        "ACCREDIAN@login$2022@$newweb$@"
      ).toString(),
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/change-password`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == "200") {
          localStorage.removeItem("user_id");
          localStorage.clear();
          navigate(`/Login`);
        }
      });
  };

  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
            <Grid item lg={3}>
              <Sidbar />
            </Grid>
            <Grid item lg={9}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                  Change Password
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Hone
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                  Change Password
                </Typography>
              </Box>
              <Box>
                <Paper
                  sx={{
                    ml: 3,
                    mr: 10,
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBottom: "1rem!important",
                        px: 3,
                      }}
                    >
                      Change Password
                    </Typography>
                  </Box>

                  <Box sx={{ px: 2 }}>
                    <form onSubmit={submitForm}>
                      <TextField
                        id="password"
                        label="New Password"
                        fullWidth
                        sx={{ pr: 3, mb: 3 }}
                        name="password"
                        type="password"
                        requireds
                        InputLabelProps={{ shrink: true }}
                        onChange={fetchVal}
                      />

                      <TextField
                        id="confirmPassword"
                        label="Confirm New Password"
                        fullWidth
                        sx={{ pr: 3, mb: 3 }}
                        name="qualification"
                        type="password"
                        required
                        InputLabelProps={{ shrink: true }}
                        onChange={fetchVal}
                      />

                      <Typography
                        color={messagecolor}
                        sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                      >
                        {message}
                      </Typography>

                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          mb: 0.3,

                          mr: 3,
                          background: "#009ef7",

                          borderRadius: "8px",
                          textTransform: "none",

                          "&:hover": {
                            background: "#009ef7",

                            borderRadius: "8px",
                            textTransform: "none",
                          },
                        }}
                        id="submit-button"
                        disabled={btnDisabled}
                      >
                        Save changes
                      </Button>
                    </form>
                    <Box sx={{ my: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          
                          fontWeight: "bold",
                        }}
                      >
                      Note:- *New Password And Confirm Password should be the same{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default ChangePassword;
