import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../dashboard.css";
const ByProgramNav = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box>
          <Paper
            sx={{
              ml:{lg:3},
              mr:{lg:10},
              borderRadius: "8px",
              boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
              p:{lg:2,xs:0},
            }}
          >
            <Grid container sx={{ py: 2 }}>
              <Grid item lg={2.5} xs={6} md={2} sm={6} sx={{mb:{xs:2,lg:0,md:0}}}>
               
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      marginBottom: "0.5rem!important",
                      textAlign: "center",
                      ml:{xs:2,lg:0},
                    }}
                    className={pathname === "/Duefee" ? "myprogramactive" : ""}
                  >
                    Due Fee Payment
                  </Typography>
                  <Typography
                    sx={{ color: "#a1a5b7", marginBottom: "0rem!important" }}
                    className={pathname === "/Duefee" ? "line" : ""}
                  ></Typography>
            
              </Grid>
              <Grid item lg={2} xs={6} md={2} sm={6}  sx={{mb:{xs:2,lg:0,md:0}}}>
            
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      marginBottom: "0rem!important",
                      textAlign: "center",
                    }}
                    className={pathname === "/Receipt" ? "myprogramactive" : ""}
                  >
                    Fee Receipt
                  </Typography>
                  <Typography
                    sx={{ color: "#a1a5b7", marginBottom: "0rem!important" }}
                    className={pathname === "/Receipt" ? "line" : ""}
                  ></Typography>
             
              </Grid>
              <Grid item lg={2} xs={6} md={2} sm={6}  sx={{mb:{xs:2,lg:0,md:0}}}>
   
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      marginBottom: "0rem!important",
                      textAlign: "center",
                    }}
                    className={
                      pathname === "/Batch-Transfer" ? "myprogramactive" : ""
                    }
                  >
                    Batch Transfer
                  </Typography>
                  <Typography
                    sx={{ color: "#a1a5b7", marginBottom: "0rem!important" }}
                    className={pathname === "/Batch-Transfer" ? "line" : ""}
                  ></Typography>
            
              </Grid>
              <Grid item lg={2.5} xs={6} md={2} sm={6}  sx={{mb:{xs:2,lg:0,md:0}}}>
                <Link to="/By-Program" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      marginBottom: "0rem!important",
                      textAlign: "center",
                    }}
                    className={
                      pathname === "/By-Program" ? "myprogramactive" : ""
                    }
                  >
                  Buy Full Course
                  </Typography>
                  <Typography
                    sx={{ color: "#a1a5b7", marginBottom: "0rem!important" }}
                    className={pathname === "/By-Program" ? "line" : ""}
                  ></Typography>
                </Link>
              </Grid>
              <Grid item lg={2.5} xs={10} md={2} sm={6}  sx={{mb:{xs:2,lg:0,md:0}}}>
           
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      marginBottom: "0rem!important",
                      textAlign:"center" ,
                      ml:{xs:-8,lg:0,md:0,sm:0},
                 
                      
                    }}
                    className={
                      pathname === "/Pause-Program" ? "myprogramactive" : ""
                    }
                  >
                    Pause My Programe
                  </Typography>
                  <Typography
                    sx={{ color: "#a1a5b7", marginBottom: "0rem!important" }}
                    className={pathname === "/Pause-Program" ? "line" : ""}
                  ></Typography>
          
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};
export default ByProgramNav;
