import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  Card,
  CardContent,
  Backdrop,
  FormControl,
} from "@mui/material";

import Testingnavbar from "../Components/Navbar/testinnav";
import "../Assets/css/resource.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Datakeymodules } from "../data/keymodulesdata";
import { useParams, useSearchParams, useLocation,useNavigate,Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Footer from "../Components/Footer/Footer";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "250px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function Resourcescallback() {
  const app = localStorage.getItem("user_id");
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

},[]);
  const [cta, setCta] = useState({
    mobile: "",
    name: "",
    newemail: "",

    ctapage: "",

    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });

  const ctahandleChange = (e) => {
    setCta({ ...cta, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: cta.mobile,
      name: cta.name,
      email: cta.newemail,
      program: localStorage.getItem("programname"),
      source: "resource",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "brochure",
      type:"CTA"
    };
    //console.log(sendRcbctaData);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((conf) => {
            if (conf.isConfirmed) {
              navigate("/resource-thank-you");
              const anchor = document.createElement('a');
            anchor.href = localStorage.getItem("downloadlink");
            anchor.download = 'key Module'; // You can set the desired file name here
            anchor.style.display = 'none';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            navigate("/resource-thank-you");
            }
          });

          setCta("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  return (
    <>
      <Testingnavbar />
      <Box
        sx={{
          pt: app
            ? { lg: 2, sm: 2, md: 2, xs: 3 }
            : { lg: 8, sm: 5, md: 10, xs:15 },
          mt: app
            ? { lg: 0, sm: 0, md: 0, xs: 0 }
            : { lg: 0, sm: 0, md: 0, xs: 0 },
      
        }}
      >
        <Box
          sx={{
            backgroundImage: `url("https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/bgimg.webp")`,
            height:{lg: "510px",md: "85vh",sm:"850px",xs:"90vh"},
         mb:{lg:2},
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",
          }}
        >
          <Container fixed>
            <Grid
              lg={12}
              xl={12}
              sm={12}
              md={12}
              container
              sx={{ pt:{lg:5,md:5,sm:5,xs:0}}}
            >
              <Grid xl={5} lg={6} sm={12} md={6} xs={12} sx={{}}>
                <Box sx={{ mt:{lg:3,md:5,sm:5,xs:0},px:{lg:5} }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize:{xl:"17px",lg: "17px",md: "17px",sm: "22px",xs: "20px"},
                     
                        fontFamily: "Poppins !important",

                        color: "#ffc107",
                      }}
                    >
                      Free Resources
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize:{xl:"35px",lg: "35px",md: "35px",sm: "40px",xs: "35px"},
                       
                        fontFamily: "Poppins !important",
                        color: "#fff",
                      }}
                    >
                      Before You Choose
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize:{xl:"15px",lg: "15px",md: "15px",sm: "25px",xs: "20px"},
                        fontFamily: "Poppins !important",

                        color: "#fff",
                      }}
                    >
                      Check out our program resources & find out more details to
                      help you upskill.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xl={6} lg={6} sm={12} md={6} xs={12} sx={{display:"flex",justifyContent:"center",mt:{sm:2,xs:2}}} >
                <Box
                  sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    background: "#fff",
                    p: 3,
                    width: "380px",
                    
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontFamily: "Poppins !important",
                        textAlign: "center",
                        color: "#000",
                        mt: 2,
                      }}
                    >
                      Fill out the form to get your free download.
                    </Typography>
                  </Box>
                  <form onSubmit={submitForm}>
                    <Box sx={{ mt: 3 }}>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BootstrapInput
                          id="bootstrap-input"
                          placeholder="First Name"
                          name="name"
                          onChange={ctahandleChange}
                          value={cta.name}
                          required
                          type="text"
                        />
                      </Box>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BootstrapInput
                          id="bootstrap-input"
                          placeholder="Email"
                          type="email"
                          name="newemail"
                          onChange={ctahandleChange}
                          value={cta.email}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          mb: 3,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BootstrapInput
                          id="bootstrap-input"
                          placeholder="Mobile Number"
                          onChange={ctahandleChange}
                          value={cta.mobile}
                          required
                          type="number"
                          name="mobile"
                        />
                      </Box>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ textTransform: "none" }}
                        >
                          {" "}
                          Get the guide
                        </Button>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Poppins !important",
                            textAlign: "center",
                            color: "#6c757d",
                            mt: 2,
                          }}
                        >
                          By submitting this form, I agree to the terms in
                          Accredian{" "}
                          <span style={{ color: "#ffc107" }}>
                            {" "}
                           <Link to='/Policy/privacypolicy' style={{ color: "#ffc107",textDecoration:"none" }}> Privacy Policy</Link>
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </>
  );
}
export default Resourcescallback;
