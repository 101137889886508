import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Backdrop,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CottageIcon from "@mui/icons-material/Cottage";
import TaskIcon from "@mui/icons-material/Task";
import PersonIcon from '@mui/icons-material/Person';
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DonutSmallRoundedIcon from "@mui/icons-material/DonutSmallRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ArticleIcon from '@mui/icons-material/Article';
import axios from "axios";
import { decode as atob, encode as btoa } from "base-64";
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import "./dashboard.css";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
const Category = [
  {
    value: "faculty",
    label: "Faculty",
  },
  {
    value: "material",
    label: "Material",
  },
  {
    value: "quizzes_assignments",
    label: "Quizzes/Assignments",
  },
  {
    value: "projects",
    label: "Projects",
  },
  {
    value: "others",
    label: "Others",
  },
];
const Sidbar = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [career, setCareer] = useState(false);
  const [project, setProjrct] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [feddbackopen, setFeddbackopen] = useState();
  const [shreefeddbackopen, setFeddbackopenshree] = useState();
  const [openrc, setOpenrc] = useState(false);
  const [openpp, setOpenpp] = useState(false);
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [term, setTerm] = useState("");
  const [feedbackdes, setFeedbackdes] = useState("");
  const [otherfeedbackdes, setOtherfeedbackdes] = useState("");
  const [openloder, setOpenloder] = useState("");
  const [issudeon,setIssudon]= useState("");
  const [sharefeedback, setSharefeedback] = useState("");


  const feedbackdeschnage = (event) => {
    setFeedbackdes(event.target.value);
  };
  const otherfeedbackdeschnage = (event) => {
    setOtherfeedbackdes(event.target.value);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const careerhandleClick = () => {
    setCareer(!career);
  };
  const projecthandleClick = () => {
    setProjrct(!project);
  };
  const certificatehandleClick = () => {
    setCertificate(!certificate);
  };
  const handleRcClick = () => {
    setOpenrc(!openrc);
  };
  const handlePpClick = () => {
    setOpenpp(!openpp);
  };
  const handleClickOpen = () => {
    setFeddbackopen(true);
  };

  const handleClose = () => {
    setFeddbackopen(false);
    setFeddbackopenshree(false);
  };
  const shreehandleClickOpen = () => {
    setFeddbackopenshree(true);
  };
  const [isShown, setIsShown] = useState(false);
  const otherClick = (e) => {
    setIsShown(e.target.value);
  };
  const certificationExam = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/certificationExam`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        program_id: localStorage.getItem("program_id"),
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      setIssudon(res.data.certificateRow.eschedule);
        localStorage.setItem("issude_date", res.data.certificateRow.issued_on);
       //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  // const certifictissued = async () => {
  //   await axios({
  //     method: "post",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json",
  //     },
  //     url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/certification_exam/certifictissued`,
  //     data: {
  //       program_id: localStorage.getItem("program_id"),
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   }).then((res) => {
      
  //     //console.log(res.data,"certifictissued");

  //     //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
  //   });
  // };
  
  const sharefeedbackdetails = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetch_share_feedback_details`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),

      },
    }).then((res) => {
      setSharefeedback(res.data);
    });
  };
  const newemail = btoa(localStorage.getItem("email"));
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    // certificationExam();
    sharefeedbackdetails();
    // certifictissued();
    //console.log(user_id, enrol_id, category);
  }, []);
  const LoaderClose = () => {
    setOpenloder(false);
  };
  const LoaderOpen = () => {
    setOpenloder(true);
  };
  const submitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      enrol_id: localStorage.getItem("enrol_id"),
      message: feedbackdes,
      category:localStorage.getItem("category")
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/feedback/student-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }

        LoaderClose();
        handleClose();
      });
    //console.log(sandData);
  };
  const ShareFeedbacksubmitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      user_id : localStorage.getItem("user_id"),
      program_id : localStorage.getItem("program_id"),
      batch_id : localStorage.getItem("batch_id"),
      term_id : localStorage.getItem("term"),
      feedback_for: isShown,
      feedback : feedbackdes,
      faculty_id : sharefeedback.faculty_id,
      faculty_email : sharefeedback.faculty_email,
      faculty_name : sharefeedback.faculty_name,
      username : sharefeedback.username,
      program_name : sharefeedback.program_name,
      batch_name : sharefeedback.batch_name,
      term_name : sharefeedback.term_name,
      user_email : sharefeedback.user_email,
      other_feedback : otherfeedbackdes,
   
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/facultyFeedback/faculty-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }
        setFeedbackdes("");
        LoaderClose();
        handleClose();
        setIsShown(false);
        setOtherfeedbackdes("");
      });
    
  };
  if (user == null) {
    navigate(`/Login`);
  }
  return (
    <>
      {(() => {
        if (category == "2" || category == "1") {
          return (
            <>
              <Box sx={{ position: "sticky", top: 75 }}>
                <Paper
                  sx={{
                    py: 2,
                    width: "245px",
                    mx: 3,
                    height: "500px",
                    pl: 1,
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0,0,0,0)",
                      outline: "1px solid #fff",
                    },
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgba(76, 87, 125, .02)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      fontSize: "0.8rem",
                      fontWeight: 600,
                    }}
                  >
                    DASHBOARD
                  </Typography>
                  <Box sx={{ p: 1 }}>
                    <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Dashboard" ? "dashactive" : ""
                        }
                      >
                        <CottageIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        My Dashboard
                      </Typography>
                    </Link>
                    {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback



</Typography>
          ):(<></>)}
              <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }} target="_blanck">
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Calendar" ? "dashactive" : ""}
                      >
                        <CalendarMonthIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Event Calendar
                      </Typography>
                    </a>

                    <ListItemButton
                      onClick={handleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <ToggleOffIcon
                              sx={{
                                fontSize: "1.3rem",
                                mr: 2,
                                "&:hover": {
                                  background: "transparent",
                                  color: "#000",
                                },
                              }}
                            />
                            Manage My Program
                          </Typography>
                        }
                      />
                      {open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade" ? <ExpandMoreIcon  /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade"  } timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Duefee" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Duefee"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Duefee"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  {" "}
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Due Fee Payment
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Receipt" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Receipt"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Receipt"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Fee Receipt
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                      
                     <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                    
                      </List>
                    </Collapse>
                    {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                
                    <ListItemButton
                      onClick={careerhandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <WifiProtectedSetupIcon
                              sx={{
                                fontSize: "1.3rem",
                                mr: 2,
                                "&:hover": {
                                  background: "transparent",
                                  color: "#000",
                                },
                              }}
                            />
                           Career
                          </Typography>
                        }
                      />
                      {career || pathname==="/CareerCounselling" || pathname==="/career-transition" || pathname==="/Career-Launchpad" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={career || pathname==="/CareerCounselling" || pathname==="/career-transition" || pathname==="/Career-Launchpad" } timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                      <>
                      {(() => {
                        if (term > 3) {
                          return (
                            <>
                              <Link
                                to="/CareerCounselling"
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.9rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "1rem !important",
                                    width:{lg:"230px"},
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                    
                                  }}
                                  className={
                                    pathname === "/CareerCounselling"
                                      ? "dashactive"
                                      : ""
                                  }
                                >
                                 <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Career Counselling
                                </Typography>
                              </Link>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.9rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "1rem !important",
                                  width:{lg:"230px"},
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/CareerCounselling"
                                    ? "dashactive"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon sx={{ fontSize: "1rem", mr: 2 }} />
                                Career Counselling{" "}
                                <LockPersonIcon
                                  sx={{ fontSize: "1rem", ml:2.6, mt: 0.5 }}
                                />
                              </Typography>
                            </>
                          );
                        }
                      })()}
                    </>
                    <Link
                                to="/career-transition"
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.9rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "1rem !important",
                                    width:{lg:"230px"},
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/career-transition"
                                      ? "dashactive"
                                      : ""
                                  }
                                >
                                   <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Career Transition Manual
                                </Typography>
                              </Link>
                              <Link
                      to="/Career-Launchpad"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Career-Launchpad" ? "dashactive" : ""
                        }
                      >
                        <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                        Career Launchpad
                      </Typography>
                    </Link>

                    
                      </List>
                    </Collapse>

                    <ListItemButton
                      onClick={projecthandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <NoteAltIcon
                              sx={{
                                fontSize: "1.3rem",
                                mr: 2,
                                "&:hover": {
                                  background: "transparent",
                                  color: "#000",
                                },
                              }}
                            />
                           Project
                          </Typography>
                        }
                      />
                      {project || pathname==="/projects" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={project || pathname==="/projects"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                      {(() => {
                        if (term > 3) {
                          return (
                            <>
                             <Link to="/projects" style={{ textDecoration: "none" }}>
                      <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/projects" ? "dashactive" : ""}
                    >
                      <NoteAltIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Term Projects
                      {/* <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip> */}
                    </Typography>
                        </Link>
                            </>
                          );
                        } 
                      })()}
                     
                     
                    <Link to="/Gallery" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Gallery" ? "dashactive" : ""}
                      >
                        <CollectionsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Project Gallery
                      </Typography>
                    </Link>
                      </List>
                    </Collapse>
               
                    {/* <Link to="/Projects" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Projects" ? "dashactive" : ""}
                      >
                        <NoteAltIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Term Projects
                      </Typography>
                    </Link> */}
                  
                    <Link
                      to="/Resume-toolkit"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Resume-toolkit" ? "dashactive" : ""
                        }
                      >
                        <DescriptionIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Resume Toolkit
                      </Typography>
                    </Link>
                    <Link
                      to="/Job-guarantee"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Job-guarantee" ? "dashactive" : ""
                        }
                      >
                        <BusinessCenterIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                        Job Guarantee
                      </Typography>
                    </Link>
                    {/* <Link to="/Certificates" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certificates" ? "dashactive" : ""
                        }
                      >
                        <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certificates & Awards
                      </Typography>
                    </Link> */}
                 <ListItemButton
                      onClick={certificatehandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certificates
                          </Typography>
                        }
                      />
                      {certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates" } timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                      {(() => {
                        // if (term > 3) {
                          
                        // } 
                        return (
                          <>
                           <Link to="/Certificates" style={{ textDecoration: "none" }}>
                    <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                    className={pathname === "/Certificates" ? "dashactive" : ""}
                  >
                  
                    <MilitaryTechIcon sx={{ fontSize: "1.4rem", mr: 2 }} />
                      Certificates & Awards
                    {/* <LightTooltip
                      title="Exciting updates coming soon for this feature!"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{
                          fontSize: "1rem",
                          ml: 6,
                          mt: 0.5,
                          color: "#000",
                        }}
                      />
                    </LightTooltip> */}
                  </Typography>
                      </Link>
                          </>
                        );
                      })()}
                     
                     
                    <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                      // onClick={fetchcertificationExam}
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Certification-Exam" ? "dashactive" : ""}
                      >
                        <ArticleIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                      </List>
                    </Collapse>



                    <ListItemButton
                      onClick={handleRcClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                            }}
                          >
                            {" "}
                            <DonutSmallRoundedIcon
                              sx={{ fontSize: "1.3rem", mr: 2 }}
                            />
                            Resource Center
                          </Typography>
                        }
                      />
                      {openrc || pathname === "/Article" || pathname === "/Books" || pathname === "/Case-Studies"|| pathname === "/Cheat-Sheet" ||  pathname === "/Guides"||  pathname === "/Infographics"||  pathname === "/Interview-Questions"||  pathname === "/Research-Paper" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={openrc || pathname === "/Article" || pathname === "/Books" || pathname === "/Case-Studies"|| pathname === "/Cheat-Sheet" ||  pathname === "/Guides"||  pathname === "/Infographics"||  pathname === "/Interview-Questions"||  pathname === "/Research-Paper"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Article" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Article"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Article"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Article
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Books" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Books" ? "mangmyprogramactive" : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Books" ? "myprogrgamnav" : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Books
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Case-Studies"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Case-Studies"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Case-Studies"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Case Studies
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Cheat-Sheet"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Cheat-Sheet"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Cheat-Sheet"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  CheatSheet
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Guides" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Guides"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Guides"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Guides
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Infographics"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Infographics"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Infographics"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Infographics
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Interview-Questions"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Interview-Questions"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Interview-Questions"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Interview Questions
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Research-Paper"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              pt: 0.5,
                              pb: 1,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Research-Paper"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Research-Paper"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Research Paper
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/SuccessStory" ? "dashactive" : ""
                        }
                      >
                        <StarRateRoundedIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                        Success Story
                      </Typography>
                    </Link>
                    <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                    {/* <Link to="/zoom" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/zoom" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                      Join Class
                      </Typography>
                    </Link> */}
                  </Box>
                </Paper>
                <Box>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "183px",

                      mt: 1,
                      ml: 20,
                    }}
                    onClick={handleClickOpen}
                  >
                    <AnnouncementIcon
                      sx={{ fontSize: "1.3rem", color: "#ff6666", mr: 1 }}
                    />
                    Feedback
                  </Typography>
                </Box>
              </Box>
            </>
          );
        } else if (category == "7") {
          return (
            <>
              <Box sx={{ position: "sticky", top: 75 }}>
                <Paper
                  sx={{
                    py: 2,
                    width: "245px",
                    mx: 3,
                    height: "500px",
                    pl: 1,
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0,0,0,0)",
                      outline: "1px solid #fff",
                    },
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgba(76, 87, 125, .02)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#a1a5b7",
                      fontSize: "0.8rem",
                      fontWeight: 600,
                    }}
                  >
                    DASHBOARD
                  </Typography>
                  <Box sx={{ p: 1 }}>
                    <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Dashboard" ? "dashactive" : ""
                        }
                      >
                        <CottageIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        My Dashboard
                      </Typography>
                    </Link>
                    <Link to="/By-Program" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/By-Program" ? "dashactive" : ""
                        }
                      >
                        <ForumIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Buy Full Course
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Event Calendar{" "}
                      <LightTooltip
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6.5,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>
                    <Link
                      to="/CareerCounselling"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/CareerCounselling" ? "dashactive" : ""
                        }
                      >
                        <ForumIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Admission Counselor
                      </Typography>
                    </Link>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <NoteAltIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Term Projects{" "}
                      <LightTooltip
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 7.7,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>

                    <Link to="/Gallery" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Gallery" ? "dashactive" : ""}
                      >
                        <CollectionsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Project Gallery
                      </Typography>
                    </Link>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Certificates & Awards{" "}
                      <LightTooltip
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 1,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>

                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <SignalCellularAltIcon
                        sx={{ fontSize: "1.3rem", mr: 2 }}
                      />
                      Career Launchpad{" "}
                      <LightTooltip
                        title="Upgrade your program to access this"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 4,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>

                    <ListItemButton
                      onClick={handleRcClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                            }}
                          >
                            {" "}
                            <DonutSmallRoundedIcon
                              sx={{ fontSize: "1.3rem", mr: 2 }}
                            />
                            Resource Center
                          </Typography>
                        }
                      />
                      {openrc || pathname === "/Article" || pathname === "/Books" || pathname === "/Case-Studies"|| pathname === "/Cheat-Sheet" ||  pathname === "/Guides"||  pathname === "/Infographics"||  pathname === "/Interview-Questions"||  pathname === "/Research-Paper" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={openrc || pathname === "/Article" || pathname === "/Books" || pathname === "/Case-Studies"|| pathname === "/Cheat-Sheet" ||  pathname === "/Guides"||  pathname === "/Infographics"||  pathname === "/Interview-Questions"||  pathname === "/Research-Paper"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                        <Link to="/Article" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Article"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Article"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Article
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Books{" "}
                                <LightTooltip
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 14,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </LightTooltip>
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <Link
                          to="/Case-Studies"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Case-Studies"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Case-Studies"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Case Studies
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link
                          to="/Cheat-Sheet"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Cheat-Sheet"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Cheat-Sheet"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  CheatSheet
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>
                        <Link to="/Guides" style={{ textDecoration: "none" }}>
                          <ListItemButton
                            sx={{
                              px: 3,
                              py: 0.5,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                            className={
                              pathname === "/Guides"
                                ? "mangmyprogramactive"
                                : ""
                            }
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.88rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "0rem !important",
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/Guides"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                >
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                                  Guides
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </Link>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Infographics{" "}
                                <LightTooltip
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 9,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </LightTooltip>
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  width: 355,
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions{" "}
                                <LightTooltip
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 2.5,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </LightTooltip>
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  width: 355,
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper{" "}
                                <LightTooltip
                                  title="Upgrade your program to access this"
                                  placement="top"
                                  arrow
                                >
                                  <LockPersonIcon
                                    sx={{
                                      fontSize: "1rem",
                                      ml: 6,
                                      mt: 0.5,
                                      color: "#000",
                                    }}
                                  />
                                </LightTooltip>
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </Box>
                </Paper>
                <Box>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width: "183px",

                      mt: 1,
                      ml: 20,
                    }}
                    onClick={handleClickOpen}
                  >
                    <AnnouncementIcon
                      sx={{ fontSize: "1.3rem", color: "#ff6666", mr: 1 }}
                    />
                    Feedback
                  </Typography>
                </Box>
              </Box>
            </>
          );
        } else if (category == "8") {
          return (
            <Box sx={{ position: "sticky", top: 75 }}>
              <Paper
                sx={{
                  py: 2,
                  width: "245px",
                  mx: 3,
                  height: "455px",
                  pl: 1,
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "0.2em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0)",
                    outline: "1px solid #fff",
                  },
                  borderRadius: "8px",
                }}
                elevation={3}
              >
                <Typography
                  sx={{ color: "#a1a5b7", fontSize: "0.8rem", fontWeight: 600 }}
                >
                  DASHBOARD
                </Typography>
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  <Link to="/By-Program" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/By-Program" ? "dashactive" : ""}
                    >
                      <ForumIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Buy Full Course
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <CalendarMonthIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Event Calendar{" "}
                    <LightTooltip
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{
                          fontSize: "1rem",
                          ml: 6.5,
                          mt: 0.5,
                          color: "#000",
                        }}
                      />
                    </LightTooltip>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EditIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Certification Exam{" "}
                    <LightTooltip
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 4, mt: 0.5, color: "#000" }}
                      />
                    </LightTooltip>
                  </Typography>

                  <Link
                    to="/CareerCounselling"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/CareerCounselling" ? "dashactive" : ""
                      }
                    >
                      <ForumIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Admission Counselor
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Certificates & Awards{" "}
                    <LightTooltip
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 1, mt: 0.5, color: "#000" }}
                      />
                    </LightTooltip>
                  </Typography>
                  <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <DescriptionIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Product Portfolio
                      <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 4,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1.3rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width:{lg:"230px"},
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}

                  <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1.3rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc || pathname === "/PM-Article" || pathname === "/E-Books" || pathname === "/PM-Case-Studies"|| pathname === "/PM-Cheat-Sheet" ||  pathname === "/PM-Interview-Questions"||  pathname === "/PM-Research-Paper" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={openrc || pathname === "/PM-Article" || pathname === "/E-Books" || pathname === "/PM-Case-Studies"|| pathname === "/PM-Cheat-Sheet" ||  pathname === "/PM-Interview-Questions"||  pathname === "/PM-Research-Paper"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>

                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <StarRateRoundedIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Success Story{" "}
                    <LightTooltip
                      title="Upgrade your program to access this"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{ fontSize: "1rem", ml: 7, mt: 0.5, color: "#000" }}
                      />
                    </LightTooltip>
                  </Typography>
                </Box>
              </Paper>
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1.3rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        } else if (category == "10" || category == "11" || category == "12" || category == "13" || category == "14" || category =='18' || category == "19" || category == "21") {
          return (
            <Box sx={{ position: "sticky", top: 75 }}>
              <Paper
                sx={{
                  py: 2,
                  width: "245px",
                  mx: 3,
                  height: "455px",
                  pl: 1,
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "0.2em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0)",
                    outline: "1px solid #fff",
                  },
                  borderRadius: "8px",
                }}
                elevation={3}
              >
                <Typography
                  sx={{ color: "#a1a5b7", fontSize: "0.8rem", fontWeight: 600 }}
                >
                  DASHBOARD
                </Typography>
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  {/* <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Share Feedback
                      <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6.5,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography> */}
                                                  {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback



</Typography>
          ):(<></>)}
                  <ListItemButton
                    onClick={handleClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width:{lg:"230px"},
                          }}
                        >
                          {" "}
                          <ToggleOffIcon
                            sx={{
                              fontSize: "1.3rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Manage My Program
                        </Typography>
                      }
                    />
                    {open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/Duefee" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Duefee" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Duefee" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Due Fee Payment
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Receipt" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Receipt" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Receipt" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Fee Receipt
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      {/* <Link
                        to="/Batch-Transfer"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Batch-Transfer"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Batch-Transfer"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  className={
                                    pathname === "/Batch-Transfer"
                                      ? "myprogrgamnav"
                                      : ""
                                  }
                                />
                                Batch Transfer
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
                      {/* <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
{/* 
                      <Link
                        to="/Pause-Program"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Pause-Program"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Pause-Program"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Pause My Program
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link> */}
                    </List>
                  </Collapse>
                  {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                  <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }} target="_blanck">
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/Calendar" ? "dashactive" : ""}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Event Calendar
                    </Typography>
                  </a>
                  <Link
                    to="/CareerCounselling"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/CareerCounselling" ? "dashactive" : ""
                      }
                    >
                       <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                  />
                      Career Counselling
                    </Typography>
                  </Link>
                  {/* <Link to="/Certificates" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      cursor:"pointer",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Certificates & Awards
                  </Typography>
                  </Link> */}
                   <ListItemButton
                      onClick={certificatehandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certificates
                          </Typography>
                        }
                      />
                      {certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                      {(() => {
                        // if (term > 3) {
                          
                        // } 
                        return (
                          <>
                           <Link to="/Certificates" style={{ textDecoration: "none" }}>
                    <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                      
                    }}
                    className={pathname === "/Certificates" ? "dashactive" : ""}
                  >
                  
                    <MilitaryTechIcon sx={{ fontSize: "1.4rem", mr: 2 }} />
                      Certificates & Awards
                    {/* <LightTooltip
                      title="Exciting updates coming soon for this feature!"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{
                          fontSize: "1rem",
                          ml: 6,
                          mt: 0.5,
                          color: "#000",
                        }}
                      />
                    </LightTooltip> */}
                  </Typography>
                      </Link>
                          </>
                        );
                      })()}
                     
                     
                    <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                      // onClick={fetchcertificationExam}
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Certification-Exam" ? "dashactive" : ""}
                      >
                        <ArticleIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                      </List>
                    </Collapse>

               
                  
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1.3rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width:{lg:"230px"},
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}

                  {/* <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1.3rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openrc} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}
                  <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/SuccessStory" ? "dashactive" : ""
                      }
                    >
                      <StarRateRoundedIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Success Story
                    </Typography>
                  </Link>
                  <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                </Box>
              </Paper>
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1.3rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={{ position: "sticky", top: 75 }}>
              <Paper
                sx={{
                  py: 2,
                  width: "245px",
                  mx: 3,
                  height: "455px",
                  pl: 1,
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "0.2em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0)",
                    outline: "1px solid #fff",
                  },
                  borderRadius: "8px",
                }}
                elevation={3}
              >
                <Typography
                  sx={{ color: "#a1a5b7", fontSize: "0.8rem", fontWeight: 600 }}
                >
                  DASHBOARD
                </Typography>
                <Box sx={{ p: 1 }}>
                  <Link to="/Dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        pl: 1.1,
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                      className={pathname === "/Dashboard" ? "dashactive" : ""}
                    >
                      <CottageIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      My Dashboard
                    </Typography>
                  </Link>
                  {/* <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Share Feedback
                      <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 6.5,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography> */}
                     {sharefeedback.faculty_email!=null?(
 <Typography
 sx={{
   color: "#7e8299",
   fontSize: "0.9rem",
   fontWeight: 600,
   display: "flex",
   cursor:"pointer",
   marginBottom: "1rem !important",
   width:{lg:"230px"},
   "&:hover": { background: "transparent", color: "#000" },
   pl: 1.1,
 }}
 onClick={shreehandleClickOpen}
>
 <TaskIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
 Share Feedback



</Typography>
          ):(<></>)}

                              
                  <ListItemButton
                    onClick={handleClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                            width:{lg:"230px"},
                          }}
                        >
                          {" "}
                          <ToggleOffIcon
                            sx={{
                              fontSize: "1.3rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Manage My Program
                        </Typography>
                      }
                    />
                    {open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={open || pathname==="/Duefee" || pathname==="/Receipt" || pathname==="/Program-Upgrade"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/Duefee" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Duefee" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Duefee" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Due Fee Payment
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Receipt" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Receipt" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Receipt" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Fee Receipt
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Program-Upgrade"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Program-Upgrade"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Program-Upgrade"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Program Upgrade
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                  {(() => {
                        if (issudeon === 1) {
                          return (
                    <Link
                      to="/Certification-Exam"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/Certification-Exam" ? "dashactive" : ""
                        }
                      >
                        <EditIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                         );
                        }
                      })()}
                 <a href={`https://calendar.accredian.com/${newemail}`} style={{ textDecoration: "none" }} target="_blanck">
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={pathname === "/Calendar" ? "dashactive" : ""}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Event Calendar
                    </Typography>
                  </a>
                  <ListItemButton
                      onClick={careerhandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <WifiProtectedSetupIcon
                              sx={{
                                fontSize: "1.3rem",
                                mr: 2,
                                "&:hover": {
                                  background: "transparent",
                                  color: "#000",
                                },
                              }}
                            />
                           Career
                          </Typography>
                        }
                      />
                      {career || pathname==="/CareerCounselling" || pathname==="/career-transition" || pathname==="/Career-Launchpad" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={career || pathname==="/CareerCounselling" || pathname==="/career-transition" || pathname==="/Career-Launchpad"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                    
                      <Link
                                to="/CareerCounselling"
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#7e8299",
                                    fontSize: "0.9rem",
                                    fontWeight: 600,
                                    display: "flex",
                                    marginBottom: "1rem !important",
                                    width:{lg:"230px"},
                                    "&:hover": {
                                      background: "transparent",
                                      color: "#000",
                                    },
                                    pl: 1.1,
                                  }}
                                  className={
                                    pathname === "/CareerCounselling"
                                      ? "dashactive"
                                      : ""
                                  }
                                >
                                    <FiberManualRecordIcon
                                    sx={{ fontSize: "0.5rem", mt: 1, mr: 1,ml:3 }}
                                  />
                                  Career Counselling
                                </Typography>
                              </Link>
                    
                      </List>
                    </Collapse>
                  {/* <Link to="/Certificates" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      cursor:"pointer",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                    Certificates & Awards
                  </Typography>
                  </Link> */}
                   <ListItemButton
                      onClick={certificatehandleClick}
                      sx={{
                        p: 0,
                        width: "211px!important",
                        display: "flex",
                        marginBottom: "1rem !important",
                        "&:hover": { background: "transparent", color: "#000" },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              color: "#7e8299",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                              display: "flex",
                              marginBottom: "0rem !important",
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                              pl: 1.1,
                              width:{lg:"230px"},
                            }}
                          >
                            {" "}
                            <EmojiEventsIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certificates
                          </Typography>
                        }
                      />
                      {certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <Collapse in={certificate || pathname === "/Certification-Exam"|| pathname === "/Certificates"} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding sx={{ mt: -1 }}>
                      {(() => {
                        // if (term > 3) {
                          
                        // } 
                        return (
                          <>
                           <Link to="/Certificates" style={{ textDecoration: "none" }}>
                    <Typography
                    sx={{
                      color: "#7e8299",
                      fontSize: "0.9rem",
                      fontWeight: 600,
                      display: "flex",
                      marginBottom: "1rem !important",
                      width:{lg:"230px"},
                      "&:hover": { background: "transparent", color: "#000" },
                      pl: 1.1,
                    }}
                    className={pathname === "/Certificates" ? "dashactive" : ""}
                  >
                  
                    <MilitaryTechIcon sx={{ fontSize: "1.4rem", mr: 2 }} />
                      Certificates & Awards
                    {/* <LightTooltip
                      title="Exciting updates coming soon for this feature!"
                      placement="top"
                      arrow
                    >
                      <LockPersonIcon
                        sx={{
                          fontSize: "1rem",
                          ml: 6,
                          mt: 0.5,
                          color: "#000",
                        }}
                      />
                    </LightTooltip> */}
                  </Typography>
                      </Link>
                          </>
                        );
                      })()}
                     
                     
                    <Link to="/Certification-Exam" style={{ textDecoration: "none" }}>
                      <Typography
                      // onClick={fetchcertificationExam}
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={pathname === "/Certification-Exam" ? "dashactive" : ""}
                      >
                        <ArticleIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                        Certification Exam
                      </Typography>
                    </Link>
                      </List>
                    </Collapse>

                  <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                    >
                      <DescriptionIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Product Portfolio
                      <LightTooltip
                        title="Exciting updates coming soon for this feature!"
                        placement="top"
                        arrow
                      >
                        <LockPersonIcon
                          sx={{
                            fontSize: "1rem",
                            ml: 4,
                            mt: 0.5,
                            color: "#000",
                          }}
                        />
                      </LightTooltip>
                    </Typography>
                  {/* <ListItemButton
                    onClick={handlePpClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DescriptionIcon
                            sx={{
                              fontSize: "1.3rem",
                              mr: 2,
                              "&:hover": {
                                background: "transparent",
                                color: "#000",
                              },
                            }}
                          />
                          Product Portfolio
                        </Typography>
                      }
                    />
                    {openpp ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton> */}
                  {/* <Collapse in={openpp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link
                        to="/product-research"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-research"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-research"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Research{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-strategy"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-strategy"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-strategy"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Strategy{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/Ui-Ux" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Ui-Ux" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Ui-Ux" ? "myprogrgamnav" : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                UI & UX{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Product-development"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Product-development"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  width:{lg:"230px"},
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Product-development"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Product Development{" "}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/Go-To-Market"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/Go-To-Market" ? "myprogrgamnav" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/Go-To-Market"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                {" "}
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 0.7, mr: 1 }}
                                />
                                Go To Market
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse> */}

                  <ListItemButton
                    onClick={handleRcClick}
                    sx={{
                      p: 0,
                      width: "211px!important",
                      display: "flex",
                      marginBottom: "1rem !important",
                      "&:hover": { background: "transparent", color: "#000" },
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          sx={{
                            color: "#7e8299",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                            display: "flex",
                            marginBottom: "0rem !important",
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                            pl: 1.1,
                          }}
                        >
                          {" "}
                          <DonutSmallRoundedIcon
                            sx={{ fontSize: "1.3rem", mr: 2 }}
                          />
                          Resource Center
                        </Typography>
                      }
                    />
                    {openrc || pathname === "/PM-Article" || pathname === "/E-Books" || pathname === "/PM-Case-Studies"|| pathname === "/PM-Cheat-Sheet" ||  pathname === "/PM-Interview-Questions"||  pathname === "/PM-Research-Paper" ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItemButton>
                  <Collapse in={openrc || pathname === "/PM-Article" || pathname === "/E-Books" || pathname === "/PM-Case-Studies"|| pathname === "/PM-Cheat-Sheet" ||  pathname === "/PM-Interview-Questions"||  pathname === "/PM-Research-Paper"} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ mt: -1 }}>
                      <Link to="/PM-Article" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Article"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Article"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Article
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link to="/E-Books" style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/E-Books" ? "mangmyprogramactive" : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/E-Books" ? "myprogrgamnav" : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                E-Books
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Case-Studies"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Case-Studies"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Case-Studies"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Case Studies
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Cheat-Sheet"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Cheat-Sheet"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Cheat-Sheet"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                CheatSheet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>

                      <Link
                        to="/PM-Interview-Questions"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            py: 0.5,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Interview-Questions"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Interview-Questions"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Interview Questions
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        to="/PM-Research-Paper"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            px: 3,
                            pt: 0.5,
                            pb: 1,
                            "&:hover": {
                              background: "transparent",
                              color: "#000",
                            },
                          }}
                          className={
                            pathname === "/PM-Research-Paper"
                              ? "mangmyprogramactive"
                              : ""
                          }
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                sx={{
                                  color: "#7e8299",
                                  fontSize: "0.88rem",
                                  fontWeight: 600,
                                  display: "flex",
                                  marginBottom: "0rem !important",
                                  "&:hover": {
                                    background: "transparent",
                                    color: "#000",
                                  },
                                  pl: 1.1,
                                }}
                                className={
                                  pathname === "/PM-Research-Paper"
                                    ? "myprogrgamnav"
                                    : ""
                                }
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.5rem", mt: 1, mr: 1 }}
                                />
                                Research Paper
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                  <Link to="/SuccessStory" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#7e8299",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        marginBottom: "1rem !important",
                        width:{lg:"230px"},
                        "&:hover": { background: "transparent", color: "#000" },
                        pl: 1.1,
                      }}
                      className={
                        pathname === "/SuccessStory" ? "dashactive" : ""
                      }
                    >
                      <StarRateRoundedIcon sx={{ fontSize: "1.3rem", mr: 2 }} />
                      Success Story
                    </Typography>
                  </Link>
                  <Link to="/alumni" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#7e8299",
                          fontSize: "0.9rem",
                          fontWeight: 600,
                          display: "flex",
                          marginBottom: "1rem !important",
                          width:{lg:"230px"},
                          "&:hover": {
                            background: "transparent",
                            color: "#000",
                          },
                          pl: 1.1,
                        }}
                        className={
                          pathname === "/alumni" ? "dashactive" : ""
                        }
                      >
                        <PersonIcon
                          sx={{ fontSize: "1.3rem", mr: 2 }}
                        />
                       Alumni Connect
                      </Typography>
                    </Link>
                </Box>
              </Paper>
              <Box>
                <Typography
                  sx={{
                    color: "#7e8299",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    display: "flex",
                    marginBottom: "1rem !important",
                    width: "183px",
                    mt: 1,
                    ml: 20,
                  }}
                  onClick={handleClickOpen}
                >
                  <AnnouncementIcon
                    sx={{ fontSize: "1.3rem", color: "#ff6666", mr: 1 }}
                  />
                  Feedback
                </Typography>
              </Box>
            </Box>
          );
        }
      })()}
      <Dialog
        open={feddbackopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
     
        <DialogTitle id="feedback">
          {"Feedback"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
        <form onSubmit={submitForm}>
          <DialogContent>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              Any suggestions or feedback on your dashboard experience?
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="Comments or suggestions"
              multiline
              rows={3}
              placeholder="Comments or suggestions"
              value={feedbackdes}
              onChange={feedbackdeschnage}
              fullWidth
            />
          </DialogContent>
          <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                mb: 0.3,

                mr: 3,
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",

                "&:hover": {
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",
                },
              }}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                mb: 0.3,
                mr: 3,
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",

                "&:hover": {
                  background: "#f5f8fa",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#000",
                },
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
        <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openloder}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <Dialog
        open={shreefeddbackopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "600px",
          },
        }}
      >
         <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openloder}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
     
          <form onSubmit={ShareFeedbacksubmitForm}>
        <DialogTitle id="shreefeddbackopen">
          {"Share Feedback"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
      
        <DialogContent>
       
          <Box>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
            Teram {sharefeedback.term_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
              Instructor: {sharefeedback.faculty_name}
            </Typography>
          </Box>
          <TextField
            id="Category"
            select
            label={isShown === false ? "Category" : ""}
            // label="Select Program"
            required={true}
            InputLabelProps={{ shrink: false }}
            // defaultValue="faculty"
            fullWidth
            onChange={otherClick}
            sx={{ mb: 2, mt: 2 }}
          >
            {Category.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {isShown === "others" && (
            <TextField
              id="other_feedback"
              variant="outlined"
              placeholder="Other Feedback For"
              sx={{ mb: 2 }}
              fullWidth
              value={otherfeedbackdes}
              onChange={otherfeedbackdeschnage}
            />
          )}

          <TextField
            id="outlined-multiline-static"
            label="Feedback"
            multiline
            rows={3}
            placeholder="Feedback"
            value={feedbackdes}
            onChange={feedbackdeschnage}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              mr: 3,
              background: "#009ef7",

              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",
              },
            }}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mb: 0.3,
              mr: 3,
              background: "#f5f8fa",
              borderRadius: "8px",
              textTransform: "none",
              color: "#000",

              "&:hover": {
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
              },
            }}
          >
            Discard
          </Button>
         
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default Sidbar;
