import React, { useState } from "react";
import { Typography, Button, Box, Paper, Container, Grid,CardMedia, Backdrop,} from "@mui/material";
import Testingnavbar from "../Navbar/testinnav";
import Footer from "../Footer/Footer";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from '@mui/material/CircularProgress';
const Signup = () => {
  var CryptoJS = require("crypto-js");
  const [user, setUser] = useState({fristname:"",lastname:"", email: "", password: "" });
  let navigate = useNavigate();
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const [open, setOpen] = useState(false);

const LoaderClose = () => {
  setOpen(false);
};
const LoaderOpen = () => {
  setOpen(true);
};
  
  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();


    const sandData = {
      firstname:user.fristname,
      lastname:user.lastname,
      email: user.email,
      // password: CryptoJS.AES.encrypt(
      //   user.password,
      //   "ACCREDIAN@login$2022@$newweb$@"
      // ).toString(),
    };
    await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/signup/`,
      sandData,

    )
    .then((result) => {
      if (result.data.status == "200") {
        toast.success("your account has been registered successfully ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUser({fristname:"",lastname:"", email: "", password: "" });
    
          // Update the component's state or perform any desired action
        
          LoaderClose();
          const timeoutId = setTimeout(() => {
            // Perform the action you want after the delay
            navigate(`/Login`);
          }, 3000); // Delay in milliseconds (e.g., 2000 ms = 2 seconds)
      
          // Clear the timeout when the component unmounts to avoid memory leaks
          return () => {
            clearTimeout(timeoutId);
          };
     
      } else if(result.data.status == "300") {
        toast.warn("This email id already registered ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUser({fristname:"",lastname:"", email: "", password: "" });
      }else{
        toast.error("check once again email and password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUser({fristname:"",lastname:"", email: "", password: "" });
      }
      LoaderClose();
      setUser({fristname:"",lastname:"", email: "", password: "" });
    });

  };
  return (
    <>
    <ToastContainer/>
      <Testingnavbar />
      <Box sx={{ pt: 15 ,pb:3 }}>
        <Container>
          <Box
            sx={{
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              p: 5,
              borderRadius: 5,
              mx:{lg:20},
              width:{lg:"65%"},
            }}
          >
            <Typography mb={2} fontWeight="bold" fontSize={25} sx={{textAlign:{xs:"center",lg:"left"}}}>Sign up</Typography>
            <Grid container>
              <Grid lg={6} xs={12}>
                <Box >
                <form onSubmit={submitForm}>
                  <Box mb={2}>
                    {" "}
                    <TextField
                   
                      label="First Name"
                      variant="standard"
                      type="text"
                      name="fristname"
                      onChange={handleChange}
                      value={user.fristname}
                      fullWidth
                      required={true}
                    />
                  </Box>
                  <Box mb={2}>
                    {" "}
                    <TextField
                   
                      label="Last Name"
                      variant="standard"
                      type="text"
                      name="lastname"
                      onChange={handleChange}
                      value={user.lastname}
                      fullWidth
                      required={true}
                    />
                  </Box>
                  <Box mb={2}>
                    {" "}
                    <TextField
                  
                      label="Email"
                      variant="standard"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={user.email}
                      fullWidth
                      required={true}
                    />
                  </Box>
                  {/* <Box mb={2}>
                    <TextField
                 
                      label="Password"
                      variant="standard"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={user.password}
                      fullWidth
                    />
                  </Box> */}
                  <Box  pt={2} display="flex">
                    <Box>  <Button variant="contained" sx={{ textTransform: 'capitalize'}} type="submit">Register</Button></Box>
                
               <Box>  <Link to='/Login' style={{textDecoration:"none",color:"#000"}}> <Typography ml={5} pt={1}>Already have an account?</Typography></Link> </Box>
                  </Box>
                  </form>
                </Box>
              </Grid>
              <Grid lg={6} sx={{display:{xs:"none",lg:"block"}}}>
                <Box sx={{display:"flex",justifyContent:"center"}}>
                <CardMedia
                  component="img"
                  image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Auth/signup.webp"
                  alt="Goole"
                sx={{width:"auto",}}
                
                />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </>
  );
};
export default Signup;
