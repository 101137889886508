import React, { useState,useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  TextField,
  MenuItem,
  CardMedia,
} from "@mui/material";
import Dashborednav from "../Navbar/Dashborednav";
import CottageIcon from "@mui/icons-material/Cottage";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";

const date = moment().day("Sunday").add(7, "days");
const category = [
  {
    value: "--- Select Category ---",
    label: "--- Select Category ---",
  },
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
];

const Facultydashboard = () => {
    const [thinficurl, setThingicurl] = useState("");
    const fetchthinficurl = async () => {
        await axios({
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetch_thinkific_url`,
          data: {
            email: localStorage.getItem("email"),
            firstname: localStorage.getItem("username"),
            lastname: localStorage.getItem("lastname"),
          },
        }).then((res) => {
          setThingicurl(res.data);
    
          //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
        });
      };
      useEffect(() => {
      

      
        fetchthinficurl();
       
      }, []);
  return (
    <>
    <Dashborednav />
    <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Box sx={{ pt: 8 }}>
      
   
    <Grid item lg={12} container>
      <Grid item lg={3}>
        <Paper
          sx={{
            ml: 2,
            position: "fixed",
            height: "350px",
            width: "245px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontSize: "0.7rem", color: "#a1a5b7" }}>
              DASHBOARD
            </Typography>
            <Typography
              sx={{ fontSize: "1rem", marginBottom: "0.5rem !important" }}
            >
              <CottageIcon sx={{ fontSize: "1rem", mx: 1 }} />
              My Dashboard
            </Typography>
     
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={9}>
        <Grid item lg={12} container sx={{display:"flex",justifyContent:"center"}}>
          <Grid
            item
            lg={12}
            sx={{ maxWidth: "46.333333% !important ", mx: 2, p: 1 }}
          >
            <Paper sx={{ p: 2 }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  CONTINUE LEARNING{" "}
                </Typography>
                <Typography
                  sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                ></Typography>
                                <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/facaltylearning.webp"
                                alt="Goole"
                          
                                />
                                          <Box sx={{mt: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          mb: 2,
                          background: "#002D60",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                          height: "32px",
                        }}
                        className="start-learning-button"
                        href={thinficurl}
                        target="_blank"
                      >
                       Faculty Orientation{" "}
                      </Button>
                    </Box>
              </Box>
            </Paper>
          </Grid>

        </Grid>



      </Grid>
    </Grid>
    </Box>
    </Box>
    </>
  );
};
export default Facultydashboard;
