import React, { useEffect, useState,useRef} from 'react';
import ProgramCard from './ProgramCard';
import "../../Assets/css/Home/HomeSectionTwo.css";
import { programCardData } from '../../data/programCardData';
import { Grid, Box,Button } from '@mui/material';
import useStyles from '../../Assets/js/Home/HomeSectionTwo';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HomeSectionTwo = () => {
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        
        },
      },
    ],
  };
  const classes = useStyles();
  const [data, setData] = useState();
  const [active, setActive] = useState(0);
  const [category, setCategory] = useState("rec");
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleSelect = (selected,i) => {
    setCategory(selected);
    setActive(i);
  }

  useEffect(()=>{
    var newData = [];
    programCardData.map((program)=>{
      program.category.map((tag)=>{
        if(tag === category){
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  },[category])
  

  return (
    <>
       
        <Box sx={{ml:{lg:12,sm:7,md:7,xs:2}}}>
        <Container fixed> 
            <h3 className={classes.sectiononfont}>Get Certified, Step Up Your Career with Top Programs</h3>
           <Box sx={{display:{xs:'none',lg:'block',sm:"none",md:"none"}}}>
            <Grid lg={12} container >
              <Grid  lg={2.5} sm={2.5} md={2.5} sx={{ml:-3}}>
                <div className='program-menu' >
                  <button onClick={()=>handleSelect('rec', 0)} className={active == 0 ? "active" : ""}>Recommended</button>
                  <button onClick={()=>handleSelect('ds' , 1)} className={active == 1 ? "active" : ""}>Data Science</button>
                  <button onClick={()=>handleSelect('ai', 2)} className={active == 2 ? "active" : ""}>Artificial Inteligence</button>
                  <button onClick={()=>handleSelect('pm', 3)} className={active == 3 ? "active" : ""}>Product Management</button>
                  {/* <button onClick={()=>handleSelect('jgp', 4)} className={active == 4 ? "active" : ""}>Job Guarantee PGP</button> */}
                  <button onClick={()=>handleSelect('gm', 5)} className={active == 5 ? "active" : ""}>General Management</button>
                  {/* <button onClick={()=>handleSelect('hr', 6)} className={active == 6 ? "active" : ""}>HR Analytics</button> */}
                  {/* <button onClick={()=>handleSelect('sm', 7)} className={active == 7 ? "active" : ""}>Sales and Marketing</button> */}
                  <button onClick={()=>handleSelect('dt', 8)} className={active == 8 ? "active" : ""}>Digital Transformation</button>
                  {/* <button style={{lineHeight:"25px"}} onClick={()=>handleSelect('oscm', 9)} className={active == 9 ? "active" : ""}>Operations & Supply Chain Management</button> */}
                  <button  onClick={()=>handleSelect('ba', 10)} className={active == 10 ? "active" : ""}>Business Analytics</button>
                  <button  onClick={()=>handleSelect('bm', 11)} className={active == 11 ? "active" : ""}>Business Management</button>
                  <button  onClick={()=>handleSelect('pmi', 12)} className={active == 12 ? "active" : ""}>Project Management</button>
                  <button  onClick={()=>handleSelect('sl', 13)} className={active == 13 ? "active" : ""}>Strategy & Leadership</button>
              </div>
              </Grid>


              <Grid lg={9} sm={9} md={9}  className='program-cards' sx={{pl:8}}>
                {data && data.map((program)=>(
                <ProgramCard tag={program.tag} img={program.img_url} title={program.title} duration={program.duration} mode={program.mode} deadline={program.deadline} hexcolor={program.hexcolor} pagelink={program.pagelink} />
                ))}
              </Grid>
             
            </Grid>
            </Box>
            </Container>
            <Box sx={{display:{xs:'block',lg:'none',sm:'block',md:'block'}}}>
            <FormControl fullWidth sx={{display: {xs: 'block',lg:'none',sm:'block',md:'block' } }}>
        <InputLabel id="demo-simple-select-label"   sx={{width: "280px",mx:3}}>Program</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Program"
          onChange={handleChange}
          sx={{pr:15, width:"95%"}}
         
        >
          <MenuItem onClick={()=>handleSelect('rec', 0)} className={active == 0 ? "active" : ""} value={"Recommended"}>Recommended</MenuItem>
          <MenuItem onClick={()=>handleSelect('ds', 1)} className={active == 1 ? "active" : ""} value={"Data Science"}>Data Science</MenuItem>
          <MenuItem onClick={()=>handleSelect('ai', 2)} className={active == 2 ? "active" : ""} value={"Artificial Inteligence"}>Artificial Inteligence</MenuItem>
          <MenuItem onClick={()=>handleSelect('pm', 3)} className={active == 3 ? "active" : ""} value={"Product Management"}>Product Management</MenuItem>
          {/* <MenuItem onClick={()=>handleSelect('jgp', 4)} className={active == 4 ? "active" : ""} value={"Job Guarantee PGP"}>Job Guarantee PGP</MenuItem> */}
          <MenuItem onClick={()=>handleSelect('gm', 5)} className={active == 5 ? "active" : ""} value={"General Management"}>General Management</MenuItem>
          {/* <MenuItem onClick={()=>handleSelect('hr', 6)} className={active == 6 ? "active" : ""} value={"HR Analytics"}>HR Analytics</MenuItem> */}
          {/* <MenuItem onClick={()=>handleSelect('sm', 7)} className={active == 7 ? "active" : ""} value={"Sales and Marketing"}>Sales and Marketing</MenuItem> */}
          <MenuItem onClick={()=>handleSelect('dt', 8)} className={active == 8 ? "active" : ""} value={"Digital Transformation"}>Digital Transformation</MenuItem>
          {/* <MenuItem onClick={()=>handleSelect('oscm', 9)} className={active == 9 ? "active" : ""} value={"Operations & Supply Chain Management"}>Operations & Supply Chain Management</MenuItem> */}
           <MenuItem onClick={()=>handleSelect('ba', 10)} className={active == 10 ? "active" : ""} value={"Business Analytics"}>Business Analytics</MenuItem>
           <MenuItem onClick={()=>handleSelect('bm', 11)} className={active == 11 ? "active" : ""} value={"Business Management"}>Business Management</MenuItem>
           <MenuItem onClick={()=>handleSelect('pmi', 12)} className={active == 12 ? "active" : ""} value={"Project Management"}>Project Management</MenuItem>
           <MenuItem onClick={()=>handleSelect('sl', 13)} className={active == 13 ? "active" : ""} value={"Strategy & Leadership"}>Strategy & Leadership</MenuItem>
        </Select>
      </FormControl>
            <Slider ref={sliderRef} {...settings}>
       
                {data && data.map((program)=>(
                <ProgramCard tag={program.tag} img={program.img_url} title={program.title} duration={program.duration} mode={program.mode} deadline={program.deadline} hexcolor={program.hexcolor} />
                ))}
          
        
              </Slider>
              </Box>
        </Box>
   
    </>
  )
}

export default HomeSectionTwo