
import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from 'react';
import SkeletonL from "../Components/Skeleton/Withlogin";
// import Duefee from "../Components/Dashboard/Manage-My-Program/Duefee";
const Duefee =lazy(() => import('../Components/Dashboard/Manage-My-Program/Duefee'));
const AuthDuefee = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ?  <Suspense fallback={<SkeletonL/>}>
  <Duefee/>
</Suspense> : <Navigate to="/Login" />;
}
export default AuthDuefee;
