import React from 'react'
import { Grid, Box,Container,Typography } from '@mui/material'
import "../../Assets/css/Home/HomeSectionThree.css";

const HomeSectionThree = () => {
  return (
  <>
  <Container fixed>
    <Box className='CareerBanner' sx={{ display: {xs: 'none',lg:'block' }, ml:-1 ,mr:5 }}>
      <Box sx={{mt:4,mb:2,pt:5,pb:1}}>
        <Typography sx={{textAlign:"center",fontSize:"26px",marginBottom: "0.3rem!important",fontWeight:400}}>Experiential Learning for Career Growth</Typography>
        <Typography sx={{textAlign:"center",fontSize:"20px",fontWeight:300,marginBottom: "0.3rem!important"}}>One program that fits all your requirements</Typography>
        </Box>
        <Grid container spacing={3} className='features' sx={{flexGrow:1}}>
        <Grid item xs={3.2} className='feat'>
            <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/el1.webp" alt="" />
            <h2>Online Classes</h2>
            <p>Live, Instructor-led every Saturday &amp; Sunday</p>
        </Grid>
        <Grid item xs={3.2} className='feat'>
            <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/el2.webp" alt="" />
            <h2>Daily Practice</h2>
            <p>Work on assignments & live projects</p>
        </Grid>
        <Grid item xs={3.2} className='feat'>
            <img src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/el3.webp" alt="" />
            <h2>Ask Questions</h2>
            <p>Reach out for any technical help, anytime</p>
        </Grid>
    </Grid>
    </Box>
    </Container>
    </>
  )
}

export default HomeSectionThree