import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,

} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const Sociallinks = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchsocialdetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if(res.data.status=="300"){
        setData([]);
      }else{
        setData(res.data[0]);
      }
      
    });
  };
  const [data, setData] = useState({
    linkedin: "",
    github: "",
    facebook: "",
  });
  const { linkedin, github, facebook } = data;
  const datahandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    fetchData();
  }, []);
  if (user == null) {
    navigate(`/Login`);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const sandData = {
      data,
      user,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/updatesocialdetails`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == "200") {
          toast.success("🦄 Successful Save", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("🦄 Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  return (
    <Paper
      sx={{
        ml: 3,
        mr:{lg:10,sm:3,md:3,xs:3},
        borderRadius: "8px",
        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
        p: 2,
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>
          Social links (Optional)
        </Typography>
      </Box>
      <Box>
        <form onSubmit={submitForm}>
          <Grid container>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="LinkedIn"
                label="LinkedIn"
                placeholder="URL (https://www.linkedin.com/in/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={linkedin}
                name="linkedin"
                type="text"
                required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="GitHub"
                label="GitHub"
                placeholder="URL (https://github.com/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={github}
                name="github"
                type="text"
                required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Facebook"
                label="Facebook"
                placeholder="URL (https://www.facebook.com/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={facebook}
                name="facebook"
                type="text"
                required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mb: 0.3,
                  float: "right",
                  mr: 3,
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",

                  "&:hover": {
                    background: "#009ef7",

                    borderRadius: "8px",
                    textTransform: "none",
                  },
                }}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};
export default Sociallinks;
