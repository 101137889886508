
import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Calendar from "../Components/Dashboard/Calendar";
const AuthCalendar = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Calendar /> : <Navigate to="/Login" />;
}
export default AuthCalendar;
