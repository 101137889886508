import React, { useEffect, useState } from "react";
import Homelogin from "./home-without-login-bar";
import Loginbar from "./home-login-bar";
function Testingnavbar(props) {

  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
   

  
  }, []);
  const [user, setUser] = useState("");
 
  return (
    <>
      {(() => {
        if (user) {
          return (
            <>
              <Loginbar />
            </>
          );
        } else {
          return (
            <>
              <Homelogin program={props.program}/>
            </>
          );
        }
      })()}
    </>
  );
}
export default Testingnavbar;
// export default Testingnavbar;