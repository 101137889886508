export const Datakeymodules = [

    {
        id:"DS",
        bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/kds.webp",
        cardinfo:[
            {
                programid:1,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Data Science & AI IIT G ",
                shortname:"IIT Guwahati",
                time:"12 Months",
                programpagelink:"/iit/executive-program-in-ds-and-ai-advance",
                modulelink:"https://drive.google.com/uc?export=download&id=1C_UWKY0ixbKhW9c3CKSWmcHD4YIrcXW9",
                youtublink:"https://www.youtube.com/embed/H4onZUViTno?si=isqnMqTwhizffZHo",

            },
            {
                programid:2,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Data Science & AI IIT G",
                shortname:"IIT Guwahati",
                time:"10 Months",
                programpagelink:"/iit/executive-program-in-ds-and-ai",
                modulelink:"https://drive.google.com/uc?export=download&id=1OFP4bsTO-ocfpq2Dh0gUImjc2IJ028-e",
                youtublink:"https://www.youtube.com/embed/iwqA9As21us?si=uNk6cKtle0N95Ruy",

            },
            {
                programid:3,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Advanced Certification in Data Science & ML IIT G",
                shortname:"IIT Guwahati",
                time:"6 Months",
                programpagelink:"/iit/advanced-certification-in-ds-and-ml",
                modulelink:"https://drive.google.com/uc?export=download&id=18ym5aAr2zXfhQTCQP3L7uFgPGfoipiX3",
                youtublink:"https://www.youtube.com/embed/zvjd_-IKtrU?si=YfEjcNzX8Px9Gsrx",

            },
            {
                programid:4,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Certificate in Data Analytics IIT G",
                shortname:"IIT Guwahati",
                time:"3 Months",
                programpagelink:"/iit/certificate-in-data-analytics",
                modulelink:"https://drive.google.com/uc?export=download&id=1pzIhPvQcyZu81L465QIebxzESpZJ-8_7",
                youtublink:"https://www.youtube.com/embed/cbThusbf2Ik?si=F8HFKxCKWdKbmNjs",

            },
            {
                programid:5,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredian.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredianlogo.webp",
                programname:"PGP in Data Science & AI",
                shortname:"In-house Programs",
                time:"12 Months",
                programpagelink:"/pgp-in-data-science-and-ai",
                modulelink:"https://drive.google.com/uc?export=download&id=1hS-1F_AlulHBind_UUzdaxMwNPgc-4TD",
                youtublink:"https://www.youtube.com/embed/AJyoC2Sxmi0?si=CCbcHlQN5mRwjK16",

            },
            {
                programid:6,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredian.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredianlogo.webp",
                programname:"Global Certificate in Data Science & AI Program",
                shortname:"In-house Programs",
                time:"10 Months",
                programpagelink:"/global-certificate-in-data-science-and-ai",
                modulelink:"https://drive.google.com/uc?export=download&id=1FsEhentDownQ67roHascZ9hiM4_B20sJ",
                youtublink:"https://www.youtube.com/embed/N7VzKzvMOJc?si=WHiy-W4uBW1i9HB1",

            },
            {
                programid:7,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredian.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredianlogo.webp",
                programname:"Global Certificate in Data Science Program",
                shortname:"In-house Programs",
                time:"6 Months",
                programpagelink:"/global-certificate-in-data-science",
                modulelink:"https://drive.google.com/uc?export=download&id=1mKsNFbg4aKHcnhL9m3CPE9JXBI44Xeqm",
                youtublink:"https://www.youtube.com/embed/R-v17KhLosA?si=XFseumOmPxv8a4tB",

            },
            {
                programid:8,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredian.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/accredianlogo.webp",
                programname:"Certificate in Data Science Foundation Program",
                shortname:"In-house Programs",
                time:"3 Months",
                programpagelink:"/certificate-in-data-science-foundation",
                modulelink:"https://drive.google.com/uc?export=download&id=1ySas-DDhPE74BgLMzZtgknZvdbOvStkK",
                youtublink:"https://www.youtube.com/embed/08n6UMIAWcE?si=29yQ3kz-Y7Ng7dos",

            },

        ],

    },
    {
        id:"PM",
        bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/kpm.webp",
        cardinfo:[
            {
                programid:9,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Product Management & Data Science IIT G ",
                shortname:"IIT Guwahati",
                time:"11 Months",
                programpagelink:"/iit/executive-program-in-pmds",
                modulelink:"https://drive.google.com/uc?export=download&id=1miDbVwwOT1egGN2nJn7oqZtY5T5NtEha",
                youtublink:"https://www.youtube.com/embed/TuUNBhfyjk4?si=PiuosgvBbTHMa0b3",

            },
            {
                programid:10,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Data Driven Product Management IIT G",
                shortname:"IIT Guwahati",
                time:"11 Months",
                programpagelink:"/iit/executive-program-in-data-driven-product-management",
                modulelink:"https://drive.google.com/uc?export=download&id=1au4SN-dL1PtCcqQodycKat70TCxFnwjL",
                youtublink:"https://www.youtube.com/embed/yOumeJOJrOA?si=ahhPBiHJ9yaVvvO7",

            },
            {
                programid:11,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Product Management IIT G",
                shortname:"IIT Guwahati",
                time:"8 Months",
                programpagelink:"/iit/executive-program-in-pm",
                modulelink:"https://drive.google.com/uc?export=download&id=1BolNJSBE-IxLh9q8r4cEj76sFJthSHrF",
                youtublink:"https://www.youtube.com/embed/orFejHJvow4?si=XLUtqkN4S9l_fxdu",

            },
            {
                programid:12,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Advanced Certification in Product Management IIT G",
                shortname:"IIT Guwahati",
                time:"5 Months",
                programpagelink:"/iit/advanced-certification-in-pm",
                modulelink:"https://drive.google.com/uc?export=download&id=1LBZh9DyXnnbrVF06J7bW7tcMJN-AoCWI",
                youtublink:"https://www.youtube.com/embed/zXf4z0cXVE4?si=FHvnAKIGOfEd_TSb",

            },
            {
                programid:13,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/xlri.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/xlrilogo.webp",
                programname:"Executive Program in Product Management  XLRI",
                shortname:"XLRI Delhi NCR",
                time:"5 Months",
                programpagelink:"/Xlri/executive-program-in-pm-by-xlri",
                modulelink:"https://drive.google.com/uc?export=download&id=1loevsXYkw6udaMfTFAADu4pFLjj8LD0t",
                youtublink:"https://www.youtube.com/embed/2UI6-uNEdjc?si=VMknG0n1VHM_vRkr",

            },

        ],

    },
    {
        id:"GM",
        bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/kgm.webp",
        cardinfo:[
            {
                programid:14,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/xlri.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/xlrilogo.webp",
                programname:"Executive Program in General Management XLRI",
                shortname:"XLRI Delhi NCR",
                time:"11 Months",
                programpagelink:"/XLRI/executive-program-in-general-management",
                modulelink:"https://drive.google.com/uc?export=download&id=1wn3ODs4XqOOw2nOkjvDLCXpG7bZ6I9qE",
                youtublink:"https://www.youtube.com/embed/3EWDbcrTyII?si=M4nNoZo1giWnwsoO",

            },
      
    
        

        ],

    },
    {
        id:"HR",
        bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/khr.webp",
        cardinfo:[
            {
                programid:15,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/imi.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/imilogo.webp",
                programname:"Executive Program in Strategic HR Analytics IMI Delhi",
                shortname:"IMI New Delhi",
                time:"6 Months",
                programpagelink:"/imi/executive-program-in-strategic-hr-analytic",
                modulelink:"https://drive.google.com/uc?export=download&id=1wn3ODs4XqOOw2nOkjvDLCXpG7bZ6I9qE",
                youtublink:"https://www.youtube.com/embed/gKLnGeOLa4M?si=th1RADzbiO1k7Fq-",

            },

        ],

    },
    // {
    //     id:"SM",
    //     bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/ksm.webp",
    //     cardinfo:[
    //         {
    //             programid:16,
    //             programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/imi.webp",
    //             logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/imilogo.webp",
    //             programname:"Executive Program in Sales & Marketing IMI Delhi",
    //             shortname:"IMI New Delhi",
    //             time:"6 Months",
    //             programpagelink:"/imi/executive-program-in-sales-marketing",
    //             modulelink:"https://drive.google.com/uc?export=download&id=1Dg6aa32DerwxfBNp8CinjsxtLIwO3IDx",
    //             youtublink:"https://www.youtube.com/embed/GIRgNTk44lE?si=rqc48MnjDywwYTa_",

    //         },

    //     ],

    // },
    {
        id:"BA",
        bannerimag:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/keymodule/kba.webp",
        cardinfo:[
            {
                programid:17,
                programimage:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iit.webp",
                logo:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Resource/iitlogo.webp",
                programname:"Executive Program in Data Science & Business Analytics: IIT G",
                shortname:"IIT Guwahati",
                time:"11 Months",
                programpagelink:"/iit/executive-program-in-ds-ba",
                modulelink:"https://drive.google.com/uc?export=download&id=1q4HHAPVJXxZMO5j9o4WURrveI2ike3Br",
                youtublink:"https://www.youtube.com/embed/Dz7pDLdxBZM?si=tH821gdjyp5qPxnR",

            },

        ],

    },


];