import './App.css';
import React from "react";
import Home from './Components/Home/Home';
// import Program from './Components/ProgramPage/Program';
import Faqs from './Components/Faq/Subpage';
import { useEffect,Suspense, lazy } from 'react';
// import Login from './Components/Login/Login';
import AuthLogin from './Auth/AuthLogin';
import AuthSignup from './Auth/AuthSignup';
import ChangePassword from './Components/Dashboard/ChangePassword';
import AuthDashbord from './Auth/AuthDashbord';
import AuthMyaccount from './Auth/AuthMyaccount';
import AuthCareercounselling from './Auth/AuthCareercounselling';
import AuthSuccesstory from './Auth/AuthSuccesstory';
import AuthCalendar from './Auth/AuthCalendar';
import AuthProfile from './Auth/AuthProfile';
import AuthDuefee from './Auth/AuthDuefee';
import AuthReceipt from './Auth/AuthReceipt';
import AuthBatchTransfer from './Auth/AuthBatchTransfer';
import AuthPauseprogram from './Auth/AuthPauseprogram';
import AuthUpgradeprogram from './Auth/AuthUpgradeprogram';
import AuthCertificationexam from './Auth/AuthCertificationexam';
import AuthJobprotal from './Auth/AuthJobprotal';
import AuthCareerlaunchpad from './Auth/AuthCareerlaunchpad';
import AuthArticle from './Auth/AuthArticle';
import AuthCaseStudies from './Auth/AuthCaseStudies';
import AuthCheatSheet from './Auth/AuthCheatSheet';
import AuthGuides from './Auth/AuthGuides';
import AuthInfographics from './Auth/AuthInfographics';
import AuthInterviewQuestions from './Auth/AuthInterviewQuestions';
import AuthResearchPaper from './Auth/AuthResearchPaper';
import AuthEBooks from './Auth/AuthEBooks';
import AuthBooks from './Auth/AuthBooks';
import AuthBookpanel from './Auth/AuthBookpanel';
import AuthCertificates from './Auth/AuthCertificates';
import AuthProductResearch from './Auth/AuthProductResearch';
import AuthProblemStatement from './Auth/AuthProblemStatement';
import AuthProductstrategy from './Auth/AuthProductstrategy';
import AuthUiux from './Auth/AuthUiux';
import AuthProductDevelopment from './Auth/AuthProductDevelopment';
import AuthGoToMarket from './Auth/AuthGoToMarket';
import AuthProjects from './Auth/AuthProjects';
import AuthTermProject from './Auth/AuthTermProject';
import AuthPmArticle from './Auth/AuthPmArticle';
import AuthPmCaseStudies from './Auth/AuthPmCaseStudies';
import AuthPmCheatSheet from './Auth/AuthPmCheatSheet';
import AuthPmInterviewQuestions from './Auth/AuthPmInterviewQuestions';
import AuthPmResearchPaper from './Auth/AuthPmResearchPaper';
import AuthCompetitioncorner from './Auth/AuthCompetitioncorner';
import AuthResumetoolkit from './Auth/AuthResumetoolkit';
import AuthQuiz from './Auth/AuthQuiz';
import AuthJobquiz from './Auth/AuthJobquiz';
import AuthJobguarantee from './Auth/AuthJobguarantee';
import Certificate from './Components/certificate/certificate';
import Competitioncornerquestion from './Components/Dashboard/Competition-corner/competition-corner-question';
// import Signup from './Components/Signup/Signup';
import Lostpassword from './Components/Home-password/Lostpassword';
import Resetpassword from './Components/Home-password/Resetpassword';
// import CareerTrasition from './Components/Dashboard/Career-Transition/career';
import CareerQuiz from './Components/Dashboard/Career-Transition/quiz/quiz'
import FEE from './Components/Dashboard/fee-recepit/fee';
import HowTo from './Components/Dashboard/Howto';
import AuthByprogram from './Auth/AuthByprogram';
import Logout from './Components/Login/Logout';
// import Keymodule from './Resource/key-modules';

import Resourcescallback from './Resource/resource-callback';
import Resourcescthankyou from './Resource/resource-thank-you';
// import Adani from './pages/adaniProgramPage/AdaniProgram'
import SkeletonWL from './Components/Skeleton/Withoutlogin';
import SkeletonL from './Components/Skeleton/Withlogin';

// import Factsheet from './Resource/fact-sheet';
import Datasciencebook from './pages/ebook/datascienceebook';
import Webinar from './pages/webinar/webinar';
import OutlinedCard from './Components/Broucher/broucher';
import Payment from './pages/webinar/payment';
import YourComponent from './pages/demo/YourComponent';
// import Thankyou from './pages/webinar/thankyou';
import Thankyou from './pages/webinar/thankyou-new';


// import Alumni from './Components/Dashboard/Alumni/alumni';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import ZoomComponent from './Components/Dashboard/zoom-sdk/zoomComponent';
// import AppZoom from './Components/Dashboard/zoom-sdk/zoomComponent2';


const CareerTrasition = lazy(() => import('./Components/Dashboard/Career-Transition/career'));
const Alumni = lazy(() => import('./Components/Dashboard/Alumni/alumni'));
const Successstories = lazy(() => import('./Components/successstories/Successstoriesfornt'));
const Whyinsaidhome = lazy(() => import('./Components/WhyInsaid/Whyinsaidhome'));
const Career = lazy(() => import('./Components/Careers/Career'));
const About = lazy(() => import('./Components/About/About'));
const Faq = lazy(() => import('./Components/Faq/MasterFaq'));
const Policy = lazy(() => import('./Components/Policy/Policy'));
const Keymodule = lazy(() => import('./Resource/key-modules'));
const Factsheet = lazy(() => import('./Resource/fact-sheet'));
const Ebook = lazy(() => import('./Resource/ebook'));
const Gallery = lazy(() => import('./Components/Gallery/Gallery'));
const Iit = lazy(() => import('./pages/IitProgramPage/IitProgram'));
const Xlri = lazy(() => import('./pages/xlriProgrampage/XlriProgram'));
const IMI = lazy(() => import('./pages/imi/ImiProgram'));
const Spj = lazy(() => import('./pages/spj/SpjProgram'));
const GM = lazy(() => import('./pages/general-management/GmProgram'));
const Adani = lazy(() => import('./pages/adaniProgramPage/AdaniProgram'));
const Program = lazy(() => import('./Components/ProgramPage/Program'));
const IIM = lazy(()=> import('./pages/iim/IimProgram'))

function CareerTransitionPrivet() {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL/>}>
  <CareerTrasition/>
</Suspense> : <Navigate to="/Login" />;
}
function AlumniPrivet() {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Suspense fallback={<SkeletonL/>}>
  <Alumni/>
</Suspense> : <Navigate to="/Login" />;
}
function CareerQuizPrivet() {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <CareerQuiz /> : <Navigate to="/Login" />;
}

function App () {
  return (
    <>
      <Routes>
        
        {/* {localStorage.getItem('user_id')} */}
        {/* <Route path='/WordpressPasswordGenerator' element={<WordpressPasswordGenerator/>} />
        <Route path='/testing2' element={<PDFDragDrop/>} /> */}
        <Route path='/' element={<Home />} />
        <Route path='webinar/:id' element={<Webinar />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='demo/:id' element={<YourComponent />} />
        <Route path='/:id' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Program/>
        </Suspense>
        }  />
        <Route path='iit/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <Iit/>
         </Suspense>
        }/>
        {/* <Route path='Xlri/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <Xlri/>
         </Suspense>
        }/> */}
        <Route path='imi/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <IMI/>
         </Suspense>
        }/>
           <Route path='iim/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <IIM/>
         </Suspense>
        }/>
        <Route path='XLRI/:id' element={ 
           <Suspense fallback={<SkeletonWL/>}>
           <GM/>
         </Suspense>
         }/>
        {/* <Route path='Adani/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <Adani/>
         </Suspense>
        }/> */}
        <Route path='spj/:id' element={
           <Suspense fallback={<SkeletonWL/>}>
           <Spj/>
         </Suspense>
        }/>
        <Route path='/Career' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Career/>
        </Suspense>
        } />
        <Route path='/datascience-ebook' element={<Datasciencebook />} />
        <Route path='/About' element={
          <Suspense fallback={<SkeletonWL/>}>
          <About/>
        </Suspense>
        } />
        <Route path='/Faq' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Faq/>
        </Suspense>
        } />
        <Route path='/Successstories' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Successstories/>
        </Suspense>
        } />
        <Route path='/Faqs/:id' element={<Faqs />} />
        <Route path='/Policy/:id'   element={
          <Suspense fallback={<SkeletonWL/>}>
          <Policy/>
        </Suspense>
        } />
        <Route path='/whyaccredian' element={
           <Suspense fallback={<SkeletonWL/>}>
           <Whyinsaidhome/>
         </Suspense>
        } />
       <Route path='/Gallery' element={
        <Suspense fallback={<SkeletonWL/>}>
        <Gallery />
      </Suspense>
        } />
        <Route path='/key-modules-resource/:id' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Keymodule/>
        </Suspense>
        } />
        <Route path='/resource-fact-sheet/:id' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Factsheet/>
        </Suspense>
        } />
        <Route path='/ebook' element={
          <Suspense fallback={<SkeletonWL/>}>
          <Ebook/>
        </Suspense>
        } />
        <Route path='/resource-callback' element={<Resourcescallback/>} />
        <Route path='/resource-thank-you' element={<Resourcescthankyou/>} />
        <Route path='/Login' element={<AuthLogin />} />
        {/* <Route path='/AuthLogin' element={<LoginAuths />} /> */}
        <Route path='/Logout' element={<Logout />} />
        <Route path='/Signup' element={<AuthSignup />} />
        <Route path='/Lost-password'  element={<Lostpassword />} />
        <Route path='/Reset-password/:id'  element={<Resetpassword />} />
        <Route path='/CareerCounselling' element={<AuthCareercounselling />} />
        <Route path='/SuccessStory' element={<AuthSuccesstory />} />
        <Route path='/Calendar' element={<AuthCalendar />} />
        <Route path='/Myaccount' element={<AuthMyaccount/>} />
        <Route path='/Dashboard' element={ <AuthDashbord/> } />
        <Route path='/Profile' element={ <AuthProfile/> } />
        <Route path='/ChangePassword' element={<ChangePassword />} />
        <Route path='/Duefee' element={<AuthDuefee />}  />
        <Route path='/Receipt' element={<AuthReceipt/>}  />
        <Route path='/Batch-Transfer' element={<AuthBatchTransfer/>}  />
        <Route path='/Pause-Program' element={<AuthPauseprogram/>}  />
        <Route path='/Program-Upgrade' element={<AuthUpgradeprogram/>}  />
        <Route path='/Certification-Exam' element={<AuthCertificationexam/>}  />
        <Route path='/Job-Protal' element={<AuthJobprotal/>}  />
        <Route path='/Career-Launchpad' element={<AuthCareerlaunchpad/>}  />
        <Route path='/Article' element={<AuthArticle/>}  />
        <Route path='/Case-Studies' element={<AuthCaseStudies/>} />
        <Route path='/Cheat-Sheet' element={<AuthCheatSheet/>} />
        <Route path='/Guides' element={<AuthGuides/>} />
        <Route path='/Infographics' element={<AuthInfographics/>} />
        <Route path='/Interview-Questions' element={<AuthInterviewQuestions/>} />
        <Route path='/Research-Paper' element={<AuthResearchPaper/>} />
        <Route path='/E-Books' element={<AuthEBooks/>} />
        <Route path='/Books' element={<AuthBooks/>} />
        <Route path='/Book-Panel' element={<AuthBookpanel/>} />
        <Route path='/PM-Article' element={<AuthPmArticle/>} />
        <Route path='/PM-Case-Studies' element={<AuthPmCaseStudies/>} />
        <Route path='/PM-Cheat-Sheet' element={<AuthPmCheatSheet/>} />
        <Route path='/PM-Interview-Questions' element={<AuthPmInterviewQuestions/>} />
        <Route path='/PM-Research-Paper' element={<AuthPmResearchPaper/>} />
        <Route path='/Certificates' element={<AuthCertificates/>} />
        <Route path='/Product-research' element={<AuthProductResearch/>} />
        <Route path='/Product-strategy' element={<AuthProductstrategy/>} />
        <Route path='/Ui-Ux' element={<AuthUiux/>} />
        <Route path='/Product-development' element={<AuthProductDevelopment/>} />
        <Route path='/Problem-statement' element={<AuthProblemStatement/>} />
        <Route path='/Go-To-Market' element={<AuthGoToMarket/>} />
        <Route path='/Projects' element={<AuthProjects/>} />
        <Route path='/Term-projects/:id' element={<AuthTermProject/>} />
        <Route path='/Term-projects-quiz' element={<AuthQuiz/>} />
        <Route path='/Competition-corner' element={<AuthCompetitioncorner/>} />
        <Route path='/Resume-toolkit' element={<AuthResumetoolkit/>} />
        <Route path='/Job-guarantee' element={<AuthJobguarantee/>} />
        <Route path='/Job-guarantee-quiz' element={<AuthJobquiz/>} />
        <Route path='/certificate' element={<Certificate/>} />
        <Route path='/competition-corner-question' element={<Competitioncornerquestion/>} />
        <Route path='/career-transition' element={<CareerTransitionPrivet/>} />
        <Route path='/career-transition-quiz' element={<CareerQuizPrivet/>} />
        <Route path='fee' element={<FEE/>} />
        <Route path ='/By-Program' element={<AuthByprogram/>} />
        <Route path ='/alumni' element={<AlumniPrivet/>} />
        {/* <Route path ='/zoom' element={<ZoomComponent/>} /> */}
        <Route path ='/brochure/:id' element={<OutlinedCard/>} />
        <Route path ='/Thankyou' element={<Thankyou/>}/>
        {/* <Route path ='/rcb' element={<Career_Transition/>}/> */}
        
      </Routes>
    </>
  );
}
export default App;











