import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Quiz from "../Components/Dashboard/Term-projects/quiz/quiz";
const AuthQuiz = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Quiz /> : <Navigate to="/Login" />;
}
export default AuthQuiz;
