
import React from "react";
import Signup from "../Components/Signup/Signup";
import Home from "../Components/Home/Home";
import {
  Navigate,
} from "react-router-dom";
const AuthSignup = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Home /> : <Signup/>;
}
export default AuthSignup;
