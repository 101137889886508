
import React from "react";
import ResearchPaper from '../Components/Dashboard/Resource-Center/ResearchPaper';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
const AuthResearchPaper = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <ResearchPaper /> : <Navigate to="/Login" />;
}
export default AuthResearchPaper;
