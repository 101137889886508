import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  TextField,
  MenuItem,
  CardMedia,
  Backdrop,
} from "@mui/material";
import Dashborednav from "../Navbar/Dashborednav";
import CottageIcon from "@mui/icons-material/Cottage";
import Moment from "react-moment";
import moment from "moment";
import { useParams, useSearchParams, useLocation,Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
const date = moment().day("Sunday").add(7, "days");

const category = [
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  {
    value: "HR Analytics",
    label: "HR Analytics",
  },
  {
    value: "Sales and Marketing",
    label: "Sales and Marketing",
  },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Operations & Supply Chain Management",
    label: "Operations & Supply Chain Management",
  },
];
const Withoutenrollment = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [rcbprogram, setProgramrcb] = useState("");
  const [loader, setLoader] = useState(false);
  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");

  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };
  const progamhandleChange = (event) => {
    setProgramrcb(event.target.value);
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    },
  };
  const [rcb, setRcb] = useState({
    mobile: "",
    name: "",
    newemail: "",
    program: "",
    ctaname: "",
    ctapage: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const rcbsubmitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: rcb.mobile,
      name: rcb.name,
      email: rcb.newemail,
      program: rcbprogram,
      source: "Request Callback ",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "request_callback",
      type:"CTA"
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          });

          e.target.reset();
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
          e.target.reset();
        }
        e.target.reset();
      });
  };
  return (
    <>
      <Dashborednav />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid item lg={12} container>
            <Grid item lg={3} sx={{display:{xs:"none",sm:"none",md:"none",lg:"block"}}}>
              <Paper
                sx={{
                  ml: 2,
                  position: "fixed",
                  height: "350px",
                  borderRadius: "8px",
                  width: "245px",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography sx={{ fontSize: "0.7rem", color: "#a1a5b7" }}>
                    DASHBOARD
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", marginBottom: "0.5rem !important" }}
                  >
                    <CottageIcon sx={{ fontSize: "1rem", mx: 1 }} />
                    My Dashboard
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={9} xs={12}>
              <Grid item lg={12} container sx={{ pb: 4 }}>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, p: 1 }}
                >
                  <Paper sx={{ p: 2 }}>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Schedule a Call{" "}
                      </Typography>
                      <Typography
                        sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                      ></Typography>

                      <form onSubmit={rcbsubmitForm}>
                        <TextField
                          id="number"
                          label="Mobile"
                          variant="standard"
                          textAlign="canter"
                          sx={{ pb: 1 }}
                          required={true}
                          onChange={rcbhandleChange}
                          value={rcb.mobile}
                          type="number"
                          name="mobile"
                          fullWidth
                        />
                        <TextField
                          id="name"
                          label="Name"
                          variant="standard"
                          textAlign="canter"
                          sx={{ pb: 1 }}
                          onChange={rcbhandleChange}
                          value={rcb.name}
                          fullWidth
                          type="text"
                          name="name"
                          required={true}
                        />
                        <TextField
                          id="newemail"
                          fullWidth
                          label="Email"
                          variant="standard"
                          textAlign="canter"
                          sx={{ pb: 2 }}
                          type="email"
                          name="newemail"
                          onChange={rcbhandleChange}
                          value={rcb.newemail}
                          required={true}
                        />
                        <TextField
                          id="program"
                          select
                          value={rcbprogram}
                          onChange={progamhandleChange}
                          variant="standard"
                          name="program"
                          fullWidth
                          sx={{ pb: 3 }}
                          label={rcbprogram === "" ? "Select Program" : ""}
                          // label="Select Program"
                          required={true}
                          InputLabelProps={{ shrink: false }}
                        >
                          <MenuItem value="">
                            <em>---- Select Program ----</em>
                          </MenuItem>
                          {category.map((rcb) => (
                            <MenuItem
                              key={rcb.value}
                              value={rcb.value}
                              name="program"
                            >
                              {rcb.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            marginBottom: "0.5rem !important",
                          }}
                        >
                          Note: You will receive a call between Mon-Fri from
                          10AM - 6PM.
                        </Typography>
                        <center>
                          {" "}
                          <Button
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{
                              textTransform: "none",
                              background: "#009ef7",
                              fontWeight: 800,
                              borderRadius: "3px",
                              p: 1,
                              "&:hover": {
                                textTransform: "none",
                                background: "#009ef7",
                                fontWeight: 800,
                                borderRadius: "3px",
                                p: 1,
                              },
                            }}
                          >
                            Request call back from expert
                          </Button>
                        </center>
                      </form>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, mx:{lg:3,sm:3,md:3,xs:0} , pt: 2 }} 
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(21,101,192,0.8), rgba(21,101,192,0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "392px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                       Executive Program in Data Science & Artificial intelligence with E&ICT Academy, IIT Guwahati
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        12 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                        <Link to='/iit/executive-program-in-ds-and-ai-advance' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {/* <Box sx={{ pt: 5, pl: 3, pr: 4, pb: 4 }}>
          <Paper sx={{ p: 1 }}>
            <Grid item lg={12} container sx={{ pt: 3 }}>
              <Grid
                item
                lg={6}
                sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, mx: 2, p: 1 }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  LATEST FROM INSAID{" "}
                </Typography>
                <Typography
                  sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                ></Typography>
              </Grid>
              <Grid
                item
                lg={6}
                sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, mx: 2, p: 1 }}
              >
                <Box display="flex" sx={{ ml: 3 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginBottom: "0.5rem !important",
                      mx: 2,
                    }}
                  >
                    Follow us on{" "}
                  </Typography>
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/linkedin.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/youtube.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/facebook.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/twitter.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: "310px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid #fff",
                },
              }}
            >
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box> */}

              <Grid item lg={12} container>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(255,41,70,.8), rgba(255,41,70,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                      PGP in Data Science & AI
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        12 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='pgp-in-data-science-and-ai' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, mx:{lg:3,sm:3,md:3,xs:0} , pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(23,155,173,.8), rgba(23,155,173,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                     Executive Program in Product Management and Data Science with E&ICT Academy, IIT Guwahati
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        11 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='/iit/executive-program-in-pmds' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(255,100,23,.8),rgba(255,100,23,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Executive Program in Product Management by XLRI | Delhi-NCR
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        5 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='/Xlri/executive-program-in-pm-by-xlri' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, mx:{lg:3,sm:3,md:3,xs:0} , pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(21,101,192,0.8), rgba(21,101,192,0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                      Executive Program in General Management by XLRI | Delhi-NCR
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        11 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='/XLRI/executive-program-in-general-management' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"}, pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(255,41,70,.8), rgba(255,41,70,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                     Executive Program in Data Science and Business Analytics with E&ICT Academy, IIT Guwahati
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        11 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='/iit/executive-program-in-ds-ba' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  md={6}
                  xs={12}
                  sx={{ maxWidth:{lg: "46.333333% !important",sm:"46.333333% !important",md:"46.333333% !important",xs:"100%"},mx:{lg:3,sm:3,md:3,xs:0}, pb: 4 }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(0deg, rgba(23,155,173,.8), rgba(23,155,173,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/myaccount.webp)",
                      backgroundSize: "cover",
                      height: "362px",
                    }}
                  >
                    <Box sx={{ pt: 14 }}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "#fff",
                          textAlign: "center",
                          height:"56px",
                          fontWeight: "bold",
                          marginBottom: "0.5rem !important",
                        }}
                      >
                   Executive Program in Strategic HR Analytics by IMI Delhi
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        6 Months | Online
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          textAlign: "center",
                         

                          marginBottom: "0.5rem !important",
                        }}
                      >
                        Next batch:
                        <Moment format="D MMM YYYY">{date}</Moment>
                      </Typography>

                      <center>
                      <Link to='/imi/executive-program-in-strategic-hr-analytic' style={{ textDecoration: "none", color: "#000" }}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            background: "#f8f9fa",
                            color: "#000",
                            "&:hover": {
                              textTransform: "none",
                              background: "#f8f9fa",
                              color: "#000",
                            },
                          }}
                        >
                          Explore Now
                        </Button>
                        </Link>
                      </center>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Withoutenrollment;
