import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  CardMedia,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/LoginDashboard";
import Sidbar from "../Sidbar";
import axios from "axios";

const Books = () => {
  let navigate = useNavigate();
  const [cv, setCv] = useState("");
  const [ds, setDs] = useState("");
  const [dl, setDl] = useState("");
  const [ml, setMl] = useState("");
  const [ms, setMs] = useState("");
  const [py, setPy] = useState("");
  const [sl, setSl] = useState("");
  const [st, setSt] = useState("");
  function bookCall(name, boockid) {
    window.localStorage.setItem("bookcat", name);
    window.localStorage.setItem("boockid", boockid);

    navigate(`/Book-Panel`);
  }
  const fetchdata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/resourcecenter/fetchboockcount`,
    }).then((res) => {
      setCv(res.data.ComputerVision);
      setDs(res.data.DataScience);
      setDl(res.data.DeepLearning);
      setMl(res.data.MachineLearning);
      setMs(res.data.Mathematics);
      setPy(res.data.Python);
      setSl(res.data.Sl);
      setSt(res.data.Statistics);

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} xs={12} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box>
                <Typography
                  sx={{
                    marginBottom: "0.5rem!important",
                    fontWeight: "bold",
                    fontSize: "1.7rem",
                  }}
                >
                  BOOKS PANEL
                </Typography>
              </Box>
              <Box
                sx={{
                  mr:{lg:8},
                }}
              >
                <Grid container>
                  <Grid lg={3} xs={12} >
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Computer Vision
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {cv} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Computer Vision", 1)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Data Science
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {ds} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Data Science", 2)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Deep Learning
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {dl} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Deep Learning", 3)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Machine Learning
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {ml} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Machine Learning", 4)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Mathematics
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {ms} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Mathematics", 5)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Python
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {py} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Python", 6)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          SQL
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {sl} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("SQL", 7)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid lg={3} xs={12}>
                    <Card
                      sx={{
                        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                        mx: 2,
                        mb: 3,
                      }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          image="../Rcimages/fileico.svg"
                          alt="Goole"
                          sx={{
                            width: "50px",
                            mb: 0.9,
                            mx: "auto",
                          }}
                        />
                        <Typography
                          sx={{
                            marginBottom: "0.1rem!important",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Statistics
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "0rem!important",

                            fontSize: "0.93rem",
                            textAlign: "center",
                            color: "#a1a5b7",
                          }}
                        >
                          {st} items
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            background: "#009ef7",
                            borderRadius: "8px",
                            textTransform: "none",
                            mx: "auto",
                            mt: -2,
                            "&:hover": {
                              background: "#009ef7",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={() => bookCall("Statistics", 8)}
                        >
                          Explore
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Books;
