import { Typography } from "@mui/material";
import { Link} from "react-router-dom";

export const Iitprogramnew = [



   {/* Start gcds*/},

  {
    id: "executive-program-in-ds-and-ai-advance",
    category:"2",
    university:"iitg",
    backgroundimage:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp",
    mobbackground:
      "linear-gradient(0deg, rgba(25,122,237,1) 0%, rgba(34,39,125,1) 0%, rgba(31,33,64,1) 99%)",

    hadding: "Executive Program in Data Science & Artificial Intelligence with E&ICT Academy, IIT Guwahati",
    title:"IITG - Executive Program in DS & AI 12M",
    programduration: "12 Months | Live Instructor led | Weekend Online",
    programdescription:"Embark on a 12-term learning journey in Data Science, Machine Learning, & End-to-End AI Product Development.",
    callnumber: "70421-48123",
    stacksone: "E&ICT Academy",
    stacksonedescription: "IIT Guwahati",
    stackstwo: "Live Classes",
    stackstwodescription: "12 Months",
    stacksthree: "Next Cohort",
    stacksthreedescription: "28th January 2024",
    stackslast: "Career Services ",
    stackslastdescription: "by Accredian",
    progarminclud1: "12 Terms - Machine Learning, AI & End to End AI Product",
    progarminclud2: "Duration: 12 Months | Weekend Online",
    progarminclud3: "Live Instructor led classes",
    progarminclud4: "Daily doubt clearing",
    progarminclud5: "Term projects & Capstone projects",
    progarminclud6: "No Cost EMI upto 12 months",
    progarminclud7: "Placement Support & Assistance",
    progarminclud8: "2-day career immersion at IIT Guwahati",
    progarminclud9: "1:1 career mentorship sessions",
    progarminclud10: "Learn from the top industry professionals and expert sessions by IIT Guwahati Faculty",
    progarminclud11: "Certification from E&ICT, IIT Guwahati",
    whoshouldjoin:
      "This program is suitable for individuals interested in establishing a foundation in Data Science and developing AI solutions for real-world applications.",
    Jobopportunities:
      "Prepare for interviews in roles such as Data Analyst, Business Analyst, ML Engineer, Data scientist, AI Engineer, AI Specialist, AI Leader, AI Product Manager and more.",
    suchitquotes:
      "Every individual from various backgrounds must learn Analytics to outshine in the competitive world today!",
    suchitinfo: "-Suchit Majumdar, Chief Data Science Mentor, Accredian.",
    Programsyllabustrmtext: "12 Terms",
    Programsyllabustremds: "(12 months)",
    Programsyllabusclasstext: "190+ hours of",
    Programsyllabuscareetext: "120+ career",
    growt:"150%",
    scholarshipseats:"2",
    nonscholarshipseats:"4",
    setsction:"block",
    accd: [
      {
        hadding: "Term 1 : Data Analysis with Python",
        week: "4 weeks",
        accodingid: "trem1",
        tremone: [
          "Data Science Fundamentals   ",
          " Data Operation with Numpy ",
          "Data Manipulation with Pandas ",
        ],
      },
      {
        hadding: "Term 2 : Data Visualization Techniques",
        week: "4 weeks",
        accodingid: "trem2",
        tremone: [
          "Introduction to Data Visualization",
          "Data Visualization using Matplotlib",
          "Hands-on Pandas for Rapid Visualization ",
          "Seaborn for Data Visualization",
        ],
      },
      {
        hadding: "Term 3 : EDA & Data Storytelling",
        week: "4 weeks",
        accodingid: "trem3",
        tremone: [
          "Introduction to Exploratory Data Analysis",
          "EDA Framework Deep Dive",
          "Scientific Exploration of Industry Data (Case Study) ",
          "Student Presentations & Insight Delivery ",
        ],
      },
      {
        hadding: "Minor Capstone Project",
        week: "",
        accodingid: "trem4",
        tremone: [
          "In a simulated environment, you get to work with a major Telecom company that seeks help from your team of Data Scientists to resolve a major marketing hurdle. Get a near real world exposure of working on industry problems within data science teams",
        ],
      },
      {
        hadding: "Term 4 : Machine Learning Foundation",
        week: "4 weeks",
        accodingid: "trem5",
        tremone: [
          "Introduction to Machine Learning",
          "Linear Regression",
          "Logistic Regression",
          "Model Evaluation Techniques",
        ],
      },
      {
        hadding: "Term 5 : Machine Learning Intermediate",
        week: "4 weeks",
        accodingid: "trem6",
        tremone: [
          "Decision Trees",
          "Random Forests ",
          "Dimensionality Reduction using Principal Component Analysis (PCA) ",
          "Naive Bayes Classifier ",
        ],
      },
      {
        hadding: "Term 6: Machine Learning Advanced",
        week: "4 weeks",
        accodingid: "trem7",
        tremone: [
          "KNN (K- Nearestm neighbors)",
          "K-means Clustering",
          "Ensemble Learning ",
          "Optimization ",
         
        ],
      },
      {
        hadding: "Major Capstone Project",
        week: "",
        accodingid: "trem8",
        tremone: [
          "Experience a real world simulation of a company Employee Retention Program that needs to be solved using Data Science and Machine Learning.Work with a team of Data Scientists and experience real-world problem solving through Data Science",
         
        ],
      },
      {
        hadding: "Term 7 : Basics of AI, TensorFlow, and Keras",
        week: "4 weeks",
        accodingid: "trem9",
        tremone: [
          "Introduction to Artificial Intelligence",
          "Introduction to Deep Learning Module",
          "Deep Learning Model Practical with Tensorflow and Keras",
        
         
        ],
      },
      {
        hadding: "Term 8 : Computer Vision",
        week: "4 weeks",
        accodingid: "trem10",
        tremone: [
          "Introduction to Convolutional Neural Networks",
          "Decoding Image Components",
          "Identifying MNIST using CNN",
          "Preprocessing Image Data to apply CNN",
        
         
        ],
      },
      {
        hadding: "Term 9: Natural Language Processing",
        week: "4 weeks",
        accodingid: "trem11",
        tremone: [
          "Introduction to NLP & Word Vectors",
          "Decoding a Textual Data",
          "NLP using Recurrent Neural Networks (RNN)",
          "NLP using Memory Alterations",
        
         
        ],
      },
      {
        hadding: "AI Capstone Project",
        week: "",
        accodingid: "trem12",
        tremone: [
          "In this ever evolving world, solving complex problems get easier using AI.In this capstone project you will be an AI expert who is helping a hypothetical company resolve some major challenges it faces by using AI. Get a chance to work with a team of AI specialists and a simulated environment to help you relate to real world challenges companies face regularly.",
         
        ],
      },
      {
        hadding: "Term 10 : Specialization in CV",
        week: "4 weeks",
        accodingid: "trem13",
        tremone: [
          "Transfer Learning",
          "Object Detection using CNN based Algorithms - I",
          "Object Detection using CNN based Algorithms - II",
          "Instance Segmentation in Images",
        
         
        ],
      },
      {
        hadding: "Term 11 : Specialization in NLP",
        week: "4 weeks",
        accodingid: "trem14",
        tremone: [
          "Teach Machines to Generate New Textual Data (Language Modeling)",
          "Language Translation - English to Spanish using Seq2Seq Models",
          "Techniques to Enhance Seq2Seq Models (Attention Mechanism)",
          "Advanced NLP using BERT",
        
         
        ],
      },
      {
        hadding: "Term 12 : Building AI Solution",
        week: "4 weeks",
        accodingid: "trem15",
        tremone: [
          "Intoduction to Cloud Computing",
          "Language Translation - English to Spanish using Seq2Seq Models",
          "Techniques to Enhance Seq2Seq Models (Attention Mechanism)",
          "Advanced NLP using BERT",
        
         
        ],
      },
      
    ],
    programtoolhadding: "Programming Languages & Tools",
    progaming: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/tools-images/8.webp",
    ],
    toolandassinment: "75+ Topic wise Assignments",
    toolandpackge: "30+ Tools and Packages",
    faculty: [
      {
        facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Deepesh.webp",
        name: "Deepesh",
        college: "IIM-Calcutta",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Nishkam.webp",
        name: "Nishkam",
        college: "Paypal",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/amit-goyal.webp",
        name: "Amit",
        college: "Paytm",
      },
      {
        facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Gaurav-Trivedi-1.webp",
        name: "Gaurav",
        college: "IIT Guwahati",
      },
      {
        facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Lavi.webp",
        name: "Lavi",
        college: "Google",
      },
      {
        facultyimag:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/subhodeep.webp",
        name: "Subhodeep",
        college: "Jio",
      },
      {
        facultyimag: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Faculty/IITG-Faculty/Neelmani.webp",
        name: "Neelmani",
        college: "Gojek",
      },
    ],
    projectsectionhadding: "Work on world class Projects",
    project: [
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p1.webp",
        projecthadding: "Product Classification based on Review",
        sortdescription: "Predict the label of the product based on a review.",
        longdescription:
          "The dataset contains a collection of reviews of the 6 distinct products. The train set contains 40000 rows and 4 columns while the test set contains 10000 rows and 3 columns.",
        id: "1",
      },
      {
        projectimg:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p2.webp",
        projecthadding: "Classify whether Real or Fake News",
        sortdescription:
          "Determine if an article is fake news or not?.",
        longdescription:
          "The dataset contains text data of different articles. The train set contains 35918 rows and 6 columns while the test set contains 8980 rows and 5 columns.",
        id: "2",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p3.webp",
        projecthadding: "Subject Text Classification",
        sortdescription: "Predict the topic of the text passed by the user.",
        longdescription:
          "The dataset consists of different satellite images. The train set contains 3700 images while the test set contains 300 images.",
        id: "3",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p4.webp",
        projecthadding: "Predicting the Topic of the News",
        sortdescription:
          "Classify a news article into a broader topic.",
        longdescription:
          "The dataset contains a collection of news documents. The train set contains 1780 rows and 4 columns while the test set contains 445 rows and 3 columns.",
        id: "4",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p5.webp",
        projecthadding: "Nietzsche Texts Generator",
        sortdescription:
          "Generate texts out a set of characters or words of Nietzsche texts using text generation model.",
        longdescription:
          "The dataset consists of text written by Nietzsche.",
        id: "5",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p6.webp",
        projecthadding: "Abalone Age Prediction",
        sortdescription:
          "Predict the age of abalones based on physical measurements.",
        longdescription:
          "The dataset contains all the necessary information about the abalones like their sex, length, diameter, height, weight, etc. The train set contains 3341 rows and 10 columns while the test set contains 836 rows and 9 columns.",
        id: "6",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/pgpdsf/p7.webp",
        projecthadding: "Drug Prediction",
        sortdescription:
          "Automatic drug prescription based on the health report of the person.",
        longdescription:
          "The dataset contains all the necessary information about the person’s health like their sex, BP, Age, Cholesterol etc. The train set contains 160 rows and 7 columns while the test set contains 40 rows and 6 columns.",
        id: "7",
      },
      {
        projectimg: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Projects/gcd/p8.webp",
        projecthadding: "Avocado Price Prediction",
        sortdescription: "Predicting the future price of avocadoes based on historical data.",
        longdescription:
          "The dataset contains weekly retail scan data for National Retail Volume (units) and price of avocados. The train set contains 14599 rows and 14 columns while the test set contains 3650 rows and 13 columns.",
        id: "8",
      },
    ],
    cc: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/certificate/IIT-certificate/iit12.webp",
    // mc: "https://accredian.com/wp-content/uploads/2022/08/gcdm.webp",
    certifictetstate: "Professionals upskilled in Data Science & AI skillset",
 
    compnyimg: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/students-work-company/DS/sw6.webp",
    ],
    programadvantage: [
      {
        pdid: "btpa1",
        hadding: " Learning Resources & Technical Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/1.webp",
        details: [
          {
            texthdding:
              "Dedicated resource center with 1000+ resources to ease your learning journey. Daily technical support to resolve your queries.",
            desc: "desc",
          },
        ],
        classname: "btpaimageone",
      },
      {
        pdid: "btpa2",
        hadding: " Industry Immersion with Top Data Science Leaders",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/2.webp",
        details: [
          {
            haddingtext: "Monthly Speaker Connect",
            texthdding:
              "At Accredian, we bring in leading Data Science professionals from around the globe for monthly lectures. This provides you with practical insights and prepares you for intense competition in the field of Data Science and AI.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
          {
            haddingtext: "International Summits",
            texthdding:
              "We regularly arrange Data Science Summits every six months, where we invite leaders from across the world. The latest summit was the Southeast Asia Conference, focusing on the SEA region.",
            desc: "desctwo",
            hdesc: "hdesctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa3",
        hadding: "Placement Assistance",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/3.webp",
        details: [
          {
            texthdding:
              "1.Carefully crafted program, such as the ‘Career Launchpad,’ is tailored to assist candidates in refining their resumes & profiles.",
            desc: "descthre",
          },
          {
            texthdding:
              "2.Participate in Mock interviews and become job ready",
            desc: "desctwo",
          },
          {
            texthdding:
              "3.Utilise a dedicated job opening portal to find your perfect role.",
            desc: "desctwo",
          },
        ],
        classname: "btpaimagetwo",
      },
      {
        pdid: "btpa4",
        hadding: "Lifelong Community Support",
        cardimges:
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Beyond-the-program-advantage/4.webp",
        details: [
          {
            texthdding:
              "Lifetime access to the resources, content and community support to clear your doubts.",
            desc: "lastdesc",
          },
        ],
        classname: "btpaimageone",
      },
    ],
    steponehadding: "Fill the application form",
    steponedes: "Apply by filling a simple online application form.",
    steptwohadding: "Eligibility Test",
    steptwodes: "Attempt the Eligibility Test and get shortlisted for the admission procedure.",
    steptreehadding: "Start Learning",
    steptreedes:
      "Get access to your dashboard and begin your journey with starter kits.",
    programtotalfee: "2,50,000",
    mothemi12: "14,750",
    mothemi18: "10,227",
    mothemi24: "7,947",
 
    emi: "EMIs Starting 5k/month",
    studentreviews: [
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/1.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/2.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/3.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/4.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/5.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/6.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/7.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/8.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/9.webp",
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Reviews/DS/10.webp",
    ],
    faq: [
      {
        manhadding: "Program Pre-Requisites",
        faqid: "faq1",
        faqtwo: [
          {
            childfaqid: "cfaq1",
            secondhadding:
              "1.Do I need to know Programming & Math before enrolling to a program?",
            faqdetails: [
              "Background in Programming & Maths is helpful but not mandatory.",
            ],
          },
          {
            childfaqid: "cfaq2",
            secondhadding:
              "2.What is the minimum system configuration required?",
            faqdetails: [
              "Any latest processor (i3/i5/i7) with 2.0GHz clock speed and RAM 4GB (required), 8GB(recommended).",
            ],
          },
        ],
      },
      {
        manhadding: "Syllabus & Projects Covered",
        faqid: "faq2",
        faqtwo: [
          {
            childfaqid: "cfaq3",
            secondhadding: "1.How many projects can I anticipate working on?",
            faqdetails: [
              "In the Executive Program for Data Science & AI, you will undertake 12 Term Projects, 1 Mini Capstone Project, and 1 mandatory Capstone Project, as well as 1 AI Capstone Project. In addition to these, we provide a repository of over 100 projects spanning various domains for you to choose from.",
            ],
          },
          {
            childfaqid: "cfaq4",
            secondhadding:
              "2.What's the difference between Term Project & Capstone Project?",
            faqdetails: [
              "Term Projects are individual assignments specific to each term and will be shared upon the completion of every term. Capstone Projects closely resemble real-world Data Science projects, requiring the application of various concepts learned throughout the program.",
            ],
          },
          {
            childfaqid: "cfaq5",
            secondhadding:
              "3.What all topics are covered in the Executive Program in Data Science & AI",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Download our latest program{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  brochure
                </Typography>{" "}
                &{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    marginBottom: "0px !important",
                  }}
                >
                  curriculum.
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
      {
        manhadding: "Time Commitment",
        faqid: "faq3",
        faqtwo: [
          {
            childfaqid: "cfaq6",
            secondhadding: "1.How much time one needs to spend on a regular basis?",
            faqdetails: [
              "Daily 30 mins of prep time is required. You can choose to practice, read or watch videos. Additionally, on weekends one must attend 2 hours of live classes on both Saturday & Sunday.",
            ],
          },
          {
            childfaqid: "cfaq7",
            secondhadding: "2.What is the duration of the program?",
            faqdetails: [
              "Every term is for 4-5 weeks approximately. And the overall Executive Program in Data Science & AI duration is 12 months.",
            ],
          },
        ],
      },

      {
        manhadding: "Why IIT Guwahati ?",
        faqid: "faq5",
        faqtwo: [
          {
            childfaqid: "cfaq10",
            secondhadding: "1.How is the Executive Program in Data Science & AI program different?",
            faqdetails: [
              <>
              <Typography
              sx={{ marginBottom: "0.5rem !important" }}
            >
            This program is distinguished by three key elements:
        
            </Typography>
               <Typography
               sx={{ marginBottom: "0.5rem !important" }}
             >
            1. <b>Iterative Mastery Approach:</b> IITG's exclusive Iterative Mastery Approach ensures development through a step-by-step methodology, specifically designed to welcome individuals from diverse backgrounds. Whether you're seeking a lateral move in the industry or looking to broaden your skills, our program is tailored to empower you on your unique learning journey.
         
             </Typography>
                <Typography
                sx={{ marginBottom: "0.5rem !important" }}
              >
              <Typography
               sx={{ marginBottom: "0.5rem !important" }}
             >
          2. <b>Versatile Skill Development Model:</b> IIT G's unique framework ensures that every Data Science and AI concept is taught in the context of real-world industry problems. Our program goes beyond theory, emphasizing practical case studies to prepare students for the challenges they'll face in the professional arena.
         
             </Typography>
          
              </Typography>
                 <Typography
                 sx={{ marginBottom: "4px !important" }}
               >
           3. <b>Accredian Career Center:</b> Aligned with our mission to cultivate the Data leaders of tomorrow, the Accredian Career Center empowers you to confidently launch your Data Science career.
           
               </Typography>
            </>
            ],
          },
          {
            childfaqid: "cfaq11",
            secondhadding: "2.Tell me more about Career Assistance?",
            faqdetails: [
              "Accredian provides career assistance through the Career Launchpad program. Students gain hands-on experience as leaders in their chosen field with rigorous resume and portfolio building sessions. The program also covers creating a strong online presence and developing top-notch interview skills.",
            ],
          },
          {
            childfaqid: "cfaq12",
            secondhadding: "3. What's the refund policy?",
            faqdetails: [
              <Typography
                sx={{ display: "flex", marginBottom: "0px !important" }}
              >
                Go through our Student Policy{" "}
                <Typography
                  sx={{
                    color: "#eb8c28",
                    ml: 1,
                    mr: 1,
                    marginBottom: "0px !important",
                  }}
                >
               <Link to="/Policy/iitg-admissions-policy" style={{ textDecoration: "none" }}>link</Link> 
                </Typography>
              </Typography>,
            ],
          },
        ],
      },
    ],

    brochurectaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/iitdsaia.webp",
    brochurectahadding: "Download EPDSAIA®️ Program Brochure!",
    curriculumctahadding: "Download EPDSAI®️ Program Curriculum !",
    curriculumctaimg:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/CTA/Brochure-image/Executive-Program-in-DS-AI-a.webp",
    curriculumctatext: "Data Science",
    displayone: "block",
    displaytwo: "none",
    freemodulesdesplay:"block",
    jobandaddon:"none",
    careerassistance:"block",
    addmodul:"block",
    addmodultwo:"block",
    ml: "-53px",
    ctasource:"Data Science Brochure",
    programname:"Executive Program in Data Science & Artificial Intelligence",
    ctapdf:"https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-AI-12-months.pdf",
  },
 
];
