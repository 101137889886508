import React, { useEffect, useState } from "react";
import "../../Assets/css/Home/Home.css";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import HomeSectionOne from "./HomeSectionOne";
import HomeSectionTwo from "./HomeSectionTwo";
import HomeSectionThree from "./HomeSectionThree";
import HomeSectionFour from "./HomeSectionFour";
import HomeSectionFive from "./HomeSectionFive";
import HomeSectionSix from "./HomeSectionSix";
import Testingnavbar from "../Navbar/testinnav";
import Footer from "../Footer/Footer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "@mui/material/Container";
import useStyles from "../../Assets/js/Home/Home";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Carousel from "../3dcarousel/Carousel";
import { v4 as uuidv4 } from "uuid";
import Card from "../3dcarousel/Card";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Rcta from "../cta/request_a_call_back";
const app = localStorage.getItem("user_id");

const Home = () => {
  // useEffect(() => {
  //   document.title = 'Home - Accredian';
  // }, []);

  const classes = useStyles();
  const [showText, setShowText] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [cta, setCta] = useState(false);
  const handleClickOpenRcb = () => {
    setCta(true);
  };
  const RcbhandleClose = () => {
    setCta(false);
  };
  const [searchParams] = useSearchParams();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      if (showText < 2) setShowText(showText + 1);
      else setShowText(0);
    }, 2000);
    return () => clearInterval(timeout);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [showText]);
  const [program, setProgram] = useState("---- Select Program ----");
  const [Rcbopen, RcbsetOpen] = React.useState(false);
  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  
  const [open, setOpen] = useState(false);
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/1.webp"
          name="Product Manager"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo1.webp"
          program="GCPM, 6 Months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/2.webp"
          name="Vice President"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo2.webp"
          program="PGPDSAI, 12 Months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/3.webp"
          name="Senior Data Engineer"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo3.webp"
          program="PGPDSAI, 12 Months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/4.webp"
          name="Data Science Consultant"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo4.webp"
          program="GCDAI, 10 Months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/5.webp"
          name="Product Manager"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo5.webp"
          program="CPM, 3 months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/1.webp"
          name="Product Manager"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo1.webp"
          program="GCPM, 6 Months Program"
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/2.webp"
          name="Vice President"
          companyimg="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/logo2.webp"
          program="PGPDSAI, 12 Months Program"
        />
      ),
    },
  ];
 
  const [token, setToken] = useState("");
  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token);
  }, []);
  
  return (
    <>
      <Testingnavbar />
      
      <Container fixed>
        <Box sx={{ pt: app ?{lg:8.7,sm:4,md:4,xs:5} : {lg:10,sm:15,md:15,xs:16}  }}>
          <Grid lg={12} container>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{ml:{lg:-2,md:-2,sm:-2},  mb: 6 }}
            >
              <Box sx={{ mt:{lg:6,sm:3,md:3},ml:{lg:3,xs:0,md:7,sm:2,xl:1} }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: 300,
                    marginBottom: "0rem!important",
                    pb: 2,
                    textAlign:{xs:"center",lg:"left",md:"left",sm:"left"}
                  }}
                >
                  Get your Dream Job with
                </Typography>
                <Typography
                  sx={{ fontSize: "38px", fontWeight: "bold", pb: 2,  textAlign:{xs:"center",lg:"left",md:"left",sm:"left"} }}
                >
                  Top Programs
                </Typography>
              </Box>
              <Box sx={{ml:{lg:3,xs:0,md:7,sm:2,xl:3} }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 100, pb: 2,display:{xs:"none",lg:"block",md:"block",sm:"block"} }}>
                  Learn with India's leading online school for working
                  professionals
                </Typography>
              </Box>
              <Box sx={{ml:{lg:3,xs:0,md:7,sm:2,xl:1}, textAlign:{xs:"center",lg:"left",md:"left",sm:"left"}}}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "22px",
                  backgroundImage:
                    "linear-gradient(to left, #00c4ff 0%, #0091ff 71%)",
                  borderRadius: "4px",
                  width: "260px",
                  fontWeight: "bold",
                 
                }}
                onClick={handleClickOpenRcb}
              >
                Talk to an Expert
              </Button>
              <Box sx={{justifyContent:{xs:"center",lg:"left",md:"left",sm:"left"},display:{xs:"flex",lg:"left",md:"left",sm:"left"}}}>
              <div className="text-info-parent">
                {showText == 0 && (
                  <motion.div
                    className="text-info"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <img
                      className="img-text-info"
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/cr1.webp"
                    />
                    <span>150% Highest salary hike</span>
                  </motion.div>
                )}
                {showText == 1 && (
                  <motion.div
                    className="text-info"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <img
                      className="img-text-info"
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/cr2.webp"
                    />
                    <span>80% Positive Career Impact</span>
                  </motion.div>
                )}
                {showText == 2 && (
                  <motion.div
                    className="text-info"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <img
                      className="img-text-info"
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/cr3.webp"
                    />
                    <span>360 degree career support</span>
                  </motion.div>
                )}
              </div>
              </Box>
              </Box>
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{ pl:{xs:4,lg:3,md:3}, mb: 6,ml:{xs:-5,sm:0,md:0,lg:0}}}
            >
              <Carousel
                cards={cards}
                height="500px"
               
                margin="0 auto"
                offset={6}
                showArrows={false}
                autoPlay={true}
                interval={4}
                goToSlideDelay={2000}
              />
              {/* <CardMedia
                  component="img"
                  image="/images/sk.gif"
                  alt="green iguana"
                  sx={{
                    display: { xs: "none", lg: "block" },
                    width: "70%",
                    py: 2,
                    pl:15
                  }}
                /> */}
            </Grid>


          </Grid>
        </Box>
        {/* <Box sx={{ mx: "auto" }}>
          <Box
            sx={{
              textAlign: "center",
              display:{lg:"flex",md:"flex",sm:"flex"} ,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ marginBottom:{xs:"1rem !important",lg:"0rem !important"} , mt: 1, textAlign:{xs:"center"} }}>
              Need <b>help</b> choosing the right program?
            </Typography>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                background: "linear-gradient(to top, #b224ef 0%, #7579ff 100%)",
                borderRadius: "8px",
                mx: 2,
                mb:{xs:3}
              }}
              href="https://assist.accredian.com"
            >
              Take a Short Quiz
            </Button>
          </Box>
        </Box> */}
        </Container>

      <HomeSectionOne />
      <HomeSectionTwo />
      <HomeSectionThree />
      <HomeSectionFour />
      <HomeSectionFive />
      <HomeSectionSix />

      <Rcta open={cta} handleClose={RcbhandleClose} />
      <Footer />
    </>
  );
};

export default Home;
