import Moment from 'react-moment';
import moment from 'moment';
const date = moment().day('Sunday').add(7,'days');
// const date = moment();
export const programCardData = [
    
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "ARTIFICIAL INTELLIGENCE",
        category: ["rec","ai"],
        title: "IIT G - Executive Program in Data Science & AI ",
        duration: "12 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-ds-and-ai-advance",
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
    //     tag: "DATA SCIENCE",
    //     category: ["rec","ds"],
    //     title: "IIT G - Advanced Certification in Data Science & ML",
    //     duration: "6 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"/iit/advanced-certification-in-ds-and-ml",
    // },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b3.webp",
    //     tag: "PRODUCT MANAGEMENT",
    //     category: ["rec","pm"],
    //     title: "XLRI- Executive Program in Product Management",
    //     duration: "5 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ffcc00",
    //     pagelink:"/Xlri/executive-program-in-pm-by-xlri",
    // },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b4.webp",
    //     tag: "PRODUCT MANAGEMENT",
    //     category: ["rec", "pm"],
    //     title: "PGP in Product Management",
    //     duration: "8 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#3366ff",
    //     pagelink:"pgp-in-product-management",
    // },

    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "DATA SCIENCE",
        category: ["rec","ds"],
        title: "IIT G - Executive Program in Data Science & Artificial intelligence",
        duration: "12 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#3399ff",
        pagelink:"/iit/executive-program-in-ds-and-ai-advance",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "DATA SCIENCE",
        category: ["rec","ds"],
        title: "IIT G - Executive Program in Data Science and Product Management",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"/iit/executive-program-in-dspm",
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "DATA SCIENCE",
    //     category: ["ds"],
    //     title: "IIT G - Executive Program in Data Science & Artificial intelligence",
    //     duration: "10 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#3399ff",
    //     pagelink:"/iit/executive-program-in-ds-and-ai",
    // },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "DATA SCIENCE",
    //     category: ["ds"],
    //     title: "IIT G - Executive Program in Machine Learning & Artificial intelligence",
    //     duration: "9 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"/iit/executive-program-in-ml-and-ai",
    // },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "DATA SCIENCE",
        category: ["ds"],
        title: "IIT G - Executive Program in Data Science and Digital Transformation",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-ds-and-dt",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "DATA SCIENCE",
        category: ["ds"],
        title: "IIT G - Executive Program in Data Science and Business Analytics",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"/iit/executive-program-in-ds-ba",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "DATA SCIENCE",
        category: ["ds"],
        title: "Global Certificate in Data Science Program",
        duration: "6 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#3399ff",
        pagelink:"global-certificate-in-data-science",
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b3.webp",
    //     tag: "DATA ANALYTICS",
    //     category: ["ds"],
    //     title: "Certificate in Data Science Foundation Program",
    //     duration: "3 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#eb8c28",
    //     pagelink:"certificate-in-data-science-foundation",
    // },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["pm"],
        title: "IIM-V PG Certificate Program in Strategic Product Management",
        duration: "10 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iim/pg-certificate-program-in-strategic-product-management",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["rec","pm"],
        title: "IIT G - Executive Program in Product Management and Data Science",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-pmds",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["rec","pm"],
        title: "IIT G - Executive Program in Data Driven Product Management",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ffcc00",
        pagelink:"/iit/executive-program-in-data-driven-product-management",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["pm"],
        title: "IIT G - Executive Program in Product Management",
        duration: "8 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-pm",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["pm"],
        title: "IIT G - Advanced Certification in Product Management ",
        duration: "5 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/advanced-certification-in-pm",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["pm"],
        title: "IIT G - Executive Program in Product Management and Digital Transformation",
        duration: "10 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ffcc00",
        pagelink:"/iit/executive-program-in-pm-and-dt",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PRODUCT MANAGEMENT",
        category: ["pm"],
        title: "IIT G - Executive Program in Product Management and Business Analytics",
        duration: "10 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-pm-ba",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b4.webp",
        tag: "PROJECT MANAGEMENT",
        category: ["pmi"],
        title: "IIT G - Executive Program in Data Science and Project Management",
        duration: "12 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#3399ff",
        pagelink:"/iit/executive-program-in-dspmi",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "PROJECT MANAGEMENT",
        category: ["pmi"],
        title: "IIT G - Executive Program in Product Management and Project Management",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#eb8c28",
        pagelink:"/iit/executive-program-in-pmpmi",
    },
    

    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b4.webp",
        tag: "ARTIFICIAL INTELLIGENCE",
        category: ["ai", "jgp"],
        title: "PGP in Data Science & Artificial Intelligence",
        duration: "12+3 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ffcc00" ,
        pagelink:"pgp-in-data-science-and-ai",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "ARTIFICIAL INTELLIGENCE",
        category: ["ai"],
        title: "Global Certificate in Data Science & AI",
        duration: "12 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#3366ff" ,
        pagelink:"global-certificate-in-data-science-and-ai",
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b2.webp",
        tag: "ARTIFICIAL INTELLIGENCE",
        category: ["ai", "jgp"],
        title: "PGP in Machine Learning & AI",
        duration: "9 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"pgp-in-machine-learning-and-ai", 
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b3.webp",
    //     tag: "PRODUCT MANAGEMENT",
    //     category: ["pm"],
    //     title: "PGP in Product Management",
    //     duration: "12 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"pgp-in-product-management-12-months", 
    // },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b4.webp",
    //     tag: "PRODUCT MANAGEMENT",
    //     category: ["pm"],
    //     title: "Certificate in Product Management Foundation",
    //     duration: "3 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#eb8c28",
    //     pagelink:"certificate-in-product-management-foundation", 
    // },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "General Management",
        category: ["gm"],
        title: " XLRI - Executive Program in General Management",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"XLRI/executive-program-in-general-management", 
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "Strategy & Leadership",
        category: ["sl"],
        title: " XLRI - Executive Program in Strategy & Leadership",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"XLRI/executive-program-in-strategy-leadership", 
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "Business Management",
        category: ["bm"],
        title: "IIM-V Executive Program in Business Management",
        duration: "12 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"iim/executive-program-in-business-management", 
    },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "HR Analytics",
        category: ["hr"],
        title: "IMI-Executive Program in Strategic HR Analytics",
        duration: "6 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"imi/executive-program-in-strategic-hr-analytic", 
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "Sales and Marketing",
    //     category: ["sm"],
    //     title: "IMI-Executive Program in Sales and Marketing",
    //     duration: "6 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"imi/executive-program-in-sales-marketing", 
    // },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "Digital Transformation",
    //     category: ["dt"],
    //     title: "XLRI-Executive Program in Digital Transformation",
    //     duration: "8 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"/Xlri/executive-program-in-digital-transformation", 
    // },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b4.webp",
        tag: "Digital Transformation",
        category: ["dt"],
        title: "SP Jain Global - Executive Program in Digital Leadership for Business Transformation",
        duration: "6 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"/Xlri/executive-program-in-digital-transformation", 
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "Operations & Supply Chain Management",
    //     category: ["oscm"],
    //     title: "SP Jain Global - Executive Program in Operations & Supply Chain Management",
    //     duration: "6 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"/spj/executive-program-in-oscm", 
    // },
    {
        img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
        tag: "Business Analytics",
        category: ["ba"],
        title: "IIT G - Executive Program in Data Science and Business Analytics ",
        duration: "11 Months",
        mode: "Online",
        deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
        hexcolor: "#ff0000",
        pagelink:"/iit/executive-program-in-ds-ba", 
    },
    // {
    //     img_url: "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/b1.webp",
    //     tag: "Business Analytics",
    //     category: ["ba"],
    //     title: "Accredian - PGP in Data Science and Business Analytics",
    //     duration: "11+2 Months",
    //     mode: "Online",
    //     deadline: <Moment format='D MMM YYYY'>{date}</Moment>,
    //     hexcolor: "#ff0000",
    //     pagelink:"/pgp-in-ds-ba", 
    // },
]