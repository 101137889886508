import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  TextField,
  MenuItem,
  CardMedia,
} from "@mui/material";
import Dashborednav from "../Navbar/Dashborednav";
import CottageIcon from "@mui/icons-material/Cottage";
import Moment from "react-moment";
import moment from "moment";
const date = moment().day("Sunday").add(7, "days");
const category = [
  {
    value: "--- Select Category ---",
    label: "--- Select Category ---",
  },
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
];

const Withoutenrollment = () => {
  const [program, setProgram] = useState("--- Select Category ---");

  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  return (
    <>
    <Dashborednav />
    <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Box sx={{ pt: 8 }}>
      
   
    <Grid item lg={12} container>
      <Grid item lg={2}>
        <Paper
          sx={{
            ml: 2,
            position: "fixed",
            height: "350px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontSize: "0.7rem", color: "#a1a5b7" }}>
              DASHBOARD
            </Typography>
            <Typography
              sx={{ fontSize: "1rem", marginBottom: "0.5rem !important" }}
            >
              <CottageIcon sx={{ fontSize: "1rem", mx: 1 }} />
              My Dashboard
            </Typography>
            <Typography
              sx={{ fontSize: "1rem", marginBottom: "0.5rem !important" }}
            >
              <CottageIcon sx={{ fontSize: "1rem", mx: 1 }} />
              Share Feedback
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={10}>
        <Grid item lg={12} container>
          <Grid
            item
            lg={6}
            sx={{ maxWidth: "46.333333% !important ", mx: 2, p: 1 }}
          >
            <Paper sx={{ p: 2 }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  Schedule a Call{" "}
                </Typography>
                <Typography
                  sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                ></Typography>
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="standard-basic"
                  label="E-mail"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="program"
                  select
                  value={program}
                  onChange={handleChange}
                  variant="standard"
                  fullWidth
                  sx={{ my: 2 }}
                >
                  {category.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  Note: You will receive a call between Mon-Fri from 10AM - 6PM.
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "none",
                    background: "#009ef7",
                    fontWeight: 800,
                    borderRadius: "3px",
                    p: 1,
                    "&:hover": {
                      textTransform: "none",
                      background: "#009ef7",
                      fontWeight: 800,
                      borderRadius: "3px",
                      p: 1,
                    },
                  }}
                >
                  Request call back from expert
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{ maxWidth: "46.333333% !important ", mx: 2, p: 1 }}
          >
            <Paper sx={{ p: 2 }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  Sign Up for a free Webinar{" "}
                </Typography>
                <Typography
                  sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                ></Typography>
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="standard-basic"
                  label="E-mail"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                />
                <TextField
                  id="program"
                  select
                  value={program}
                  onChange={handleChange}
                  variant="standard"
                  fullWidth
                  sx={{ my: 2 }}
                >
                  {category.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  Note: Webinar will be on every Saturday & Sunday
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    textTransform: "none",
                    background: "#009ef7",
                    fontWeight: 800,
                    borderRadius: "3px",
                    p: 1,
                    "&:hover": {
                      textTransform: "none",
                      background: "#009ef7",
                      fontWeight: 800,
                      borderRadius: "3px",
                      p: 1,
                    },
                  }}
                >
                  Sing up Now for free
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ pt: 5, pl: 3, pr: 4, pb: 4 }}>
          <Paper sx={{ p: 1 }}>
            <Grid item lg={12} container sx={{ pt: 3 }}>
              <Grid
                item
                lg={6}
                sx={{ maxWidth: "46.333333% !important ", mx: 2, p: 1 }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  LATEST FROM INSAID{" "}
                </Typography>
                <Typography
                  sx={{ borderTop: "5px solid #0064CE", width: "30%" }}
                ></Typography>
              </Grid>
              <Grid
                item
                lg={6}
                sx={{ maxWidth: "46.333333% !important ", mx: 2, p: 1 }}
              >
                <Box display="flex" sx={{ ml: 3 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginBottom: "0.5rem !important",
                      mx: 2,
                    }}
                  >
                    Follow us on{" "}
                  </Typography>
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/linkedin.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/youtube.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/facebook.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                  <CardMedia
                    component="img"
                    image="https://accredian.com/wp-content/themes/betheme/dashboard-assets/app-assets/images/social/twitter.png"
                    alt="green iguana"
                    sx={{
                      objectFit: "unset",
                      width: 36,
                      height: 36,
                      mx: 2,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: "310px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid #fff",
                },
              }}
            >
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <CardMedia
                  component="img"
                  image="https://i.ytimg.com/vi/aFh-DRf9U_E/mqdefault.jpg"
                  alt="green iguana"
                  sx={{
                    objectFit: "unset",
                    width: 106,

                    mx: 2,
                  }}
                />
                <Typography sx={{ fontSize: "0.8rem" }}>
                  What does a Data Science Project look like? | Ep #34
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Grid item lg={12} container>
          <Grid
            item
            lg={6}
            sx={{ maxWidth: "46.333333% !important", mx: 3, pb: 4 }}
          >
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(21,101,192,0.8), rgba(21,101,192,0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  CERTIFICATE IN DATA SCIENCE FOUNDATION
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  3 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{ maxWidth: "46.333333% !important ", pb: 4 }}>
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(255,41,70,.8), rgba(255,41,70,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  GLOBAL CERTIFICATE IN DATA SCIENCE FOUNDATION
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  6 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{ maxWidth: "46.333333% !important ", mx: 3, pb: 4 }}
          >
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(23,155,173,.8), rgba(23,155,173,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 13,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  GLOBAL CERTIFICATE IN DATA SCIENCE & AI PROGRAM
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  10 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{ maxWidth: "46.333333% !important ", pb: 4 }}>
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(255,100,23,.8),rgba(255,100,23,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  PGP IN DATA SCIENCE & AI PROGRAM
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  12+3 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{ maxWidth: "46.333333% !important ", mx: 3, pb: 4 }}
          >
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(21,101,192,0.8), rgba(21,101,192,0.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  CERTIFICATE IN PRODUCT MANAGEMENT
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  3 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{ maxWidth: "46.333333% !important ", pb: 4 }}>
            <Box
              sx={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(255,41,70,.8), rgba(255,41,70,.8)), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pgpdsai-scaled.webp)",
                backgroundSize: "cover",
                height: "362px",
              }}
            >
              <Box sx={{ pt: 14 }}>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  GLOBAL CERTIFICATE IN PRODUCT MANAGEMENT
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  6 Months | Online
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#fff",
                    textAlign: "center",
                    mx: 15,

                    marginBottom: "0.5rem !important",
                  }}
                >
                  Next batch:
                  <Moment format="D MMM YYYY">{date}</Moment>
                </Typography>

                <center>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#f8f9fa",
                      color: "#000",
                      "&:hover": {
                        textTransform: "none",
                        background: "#f8f9fa",
                        color: "#000",
                      },
                    }}
                  >
                    Explore Now
                  </Button>
                </center>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box>
    </Box>
    </>
  );
};
export default Withoutenrollment;
