import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Link,
  Backdrop,
} from "@mui/material";
// import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/LoginDashboard";
import Sidbar from "../Sidbar";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
// import {filesize} from "filesize";
// const pubdate = [
//   {
//     value: "date",
//     label: "Publish Date",
//   },
//   {
//     value: "title",
//     label: "Title",
//   },
//   {
//     value: "BTC",
//     label: "Update Date",
//   },
//   {
//     value: "JPY",
//     label: "Downloads",
//   },
//   {
//     value: "JPY",
//     label: "Views",
//   },
// ];
const oders = [
  {
    value: "desc",
    label: "Descending",
  },
  {
    value: "asc",
    label: "Ascending",
  },
];
const firstIndex = 0;
const PmResearchPaper = () => {
  const [fdata, setFdata] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [data, setData] = useState(fdata.slice(firstIndex, pageSize));
  const [searchTerm, setSearchTerm] = useState("");
  const [aoderby, setAoder] = useState("");
  const [open, setOpen] = useState(false);
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, value) => {
    setCurrentPage(value);
    setData(fdata.slice(firstIndex + pageSize * (value - 1), pageSize * value));
  };
  // const [file ,setfile] =useState("");
  const fetchdata = async () => {
    LoaderOpen();
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/resourcecenter/fetchresourcecenter`,
      data: {
        id: 21,
      },
    }).then((res) => {
      setFdata(res.data);
      setData(res.data.slice(0, pageSize));
      LoaderClose();
      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const imageDownload = (url, count, docid, name) => {
    LoaderOpen();
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.click();
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      count: count,
      docid: docid,
    };
    axios
      .post(
        "${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/resourcecenter/updatecount",
        sandData,
        options
      )
      .then((result) => {
        fetchdata();
        LoaderClose();
      });
    //console.log(count);
  };

  useEffect(() => {
    fetchdata();
  }, []);
  function Sortdata() {
    if (aoderby) {
      if (aoderby == "asc") {
        var acsortingdata = fdata.sort().reverse();
        //console.log(data, "asc");

        setData(acsortingdata.slice(0, pageSize));
      } else {
        //console.log(data, "dasc");
        var sortingdata = fdata.sort().reverse();

        setData(sortingdata.slice(0, pageSize));
      }
    }
  }
  function oderby(e) {
    setAoder(e.target.value);
    //console.log(e.target.value);
  }

  // useEffect(() => {

  //   const getMovieDetails = async () => {
  //     const dataFromServer = await axios.post(
  //       `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/resourcecenter/fetchresourcecenter?page=${currentPage}`,

  //     );
  //     const responeFromServer = dataFromServer.data.results;
  //     setFdata(responeFromServer);
  //     //console.log(responeFromServer);
  //   };

  //   getMovieDetails();
  // }, [currentPage]);

  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>

              <Box sx={{ px:{xs:2}}}>
                <Paper
                  sx={{
                    ml:{lg:3},
                    mr:{lg:10},
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Grid container>
                  <Grid lg={8} xs={12}>
                      <Box sx={{ pl:{lg:2} }}>
                        <Typography
                          sx={{
                            marginBottom: "0.5rem!important",
                            fontWeight: "bold",
                            fontSize: "2rem",
                          }}
                        >
                          Research Paper
                        </Typography>
                        <Box display="flex">
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#a1a5b7",
                              fontSize: "0.8rem",
                            }}
                          >
                            Dashboard{" "}
                          </Typography>{" "}
                          <RemoveIcon
                            sx={{ fontSize: "1rem", mt: 0.4, color: "#a1a5b7" }}
                          />
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              color: "#a1a5b7",
                              fontSize: "0.8rem",
                            }}
                          >
                            {" "}
                            Resource Centre{" "}
                          </Typography>{" "}
                          <RemoveIcon
                            sx={{ fontSize: "1rem", mt: 0.4, color: "#a1a5b7" }}
                          />
                          <Typography
                            sx={{
                              marginBottom: "0.5rem!important",
                              fontSize: "0.8rem",
                            }}
                          >
                            Research Paper
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid lg={4}>
                      <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/pdficon.webp"
                        alt="Goole"
                        sx={{
                          width: "80px",
                          mx: "auto",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box sx={{ px:{xs:2}}}>
                <Paper
                  sx={{
                    ml:{lg:3},
                    mr:{lg:10},
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Box>
                    <Grid container>
                    <Grid lg={5} xs={12} sx={{mb:{xs:2}}}>
                        <TextField
                          fullWidth
                          label="Search Keyword..."
                          id="fullWidth"
                          size="small"
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid lg={2}>
                        {/* <TextField
                          id="simaple"
                          select
                          label="Select"
                          defaultValue="date"
                          fullWidth
                          sx={{ ml: 2 }}
                          size="small"
                        >
                          {pubdate.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </Grid>
                      <Grid lg={2} xs={12} sx={{mb:{xs:2}}}>
                        <TextField
                          id="oders"
                          select
                          label="Select"
                          defaultValue="asc"
                          fullWidth
                          sx={{ ml:{lg:4}}}
                          size="small"
                          onChange={oderby}
                        >
                          {oders.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid lg={3} xs={12}>
                        <Button
                          variant="contained"
                          sx={{
                            mb: 0.3,
                            float:{lg:"right"},
                            mr: 3,
                            background: "#e4e6ef",
                            color: "#000",
                            borderRadius: "8px",
                            textTransform: "none",

                            "&:hover": {
                              background: "#e4e6ef",
                              color: "#000",
                              borderRadius: "8px",
                              textTransform: "none",
                            },
                          }}
                          onClick={Sortdata}
                        >
                          Apply Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Box sx={{ ml:{lg:3}, mr:{lg:10},px:{xs:2}}}>
                <Grid container>
                  {data &&
                    data
                      .filter((val) => {
                        if (searchTerm == "") {
                          return val;
                        } else if (
                          val.hadding
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((rcdoc) => (
                        <>
                       <Grid lg={4} xs={12} sx={{mb:4 }}>
                    <Card sx={{ minWidth: 225, width:{lg:285,xs:330}, ml:{lg:1}}} >
                              <CardContent>
                                <Box display="flex">
                                  <Box>
                                    {(() => {
                                      if (rcdoc.file_type == "pdf") {
                                        return (
                                          <>
                                            <CardMedia
                                              component="img"
                                              image="../Rcimages/pdf.svg"
                                              alt="Goole"
                                              sx={{
                                                width: "25px",
                                              }}
                                            />
                                          </>
                                        );
                                      } else if (rcdoc.file_type == "image") {
                                        return (
                                          <>
                                            <CardMedia
                                              component="img"
                                              image="../Rcimages/png.svg"
                                              alt="Goole"
                                              sx={{
                                                width: "25px",
                                              }}
                                            />
                                          </>
                                        );
                                      } else if (rcdoc.file_type == "ppt") {
                                        return (
                                          <>
                                            <CardMedia
                                              component="img"
                                              image="../Rcimages/ppt.png"
                                              alt="Goole"
                                              sx={{
                                                width: "25px",
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    })()}
                                  </Box>
                                  <Box sx={{}}>
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        ml: 2,
                                        lineHeight: 1.2,
                                        height: "40px",
                                      }}
                                      color="text.dark"
                                    >
                                      {rcdoc.hadding}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    height: "130px",
                                    py: 2,
                                    overflowY: "scroll",
                                    "&::-webkit-scrollbar": {
                                      width: "0.2em",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      boxShadow:
                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                      webkitBoxShadow:
                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "rgba(0,0,0,0)",
                                      outline: "1px solid #fff",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{ mb: 1.5, fontSize: 13 }}
                                    color="text.dark"
                                  >
                                    {rcdoc.description}
                                  </Typography>
                                </Box>
                              </CardContent>
                              <CardActions sx={{ ml: 1 }}>
                                <Box display="flex">
                                  <Box>
                                    <Button
                                      variant="contained"
                                      sx={{
                                        mb: 0.3,
                                        float: "right",
                                        mr: 3,
                                        background: "#009ef7",

                                        borderRadius: "8px",
                                        textTransform: "none",

                                        "&:hover": {
                                          background: "#009ef7",

                                          borderRadius: "8px",
                                          textTransform: "none",
                                        },
                                      }}
                                      onClick={() =>
                                        imageDownload(
                                          rcdoc.file_link,
                                          rcdoc.count,
                                          rcdoc.docid
                                        )
                                      }
                                    >
                                      Download
                                    </Button>
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        marginBottom: "0rem!important",
                                        ml: 3,
                                      }}
                                      color="text.dark"
                                    >
                                      {rcdoc.count} Downloads
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        marginBottom: "0rem!important",
                                        ml: 3,
                                      }}
                                      color="text.dark"
                                    >
                                      {rcdoc.file_size.value}{rcdoc.file_size.unit}
                                    </Typography>
                                  </Box>
                                </Box>
                              </CardActions>
                            </Card>
                          </Grid>
                        </>
                      ))}
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={Math.ceil(fdata.length / pageSize)}
                    page={currentPage}
                    onChange={handleChange}
                    color="primary"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default PmResearchPaper;
