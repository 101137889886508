import React from "react";
import "../../Assets/css/Home/HomeSectionFour.css";
import { Grid, Box, Typography, Container, CardMedia } from "@mui/material";
import Divider from "@mui/material/Divider";
const HomeSectionFour = () => {
  return (
    <>
      <Container fixed>
        <Box sx={{ display: { xs: "none", lg: "block",sm:"none",md:"none" }, mt: 10 }}>
          <Typography
            sx={{
              fontSize: "26px",
              marginBottom: "0.3rem!important",
              fontWeight: 400,
            }}
          >
            Placement Support
          </Typography>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: 400,
              marginBottom: "0.3rem!important",
            }}
          >
            One dashboard for all. Get 100% assurance to crack any interview.
          </Typography>
          <Grid lg={12} xl={12} container sx={{ mt: 9 }}>
            <Grid lg={6} xl={6} className="plc-left">
              <Grid lg={12} xl={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="newimage">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps1.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="plc-in">
                  <h3>360 Degree - Career Launchpad</h3>
                  <span className="sub-head">
                    Get a 360 degree career transformation with resume
                    preparation, GitHub & Linkedin profile builders.
                  </span>
                </div>
              </Grid>
              <Grid lg={12} xl={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="newimage">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps2.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="plc-in">
                  <h3>Interview Preparation</h3>
                  <span className="sub-head">
                    Apply to recommended jobs based on your profile and crack
                    any interview with Top 100 Interview questions guide. Don’t
                    worry we’ll take mocks too.
                  </span>
                </div>
              </Grid>
              <Grid lg={12} xl={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="newimage">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps3.webp"
                      alt=""
                      className="lastimage"
                    />
                  </div>
                </div>
                <div className="plc-in">
                  <h3>1-on-1 Mentor Sessions</h3>
                  <span className="sub-head">
                    Global Data Leader talks help you gain practical insights
                    into industry requirements, current trends and
                    opportunities.
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/laptopscreen.webp"
                sx={{ ml: 2 }}
              />
            </Grid>
          </Grid>
        </Box>
        </Container>
        <Box
          className="place-Supp"
          sx={{ display: { xs: "block", lg: "none",sm:"block",md:"block" }, pt: 6 }}
        >
          <Typography
            variant="h4"
            component="div"
            fontWeight="blod"
            sx={{ fontSize: "22px" }}
          >
            Placement Support
          </Typography>
          <Typography
            variant="span"
            component="div"
            sx={{ fontSize: "15px", mt: 3, mb: 8 }}
          >
            One dashboard for all. Get 100% assurance to crack any interview.
          </Typography>
          <Grid lg={12} container sx={{ marginTop: "0.5rem" }}>
            <Grid  lg={6} xs={12} className="plc-left">
              <Grid lg={12} xs={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps1.webp"
                      alt=""
                    />
                  </div>
                </div>

                <div className="plc-in">
                  <Typography
                    variant="h4"
                    component="div"
                    fontWeight="blod"
                    sx={{ fontSize: "17px" }}
                  >
                    360 Degree - Career Launchpad
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ fontSize: "15px", mt: 3 }}
                  >
                    Get a 360 degree career transformation with resume
                    preparation, GitHub & Linkedin profile builders.
                  </Typography>
                </div>
              </Grid>
              <Grid lg={12} xs={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps2.webp"
                      alt=""
                    />
                  </div>
                </div>

                <div className="plc-in">
                  <Typography
                    variant="h4"
                    component="div"
                    fontWeight="blod"
                    sx={{ fontSize: "17px" }}
                  >
                    Interview Preparation
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ fontSize: "15px", mt: 3 }}
                  >
                    Apply to recommended jobs based on your profile and crack
                    any interview with Top 100 Interview questions guide. Don’t
                    worry we’ll take mocks too.
                  </Typography>
                </div>
              </Grid>
              <Grid lg={12} xs={12} className="plc-sup">
                <div className="plc-img-div">
                  <div className="">
                    <img
                      src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/ps3.webp"
                      alt=""
                      className="lastimage"
                    />
                  </div>
                </div>

                <div className="plc-in">
                  <Typography
                    variant="h4"
                    component="div"
                    fontWeight="blod"
                    sx={{ fontSize: "17px" }}
                  >
                    1-on-1 Mentor Sessions
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    sx={{ fontSize: "15px", mt: 3 }}
                  >
                    Global Data Leader talks help you gain practical insights
                    into industry requirements, current trends and
                    opportunities.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
    
    </>
  );
};

export default HomeSectionFour;
