import React, { useEffect, useState } from "react";
import { Grid, CardMedia, Container, Backdrop } from "@mui/material";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useNavigate, Redirect } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import useStyles from "./style";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Topbar from "../Topbar/topbar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
const app = localStorage.getItem("user_id");
const firstname = localStorage.getItem("username");
const email = localStorage.getItem("email");
const drawerWidth = 330;
const navItems = ["Home", "About", "Contact"];

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            pl:2.1,
            pr:1,
            py: 1,
            height: "448px",
            width:"min(780px,90vw - 300px)",
            borderRadius: "8px",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function Loginbar(props) {
  let navigate = useNavigate();
  const LogOut = () => {
    localStorage.removeItem("user_id");
    localStorage.clear();
    setUser("");
    navigate(`/Login`);
  };
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");

    setUser(user_id);

    // Clear the interval when the component is unmounted or no longer needed
  }, []);

  const { window } = props;
  const [user, setUser] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElab, setAnchorElab] = useState(null);
  const [anchorElab2, setAnchorElab2] = useState(null);
  const [anchorElab3, setAnchorElab3] = useState(null);
  const [anchorElab4, setAnchorElab4] = useState(null);
  const [anchorElab5, setAnchorElab5] = useState(null);
  const [value, setValue] = useState(0);
  const [profileanchorEl, setAnchorElProfile] = useState(null);
  const [dsopens, setOpensds] = React.useState(false);
  const [pmopens, setOpenspm] = React.useState(false);
  const [gmopens, setOpensgm] = React.useState(false);
  const [smopens, setOpenssm] = React.useState(false);
  const [dtopens, setOpensdt] = React.useState(false);
  const [baopens, setOpensba] = React.useState(false);
  const [imiopens, setOpensimi] = React.useState(false);
  const [spjopens, setOpensspj] = React.useState(false);
  const [iimopens, setOpensiim] = React.useState(false);
  const [pmiopens, setOpenspmi] = React.useState(false);
  const [slopens, setOpenssl] = React.useState(false);
  const [mobopen, setOpens] = useState(false);
  const [adani, setAdani] = useState(false);
  const [userName, setName] = useState("");
  const profileopen = Boolean(profileanchorEl);

  const [filepath, setFilepath] = useState(localStorage.getItem("profileURL")?localStorage.getItem("profileURL"):"");
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      if (res.data.status == "300") {
        localStorage.setItem("profileURL","https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp")
        setFilepath(
          "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/user.webp"
        );
      } else {
        localStorage.setItem("profileURL",res.data[0].filename)
        setFilepath(res.data[0].filename);
      }
    });
  };
  useEffect(() => {
    if(!localStorage.getItem("profileURL")){
      fetchData()
    }
  }, [localStorage.getItem("profile")]);
  // const[show,setShow]=useState(false)
  // //console.log(show)
  const [Loder, setLoder] = useState("");
  const LoaderOpen = () => {
    setLoder(true);
  };
  const LoaderClose = () => {
    setLoder(false);
  };
  const adanihandleClick = () => {
    setAdani(!adani);
  };
  useEffect(() => {
    setName(localStorage.getItem("username"));
  }, [localStorage.getItem("username")]);
  const classes = useStyles();
  const ProfilehandleClick = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const ProfilehandleClose = () => {
    setAnchorElProfile(null);
  };
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleMouseOver = (event) => {
    // setShow(true)
    setAnchorElab(event.currentTarget);
  };
  // const handleMouseLeave=(event)=>{
  //   // setAnchorElab(null);
  //   setShow(false)
  // }
  const handleClose = (event) => {
    // if (anchorElab) {
    setAnchorElab(null);

    // }
    // setAnchorElab(null);
  };
  const handleMouseOver2 = (event) => {
    // setShow(true)
    setAnchorElab2(event.currentTarget);
  };
  // const handleMouseLeave=(event)=>{
  //   // setAnchorElab(null);
  //   setShow(false)
  // }
  const handleClose2 = () => {
    // if (anchorElab) {
    setAnchorElab2(null);
    // }
    // setAnchorElab(null);
  };
  const handleMouseOver3 = (event) => {
    // setShow(true)
    setAnchorElab3(event.currentTarget);
  };
  const handleMouseOver4 = (event) => {
    // setShow(true)
    setAnchorElab4(event.currentTarget);
  };
  const handleMouseOver5 = (event) => {
    // setShow(true)
    setAnchorElab5(event.currentTarget);
  };
  // const handleMouseLeave=(event)=>{
  //   // setAnchorElab(null);
  //   setShow(false)
  // }
  const handleClose3 = () => {
    // if (anchorElab) {
    setAnchorElab3(null);
    // }
    // setAnchorElab(null);
  };
  const handlePClose = () => {
    setAnchorEl(null);
  };
  const handleClose4 = () => {
    // if (anchorElab) {
    setAnchorElab4(null);
    // }
    // setAnchorElab(null);
  };
  const handleClose5 = () => {
    // if (anchorElab) {
    setAnchorElab5(null);
    // }
    // setAnchorElab(null);
  };
  const mobhandleClick = () => {
    setOpens(!mobopen);
  };
  const dshandleClick = () => {
    setOpensds(!dsopens);
  };
  const pmhandleClick = () => {
    setOpenspm(!pmopens);
  };
  const gmhandleClick = () => {
    setOpensgm(!gmopens);
  };
  const slhandleClick = () => {
    setOpenssl(!slopens);
  };
  const smhandleClick = () => {
    setOpenssm(!smopens);
  };
  const dthandleClick = () => {
    setOpensdt(!dtopens);
  };
  const bahandleClick = () => {
    setOpensba(!baopens);
  };
  const imihandleClick = () => {
    setOpensimi(!imiopens);
  };
  const spjhandleClick = () => {
    setOpensspj(!spjopens);
  };
  const iimhandleClick = () => {
    setOpensiim(!iimopens);
  };
  const pmihandleClick = () => {
    setOpenspmi(!pmiopens);
  };
  const [abopens, setOpenab] = useState(false);
  const [resopens, setOpenres] = useState(false);
  const [glossopens, setOpengloss] = useState(false);
  const [keymodule, setKeymodule] = useState(false);
  const [factsheet, setFactsheet] = useState(false);
  const abhandleClick = () => {
    setOpenab(!abopens);
  };
  const resoursehandleClick = () => {
    setOpenres(!resopens);
  };
  const glossaryhandleClick = () => {
    setOpengloss(!glossopens);
  };
  const keymhandleClick = () => {
    setKeymodule(!keymodule);
  };
  const fshandleClick = () => {
    setFactsheet(!factsheet);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const opens = Boolean(anchorElab3);
  const mid = opens ? "simple-popper" : undefined;
  const kopens = Boolean(anchorElab4);
  const kid = kopens ? "simple-popper" : undefined;
  const fopens = Boolean(anchorElab5);
  const fid = fopens ? "simple-popper" : undefined;
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box display="flex">
        <Link style={{ textDecoration: "none", color: "#000" }} to="/">
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
            alt="green iguana"
            sx={{
              width: 120,
              py: 1,
              ml: 2.5,
            }}
          />
        </Link>
        <ClearIcon sx={{ mt: 1, ml: 18 }} onClick={handleDrawerToggle} />
      </Box>
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
          ></ListSubheader>
        }
      >
        <ListItemButton onClick={mobhandleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Program" />
          {mobopen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={mobopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={dshandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Data Science" />
              {dsopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          {/*  The above code is a JSX code snippet written in JavaScript React. It is rendering a
          collapsible list item with a link to a specific page. The `Collapse` component is
          conditionally rendering the list item based on the value of the `dsopens` variable. The
          `List` component is used to create a list, and the `ListItemButton` component represents a
          clickable list item. The `Link` component is used to create a hyperlink to the specified
          URL. The `Box` component is used to create a styled box element with specific dimensions,
          background color, and border radius. The code */ }
            <Collapse in={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-ds-and-ai-advance"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                           px:2,
                          }}
                        >
                          IIT G - Executive Program in Data Science & AI
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/pgp-in-data-science-and-ai"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                               <Box
                          sx={{
                         background:"#e64d00",
                              borderRadius:"0px 10px",
                              ml:11,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                          Job Gurantee
                          </Typography>
                        </Box>
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - PGP in Data Science & AI
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-dspm"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Data Science and Product
                          Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-ds-and-dt"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Data Science and Digital
                          Transformation
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-ds-ba"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G- Executive Program in Data Science and Business
                          Analytics
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-dspmi"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G- Executive Program in Data Science and Project
                          Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/pgp-in-pgp-in-ds-ba"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "48px",
                            pt: 2,
                            pl: 2,
                          }}
                        >
                          Accredian - PGP in Data Science and Business Analytics
                          (with 2 months Harvard Certification)
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11+2 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-ml-and-ai"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Data Science and Product
                          Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          9 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/pgp-in-machine-learning-and-ai/"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        {/* <Box
                          sx={{
                            borderBottom: "0px solid transparent",
                            borderLeft: "20px solid transparent",
                            borderRight: "0px solid transparent",
                            borderTop: "27px solid #e64d00",
                            width: 100,
                            ml: 6,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                               
                              fontSize: "13px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Job Gurantee
                          </Typography>
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - PGP in Machine Learning & AI
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          9 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-ds-and-ai"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Data Science & AI
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          10 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/global-certificate-in-data-science-and-ai"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        {/* <Box
                          sx={{
                            borderBottom: "0px solid transparent",
                            borderLeft: "20px solid transparent",
                            borderRight: "0px solid transparent",
                            borderTop: "27px solid #e64d00",
                            width: 100,
                            ml: 6,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                               
                              fontSize: "13px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Job Gurantee
                          </Typography>
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - Global Certificate in Data Science & AI
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          10 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/advanced-certification-in-ds-and-ml"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
            
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Advanced Certificate in Data Science & ML
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/global-certificate-in-data-science"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        {/* <Box
                          sx={{
                            borderBottom: "0px solid transparent",
                            borderLeft: "20px solid transparent",
                            borderRight: "0px solid transparent",
                            borderTop: "27px solid #e64d00",
                            width: 100,
                            ml: 6,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                               
                              fontSize: "13px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Job Gurantee
                          </Typography>
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - Global Certificate in Data Science
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/certificate-in-data-analytics"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Certificate in Data Analytics
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          3 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/certificate-in-data-science-foundation"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        {/* <Box
                          sx={{
                            borderBottom: "0px solid transparent",
                            borderLeft: "20px solid transparent",
                            borderRight: "0px solid transparent",
                            borderTop: "27px solid #e64d00",
                            width: 100,
                            ml: 6,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                               
                              fontSize: "13px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Job Gurantee
                          </Typography>
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - Certificate in Data Science Foundation
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          3 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>

                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/pgp-in-ai/"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                          alt="iit"
                          sx={{
                            height: "30px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        {/* <Box
                          sx={{
                            borderBottom: "0px solid transparent",
                            borderLeft: "20px solid transparent",
                            borderRight: "0px solid transparent",
                            borderTop: "27px solid #e64d00",
                            width: 100,
                            ml: 6,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                               
                              fontSize: "13px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Job Gurantee
                          </Typography>
                        </Box> */}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Accredian - PGP in Artificial Intelligence
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/advanced-program-in-ai"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Advanced Certification In Artificial
                          intelligence
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                
              </List>
            </Collapse>

            <ListItemButton onClick={pmhandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Product Management" />
              {pmopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={pmopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iim/pg-certificate-program-in-strategic-product-management"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/IIMV_LOGO.png"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                            background: "#e64d00",
                            borderRadius: "0px 10px",
                            ml: 12,
                            py: 0.5,
                            px: 1,
                            height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            New Launch
                          </Typography>
                        </Box>
                        
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIMV - PG Certificate Program in Strategic Product Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          10 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                

                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pmds"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Product Management and
                          Data Science
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-data-driven-product-management"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Data Driven Product
                          Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/Xlri/executive-program-in-pm-by-xlri"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          XLRI- Executive Program in Product Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          5 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pm-and-dt"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Product Management and
                          Digital Transformation
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          10 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pm-ba"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G- Executive Program in Product Management and
                          Business Analytics
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          10 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pm"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Executive Program in Product Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          8 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pm"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G - Advanced Certification in Product Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          5 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>

                
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pmpmi"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G- Executive Program in Product Management and
                          Project Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                
              </List>
            </Collapse>

            <ListItemButton onClick={gmhandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="General Management" />
              {gmopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={gmopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/XLRI/executive-program-in-general-management"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          XLRI- Executive Program in General Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>

            
            <ListItemButton onClick={dthandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Digital Transformation" />
              {dtopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={dtopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/Xlri/executive-program-in-digital-transformation"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          XLRI - Executive Program in Digital Transformation
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          8 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton> */}
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/spj/executive-program-in-digital-transformation"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/spjain-logo.webp"
                          alt="spjain"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          SP Jain Global - Executive Program in Digital Leadership for Business Transformation
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={iimhandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Business Management" />
              {iimopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={iimopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iim/executive-program-in-business-management"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/IIMV_LOGO.png"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                            background: "#e64d00",
                            borderRadius: "0px 10px",
                            ml: 12,
                            py: 0.5,
                            px: 1,
                            height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            New Launch
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIM Visakhapatnam - Executive Program in Business Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={pmihandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Project Management" />
              {pmiopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={pmiopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-dspmi"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          IIT G- Executive Program in Data Science and Project
                          Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/iit/executive-program-in-pmpmi"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                     
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                           IIT G - Executive Program in Product Management
                            and Project Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>

            {/* strategy and leadership */}
            <ListItemButton onClick={slhandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Strategy & Leadership" />
              {slopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={slopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/XLRI/executive-program-in-strategy-leadership"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                        <Box
                          sx={{
                            background: "#e64d00",
                            borderRadius: "0px 10px",
                            ml: 12,
                            py: 0.5,
                            px: 1,
                            height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            New Launch
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          XLRI- Executive Program in Strategy & Leadership
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          11 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse>
            {/* <ListItemButton onClick={spjhandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Operations & Supply Chain Management" />
              {spjopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={spjopens} out={dsopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/spj/executive-program-in-oscm"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/spjain-logo.webp"
                          alt="iit"
                          sx={{
                            height: "35px",
                            objectFit: "unset",
                            width: 120,
                            mt: 1,
                          }}
                        />
                           <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:12,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          SP Jain Global - Executive Program in Operations &
                          Supply Chain Management
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          6 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse> */}
           
            {/* <ListItemButton onClick={adanihandleClick}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Cyber Security" />
              {adani ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={adani} out={adani} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/Adani/executive-program-in-cyber-security"
                  >
                    <Box
                      sx={{
                        boxShadow:
                          "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                        width: "300px",
                         height: "144px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        mb: 2,
                        "&:hover": {
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "300px",
                           height: "144px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <Box display="flex" sx={{ ml: 1 }}>
                        <CardMedia
                          component="img"
                          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/adaninew.webp"
                          alt="iit"
                          sx={{
                            height: "21px",
                            objectFit: "unset",
                            width: 131,
                            mt: 1,
                          }}
                        />
                           <Box
                          sx={{
                         background:"#1966fa",
                              borderRadius:"0px 10px",
                              ml:11,
                              py:0.5,
                              px:1,
                              height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10.5px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            Best Selling
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            height: "36px",
                          pt:1.5,
                            px: 2,
                          }}
                        >
                          Adani - Executive Program in Cyber Security
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0, ml: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            mt: 1,
                            color: "#808080",
                          }}
                        >
                          12 Months - Online
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </ListItemButton>
              </List>
            </Collapse> */}
          </List>
        </Collapse>

        <ListItemButton onClick={resoursehandleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Resources" />
          {resopens ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={resopens} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={keymhandleClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Key modules" />
              {keymodule ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={keymodule} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ ml: 3 }}>
                <Link
                  to="/key-modules-resource/DS"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Science" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/key-modules-resource/PM"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product Management" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/key-modules-resource/GM"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="General Management" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/key-modules-resource/BA"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Business Analytics" />
                  </ListItemButton>
                </Link>
                {/* <Link to="/key-modules-resource/HR" style={{textDecoration:"none",color:"#000"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="HR Analytics" />
                  </ListItemButton>
               </Link> */}
              </List>
            </Collapse>

            <ListItemButton onClick={fshandleClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Fact sheet" />
              {factsheet ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={factsheet} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ ml: 3 }}>
                <Link
                  to="/resource-fact-sheet/DS"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Science" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/resource-fact-sheet/PM"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product Management" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/resource-fact-sheet/GM"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="General Management" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/resource-fact-sheet/BA"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Business Analytics" />
                  </ListItemButton>
                </Link>
                {/* <Link to="/resource-fact-sheet/HR" style={{textDecoration:"none",color:"#000"}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="HR Analytics" />
                  </ListItemButton>
               </Link> */}
              </List>
            </Collapse>
            <Link to="/ebook" style={{ textDecoration: "none", color: "#000" }}>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="E-Book" />
              </ListItemButton>
            </Link>
            <a
              href="https://blog.accredian.com/"
              target="_blanck"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Blog" />
              </ListItemButton>
            </a>

            <Link
              to="/Successstories"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Success Stories" />
              </ListItemButton>
            </Link>
            <ListItemButton onClick={glossaryhandleClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Glossary" />
              {glossopens ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={glossopens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ ml: 3 }}>
                <a
                  href="https://glossary.accredian.com/DS"
                  target="_blanck"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Science" />
                  </ListItemButton>
                </a>
                <a
                  href="https://glossary.accredian.com/PM"
                  target="_blanck"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product Management" />
                  </ListItemButton>
                </a>
                <a
                  href="https://glossary.accredian.com/GM"
                  target="_blanck"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="General Management" />
                  </ListItemButton>
                </a>
                <a
                  href="https://glossary.accredian.com/BA"
                  target="_blanck"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Business Analytics" />
                  </ListItemButton>
                </a>
              </List>
            </Collapse>

            <Link style={{ textDecoration: "none", color: "#000" }} to="/Faq">
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Master FAQs" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton onClick={abhandleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="About Accredian" />
          {abopens ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={abopens} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <a
              href="https://corporate.accredian.com"
              target="_blanck"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="For Corporate" />
              </ListItemButton>
            </a>
            <Link
              to="/whyaccredian"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Why Accredian" />
              </ListItemButton>
            </Link>
            {/* <Link
              to="/Successstories"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Success Stories" />
              </ListItemButton>
            </Link> */}
            {/* <Link
              to="/successstories"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Blog" />
              </ListItemButton>
            </Link> */}
            <Link
              to="/Career"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Career" />
              </ListItemButton>
            </Link>
            <Link style={{ textDecoration: "none", color: "#000" }} to="/About">
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Vision & Mission" />
              </ListItemButton>
            </Link>
            {/* <Link style={{ textDecoration: "none", color: "#000" }} to="/Faq">
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Master FAQs" />
              </ListItemButton>
            </Link> */}
          </List>
        </Collapse>

        <ListItemButton>
          <Typography
            id="fade-button"
            aria-controls={profileopen ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={profileopen ? "true" : undefined}
            onMouseOver={ProfilehandleClick}
            sx={{
              pt: 2,
              color: "#000",
              marginBottom: "0rem!important",
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              width: "322px",
            }}
          >
            {/* <AccountCircleIcon sx={{ mr: 1, fontSize: "31px" }} /> {userName}{" "} */}
            <CardMedia
              component="img"
              image={filepath}
              alt="green iguana"
              sx={{ width: 25, height: 25, borderRadius: "8px", mr: 1 }}
            />
            {userName}{" "}
          </Typography>

          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={profileanchorEl}
            open={profileopen}
            onClose={ProfilehandleClose}
            sx={{ mt: 1 }}
          >
            <Link
              to="/Myaccount"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <MenuItem
              // component="a"
              // href="https://accredian.com/my-account/newdashboard/"
              >
                My account
              </MenuItem>
            </Link>
            <MenuItem onClick={LogOut}>Logout</MenuItem>
          </Menu>
        </ListItemButton>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          component="nav"
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0 0 2px 0 rgb(0 0 0 / 50%)",
            position: "fixed",
            zIndex: "99",
            height: "68px",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: "none" }, color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Container fixed>
              <Box
                display="flex"
                sx={{
                  ml: { sm: "auto", md: "auto", xs: "auto" },
                  mr: { sm: "auto", md: "auto", xs: "auto" },
                }}
              >
                <Box
                  sx={{
                    display: "block",
                    ml: { sm: "auto", md: "auto", xs: "auto" },
                    mr: { sm: "auto", md: "auto", xs: "auto" },
                  }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to="/"
                  >
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                      alt="logo"
                      sx={{
                        display: "block",
                        width: 120,
                        py: 1,
                        ml: { lg: -5, sm: "auto", md: "auto", xs: "auto" },
                        mr: { sm: "auto", md: "auto", xs: "auto" },
                        mt: 1.5,
                      }}
                    />
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", lg: "block" },
                    ml: { xl: 45, lg: 45 },
                  }}
                >
                  <Box display="flex" sx={{}}>
                    <Box
                      onMouseOver={() => {
                        handleClose();
                        handleClose2();
                      }}
                    >
                      <Typography
                        sx={{
                          pt: 3,
                          color: "#000",
                          marginBottom: "0rem!important",
                          px: 2,

                          fontWeight: "bold",
                          width: "120px",
                          textAlign: "center",
                        }}
                        onMouseOver={handleClick}
                      >
                        Programs
                      </Typography>
                    </Box>
                    <Box
                      onMouseOver={() => {
                        handleClose();
                        handlePClose();
                        handleClose3();
                      }}
                    >
                      <Typography
                        sx={{
                          pt: 3,
                          color: "#000",
                          marginBottom: "0rem!important",
                          px: 2,
                          textAlign: "center",
                          width: "150px",
                          fontWeight: "bold",
                        }}
                        onMouseOver={handleMouseOver2}
                      >
                        Resources
                      </Typography>
                    </Box>

                    <Box
                      onMouseOver={() => {
                        handleClose2();
                        handlePClose();
                      }}
                    >
                      <Typography
                        sx={{
                          pt: 3,
                          color: "#000",
                          marginBottom: "0rem!important",
                          px: 2,
                          fontWeight: "bold",
                          width: "150px",
                        }}
                        onMouseOver={handleMouseOver}

                        // onMouseLeave={handleClose}
                      >
                        About Accredian
                      </Typography>
                      <Popper
                        anchorEl={anchorElab}
                        keepMounted
                        // sx={{display:`${show?'block':'none'}`}}
                        open={Boolean(anchorElab)}
                        onClose={handleClose}
                        onMouseLeave={handleClose}
                        sx={{
                          ml: 5,
                          marginTop: "20px !important",
                          zIndex: 100,
                          background: "#fff",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                        }}

                        // onMouseOut={handleClose}
                      >
                        <MenuItem
                          sx={{
                            backgroundColor: "transparent",
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <a
                            style={{ textDecoration: "none", color: "#000" }}
                            href="https://corporate.accredian.com/"
                            target="_blank"
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                color: "#666",
                                textDecoration: "none",
                                marginBottom: "0rem!important",
                                fontSize: "14px",
                              }}
                              onClose={handleClose}
                            >
                              For Corporate
                            </Typography>
                          </a>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            backgroundColor: "transparent",
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#000" }}
                            to="/whyaccredian"
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                color: "#666",
                                textDecoration: "none",
                                marginBottom: "0rem!important",
                                fontSize: "14px",
                              }}
                              onClose={handleClose}
                            >
                              Why Accredian
                            </Typography>
                          </Link>
                        </MenuItem>

                        <MenuItem
                          sx={{
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#000" }}
                            to="/Career"
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                color: "#666",
                                textDecoration: "none",
                                marginBottom: "0rem!important",
                                fontSize: "14px",
                              }}
                              onClose={handleClose}
                            >
                              {" "}
                              Career
                            </Typography>
                          </Link>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#000" }}
                            to="/About"
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                color: "#666",
                                textDecoration: "none",
                                marginBottom: "0rem!important",
                                fontSize: "14px",
                              }}
                              onClose={handleClose}
                            >
                              Vision & Mission
                            </Typography>
                          </Link>
                        </MenuItem>
                      </Popper>
                    </Box>

                    <Typography
                      id="fade-button"
                      aria-controls={profileopen ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={profileopen ? "true" : undefined}
                      onMouseOver={ProfilehandleClick}
                      sx={{
                        pt: 3,
                        color: "#000",
                        marginBottom: "0rem!important",
                        px: 2,
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {/* <AccountCircleIcon sx={{ mr: 1 }} /> {userName}{" "} */}
                      <CardMedia
                        component="img"
                        image={filepath}
                        alt="green iguana"
                        sx={{
                          width: 25,
                          height: 25,
                          borderRadius: "8px",
                          mr: 1,
                        }}
                      />
                      {userName}{" "}
                    </Typography>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={profileanchorEl}
                      open={profileopen}
                      onClose={ProfilehandleClose}
                      sx={{ mt: 1 }}
                    >
                      <Link
                        to="/Myaccount"
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <MenuItem>My account</MenuItem>
                      </Link>

                      <MenuItem onClick={LogOut}>Logout</MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Container>
            {/* new file */}
            <Popper
              anchorEl={anchorElab2}
              // sx={{display:`${show?'block':'none'}`}}
              open={Boolean(anchorElab2)}
              onClose={handleClose2}
              onMouseLeave={handleClose2}
              sx={{
                ml: 5,
                marginTop: "20px !important",
                zIndex: 100,
                background: "#fff",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
              }}

              // onMouseOut={handleClose}
            >
              <MenuItem
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={handleMouseOver4}
              >
                <Box
                  sx={{ display: "flex" }}
                  onMouseOver={() => {
                    handleClose();
                    handlePClose();
                    handleClose3();
                    handleClose5();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onClose={handleClose2}
                    onMouseOver={() => {
                      handleClose();
                      handlePClose();
                      handleClose3();
                      handleClose5();
                    }}
                  >
                    Key modules
                  </Typography>
                  <ArrowRightIcon sx={{ ml: 5 }} />
                </Box>
                <Popper
                  id={kid}
                  open={kopens}
                  anchorElab4={anchorElab4}
                  keepMounted
                  sx={{
                    marginTop: "70px!important",
                    marginLeft: {
                      lg: "945px!important",
                      xl: "1060px!important",
                    },
                    zIndex: 100,
                    background: "#fff",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/key-modules-resource/DS"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose5();
                        }}
                      >
                        Data Science
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/key-modules-resource/PM"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose5();
                        }}
                      >
                        Product Management
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/key-modules-resource/GM"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose5();
                        }}
                      >
                        General Management
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/key-modules-resource/BA"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={handleClose2}
                      >
                        Business Analytics
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    {/* <Link to="/key-modules-resource/HR" style={{textDecoration:"none"}}>
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose5();
                        }}
                      >
                       HR Analytics
                      </Typography>
                  </Link> */}
                  </MenuItem>
                </Popper>
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={handleMouseOver5}
              >
                <Box
                  sx={{ display: "flex" }}
                  onMouseOver={() => {
                    handleClose();
                    handlePClose();
                    handleClose3();
                    handleClose4();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onClose={handleClose2}
                    onMouseOver={() => {
                      handleClose();
                      handlePClose();
                      handleClose3();
                      handleClose4();
                    }}
                  >
                    Fact sheet
                  </Typography>
                  <ArrowRightIcon sx={{ ml: 7 }} />
                </Box>
                <Popper
                  id={fid}
                  open={fopens}
                  anchorElab5={anchorElab5}
                  keepMounted
                  sx={{
                    marginTop: "100px!important",
                    marginLeft: {
                      lg: "945px!important",
                      xl: "1060px!important",
                    },
                    zIndex: 100,
                    background: "#fff",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/resource-fact-sheet/DS"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose4();
                        }}
                      >
                        Data Science
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/resource-fact-sheet/PM"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose4();
                        }}
                      >
                        Product Management
                      </Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/resource-fact-sheet/GM"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose4();
                        }}
                      >
                        General Management
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <Link
                      to="/resource-fact-sheet/BA"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={handleClose2}
                      >
                        Business Analytics
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    {/* <Link to="/resource-fact-sheet/HR" style={{textDecoration:"none"}}>
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                          handleClose4();
                        }}
                      >
                      HR Analytics
                      </Typography>
                  </Link> */}
                  </MenuItem>
                </Popper>
              </MenuItem>
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={() => {
                  handleClose3();
                  handleClose4();
                  handleClose5();
                }}
              >
                <Link to={"/ebook"} style={{ textDecoration: "none" }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onClose={handleClose2}
                  >
                    {" "}
                    E-Book
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "#000" }}
                  href="https://blog.accredian.com/"
                  target="_blank"
                >
                  <Box
                    sx={{ width: "100px" }}
                    onMouseOver={() => {
                      handleClose3();
                      handleClose4();
                      handleClose5();
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#666",
                        textDecoration: "none",
                        marginBottom: "0rem!important",
                        fontSize: "14px",
                      }}
                      onClose={handleClose2}
                      onMouseOver={() => {
                        handleClose3();
                        handleClose4();
                        handleClose5();
                      }}
                    >
                      Blog
                    </Typography>
                  </Box>
                </a>
              </MenuItem>

              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={() => {
                  handleClose3();
                  handleClose4();
                  handleClose5();
                }}
              >
                <Link to={"/Successstories"} style={{ textDecoration: "none" }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onClose={handleClose2}
                  >
                    {" "}
                    Success Stories
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={handleMouseOver3}
              >
                <Box
                  sx={{ display: "flex" }}
                  onMouseOver={() => {
                    handleClose();
                    handlePClose();
                    handleClose4();
                    handleClose5();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onMouseOver={() => {
                      handleClose();
                      handlePClose();
                      handleClose4();
                      handleClose5();
                    }}
                    // onMouseLeave={handleClose3}
                  >
                    Glossary
                  </Typography>
                  <ArrowRightIcon sx={{ ml: 8 }} />
                </Box>
                <Popper
                  id={mid}
                  open={opens}
                  anchorElab3={anchorElab3}
                  keepMounted
                  sx={{
                    marginTop: "180px!important",
                    marginLeft: {
                      lg: "945px!important",
                      xl: "1060px!important",
                    },
                    zIndex: 100,
                    background: "#fff",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "#000" }}
                      href="https://glossary.accredian.com/DS"
                      target="_blank"
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                        }}
                      >
                        Data Science
                      </Typography>
                    </a>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "#000" }}
                      href="https://glossary.accredian.com/PM"
                      target="_blank"
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                        }}
                      >
                        Product Management
                      </Typography>
                    </a>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "#000" }}
                      href="https://glossary.accredian.com/GM"
                      target="_blank"
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={() => {
                          handleClose2();
                          handleClose3();
                        }}
                      >
                        General Management
                      </Typography>
                    </a>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "#000" }}
                      href="https://glossary.accredian.com/BA"
                      target="_blank"
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          color: "#666",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "14px",
                        }}
                        onClose={handleClose2}
                      >
                        Business Analytics
                      </Typography>
                    </a>
                  </MenuItem>
                </Popper>
              </MenuItem>

              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onMouseOver={() => {
                  handleClose3();
                  handleClose4();
                  handleClose5();
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "#000" }}
                  to="/Faq"
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#666",
                      textDecoration: "none",
                      marginBottom: "0rem!important",
                      fontSize: "14px",
                    }}
                    onClose={handleClose2}
                  >
                    Master FAQs
                  </Typography>
                </Link>
              </MenuItem>
            </Popper>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                top: "70px",
              }}
              open={open}
            >
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                onMouseLeave={handlePClose}
                sx={{
                  transform: "translate(118px,75px) !important",
                  position: "fixed !important",
                  zIndex: 100,
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    borderRadius: "10px",
                  }}
                >
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    // onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: "100%",
                      textTransform: "none",
                      textAlign: "left",
                      width: "210px",
                      zIndex: 100,
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      borderBottom: "0.5px solid #000",
                    }}
                    className={classes.tabs}
                  >
                    <Tab
                      label="Data Science"
                      {...a11yProps(0)}
                      onMouseOver={(e) => handleChange(e, 0)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />
                    <Tab
                      label="Product Management"
                      {...a11yProps(1)}
                      onMouseOver={(e) => handleChange(e, 1)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />

                    <Tab
                      label="General Management"
                      {...a11yProps(2)}
                      onMouseOver={(e) => handleChange(e, 2)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />

                    <Tab
                      label="Digital Transformation"
                      {...a11yProps(3)}
                      onMouseOver={(e) => handleChange(e, 3)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />
                     <Tab
                      label="Business Management"
                      {...a11yProps(4)}
                      onMouseOver={(e) => handleChange(e, 4)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />
                    <Tab
                      label="Project Management"
                      {...a11yProps(5)}
                      onMouseOver={(e) => handleChange(e, 5)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />
                     <Tab
                      label="Strategy & Leadership"
                      {...a11yProps(6)}
                      onMouseOver={(e) => handleChange(e, 6)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    />
                    
                    {/* <Tab
                      label="Operations & Supply Chain Management"
                      {...a11yProps(5)}
                      onMouseOver={(e) => handleChange(e, 5)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    /> */}
                   
                    

                    {/* <Tab
                      label="Cyber Security"
                      {...a11yProps(5)}
                      onMouseOver={(e) => handleChange(e, 5)}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "unset",
                      }}
                    /> */}
                  </Tabs>

                  <TabPanel
                    value={value}
                    index={0}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Data Science
                    </Typography>
                    <Box sx={{ height: "400px", overflowY: "scroll" }}>
                      <Box display="flex">
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-ds-and-ai-advance"
                        >
                          <Box
                            sx={{
                              
                        boxShadow:"0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="iit"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Science & AI
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",

                                  color: "#808080",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                12 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/pgp-in-data-science-and-ai"
                        >
                          <Box
                            sx={{
                              boxShadow:"0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml: 3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml: 3,
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#e64d00",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                  ml: 17.3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Job Guarantee
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  px: 2,
                                pt:1.5,
                                }}
                              >
                                Accredian - PGP in Data Science & AI
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                12 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box display="flex" sx={{ mt: 2 }}>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-dspm"
                        >
                          <Box
                            sx={{
                              boxShadow:"0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  px: 2,
                                pt:1.5,
                                }}
                              >
                                IIT G - Executive Program in Data Science and
                                Product Management
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/global-certificate-in-data-science-and-ai"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                Accredian - Global Certificate in Data Science &
                                AI
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                10 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                       
                       

                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/pgp-in-ds-ba/"
                        >
                          <Box
                            sx={{
                              boxShadow:"0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml: 3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml: 3,
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  px: 2,
                                pt:1.5,
                                }}
                              >
                                Accredian - PGP in Data Science and Business
                                Analytics (with 2 months Harvard Certification)
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11+2 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                      </Box>
                      <Box display="flex" sx={{ mt: 2}}>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-ds-and-dt"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="iit"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IITG - Executive Program in Data Science and
                                Digital Transformation
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                  mb: 2,
                                }}
                              >
                                11 Months
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/pgp-in-machine-learning-and-ai/"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml: 3,
                            
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="green iguana"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                Accredian - PGP in Machine Learning & AI
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                9 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        
                      </Box>
                      <Box display="flex" sx={{ mt: 2 }}>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-ds-and-ai"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Science & AI
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                10 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-dspmi"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                               
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Science and
                                Project Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                12 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-ds-ba"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Science and
                                Business Analytics
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/pgp-in-ai/"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                             "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                Accredian - PGP in Artificial intelligence
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                6 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        
                        
                      </Box>

                      <Box display="flex" sx={{ mt: 2 }}>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-ml-and-ai"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Machine Learning &
                                Artificial intelligence
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                9 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                    
                      </Box>
                      <Box display="flex" sx={{ mt: 1 }}>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/advanced-program-in-ai"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Advanced Certification In Artificial
                                intelligence
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                6 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                          
                        
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/global-certificate-in-data-science"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              "&:hover": {
                                boxShadow:
                                  "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                               
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                Accredian - Global Certificate in Data Science
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                6 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/certificate-in-data-science-foundation"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml: 3,
                             "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                                alt="iit"
                                sx={{
                                  height: "30px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                Accredian - Certificate in Data Science
                                Foundation
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                3 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box display="flex" sx={{ mt: 2 }}>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/advanced-certification-in-ds-and-ml"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Advanced Certification in Data Science &
                                ML
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                6 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                        
                       
                      </Box>
                 
                      <Box display="flex" sx={{ mt: 2 }}>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/certificate-in-data-analytics"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",

                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G -Certificate in Data Analytics
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                3 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                       
                      </Box>
                   
                 
                    </Box>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Product Management
                    </Typography>
                    <Box sx={{ height: "400px", overflowY: "scroll" }}>
                      <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/iim/pg-certificate-program-in-strategic-product-management"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            // ml:3,
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              // ml:3
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/IIMV_LOGO.png"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#e64d00",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                New Launch
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              IIM Visakhapatnam - PG Certificate Program in Strategic Product Management
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              10 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pmds"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Product Management
                                and Data Science
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      
                      </Box>

                    
                      <Box display="flex" sx={{ mt: 2 }}>
                      <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-data-driven-product-management"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              // ml: 3,
                             "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="iit"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Driven Product
                                Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pm-ba"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Product Management
                                and Business Analytics
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                10 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                       
                      </Box>
                      <Box display="flex" sx={{ mt: 2,mb:2 }}>
                      <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pm-and-dt"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              // ml: 3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                // ml: 3,
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="iit"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IITG - Executive Program in Product Management
                                and Digital Transformation
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                  mb: 2,
                                }}
                              >
                                10 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pmpmi"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Product Management
                                and Project Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                         <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pm"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Product Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                8 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box display="flex" sx={{ mt: 2,mb:2 }}>
                       
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/advanced-certification-in-pm"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              // ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                // ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Advanced Certification in Product
                                Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                5 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                       
                        {/* <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/Xlri/executive-program-in-pm-by-xlri"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml: 3,
                             "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                XLRI- Executive Program in Product Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                5 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                        </Link> */}
                      </Box>
                    </Box>
                  </TabPanel>

                  <TabPanel
                    value={value}
                    index={2}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      General Management
                    </Typography>

                    <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/XLRI/executive-program-in-general-management"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#1966fa",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10.5px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                Best Selling
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              XLRI- Executive Program in General Management
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              11 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel>

                  <TabPanel
                    value={value}
                    index={3}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Digital Transformation
                    </Typography>

                    <Box display="flex" sx={{ mb: 2 }}>
                      {/* <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/Xlri/executive-program-in-digital-transformation"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#1966fa",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10.5px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                Best Selling
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              XLRI- Executive Program in Digital Transformation
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              8 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link> */}
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/spj/executive-program-in-digital-transformation"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            // ml:3,
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                             
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/spjain-logo.webp"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#1966fa",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10.5px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                Best Selling
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              SP Jain Global - Executive Program in Digital Leadership for Business Transformation
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              6 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel>
                  {/* ocsm */}
                  {/* <TabPanel
                    value={value}
                    index={5}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Operations & Supply Chain Management
                    </Typography>

                    <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/spj/executive-program-in-oscm"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/spjain-logo.webp"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#1966fa",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10.5px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                Best Selling
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              SP Jain Global - Executive Program in Operations &
                              Supply Chain Management
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              6 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel> */}
                  <TabPanel
                    value={value}
                    index={4}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Business Management
                    </Typography>

                    <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/iim/executive-program-in-business-management"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/IIMV_LOGO.png"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#e64d00",
                                borderRadius: "0px 10px",
                                ml: 19.2,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                New Launch
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              IIM Visakhapatnam - Executive Program in Business Management
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              12 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel>
                  {/* <TabPanel
                    value={value}
                    index={5}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Cyber Security
                    </Typography>

                    <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/Adani/executive-program-in-cyber-security"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/adaninew.webp"
                              alt="green iguana"
                              sx={{
                                height: "21px",
                                objectFit: "unset",
                                width: 131,
                                mt: 1,
                              }}
                            />
                            <Box
                              sx={{
                                background: "#1966fa",
                                borderRadius: "0px 10px",
                                ml: 17.8,
                                py: 0.5,
                                px: 1,
                                height: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",

                                  fontSize: "10.5px",
                                  marginBottom: "0rem !important",
                                }}
                              >
                                Best Selling
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              Adani - Executive Program in Cyber Security
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              12 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel> */}
                  {/* <TabPanel
                  value={value}
                  index={6}
                  style={{ background: "#FAFBFC", borderRadius:"10px", }}
                >
                  <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                  Choose any two verticals
                  </Typography>
<Box>
                  <FormGroup>
                    <Box display="flex">
      <Box sx={{width:"220px"}}> <FormControlLabel control={<Checkbox  />} label="Data Science" /></Box>
     <Box sx={{width:"220px"}}><FormControlLabel control={<Checkbox  />} label="Product Management" /></Box>
      <Box sx={{width:"220px"}}><FormControlLabel control={<Checkbox  />} label="Digital Transformation" /></Box>
      
     
     
      </Box>
      <Box display="flex">
        <Box sx={{width:"220px"}}><FormControlLabel control={<Checkbox  />} label="General Management" /></Box>
      
      <Box sx={{width:"220px"}}><FormControlLabel control={<Checkbox  />} label="Business Analytics" /></Box>
      <Box sx={{width:"220px"}}><FormControlLabel control={<Checkbox  />} label="Cyber Security" /></Box>
      </Box>
  
    </FormGroup>
    <Box>
    <Button
             
              sx={{ textTransform: "capitalize", my: 2,float:"right" }}
            >
              Explore programs <KeyboardDoubleArrowRightIcon/>
            </Button>
      </Box>
    </Box>
  
<Box sx={{mt:4}}>   <Divider sx={{width:"813px"}}/></Box>
    <Box sx={{mt:3}}>
 
    <Box display="flex">
                    <Link
                      style={{ textDecoration: "none", color: "#000" }}
                      to="/Adani/executive-program-in-cyber-security"
                    >
                      <Box
                        sx={{
                          boxShadow:
                            "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                          width: "390px",
                           height: "144px",
                          backgroundColor: "#fff",
                           borderRadius: "10px",
                          "&:hover": {
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "390px",
                             height: "144px",
                            backgroundColor: "#fff",
                             borderRadius: "10px",
                          },
                        }}
                      >
                        <Box display="flex" sx={{ ml: 1 }}>
                          <CardMedia
                            component="img"
                            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/adaninew.webp"
                            alt="green iguana"
                            sx={{
                              height: "21px",
                              objectFit: "unset",
                              width: 131,
                              mt: 1,
                            }}
                          />
                          <Box
                            sx={{
                              borderBottom: "0px solid transparent",
                              borderLeft: "20px solid transparent",
                              borderRight: "0px solid transparent",
                              borderTop: "27px solid #1966fa",
                              width: 100,
                              ml: 16,
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                 
                                fontSize: "13px",
                                marginBottom: "0rem !important",
                              }}
                            >
                              Best Selling
                            </Typography>
                          </Box>
                        </Box>
                         <Box >
                          <Typography
                            sx={{
                            fontWeight: "700",
                              letterSpacing: "0px",
                              fontSize: "14px",
                              height: "36px",
                            pt:1.5,
                            px:2,
                            }}
                          >
                              Adani - Executive Program in Cyber Security
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 0, ml: 2 }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: 1,
                              color: "#808080",
                            }}
                          >
                            12 Months - Online
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                  </Box>
                </TabPanel> */}
                <TabPanel
                    value={value}
                    index={5}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                      Project Management
                    </Typography>

                    <Box display="flex">
                      <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-dspmi"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                               
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              {/* <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box> */}
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Data Science and
                                Project Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                12 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                      </Link>
                      <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/iit/executive-program-in-pmpmi"
                        >
                          <Box
                            sx={{
                              boxShadow:
                                "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                              ml:3,
                              "&:hover": {
                                boxShadow:
                                  "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                                width: "355px",
                                 height: "144px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                ml:3
                              },
                            }}
                          >
                            <Box display="flex" sx={{ ml: 1 }}>
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iit.webp"
                                alt="green iguana"
                                sx={{
                                  height: "35px",
                                  objectFit: "unset",
                                  width: 120,
                                  mt: 1,
                                }}
                              />
                              {/* <Box
                                sx={{
                                  background: "#1966fa",
                                  borderRadius: "0px 10px",
                                  ml: 19.2,
                                  py: 0.5,
                                  px: 1,
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",

                                    fontSize: "10.5px",
                                    marginBottom: "0rem !important",
                                  }}
                                >
                                  Best Selling
                                </Typography>
                              </Box> */}
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                        fontWeight: "700",
                                  letterSpacing: "0px",
                                  fontSize: "14px",
                                  height: "36px",
                                  marginBottom:"1.3rem !important",
                                  pt: 1.5,
                                  px: 2,
                                }}
                              >
                                IIT G - Executive Program in Product Management
                                and Project Management
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0, ml: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  mt: 1,
                                  color: "#808080",
                                }}
                              >
                                11 Months - Online
                              </Typography>
                            </Box>
                          </Box>
                      </Link>

                    </Box>
                  </TabPanel>
                  {/* strategy and leadership */}
                  <TabPanel
                    value={value}
                    index={6}
                    style={{ background: "#FAFBFC", borderRadius: "10px" }}
                  >
                    <Typography sx={{ color: "#132b5f", fontSize: "20px" }}>
                    Strategy & Leadership
                    </Typography>

                    <Box display="flex">
                      <Link
                        style={{ textDecoration: "none", color: "#000" }}
                        to="/XLRI/executive-program-in-strategy-leadership"
                      >
                        <Box
                          sx={{
                            boxShadow:
                              "0 3px 6px rgba(9,30,66,.03), 0 2px 4px rgba(9,30,66,.08)",
                            width: "355px",
                             height: "144px",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              boxShadow:
                                "0 2px 6px rgba(9,30,66,.04), 0 8px 12px rgba(9,30,66,.08)",
                              width: "355px",
                               height: "144px",
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Box display="flex" sx={{ ml: 1 }}>
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/xlri.webp"
                              alt="green iguana"
                              sx={{
                                height: "35px",
                                objectFit: "unset",
                                width: 120,
                                mt: 1,
                              }}
                            />
                            <Box
                          sx={{
                            background: "#e64d00",
                            borderRadius: "0px 10px",
                            ml: 19.2,
                            py: 0.5,
                            px: 1,
                            height: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",

                              fontSize: "10px",
                              marginBottom: "0rem !important",
                            }}
                          >
                            New Launch
                          </Typography>
                        </Box>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                letterSpacing: "0px",
                                fontSize: "14px",
                                height: "36px",
                              pt:1.5,
                                px: 2,
                              }}
                            >
                              XLRI- Executive Program in Strategy & Leadership
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 0, ml: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                mt: 1,
                                color: "#808080",
                              }}
                            >
                              11 Months - Online
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </TabPanel>
                </Box>
              </Popper>
            </Backdrop>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 3 }}></Box>
      </Box>
    </>
  );
}

Loginbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Loginbar;

// export default Testingnavbar;
