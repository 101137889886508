import React from 'react'
import { Grid, Box, Typography,Container } from '@mui/material'

const Statistics = () => {
  return (
    <Box sx={{ backgroundColor: "#055FA0" }}>
      <Container fixed>
      <Grid  lg={12} container  sx={{py:5}}>
        <Grid  lg={4} xs={12}>
        <Typography
              variant="h3"
              component="div"
              sx={{ mx:0, my: 2, fontWeight: "bold",color:'#fff',fontSize:'42px' }}
              align="center"
            >
              {" "}
             150%
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ mx:0, my: 2,color:'#fff',fontSize:'24px'}}
              align="center"
            >
              {" "}
              Highest Salary Hike
            </Typography>
        </Grid>
        <Grid lg={4} xs={12}>
        <Typography
              variant="h3"
              component="div"
              sx={{ mx:0, my: 2, fontWeight: "bold" ,color:'#fff',fontSize:'42px'}}
              align="center"
            >
              {" "}
              60%
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ mx:0, my: 2,color:'#fff',fontSize:'24px'}}
              align="center"
            >
              {" "}
              Average Salary Hike
            </Typography>
   </Grid>
   <Grid lg={4} xs={12}>
   <Typography
              variant="h3"
              component="div"
              sx={{ mx:0, my: 2, fontWeight: "bold" ,color:'#fff',fontSize:'42px'}}
              align="center"
            >
              {" "}
              80%
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ mx:0, my: 2,color:'#fff',fontSize:'24px'}}
              align="center"
            >
              {" "}
              Positive Career Impact
            </Typography>
   </Grid>

        </Grid>
      </Container>
    
      
      </Box>
  )
}

export default Statistics