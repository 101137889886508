import React, { useEffect, useState,useRef } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Badge,
  Checkbox,
  Tabs,
  Tab,
  CardMedia,
  Step,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import logo from '../Accredian-react-site-master/logo/accredian.webp'
import Sidbar from "../Sidbar";
import LoginDashboard from "../../Navbar/LoginDashboard";
import jsPDF from 'jspdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableDateCell } from "@fullcalendar/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useReactToPrint } from 'react-to-print';
import './fee.css'

const FEE=(props)=>{
    // const doc = new jsPDF();
    const reportTemplateRef = useRef(null);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
            // format: [612, 782],
            unit: "pt",
            orientation: "p",
            lineHeight: 1.2,
		});
  
		// Adding the fonts.
		// doc.setFont('Inter-Regular', 'normal');

		doc.html(reportTemplateRef.current, {
			async callback(doc) {
                window.open(doc.output('bloburl'), '_blank');
				// await doc.save('document');
			},
            html2canvas: {   allowTaint: true,
                dpi: 300,
                letterRendering: true,
                logging: false,
                scale: 1.3},
		});
       
       
	};
    let str2 = props.details.amount.toString().slice(0, -2);
    //console.log(typeof(str2),"ww")
    let totalamount = str2;
    let netamount = totalamount / 1.18;
    let gst = totalamount - netamount;
    
    let igst;
    if (props.details.state === 'Haryana') {
      igst = ['CGST @9% : ', (gst / 2).toFixed(2), 'SGST @9% : ', (gst / 2).toFixed(2)];
    } else {
      igst = gst.toFixed(2);
    }
    
    function printDiv(divId) {
        var printContents = document.getElementById(divId).innerHTML;
        var originalContents = document.body.innerHTML;
      
        document.body.innerHTML = printContents;
        window.print();
      
        document.body.innerHTML = originalContents;
      }
    return (
        <>
        {/* <LoginDashboard/> */}
        {/* <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 2 }}>
            <Box sx={{display:"flex",justifyContent:"flex-end",mb:3}}>
                <Button onClick={handleGeneratePdf} sx={{mr:3,width:"100px"}}  variant="contained" size="small">Download</Button>
                <Button onClick={()=>{printDiv('myDivToPrint')}} sx={{mr:3,width:"100px"}}  variant="contained" size="small">Print</Button>
            </Box>
          <Grid container>
            <Grid item lg={3}>
              <Sidbar />
              ()=>{printDiv('myDivToPrint')}
              ref={reportTemplateRef}
            </Grid>
            <Grid item lg={9}> */}
            <Box sx={{display:"flex",justifyContent:"space-between",mb:3}}>
                {/* <Button onClick={()=>{props.setShow(false)}} sx={{mr:3,width:"100px"}}  variant="contained" size="small">Back</Button> */}
                <Box display="flex" onClick={()=>{props.setShow(false)}} sx={{cursor:"pointer"}}>
                  <ArrowBackIcon sx={{ fontWeight: "bold" }} />
                  <Typography
                    sx={{ ml: 1, fontWeight: "bold", fontSize: "1rem" }}
                  >
                    {" "}
                    Back
                  </Typography>
                </Box>
                <Button onClick={handlePrint} sx={{mr:3,width:20,height:30}}  variant="contained" size="small">Print</Button>
            </Box>
            <Box  ref={componentRef} sx={{display:"flex",justifyContent:"center",placeItems:"center"}}>
            <Box id="myDivToPrint"  sx={{background:"#fff",boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",width:{lg:"70%",xs:"100%"}}}>
                <Box sx={{display:"flex",justifyContent:"space-between",pb:1,pt:3,pl:3,pr:3}}>
            <Typography sx={{color:"#073763",fontWeight:"bold",fontSize:"30px",marginBottom:"0rem ! important"}}>TAX INVOICE</Typography>
            {/* <img src={logo} width={134} height={48} alt="logo"></img> */}
            <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                        alt="logo"
                        sx={{
                          objectFit:"unset",
                       
                        width:134,
                        height:48
                       
                        }}
                      />

            
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <hr style={{color:"#073763",border:"none",borderTop:"2px solid #073763",width:"95%"}}/>
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",mt:2,mx:3}}>
        <Box>
            <Typography sx={{fontWeight:"bold",marginBottom:"0rem !important"}}>FullStack Education Pvt. Ltd</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>250, Nimble Coworks</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>Udyog Vihar, Phase - IV</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>Gurugram, Haryana - 122015</Typography>
            <Typography>GSTIN: 06AADCF7430M1ZH</Typography>
        </Box>
        <Box>
            <Typography sx={{fontWeight:"bold",marginBottom:"0rem !important"}}>Billing Address:</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>{props.details.username}</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>{props.details.email_id}</Typography>
            <Typography>{localStorage.getItem("mobile")}</Typography>
            {/* props.details.phone_number */}
           
        </Box>
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",mt:2,mx:3}}>
        <Box  >
            {/* <Typography sx={{fontWeight:"bold",marginBottom:"0rem !important"}}>FullStack Education Pvt. Ltd</Typography> */}
            <Typography sx={{marginBottom:"0rem !important"}}>Payment ID: {props.details.payment_id.Payment_ID}</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>{props.details.payment_id.Order_ID?`Order ID:${props.details.payment_id.Order_ID}`:""}</Typography>
            {/* <Typography sx={{marginBottom:"0rem !important"}}>Gurugram, Haryana - 122015</Typography> */}
            {/* <Typography>GSTIN: 06AADCF7430M1ZH</Typography> */}
        </Box>
        <Box>
            {/* <Typography sx={{fontWeight:"bold",marginBottom:"0rem !important"}}>Billing Address:</Typography> */}
            <Typography sx={{marginBottom:"0rem !important"}}>{props.details.receipt?`Invoice No:${props.details.receipt}`:""}</Typography>
            <Typography sx={{marginBottom:"0rem !important"}}>Invoice Date: {props.details.order_date}</Typography>
            {/* <Typography>7028589303</Typography> */}
           
        </Box>
        </Box>
        <Box id="table" sx={{display:"flex",justifyContent:"center",mt:3,alignItems:"center"}}>
        <table style={{border:"2px solid black",width:"95%" ,borderCollapse: "collapse"}}>
  <thead style={{backgroundColor:"#3c3d3a",color:"white",padding:"5px",height:"50px"}} >
    
    <tr style={{padding:"5px"}}>
      <th style={{borderLeft: "none" ,color:"white",background:"#3c3d3a",fontWeight:"bold"}}>S.No.</th>
      <th style={{color:"white",background:"#3c3d3a",fontWeight:"bold"}}>Item Description</th>
      <th style={{color:"white",background:"#3c3d3a",fontWeight:"bold"}}>Currency</th>
      <th style={{color:"white",background:"#3c3d3a",fontWeight:"bold"}}>Net Ammount</th>
    </tr>
  </thead>
  <tbody style={{height:"80px"}}>
    <tr style={{textAlign:"center"}}>
      <td><b>1.</b></td>
      <td>{props.details.product_name}</td>
      <td>INR</td>
      <td>{netamount.toFixed(2)}</td>
    </tr>
  </tbody>
</table>
 
        </Box>
    
        {/* <Box sx={{display:"flex",justifyContent:"flex-end"}}>
             <Typography sx={{fontWeight:"bold",marginBottom:"0.5rem !important"}}>Sub Total      8500</Typography>
             <Typography sx={{fontWeight
                :"bold",marginBottom:"0.5rem !important"}}>IGST@ 18%      0.15</Typography>
        </Box> */}

<Box id="sub" sx={{mt:2,display:"flex",justifyContent:"flex-end",mx:5}}>
    <table style={{borderCollapse:"collapse"}}>
        <tbody>
            <tr>
                <td><b>Sub Total</b></td>
                <td style={{marginLeft:"15px !important"}}>{netamount.toFixed(2)}</td>
            </tr>
            <tr style={{borderBottom:"1px solid balck"}}>
                <td><b>IGST@ 18%</b></td>
                <td style={{marginLeft:"15px"}}>{igst}</td>
            </tr>
            <tr style={{backgroundColor:"#ebebeb"}}>
                <td><b>Total:</b></td>
                <td style={{marginLeft:"15px"}}>{parseFloat(totalamount).toFixed(2)}</td>
            </tr>
        </tbody>
    </table>
   
</Box>
        <Box id="foot" sx={{mt:15,mx:3}}>
            <Typography>* This is a computer generated invoice, no signature required</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:3,mb:5}}>
        <hr style={{color:"#073763",border:"none",borderTop:"2px solid #073763",margin:"10px 0px",width:"95%"}}/>
        </Box>
                </Box>
            
            </Box>
                
            {/* </Grid>
            </Grid>
            </Box>
            </Box> */}
        
        </>
    )
}
export default FEE