import { makeStyles } from '@mui/styles';
export default makeStyles(theme => ({

  alumnihadding:{
    fontSize:"42px !important",
    "@media (max-width: 700px)": {
      paddingLeft: "0px",
      paddingRight:"0px",
      fontSize:"25px !important",
     

    }
  },
  susses:{
    paddingLeft:"18px",
    paddingRight:"32px",
    "@media (max-width: 700px)": {
      
      paddingLeft:"0px",
    paddingRight:"0px",
    }
  },
  seconbuttonprv:{
    marginTop: "-593px !important",
    marginLeft: "-89px !important",
    "@media (max-width: 700px)": {
     
      marginTop: "-590px !important",
      marginLeft: "-50px !important",
     
    }
  },
  seconbuttonnext:{
    float:"right",
    marginTop: "-289px !important",
    marginRight: "-44px !important",
    "@media (max-width: 700px)": {
     
      marginTop: "-300px !important",
  
     
    }
  },
  imagsilder:{
    // marginTop:'-76px' ,
    marginLeft:'-30px',
    "@media (max-width: 700px)": {
      marginTop:'10px' ,
      marginLeft:'0px',

    }
  },
  images:{
    width:"80%",
    marginLeft:"70px",
    height:"200px !important",
    // border:"1px solid #007bff",
    "@media (max-width: 700px)": {
      width:"100%",
      height:"220px !important",
      border:"1px solid #007bff",
    }
  },
  slbuttonprv:{
    marginTop: "-400px !important",
    marginLeft: "20px !important",
    "@media (max-width: 700px)": {
      marginTop: "-256px !important",
      marginLeft: "-22px !important",
    }
  },
  slbuttonnext:{
    float: "right",
    // marginLeft:"50px !important",
    marginTop: "-200px !important",
    marginRight:"-30px !important",
    "@media (max-width: 700px)": {
      marginTop: "-134px !important",
      marginRight:"-27px !important",
    }
  },
  logothree: {
    width: "68px",
},
textmargin:{
  marginLeft: "20px",
},
sfbox:{
  display:"flex",
  marginBottom:"10px",
  marginLeft:"-10px"
},

}));
