
import React from "react";
import { Suspense, lazy } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";

// const Dashboard = lazy(() => import('../Components/Dashboard/Dashboard.jsx'));

const AuthDashbord = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Dashboard/> : <Navigate to="/Login" />;
}
export default AuthDashbord;
