import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  MenuItem,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/LoginDashboard";
import Sidbar from "../Sidbar";
import MyProgramNav from "./MyProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const aser = 1;
const PauseProgram = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");

  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
  }, []);
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const currencies = [
    {
      value: "4",
      label: "March 2023",
    },
    {
      value: "5",
      label: "April 2023",
    },
    {
      value: "6",
      label: "May 2023",
    },
    {
      value: "7",
      label: "June 2023",
    },
    {
      value: "8",
      label: "July 2023",
    },
    {
      value: "9",
      label: "August 2023",
    },
  ];
  const [currency, setCurrency] = useState("4");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  if (user == null) {
    navigate(`/Login`);
  }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Box sx={{ pt:{lg:8,xs:3},px:{xs:2,lg:0}}}>
          <Grid container>
          <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                  Pause My Programe
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Hone
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                  Pause My Programe
                </Typography>
              </Box>
              <Box>
                <MyProgramNav />
              </Box>
              <Box>
                <Box>
                  {(() => {
                    if (aser == "1") {
                      return (
                        <>
                          <Box>
                            <Grid container>
                              <Grid item lg={6} xs={12} sx={{mb:{xs:3,lg:0}}}>
                                <Paper
                                  sx={{
                                    ml:{lg:3},
                                    mr:{lg:7},
                                    borderRadius: "8px",
                                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                    p: 2,
                                    height:{lg:"269px",xs:"289px"},
                                  }}
                                >
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Pause My Program
                                  </Typography>
                                  <Box display="flex">
                                    <FiberManualRecordIcon
                                      sx={{
                                        fontSize: "0.4rem",
                                        mx: 0.5,
                                        mt: 0.5,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        marginBottom: "0.8rem !important",
                                      }}
                                    >
                                      Request for Program Restart option is
                                      available for anyone who couldn't continue
                                      the program, missed too many live classes
                                      due to any reason and would want to
                                      restart the program again after 2-3 months
                                      or more.
                                    </Typography>
                                  </Box>
                                  <Box display="flex">
                                    <FiberManualRecordIcon
                                      sx={{
                                        fontSize: "0.4rem",
                                        mx: 0.5,
                                        mt: 0.5,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        marginBottom: "0.8rem !important",
                                      }}
                                    >
                                      Once a program restart request is raised,
                                      you'll receive a revert from our team
                                      within the next 24 hours.
                                    </Typography>
                                  </Box>
                                  <Box >
                                    <FormGroup sx={{pb:{xs:3,lg:0}}}>
                                      <center>
                                        {" "}
                                        <FormControlLabel
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 18,
                                            },
                                          }}
                                          control={<Checkbox />}
                                          label={
                                            <Typography
                                              sx={{
                                                fontSize: "0.89rem",
                                                marginBottom:
                                                  "0.8rem !important",
                                                mt: 2,
                                              }}
                                              onClick={handleClick}
                                            >
                                              Select{" "}
                                            </Typography>
                                          }
                                        />
                                      </center>
                                    </FormGroup>
                                  </Box>
                                </Paper>
                              </Grid>
                              <Grid item lg={6} xs={12} sx={{mb:{xs:3,lg:0}}}>
                                <Paper
                                  sx={{
                                    ml:{lg:3},
                                    mr:{lg:7},
                                    borderRadius: "8px",
                                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                    p: 2,
                                    height: "269px",
                                  }}
                                >
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Withdraw
                                  </Typography>
                                  <Box display="flex">
                                    <FiberManualRecordIcon
                                      sx={{
                                        fontSize: "0.4rem",
                                        mx: 0.5,
                                        mt: 0.5,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        marginBottom: "0.8rem !important",
                                      }}
                                    >
                                      Withdraw from program option is available
                                      for anyone who wants to quit the program
                                      within the first 7 days from the first
                                      installment/full fee payment made.
                                    </Typography>
                                  </Box>
                                  <Box display="flex">
                                    <FiberManualRecordIcon
                                      sx={{
                                        fontSize: "0.4rem",
                                        mx: 0.5,
                                        mt: 0.5,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        marginBottom: "0.8rem !important",
                                      }}
                                    >
                                      Once a request is raised, you'll receive a
                                      revert from our team within the next 24
                                      hours.
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <FormGroup>
                                      <center>
                                        {" "}
                                        <FormControlLabel
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 18,
                                            },
                                            mt: 2,
                                          }}
                                          disabled
                                          control={<Checkbox />}
                                          label={
                                            <Typography
                                              sx={{
                                                fontSize: "0.89rem",
                                                marginBottom:
                                                  "0.8rem !important",
                                                mt: 2,
                                              }}
                                            >
                                              Select{" "}
                                            </Typography>
                                          }
                                        />
                                      </center>
                                    </FormGroup>
                                  </Box>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box>
                            {isShown && (
                              <>
                                  <Grid item lg={12} xs={12}>
                                  <Box sx={{ my: 3}} >
                                <Paper
                                  sx={{
                                    ml:{lg:3},
                    mr:{lg:7},
                                    borderRadius: "8px",
                                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                                    p: 2,
                                   
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      marginBottom: "0.8rem !important",
                                    }}
                                  >
                                    Restart My Program After Long Break
                                  </Typography>
                                  <Typography sx={{ fontSize: "0.94rem" }}>
                                    Please let us know why you'd want to restart
                                    the program (select a relevant options) :
                                  </Typography>
                                  <Box>
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="female"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            Found the program to be very basic
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="male"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            Unable to follow the faculty
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="other"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            Finding it difficult to learn online
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="others"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            I'm unable to dedicate time due to
                                            professional commitments
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="others"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            Medical Emergency
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="others"
                                        control={
                                          <Radio
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            sx={{
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem ! important",
                                            }}
                                          >
                                            Others (please specify)
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </Box>
                                  <Box sx={{ mt: 2, mx: 6 }}>
                                    <TextField
                                      id="outlined-select-currency"
                                      select
                                      label="Start Batch From"
                                      value={currency}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      {currencies.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Box>
                                  <Box sx={{ mt: 3 }}>
                                    <Box
                                      display="flex"
                                      sx={{
                                        background: "#F5F8FA",
                                        px: 2,
                                        py: 1,
                                        mb: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        Offered Fee
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ml:{lg:80,xs:14},
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        ₹ 105,020
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      sx={{ px: 2, py: 1, mb: 1 }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        Paid Fee
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ml:{lg:82,xs:15},
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        - ₹ 9000000
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      sx={{
                                        background: "#F5F8FA",
                                        px: 2,
                                        py: 1,
                                        mb: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        Remaining Fee
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ml:{lg:77.5,xs:11},
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        ₹ 105,011
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      sx={{ px: 2, py: 1, mb: 1 }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        Restart Fee
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ml:{lg:79,xs:14},
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        + ₹ 3,000
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      sx={{
                                        background: "#F5F8FA",
                                        px: 2,
                                        py: 1,
                                        mb: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        Total Due Fee After Break
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ml:{lg:68,xs:2},
                                          fontSize: "1rem",
                                          marginBottom: "0rem!important",
                                        }}
                                      >
                                        ₹ 108,011
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box sx={{ mt: 2 }}>
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        textAlign: "center",
                                        marginBottom: "0.4rem!important",
                                      }}
                                    >
                                      <b>Note:</b> Program Restart fee of INR
                                      3000 will be applicable during the
                                      re-joining of your program.
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Box sx={{ mt: 1, mb: 2 }} display="flex">
                                      <Box sx={{ ml:{lg:40,xs:3}}}>
                                        <FormGroup>
                                          <FormControlLabel
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                              },
                                            }}
                                            control={<Checkbox />}
                                            label={
                                              <Typography
                                                sx={{
                                                  fontSize: "0.89rem",
                                                  marginBottom:
                                                    "0.8rem !important",
                                                  mt: 2,
                                                }}
                                              >
                                                I accept the{" "}
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </Box>
                                      <Box>
                                        <Link
                                          to="/Policy/admissions-policy"
                                          style={{ textDecoration: "none" }}
                                        >
                                          {" "}
                                          <Typography
                                            sx={{
                                              fontSize: "0.89rem",
                                              marginBottom: "0.8rem !important",
                                              mt: 2,
                                              color: "#009ef7",
                                            }}
                                          >
                                            Terms & Condition
                                          </Typography>
                                        </Link>
                                      </Box>
                                    </Box>
                                    <center>
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                          mb: 0.3,

                                          mr: 3,
                                          background: "#009ef7",

                                          borderRadius: "8px",
                                          textTransform: "none",

                                          "&:hover": {
                                            background: "#009ef7",

                                            borderRadius: "8px",
                                            textTransform: "none",
                                          },
                                        }}
                                      >
                                        Restart & Pay Later
                                      </Button>
                                    </center>
                                  </Box>
                                </Paper>
                              </Box>

                                  </Grid>
               </>
                            )}
                          </Box>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Box
                            display="flex"
                            sx={{ borderBottom: "0.5px solid #eff2f5" }}
                          >
                            <Typography sx={{ fontWeight: "bold" }}>
                              Re-Enroll <small>from beginning</small>
                            </Typography>
                          </Box>
                          <Box sx={{ py: 3 }}>
                            <Box display="flex">
                              <FiberManualRecordIcon
                                sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  marginBottom: "0.8rem !important",
                                }}
                              >
                                Re-enrolment will give you complete flexibility
                                to restart your program again.
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <FiberManualRecordIcon
                                sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  marginBottom: "0.8rem !important",
                                }}
                              >
                                You can restart program from beginning along
                                with your preferred future cohort.
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <FiberManualRecordIcon
                                sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  marginBottom: "0.8rem !important",
                                }}
                              >
                                Once program fee is paid, you'll receive an
                                email confirmation in next 24 hours.
                              </Typography>
                            </Box>
                            <Box
                              sx={{ borderTop: "0.5px solid #eff2f5", mt: 5 }}
                              display="flex"
                            >
                              <Box sx={{ ml:{lg:40}}}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 18 },
                                    }}
                                    control={<Checkbox />}
                                    label={
                                      <Typography
                                        sx={{
                                          fontSize: "0.89rem",
                                          marginBottom: "0.8rem !important",
                                          mt: 2,
                                        }}
                                      >
                                        I accept the{" "}
                                      </Typography>
                                    }
                                  />
                                </FormGroup>
                              </Box>
                              <Box>
                                <Link
                                  to="/Policy/admissions-policy"
                                  style={{ textDecoration: "none" }}
                                >
                                  {" "}
                                  <Typography
                                    sx={{
                                      fontSize: "0.89rem",
                                      marginBottom: "0.8rem !important",
                                      mt: 2,
                                      color: "#009ef7",
                                    }}
                                  >
                                    Terms & Condition
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                            <Box>
                              <center>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  sx={{
                                    mb: 0.3,

                                    mr: 3,
                                    background: "#009ef7",

                                    borderRadius: "8px",
                                    textTransform: "none",

                                    "&:hover": {
                                      background: "#009ef7",

                                      borderRadius: "8px",
                                      textTransform: "none",
                                    },
                                  }}
                                >
                                  Re-Enroll
                                </Button>
                              </center>
                            </Box>
                          </Box>
                        </>
                      );
                    }
                  })()}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default PauseProgram;
