import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  sesction: {
   
    "@media (max-width: 700px)": {
   marginBottom:"10px",

    },
  },
  boxsesction: {
   
    "@media (max-width: 700px)": {
   marginBottom:"10px",
   marginLeft: "45px !important",


    },
  },
  lastimag:{
    marginTop: "-19px !important",
    "@media (max-width: 700px)": {
    
      marginLeft: "99px !important",
      marginTop: "10px !important",
      marginBottom:"10px",
   
       },
  },
  sectiononfont: {
    fontSize: "36px !important",
    fontWeight: 400,
    lineHeight:1.2,
    "@media (max-width: 700px)": {
      fontSize: "25px !important",
      lineHeight:1.2,
    }
  },
  programmenu:{
    display:"flex",
    flexDirection: "column",
    height:"fit-content",
    marginTop: "1rem"  ,  
    position:"sticky",
    top: 0,
    width:"fit-content",
 
    
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius:"8px",
},
button:{
  padding: "1rem 3rem",
  background:"none",
  fontSize:"16px",
  border:"none",
  cursor:"pointer",
  textAlign: "start",
  color:"#495057",
  width:"16rem",
  borderRadius:"8px",
}
}));
