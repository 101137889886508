import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../Assets/css/Home/HomeSectionSix.css";
import { alumniCardData } from "../../data/alumniCardData";
import "react-multi-carousel/lib/styles.css";
import { Grid, Box, Container, CardMedia } from "@mui/material";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import useStyles from "../../Assets/js/Home/HomeSectionSix";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import axios from "axios";
import Career_Transition from "../../pages/demo/CTA_Modal/career_transition";
import { useLocation, useSearchParams } from "react-router-dom";
// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 3, // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//     slidesToSlide: 2, // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };
const currencies = [
  {
    value: "---- Select Program ----",
    label: "---- Select Program ----",
  },
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value:"Business Management",
    label:"Business Management"
  }
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
];
const RcbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
  },
}));
function RcbDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RcbDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const HomeSectionSix = () => {
  const classes = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [Rcbopen, RcbsetOpen] = React.useState(false);

  const handleClickOpenRcb = () => {
    RcbsetOpen(true);
  };
  const RcbhandleClose = () => {
    RcbsetOpen(false);
  };
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const [program, setProgram] = useState("---- Select Program ----");
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setProgram(event.target.value);
  };
  const LoaderClose = () => {
    setOpen(false);
  };
  const LoaderOpen = () => {
    setOpen(true);
  };
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  const [rcb, setRcb] = useState({
    mobile: "",
    name: "",
    newemail: "",
    program: "",
    ctaname: "",
    ctapage: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: rcb.mobile,
      name: rcb.name,
      email: rcb.newemail,
      program: program,
      source: "Request Callback Top Bar",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "career_transition",
      type:"CTA"
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        RcbhandleClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((conf) => {
            if (conf.isConfirmed) {
             window.open("https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/Career-Handbook.pdf", "_blank");
            }
          });

          setRcb("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  return (
    <>
      <Box>
        <Container fixed>
        <Box sx={{ px: { xs: 1, lg: 0, sm: 0, md: 2 } }}>
          <Grid container lg={12} sm={12} md={12} xl={12}>
            <Grid xl={8} lg={8} sm={12} md={8} xs={12} sx={{ my: 4}}>
              <Typography
                variant="h4"
                component="div"
                fontWeight="bold"
                fontSize="25px"
                sx={{ml:{lg:"20px"},textAlign:{sm:"center",lg:"left",md:"left"} }}
              >
                What Our Alumni Loved THE MOST!
              </Typography>
              <Typography variant="span" component="div" fontSize="17px" sx={{ml:{lg:"20px"},textAlign:{sm:"center",lg:"left",md:"left"} }} >
                Join the most powerful network 4000+ Data Professionals.
              </Typography>
            </Grid>
            <Grid
              lg={4}
              sm={12}
              md={4}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Button
                variant="contained"
                className=""
                sx={{
                  color: "#ffffff",
                  backgroundImage:
                    "linear-gradient(to left, #00c4ff 0%, #0091ff 71%)",
                  fontWeight: "600",
                  fontSize: "15px",
                  my: 4,
                  textTransform: "none",
                  mr:{lg:2.5}
                }}
                onClick={handleClickOpenRcb}
              >
                <VerticalAlignBottomIcon />
                Career Transition Handbook
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: {lg:0,xs:2,md:10,sm:10}, ml:{lg:2,sm:6,md:4} }}>
          <Slider ref={sliderRef} {...settings}>
            {alumniCardData &&
              alumniCardData.map((alumni) => (
                <div className="an-card">
                  <div>
                    <div
                      style={{ background: alumni.bg_color }}
                      className="an-card-header"
                    >
                      <img src={alumni.photo_url} alt="" />
                    </div>
                    <div className="an-card-body">
                      <p className="an-review">{alumni.review}</p>
                    </div>
                  </div>
                  <div className="an-card-footer">
                    <div className="an-details">
                      <span className="an-name">{alumni.name}</span>
                      <span className="an-designation">
                        {alumni.designation}
                      </span>
                    </div>

                    <div className="alumnilogosection">
                      <img
                        src={alumni.company_logo}
                        alt=""
                        className="alumnilogo"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </Box>
        </Container>
       
      </Box>

      <Career_Transition open={Rcbopen} handleClose={RcbhandleClose}/>
    </>
  );
};

export default HomeSectionSix;
