import { Typography,Container,Box} from "@mui/material";
export const admissionspolic = [
    {
        policyhadding: "1.  Registration Fee",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 1.1 The registration fee of INR 3,000 is your seat reservation fee paid for a particular program. This fee is non-refundable and will get adjusted against the final fee payable by the student.</Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 1.2 Student will have to confirm his admission within 5 days of paying the registration fee. To confirm your registration, you can either pay the full fee or make a partial payment.</Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}>1.3 In case, a student does not confirm his admission within 5 days, his/her seat will get released to another candidate and registration fee of INR 3,000 will get forfeited.</Typography>,
        ],
      },
      {
        policyhadding: " 2. Basic Requirements",
    
        policydescription: [
          "To have the best course experience please use any latest processor (i3/i5/i7) with 2.0Ghz clock speed and 4GB RAM (Minimum), 8GB (Recommended).Please ensure that you have high-speed uninterrupted internet connection, to attend live sessions and access course resources without any hassle.",
        
          
        ],
      },
      {
        policyhadding: "3. Class Timings & Schedule",
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 3.1  Classes commence on weekends on two available slots. The term timings could be either of the two slots available and will be communicated to you before the start of every term.</Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "0px !important",ml:3}}>    DS & AI – 8:00 – 10 AM or 11:00 AM – 1:00 PM</Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:3}}>  Product Management –  8:00 – 10:00 AM or 11:00 AM – 1:00 PM </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 3.2  While Accredian with its best intentions aims to complete the entire course in stipulated time frame, yet the course may get extended due to unforeseen circumstances that are beyond the control of the institution. Circumstances include but are not limited to overall cohort's pace of learning, extension of sessions for benefit of students, instructor issues, and similar operational challenges that may cause the sessions to extend. However, if sessions do extend, Accredian will NOT levy any extra charges on students for extra instructor hours, LMS portal access charges and support fees. The extra cost will be borne by Accredian for the delays.</Typography>,
        ],
        
      },
      {
        policyhadding: "4. Tools",
    
        policydescription: [
          "Open-source tools will be used to teach course topics, in case the need arises to use premium tools, they will be used only when a trial version is available. The student can practice the tool by availing the trail version & there will be no subscription provided from Accredian for the tool.",
         
          
        ],
      },
      {
        policyhadding: "5.Query Resolution",
    
        policydescription: [
          "Daily operating hours for query resolution are 10:00 AM to 7:00 PM from Monday – Saturday, the turnaround time for query resolution is 24 Hours. Accredian puts all efforts to resolve the queries with the stipulated window of 24 hours, but sometimes due to high traffic the resolution process can take more time.",
          
          
        ],
      },
      {
        policyhadding: "6. Rules for taking Batch transfer",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 6.1 Batch transfer taken within <b>30 days of program start or before program start</b> will be free of cost. Free of cost batch transfer is applicable only once. And for any further batch transfer request, batch transfer fee of INR 3,000 is applicable. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 6.2 Batch transfer taken <b>after 30 days of program start</b> will have a Batch transfer fee of INR 3,000 </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 6.3 Batch transfer is available only for ongoing batches. Once the batch has completed, Batch transfer requests shall not be entertained and students will need to pay 1/4 of the program fee to re-enroll. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 6.4 As a part of Batch transfer, a student accepts that she/he will have to undergo the course as per the new curriculum of the batch that s/he wishes to transfer to. In this regard, all your prior curriculum communications stand void and the institute shall be covering only basis of new curriculum. </Typography>,
        ],
      },
      {
        policyhadding: "7. Pause My Program",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 7.1 We strongly recommend completing the program at one go. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 7.2 However we understand that at times it may be difficult to pursue the course in a single stretch. Hence, try to pause for a minimal duration to avoid forgetting topics learned so far. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 7.3 A student is entitled to pause a maximum of 6 months overall, taking all pauses into account. Post this you will have to re-enroll as a fresh candidate. This ensures that the learning of student is continuous and avoids dragging forever issues. </Typography>,

        ],
      },
      {
        policyhadding: "8. Fee Payment Schedule",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 8.1 In case a student avails installment (max up to 2-3 EMIs) option to pay the program fee while registering for any program, he/she is expected to pay the installments in consecutive months from the first installment date. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 8.2 In exceptional circumstances, a grace period of 15 days will be given to students to pay the fee. Failure to pay beyond 15 days will entail a late payment fee of Rs 1,000 per week</Typography>,

        ],
      },
      {
        policyhadding: "9. Student Loan Facility",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 9.1 For any issues with loan/credit facility availed by students from third-party providers like EarlySalary, Zest Money, etc. the third-party loan provider or student should directly reach out to each other for resolution of any dispute. Accredian has no control either over the student or the third party which has provided the loan to the student. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 9.2 For any issues the student decides to opt for Batch Transfer/Pause My Program option, the EMI plan with the third party loan provider will continue as per the discussed terms during the enrollment period.</Typography>,
          
        ],
      },
    
      {
        policyhadding: "10.Faculty Allocation",
    
        policydescription: [
          "All the faculties at Accredian are highly experienced in the Data Science industry and have mastered their domains. Different faculties get assigned for different terms and the details will be communicated to you before the start of every term. .",
          
        ],
      },
      {
        policyhadding: "11. Access to different Faculty Recordings",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 11.1 In general, we don’t encourage sharing access to a different faculty’s class recordings beyond what you’ve attended as we ensure that the program content remains entirely the same. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 11.2 However, in case you’d want to have them, you can request for class recordings of ONE particular term free of cost. Any further request after availing one free term recordings will be considered equivalent to batch transfer and a batch transfer fee of 3000 per term will be applicable. </Typography>,
          
        ],
      },
      {
        policyhadding: "12.Issuance of Certificate",
    
        policydescription: [
          "Certificates will be issued only on the name of a student who has actually enrolled in the program. It’ll not be issued to anyone else apart from the student under any circumstance.",
          
        ],
      },
      {
        policyhadding: "13. Refund Policy ",
    
        policydescription: [
            "One of the biggest USP of Accredian programs is the cooling-off period you get once you have enrolled for a program. This cooling-off period is for 7 days and starts from the day of payment of your full fee or first installment.  Below is the purpose of this cooling-off period",
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 13.1 If you want to change your start batch or program, the cooling-off period allows you to do that free of cost. Please speak to Admissions Team regarding this. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 13.2 Because of any reason, if you want to withdraw from the program, you can do so <b>during this 7 day window.</b> To withdraw from the program, please use <b>Withdraw from the program</b> option on the portal. Full fee will be refunded to you after deduction of <b>administrative fee</b> of INR 5,000. (Note: Administrative fee includes class participation license for students, access to Accredian Learning Center, access to starter kits, faculty, teaching & support staff reserved for particular cohort).  </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 13.3 It is the sole responsibility of the student enrolling into a Program to check the accuracy of, and evaluate the suitability and relevance of, the Program enrolled. Transfer of enrollment into other programs is NOT possible. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 13.4 Due to any reason, if you have availed of the benefits of the Batch Transfer or Pause My Program in the duration of your program, you will not be eligible for the Refund or Program transfer option. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important"}}> <b>After 7 days cooling-off period, no refund will be processed</b> irrespective of whether your program has started or not.  </Typography>,
        ],
      },
      {
        policyhadding:  <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important"}}> <b> 14. How to deal with issues before or during the program? : </b> After 7 day cooling off window closes or anytime during the program, you might run into some issues like the ones below.   </Typography>,
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 14.1 Not able to attend classes due to medical reasons including suffering from illness </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 14.2 Not able to attend classes as traveling abroad in a different timezone  </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 14.3 Not being able to grasp content due to unpreparedness for that cohort </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 14.4 Not able to dedicate time to program due to professional workload </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 14.5 Not being able to attend classes on weekends due to family commitments </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important"}}> To help you deal with these issues, you have an option to <b>pause the program</b> for a certain time and restart the program with another batch. This option is called “Batch Transfer” option. </Typography>,
        ],
      },
      {
        policyhadding: "15.Accredian’s Intellectual Property Rights",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 15.1 All information and data shared with the users of Accredian’s (International School of AI & Data Science) product is considered content, which may include but is not restricted to pre-reads, presentations, post-reads, session recordings, videos, graphics, interactive materials, and other material. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 15.2 The programs and all related services provided are owned and operated by Accredian (International School of AI & Data Science) and its licensors. The content available on the platform for every program including but not limited to videos, quizzes, assignments, case studies, presentations, reads, projects, activities, illustrations, graphics, and all other resources are the property of Accredian (International School of AI & Data Science) and/or its affiliates or licensors and are protected by copyrights and patent/ other proprietary intellectual property rights under Indian & foreign laws. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 15.3 In addition, elements of the platform, program, and Accredian’s (International School of AI & Data Science) content are protected by trade dress and other Indian and other international intellectual property laws and may not be copied, downloaded, reproduced, shared, or distributed in any way whole or in part without the written consent from Accredian (International School of AI & Data Science). The content that Accredian (International School of AI & Data Science) deems fit will be downloadable. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 15.4 The users accessing the platform and the program resources agree to not (a) Reproduce, duplicate, copy, sell, or exploit for any commercial purpose (b) use of Accredian’s (International School of AI & DataScience) name, trademarks, service marks, content, or any other material in connection to, or to transmit, any unsolicited communications or emails or for any other unauthorized purpose.. </Typography>,
        ],
      },
      {
        policyhadding: "16.Third-Party Intellectual Property",
    
        policydescription: [
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 16.1 Accredian (International School of AI & Data Science) respects third-party intellectual property rights including copyrights and trademarks and actively supports its protections. It is policy to expeditiously respond to clear notices of alleged IP infringement. If proper notification is received of IP infringement, our response will be removing or disabling access to the material having claimed to be the subject of infringing activity.</Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 16.2 Accredian (International School of AI & Data Science) shall not be held liable for the unauthorized use of any third-party IP, and the user that carries out such unauthorized use or infringes any IP available on the Platform shall fully indemnify and hold Accredian (International School of AI & Data Science) harmless against any and all claims that may arise as a result of such use. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:2}}> 16.3 If you believe that your product or other work has been misrepresented or used in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide us with the following information:. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.1 An electronic or physical signature of the person authorized to act on behalf of the owner of the product, copyright, or other intellectual property interest. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.2 A description of the product, work, or other intellectual property that you claim has been misrepresented or infringed.  </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.3 A description of where the material that you claim is misrepresenting or infringing your product, work, or other intellectual property is located on the Platform/Program. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.4 Your address, telephone number, and email address. </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.5 A statement by you that you have a good faith belief that the disputed use is not authorized by the owner of the product, work, copyright or intellectual property, or its agent, or applicable law; and </Typography>,
            <Typography sx={{fontSize:"14px",marginBottom: "1.1px !important",ml:4}}> 16.3.6 A statement by you, made under the penalty of perjury, that the aforementioned information in your notice is accurate and that you are the product, work, copyright, or intellectual property owner or authorized to act on such owner's behalf. </Typography>,
        ],
      },
];