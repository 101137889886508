import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";


import "./Footer.css";

export default function StudentFooter() {

  return (
    <>
      <Box sx={{position:"fixed",left:0,bottom:0,width:"100%",backgroundColor: "#f3f6f9",padding:"10px"}}>
        </Box>
    </>
  );
}
