import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Badge,
  Backdrop,
  CardMedia,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CachedIcon from "@mui/icons-material/Cached";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import LoginDashboard from "../../Navbar/LoginDashboard";
import Sidbar from "../Sidbar";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import StudentFooter from "../../Footer/studentFooter";
const Competitioncornerquestion = (props) => {
  const [feddbackopen, setFeddbackopen] = useState();
  const [user, setUser] = useState("");
  const [datas, setData] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [ansisShown, setIsShownans] = useState(false);
  const [insaidtvpop, setInsaidtvpop] = useState();
  const [info, setinfo] = useState(false);
  const [isShownbtn, setIsShownbtn] = useState(true);
  const [isShownrbtn, setIsShownrbtn] = useState(false);
  const [btndisabled, setBtndisabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [coin, setCoindata] = useState("");
  const [showcoins ,setShowcoins] = useState(false);
  const [Jobid, setJobid] = useState("");
  const [Joblink, setJoblink] = useState("");
  const [Jobcategory, setJobcategory] = useState("");
  const [reporttype, setReporttype] = useState("");
  const [reprotdiscrtiption, setreprotdiscrtiption] = useState("");
  const [cod, setCod] = useState({ cod_option: `` });
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const handleChangesetJobid = (event) => {
    setJobid(event.target.value);
  };
  const handleChangesetJoblink= (event) => {
    setJoblink(event.target.value);
  };
  const handleChangesetJobcategory= (event) => {
    Jobcategory(event.target.value);
  };
  const handleChangediscrtiption = (event) => {
    setreprotdiscrtiption(event.target.value);
  };
  const handleChange = (event) => {
    setReporttype(event.target.value);
  };
  const handleClickOpen = (id, question, question_text) => {
    setJobid(id);
    setJoblink(question);
    setJobcategory(question_text);
    setFeddbackopen(true);
  };

  const handleClose = () => {
    setFeddbackopen(false);
  };


const LoaderClose = () => {
  setOpen(false);
};
const LoaderOpen = () => {
  setOpen(true);
};

  const handalChnage = (e) => {
    setCod({ ...cod, [e.target.name]: e.target.value });
  };
  const fetchdata = async () => {
    setCod({ cod_option: "" });
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/competition-corner/fetchquestion`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        category: localStorage.getItem("category"),
        qid: localStorage.getItem("qid"),
      },
    }).then((res) => {
      setData(res.data);

      LoaderClose();
 
      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const newxquction = async () => {
    setCod({ cod_option: "" });
    setIsShownans(false);
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/competition-corner/nextquestion`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
        category: localStorage.getItem("category"),
     
      },
    }).then((res) => {
      setData(res.data);

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };

  const fetchcoindata = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetchstudentcoins`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      setCoindata(res.data);
    });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    category: localStorage.getItem("category"),
  };
  const CourseData = {
    enrol_id: localStorage.getItem("enrol_id"),
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    fetchdata();
    fetchcoindata();
    setUser(user_id);
  }, []);
  const submitForm = async (e) => {
    e.preventDefault();
    LoaderOpen();
    setIsShownans((current) => !current);
       const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        };
        const sandData = {
          qid:datas.qid,
          correct_answer:parseInt(cod.cod_option) ,
          difficulty:datas.difficulty,
          answer:datas.answer,
          enrol_id: localStorage.getItem("enrol_id"),
        

        };
        axios
        .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/competition-corner/submit`, JSON.stringify(sandData), options,)
        .then((res) => {
          
          LoaderClose();
          fetchcoindata();
          setShowcoins(true);
        });  
  }
  const submitFormreport = async (e) => {
    LoaderOpen();
 e.preventDefault();

 const options = {
   headers: {
     "Access-Control-Allow-Origin": "*",
     "Content-Type": "application/json",
   },
 };

 const sandData = {
   enrol_id:parseInt(localStorage.getItem("enrol_id")),
   question_report_type:reporttype,
   question_id: Jobid,
   question_category:parseInt(localStorage.getItem("category")),
   question_report_des:reprotdiscrtiption,
   question_topic:Joblink,
   question_name:Jobcategory,

 };
 await axios
 .post(
   `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/ccquestionreportmail/reportquestion`,
   JSON.stringify(sandData),
   options
 )
 .then((result) => {
   if (result.data.status == 200) {
     Swal.fire({
       title: "Success",
       text: "Thank you",
       icon: "success",
       
       confirmButtonText: "OK",
     });

   } 
   setReporttype("");
   setJobid("");
   setJoblink("");
   setJobcategory("");
   setreprotdiscrtiption("");
   LoaderClose();
   handleClose();
   
 });
 //console.log(sandData);
};
  return (
    <>
      <LoginDashboard  showcoins={showcoins} />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5}}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Link
                to="/Competition-corner"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Box display="flex">
                  <ArrowBackIcon sx={{ fontWeight: "bold" }} />
                  <Typography
                    sx={{ ml: 2, fontWeight: "bold", fontSize: "1rem" }}
                  >
                    {" "}
                    Back to questions
                  </Typography>
                </Box>
              </Link>
              <Box
                sx={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                  backgroundColor: "#fff",
                  width:{lg: "90%",sm: "100%",md: "100%",xs: "100%"},
                  borderRadius: "5px",
                  py: 3,
                }}
              >
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box mx={2} >
                    <Typography variant="h5" fontWeight="bold">
                      {datas.question}
                    </Typography>
                  </Box>
                  <Box display="flex" mr={3}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#ff3333",
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        display: "flex",
                        cursor: 'pointer' ,

                        mt: 0.6,
                      }}
                      onClick={() =>
                        handleClickOpen(datas.qid, datas.question, datas.question_text)
                        
                      }
                    >
                      <ReportProblemIcon
                        sx={{ fontSize: "1rem", color: "#ff3333", mr: 0.5 }}
                      />
                      Report
                    </Typography>
                  </Box>
                </Box>
                <Box mx={2} display="flex">
                  <Box>
                    <Typography
                      variant="h5"
                      fontSize={17}
                      width={750}
                      height={50}
                      sx={{width:{lg:"750px",xs:"210px",sm:"600px",md:"700px"},height:{lg:"50px",xs:"auto",sm:"50px",md:"50px"}}}

                    >
                      Question :{datas.question_text}
                    </Typography>
                  </Box>
                  <Box ml={5}>
                    <Badge
                      sx={{
                        background: "#50cd89",
                        color: "#fff",
                        width: "60px",
                        px: 1.2,
                        py: 0.4,
                        borderRadius: "50px",
                        ml: 3,
                      }}
                    >
                      <CardMedia
                        component="img"
                        image="../images/star.png"
                        alt="Goole"
                        sx={{
                          width: "20px",
                          height: "20px",
                          objectFit: "unset",
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.8rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        {(() => {
                          if (datas.difficulty === "Easy") {
                            return <>+5</>;
                          } else if (datas.difficulty === "Medium") {
                            return <>+10</>;
                          } else if (datas.difficulty === "Hard") {
                            return <>+15</>;
                          }
                        })()}
                      </Typography>
                    </Badge>
                  </Box>
                </Box>
                <form onSubmit={submitForm}>
                <Box sx={{ px: 2, py: 2 }}>
                
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="cod_option"
                    onChange={handalChnage}
                    value={cod.cod_option}
                  >
                    <Grid container>
                      <Grid item lg={10} xs={8} sm={10} md={10}>
                        {(() => {
                          if (datas.newanswer === 1) {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={datas.newanswer === 1}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option1}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  value="1"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option1}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          }
                        })()}
                      </Grid>
                      <Grid item lg={2} xs={2} sm={2} md={2}>
                        {(() => {
                          if (datas.answer === 1 && datas.attempt == 1) {
                            return (
                              <>
                                <CheckCircleIcon sx={{ color: "#00802b" }} />
                              </>
                            );
                          } else if (datas.answer === 2 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 3 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 4 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          }
                        })()}
                        {ansisShown && (
                          <>
                            <Box>
                              {(() => {
                                if (datas.answer === 1 && datas.attempt == 0) {
                                  return (
                                    <>
                                      <Box display="flex">
                                        <CheckCircleOutlineIcon
                                          sx={{ color: "#50cd89" }}
                                        />

                                        <Badge
                                          sx={{
                                            background: "#50cd89",
                                            color: "#fff",
                                            width: "60px",
                                            px: 1.2,
                                            py: 0.4,
                                            borderRadius: "50px",
                                            ml: 3,
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            image="../images/star.png"
                                            alt="Goole"
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              objectFit: "unset",
                                            }}
                                          />
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem !important",
                                            }}
                                          >
                                            {(() => {
                                              if (datas.difficulty === "Easy") {
                                                return <>+5</>;
                                              } else if (
                                                datas.difficulty === "Medium"
                                              ) {
                                                return <>+10</>;
                                              } else if (
                                                datas.difficulty === "Hard"
                                              ) {
                                                return <>+15</>;
                                              }
                                            })()}
                                          </Typography>
                                        </Badge>
                                      </Box>
                                    </>
                                  );
                                } else if (datas.attempt == 0) {
                                  return (
                                    <Box display="flex">
                                      <HighlightOffIcon sx={{ color: "red" }} />
                                      <Badge
                                        sx={{
                                          background: "red",
                                          color: "#fff",
                                          width: "60px",
                                          px: 1.2,
                                          py: 0.4,
                                          borderRadius: "50px",
                                          ml: 3,
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image="../images/star.png"
                                          alt="Goole"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "unset",
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "0.8rem",
                                            marginBottom: "0rem !important",
                                          }}
                                        >
                                          +0
                                        </Typography>
                                      </Badge>
                                    </Box>
                                  );
                                }
                              })()}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={10} xs={8} sm={10} md={10}>
                        {(() => {
                          if (datas.newanswer === 2) {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={datas.newanswer === 2}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option2}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  value="2"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option2}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          }
                        })()}
                      </Grid>
                      <Grid item lg={2} xs={2} sm={2} md={2}>
                        {(() => {
                          if (datas.answer === 2 && datas.attempt == 1) {
                            return (
                              <>
                                <CheckCircleIcon sx={{ color: "#00802b" }} />
                              </>
                            );
                          } else if (datas.answer === 1 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 3 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 4 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          }
                        })()}
                        {ansisShown && (
                          <>
                            <Box>
                              {(() => {
                                if (datas.answer === 2 && datas.attempt == 0) {
                                  return (
                                    <>
                                      <Box display="flex">
                                        <CheckCircleOutlineIcon
                                          sx={{ color: "#50cd89" }}
                                        />
                                        <Badge
                                          sx={{
                                            background: "#50cd89",
                                            color: "#fff",
                                            width: "60px",
                                            px: 1.2,
                                            py: 0.4,
                                            borderRadius: "50px",
                                            ml: 3,
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            image="../images/star.png"
                                            alt="Goole"
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              objectFit: "unset",
                                            }}
                                          />
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem !important",
                                            }}
                                          >
                                            {(() => {
                                              if (datas.difficulty === "Easy") {
                                                return <>+5</>;
                                              } else if (
                                                datas.difficulty === "Medium"
                                              ) {
                                                return <>+10</>;
                                              } else if (
                                                datas.difficulty === "Hard"
                                              ) {
                                                return <>+15</>;
                                              }
                                            })()}
                                          </Typography>
                                        </Badge>
                                      </Box>
                                    </>
                                  );
                                } else if (datas.attempt == 0) {
                                  return (
                                    <Box display="flex">
                                      <HighlightOffIcon sx={{ color: "red" }} />
                                      <Badge
                                        sx={{
                                          background: "red",
                                          color: "#fff",
                                          width: "60px",
                                          px: 1.2,
                                          py: 0.4,
                                          borderRadius: "50px",
                                          ml: 3,
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image="../images/star.png"
                                          alt="Goole"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "unset",
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "0.8rem",
                                            marginBottom: "0rem !important",
                                          }}
                                        >
                                          +0
                                        </Typography>
                                      </Badge>
                                    </Box>
                                  );
                                }
                              })()}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={10} xs={8} sm={10} md={10}>
                        {(() => {
                          if (datas.newanswer === 3) {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={datas.newanswer === 3}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option3}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  value="3"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option3}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          }
                        })()}
                      </Grid>
                      <Grid item lg={2} xs={2} sm={2} md={2}>
                        {(() => {
                          if (datas.answer === 3 && datas.attempt == 1) {
                            return (
                              <>
                                <CheckCircleIcon sx={{ color: "#00802b" }} />
                              </>
                            );
                          } else if (datas.answer === 1 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 2 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 4 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          }
                        })()}
                        {ansisShown && (
                          <>
                            <Box>
                              {(() => {
                                if (datas.answer === 3 && datas.attempt == 0) {
                                  return (
                                    <>
                                      <Box display="flex">
                                        <CheckCircleOutlineIcon
                                          sx={{ color: "#50cd89" }}
                                        />
                                        <Badge
                                          sx={{
                                            background: "#50cd89",
                                            color: "#fff",
                                            width: "60px",
                                            px: 1.2,
                                            py: 0.4,
                                            borderRadius: "50px",
                                            ml: 3,
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            image="../images/star.png"
                                            alt="Goole"
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              objectFit: "unset",
                                            }}
                                          />
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem !important",
                                            }}
                                          >
                                            {(() => {
                                              if (datas.difficulty === "Easy") {
                                                return <>+5</>;
                                              } else if (
                                                datas.difficulty === "Medium"
                                              ) {
                                                return <>+10</>;
                                              } else if (
                                                datas.difficulty === "Hard"
                                              ) {
                                                return <>+15</>;
                                              }
                                            })()}
                                          </Typography>
                                        </Badge>
                                      </Box>
                                    </>
                                  );
                                } else if (datas.attempt == 0) {
                                  return (
                                    <Box display="flex">
                                      <HighlightOffIcon sx={{ color: "red" }} />
                                      <Badge
                                        sx={{
                                          background: "red",
                                          color: "#fff",
                                          width: "60px",
                                          px: 1.2,
                                          py: 0.4,
                                          borderRadius: "50px",
                                          ml: 3,
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image="../images/star.png"
                                          alt="Goole"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "unset",
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "0.8rem",
                                            marginBottom: "0rem !important",
                                          }}
                                        >
                                          +0
                                        </Typography>
                                      </Badge>
                                    </Box>
                                  );
                                }
                              })()}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={10} xs={8} sm={10} md={10}>
                        {(() => {
                          if (datas.newanswer === 4) {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={datas.newanswer === 4}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option4}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          } else {
                            return (
                              <>
                                {" "}
                                <FormControlLabel
                                  value="4"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 18,
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        marginBottom: "0rem ! important",
                                      }}
                                    >
                                      {datas.option4}
                                    </Typography>
                                  }
                                />
                              </>
                            );
                          }
                        })()}
                      </Grid>
                      <Grid item lg={2} xs={2} sm={2} md={2}>
                        {(() => {
                          if (datas.answer === 4 && datas.attempt == 1) {
                            return (
                              <>
                                <CheckCircleIcon sx={{ color: "#00802b" }} />
                              </>
                            );
                          } else if (datas.answer === 1 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 2 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          } else if (datas.answer === 3 && datas.attempt == 1) {
                            return (
                              <>
                                <CancelIcon sx={{ color: "#ff0000" }} />
                              </>
                            );
                          }
                        })()}
                        {ansisShown && (
                          <>
                            <Box>
                              {(() => {
                                if (datas.answer === 4 && datas.attempt === 0) {
                                  return (
                                    <>
                                      <Box display="flex">
                                        <CheckCircleOutlineIcon
                                          sx={{ color: "#50cd89" }}
                                        />
                                        <Badge
                                          sx={{
                                            background: "#50cd89",
                                            color: "#fff",
                                            width: "60px",
                                            px: 1.2,
                                            py: 0.4,
                                            borderRadius: "50px",
                                            ml: 3,
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            image="../images/star.png"
                                            alt="Goole"
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              objectFit: "unset",
                                            }}
                                          />
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "0.8rem",
                                              marginBottom: "0rem !important",
                                            }}
                                          >
                                            {(() => {
                                              if (datas.difficulty === "Easy") {
                                                return <>+5</>;
                                              } else if (
                                                datas.difficulty === "Medium"
                                              ) {
                                                return <>+10</>;
                                              } else if (
                                                datas.difficulty === "Hard"
                                              ) {
                                                return <>+15</>;
                                              }
                                            })()}
                                          </Typography>
                                        </Badge>
                                      </Box>
                                    </>
                                  );
                                } else if (datas.attempt === 0) {
                                  return (
                                    <Box display="flex">
                                      <HighlightOffIcon sx={{ color: "red" }} />
                                      <Badge
                                        sx={{
                                          background: "red",
                                          color: "#fff",
                                          width: "60px",
                                          px: 1.2,
                                          py: 0.4,
                                          borderRadius: "50px",
                                          ml: 3,
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image="../images/star.png"
                                          alt="Goole"
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "unset",
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "0.8rem",
                                            marginBottom: "0rem !important",
                                          }}
                                        >
                                          +0
                                        </Typography>
                                      </Badge>
                                    </Box>
                                  );
                                }
                              })()}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Box>
                <Box sx={{ height:{lg: "70px",sm: "70px",xs: "100px",md: "70px"} }} mx={2}>
                  {isShown && (
                    <Typography
                      sx={{
                        p: 2,
                        marginBottom: "0rem! important",
                        fontSize: "1rem",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        mb:3,
                      }}
                    >
                      Hint:{datas.hint_text}
                    </Typography>
                  )}
                </Box>
                <Box mx={2}>
                  <Grid container sx={{ pt: 5 }}>
                    <Grid item lg={4} xs={4} sm={4} md={4}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#fff",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "5px",
                          textTransform: "none",
                          color: "#000",
                          width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                          mb:2,
                          "&:hover": {
                            background: "#fff",
                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                            borderRadius: "5px",
                            textTransform: "none",
                            color: "#000",
                            width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                          },
                        }}
                        onClick={handleClick}
                      >
                        <Typography
                          sx={{ marginBottom: "0rem !important", ml: 1,fontSize:{lg:"15px",xs:"12px",sm:"15px",md:"15px"} }}
                        >
                          Give me a hint
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item lg={4} xs={4} sm={4} md={4}>
                      <>
                        {(() => {
                          if (datas.attempt === 1) {
                            return <> </>;
                          } else {
                            return (
                              <>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  sx={{
                                    background: "#fff",
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    borderRadius: "5px",
                                    textTransform: "none",
                                    color: "#000",
                                    width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                                    mb:2,
                                    "&:hover": {
                                      background: "#fff",
                                      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                      borderRadius: "5px",
                                      textTransform: "none",
                                      color: "#000",
                                      width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                                    },
                                  }}
                                  // onClick={anshandleClick}
                                >
                                  <Typography
                                    sx={{
                                      marginBottom: "0rem !important",
                                      ml: 1,
                                      fontSize:{lg:"15px",xs:"12px",sm:"15px",md:"15px"}
                                    }}
                                  >
                                    Submit
                                  </Typography>
                                </Button>
                              </>
                            );
                          }
                        })()}
                        ;
                      </>
                    </Grid>
                    <Grid item lg={4} xs={4} sm={4} md={4}>
                      <Button
                        variant="contained"
                      
                        sx={{
                          background: "#fff",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "5px",
                          textTransform: "none",
                          color: "#000",
                          width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                          mb:2,

                          "&:hover": {
                            background: "#fff",
                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                            borderRadius: "5px",
                            textTransform: "none",
                            color: "#000",
                            width:{lg:"220px",xs: "120px",sm: "220px",md:"220px"},
                          },
                        }}
                        onClick={newxquction}
                      >
                        <Typography
                          sx={{ marginBottom: "0rem !important", ml: 1,fontSize:{lg:"15px",xs:"12px",sm:"15px",md:"15px"} }}
                        >
                          Next Question
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={feddbackopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="feedback">
          {"Report Quction"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
     
          
        <form onSubmit={submitFormreport}>
      
        <DialogContent>
          <TextField
            id="Jobid"
            label="Jobid"
            variant="outlined"
            name="Jobid"
            value={Jobid}
            onChange={handleChangesetJobid}
            sx={{ visibility: "hidden" }}
          />
          <TextField
            id="Joblink"
            label="Joblink"
            variant="outlined"
            name="Joblink"
            value={Joblink}
            onChange={handleChangesetJoblink}
            sx={{ visibility: "hidden" }}
          />
          <TextField
            id="Jobcategory"
            label="Jobcategory"
            variant="outlined"
            name="Jobcategory"
            value={Jobcategory}
            onChange={handleChangesetJobcategory}
            sx={{ visibility: "hidden" }}
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="reporttype"
            onChange={handleChange}
            value={reporttype}
            sx={{ mt: -13 }}
          >
            <FormControlLabel
              value="Wrong question"
              control={<Radio />}
              label="Wrong question"
              name="reporttype"
            />
            <FormControlLabel
              value="Didn't understand"
              control={<Radio />}
              label="Didn't understand"
              name="reporttype"
            />
            <FormControlLabel
              value="Typo error"
              control={<Radio />}
              label="Typo error"
              name="reporttype"
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label="Other"
              name="reporttype"
            />
          </RadioGroup>
          <TextField
            id="outlined-multiline-static"
            label="Explain this report"
            multiline
            rows={3}
            placeholder="Explain this report"
            name="reprotdiscrtiption"
            value={reprotdiscrtiption}
            onChange={handleChangediscrtiption}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              mr: 3,
              background: "#009ef7",

              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",
              },
            }}
          >
            Submit
          </Button>
        
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mb: 0.3,
              mr: 3,
              background: "#f5f8fa",
              borderRadius: "8px",
              textTransform: "none",
              color: "#000",

              "&:hover": {
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
              },
            }}
          >
            Discard
          </Button>
          
        </DialogActions>
        </form>
   
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </Dialog>
      <StudentFooter/>
    </>
  );
};
export default Competitioncornerquestion;
