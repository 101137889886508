
import React from "react";

import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Myaccount from "../Components/Myaccount/Myaccount";
const AuthMyaccount = () => {
  const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    if(user){
      return true
    } else {
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Myaccount /> : <Navigate to="/Login" />;
}
export default AuthMyaccount;
