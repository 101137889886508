import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  tabs: {

    "& .MuiTabs-indicator": {
      visibility:"hidden",
      borderBottom:"0.5px solid #000",
    },
    "& .MuiTab-root.Mui-selected": {
 backgroundColor:"#EBF2FF",
 borderLeft:"5px solid #1A1EF0",
 color:"#000",
 fontWeight:"bold",

 
    }
  },
  newcardbox:{
  boxShadow:"rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px",
width:"380px",
height:"160px",
backgroundColor:"#fff",
borderRadius:"0px",
       
},

haddingmob:{
height:"26px",
},

  
trapezoidleft: {
borderBottom:"0px solid transparent",
borderLeft:"20px solid transparent",
borderRight:"0px solid transparent",
borderTop:"27px solid #1966fa",

}	,
monht:{
paddingbottom:"2px",
},
cardmargin:{
marginleft:"145px",
},
forncarmargin:{
  marginleft:"1rem",
},
navbartoplogin:{
marginTop:"0px",
},
navbarwitoutloging:{
  marginTop:"50px",
    
  "@media (min-width: 500px)": {
    marginTop:"100px",
   
   },
   "@media (max-width: 700px)": {
    marginTop:"300px",
   
   }
},
}));
