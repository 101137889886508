import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
   list: {
       ul :{
li :{
    display: "list-item",
},
     
       },
      },
}));
