import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/LoginDashboard";
import Footer from "../Footer/Footer";
import Sidbar from "./Sidbar";
import "./dashboard.css";
import StudentDashboard from "./StudentDashboard";
import TryalDashboard from "./TryalDashboard";
import CircularProgress from '@mui/material/CircularProgress';
import Withoutenrollment from "./Withoutenrollment";
import Facultydashboard from "./Facultydashboard";
// import HowTo from "./Howto";
const Dashboard = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState("");
  const [category, setCategory] = useState("");
  const [enrolid, setEnrolide] = useState("");
  const [userrole, setUserrole] = useState("");

  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var user_role = localStorage.getItem("user_role");

    setUser(user_id);
    setCategory(category);
    setUserrole(user_role);
    setEnrolide(enrol_id);
  }, []);

  if (user == null) {
    navigate(`/Login`);
  }

  return (
    <>
    
      {(() => {
         if(userrole === '7'){
          return (
            <>
              <Facultydashboard />
            </>
          );
        }
        else if(enrolid === 'null'){
          return (
            <>
              <Withoutenrollment />
            </>
          );
        }
   else if (category === "7" || category === "8") {
          return (
            <>
              <TryalDashboard />
            </>
          );
        } 
            
        else {
          return (
            <>
              <StudentDashboard />
            </>
          );
        }
      })()}
    </>
  );
};
export default Dashboard;
