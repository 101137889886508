import React, { useEffect } from "react";
import "../../../../Assets/css/Term-project/quiz.css";
import { useState} from "react";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidbar from "../../Sidbar";
import LoginDashboard from "../../../Navbar/LoginDashboard";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
    Typography,
    Box,
    Paper,
    Grid,
    TextField,
    FormControlLabel,
    Link,
    Badge,
    Checkbox, 
    Tabs,
    Tab,
    Step,
  } from "@mui/material";
  import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Output from "./quiz_result_table";
import { LocalGasStationRounded } from "@mui/icons-material";
import OutputJob from "./quiz_output_table";
import axios from "axios";
const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};
// const theme = createTheme({
//   overrides: {
//       MuiFormControlLabel: {
//           label: {
//               width:"100%",
//           }
//       }
//   }
// });

function QuizJob(props){
    const notify = () => toast.error("Please select a option");
    const[Questions,setQuestions]=useState();
    const [shake, setShake] = useState(false);
    // //console.log(props.quiz_id,"iddddd")
    // const selected_id=localStorage.getItem("id")
    const quiz_id=localStorage.getItem("quiz_id")
    useEffect(()=>{
      const getQuestion= async () =>{
        // if(quiz_id){
        //   const sendData = {
        //     quiz_id: quiz_id,
           
        //   };
          axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/job-guarantee/question`,
            // JSON.stringify(sendData),
            options
          ).then((result)=>{
            setQuestions(result.data);
          })
          
        // }
       
       
        
      }
      getQuestion()
    },[])
    // //console.log(Questions,"Questions aaya hai bhai")
    const [currentQuestion, setCurrentQuestion] =useState(0);
    const[selected,setSelected] = useState(0);
  const [score, setScore] =useState(0);
  const[user_input,setUserInput]=useState([]);
  const [showScore, setShowScore] =useState(false); 
  const[output,setOutput]=useState([]);
  
  const radioButtons = document.querySelectorAll('input[name="radio-buttons-group"]');
  
  
  // //console.log(radioButtons,"raddddd")
  // const btn=document.getElementById('nxt')
  // if(showScore){
  //   btn.style.display="none";
  // }
  //console.log(score,"upar wala score")

  // const inputClick=(id,ans,ques,val,choice)=>{
  //   //console.log("iiiiiiiiits is clicked")
  //   if(id===ans){
  //     if(!selected){
  //       document.getElementsByClassName("icondiv")[id-1].style.display="block"
  //       const obj={
  //         question_txt:ques,
  //         your_answer:val,
  //         correct_answer:val,
  //         result:"Correct"
  //        }
  //         output.push(obj)
  //         setScore(score + 1);
       
  //     }
  //   }
    
  //   else if(!selected){
  //     document.getElementsByClassName("icondiv")[ans-1].style.display="block"
  //     document.getElementsByClassName("wrongdiv")[id-1].style.display="block"
  //     const obj={
  //       question_txt:ques,
  //       your_answer:choice[id-1],
  //       correct_answer:choice[ans-1],
  //       result:"Incorrect"
  //     }
  //       output.push(obj)
  //   }
  // }
  const handleClick = (id,ans,ques,val,choice) => {
    // setSelected(1)
    user_input.push(id);
    
        if (id===ans) {
          
           if(!selected){
            // document.getElementsByClassName("icondiv")[id-1].style.display="block"
            // document.getElementsByClassName("txt").style.marginTop="7px"
            document.getElementsByClassName("option")[id-1].style.background="lightgreen"
              const obj={
               question_txt:ques,
               your_answer:val,
               correct_answer:val,
               result:"Correct"
              }
            
              
                  output.push(obj);
                  //console.log(score,"see score")
                  setScore(score+1)
               
               
               
            
           }
              
           
          
        
          
        }
        else if(!selected){
          // document.getElementsByClassName("txt").style.marginTop="7px"
          // document.getElementsByClassName("icondiv")[ans-1].style.display="block"
          // document.getElementsByClassName("wrongdiv")[id-1].style.display="block"
          document.getElementsByClassName("option")[ans-1].style.background="lightgreen"
          document.getElementsByClassName("option")[id-1].style.background="#FF6961"
              const obj={
                question_txt:ques,
                your_answer:choice[id-1],
                correct_answer:choice[ans-1],
                result:"Incorrect"
              }
             
              output.push(obj)
               
            
            
          
              
           }
           
   
    };
    const handleChange=(e)=>{
       
        if(e.target.checked){
          setSelected(true)
        }
        
    }
    const handleNext=()=>{

      if(currentQuestion+1===(Questions&&Questions.length)){
        // //console.log("inside if")
        const user_id=localStorage.getItem("user_id");
        // const enrol_id=localStorage.getItem("enrol_id");
        const answer=user_input.toString();
       const user_selection=answer;
        const sendData = {
            user_id,
            score,
            user_selection
           
          };

          axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/job-guarantee/quiz-result`,
            JSON.stringify(sendData), 
            options
          ).then((result)=>{
            // //console.log(result)
          })
      }

      
      for(var i=0;i<document.getElementsByClassName("option").length;i++){
        document.getElementsByClassName("option")[i].style.background="#bed5de"
      }
      
        // document.getElementsByClassName("option")[0].style.background="#bed5de"
        // document.getElementsByClassName("option")[1].style.background="#bed5de"
        // document.getElementsByClassName("option")[2].style.background="#bed5de"
        // document.getElementsByClassName("option")[3].style.background="#bed5de"
        // document.getElementsByClassName("wrongdiv")[0].style.display="none"
        // document.getElementsByClassName("wrongdiv")[1].style.display="none"
        // document.getElementsByClassName("wrongdiv")[2].style.display="none"
        // document.getElementsByClassName("wrongdiv")[3].style.display="none"
        // document.getElementsByClassName("icondiv")[0].style.display="none"
        // document.getElementsByClassName("icondiv")[1].style.display="none"
        // document.getElementsByClassName("icondiv")[2].style.display="none"
        // document.getElementsByClassName("icondiv")[3].style.display="none"
        // if(!selected){
        //   //  notify()
        // alert("select")
        // }

        // else{
          setSelected(selected=>!selected)
            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < Questions.length) {
              setCurrentQuestion(nextQuestion);
            } else {
              setShowScore(true);
            }
            for(let i=0;i<radioButtons.length;i++){
              
                radioButtons[i].checked = 0;
               
             }
             
        // }

        
       
    }
    // //console.log(user_input,"hopesooo")
        return (
        <>
        
         {/* <img className="logo" src={logo} alt="logo"/> */}
         <LoginDashboard />
         <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>

         <Box sx={{ pt: 8 }}>
            <Grid container>
            <Grid item lg={3} sx={{display:{lg:"block",xs:"none",sm:"none",md:"none"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9}>
              {showScore?(
                 <Box
                 sx={{
                  // display:"grid",
                  // placeItems:"center",
                  mt:4,
                  mb:4
              }}
                 >
                  <Box sx={{pl:8}}>
                  <Typography
                  sx={{
                    fontWeight:"bold",
                    fontSize:"16px",
                    // color:"#595c6d",
                  }}
                  >
                  Your score is {score} out of {Questions&&Questions.length}
                  </Typography>
                  </Box>
                 
                  <Box 
                  sx={{
                    mb:4,
                    mt:3
                  }}
                  >
                  <OutputJob question={output} userInput={user_input} />
                  </Box>
                 </Box>
              ):
              (
                <>
                 <Box sx={{
                display:"grid",
                placeItems:"center",
                mt:8,
                mb:4
            }}>
                <Paper
                elevation={2}
                sx={{
                  
                  mr: {lg:6,xs:0,sm:0},
                     width:{lg:"60vw",xs:"80%",sm:"60vw",md:"60vw"},
                    borderRadius: "8px",
                    
                    p: 3,
                  }}
                >
                    <Box
                    sx={{
                        display:"flex"
                    }}
                    >
                        <Typography
                        sx={{
                          color:"#595c6d",
                            width:"5%",
                            mr:0.8
                        }}
                        >
                        {`Q.${currentQuestion+1}`}
                        </Typography>
                        <Typography
                    sx={{
                        color:"#595c6d",

                    }}
                    >
                    {Questions&&Questions[currentQuestion].question_text}
                    </Typography>
                    </Box>
                    <Box
                    sx={{
                       
                    }}
                    >
                         <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={handleChange}
                          
                        >
                          {Questions&&Object.values(JSON.parse(Questions[currentQuestion].choices)).map((val,i)=>(
                            // <ThemeProvider theme={theme}>
                           
                                 <FormControlLabel
                             
                               sx={{
                                 alignItems: "center",
                                 backgroundColor: "#bed5de",
                                 border: "2px solid #aec6cf",
                                 borderRadius: "4px",
                                
                                 display: "flex",
                                 fontSize: "18px",
                                 margin: "5px 0",
                                 opacity: 1,
                                 padding: "2px",
                                 textAlign: "start",
                                 width: "auto",
                                 "&:hover":{
                                     border: "2px solid #002e60",
                                     backgroundColor: "hsl(206, 92%, 94%)",
                                 }
                               }}
                               fullwidth
                               className={`option ${shake?"shake":null}`}
                              //  onClick={()=>{
                              //   setShake(true);
        
                                
                              //   setTimeout(() => setShake(false), 1000);
                              //  }}
                              
                                 value={`Q.${currentQuestion+1}+${i+1}`}
                                 control={
                                   <Radio
                                   className="radio"
                                     sx={{
                                       "& .MuiSvgIcon-root": {
                                         fontSize: 18,
                                         
                                         // backgroundColor:"#fff"
                                         
                                       },
                                     }}
                                     disabled={selected}
                                     onClick={()=>{
                                      handleClick(i+1,Questions&&Questions[currentQuestion].answer,
                                        Questions&&Questions[currentQuestion].question_text,val,
                                        Questions&&Object.values(JSON.parse(Questions[currentQuestion].choices))
                                        )
                                     }}
                                    //  onClick={()=>{
                                    //   //console.log("dekhtey hain ")
                                    //  }}
                                    //  onChange={handleChange}
                                    //  onClick={()=>{
                                    //   inputClick(i+1,Questions&&Questions[currentQuestion].answer,
                                    //     Questions&&Questions[currentQuestion].question_txt,val,
                                    //     Questions&&Object.values(JSON.parse(Questions[currentQuestion].choices))
                                    //     )
                                    //  }}
                                   />
                                 }
                                 label={
                                 
                                  <Box
                                    sx={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}
                                  >
                                    
                                    
                                      
                                      <Typography
                                      className="txt"
                                       sx={{
                                         fontSize: "0.9rem",
                                         marginBottom: "0rem ! important",
                                         color: "#002e60",
                                         width:"100%"
                                       }}
                                      //  onClick={()=>{
                                      //   handleClick(i+1,Questions&&Questions[currentQuestion].answer,
                                      //     Questions&&Questions[currentQuestion].question_text,val,
                                      //     Questions&&Object.values(JSON.parse(Questions[currentQuestion].choices))
                                      //     )
                                      //  }}
  
                                     >
                                       {val}
                                     </Typography>
                                      
                                     
                                 <Box className="icondiv">
                                 <DoneIcon sx={{
                                  color:"green",
                                  
                                 }} />
                                 </Box>
                                 <Box className="wrongdiv">
                                 <CloseIcon sx={{
                                  
                                  color:"red"
                                 }} />
                                 </Box>
                                  
                                  
                                  
                                    
                                   
                                  
                                  </Box>
                                  
                                   
                                 }
                               />
                          
                             
                            // </ThemeProvider>
                               
                          ))}
                         
                         
                        </RadioGroup>
                    </Box>
                   
                    
                </Paper>
                
            </Box>
            <Box
                sx={{
                    mt:2,mb:2,
                    pl:8
                }}
                >
                    <Button
                    sx={{
                      backgroundColor:"#002e60",
                      borderRadius:"5px",
                      textTransform:"none",
                      py:1,
                      "&:hover": {
                        background: "#002e60",

                        borderRadius: "5px",
                        textTransform: "none",
                      },

                    }}
                    variant="contained"
                    size="large"
                    className="nextbtn"
                    onClick={handleNext}
                    disabled={!selected}
                    >
                        {currentQuestion+1===(Questions&&Questions.length)?"Submit":"Next"}
                    </Button>
                </Box>
                </>
              )
             
            }
           
           
            </Grid>
            </Grid>
            
            </Box>
         </Box>
        
        
        </>
    )
}
export default QuizJob;