import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Backdrop,
} from "@mui/material";
import Testingnavbar from "../Navbar/testinnav";
import Footer from "../Footer/Footer";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from "react-toastify";
export default function Login() {
  const [open, setOpen] = useState(false);
  function clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }
  var CryptoJS = require("crypto-js");
  let navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const submitForm = (e) => {
    LoaderOpen();
    e.preventDefault();
    const sendData = {
      email: user.email,
      password: CryptoJS.AES.encrypt(
        user.password,
        "ACCREDIAN@login$2022@$newweb$@"
      ).toString(),
    };
    axios 
      .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/login`, JSON.stringify(sendData), options, {
        withCredentials: true,
      })
      .then((result) => {
        // //console.log(result.data.status);
        if (result.data.count == "2"  || result.data.count == "3") {
          window.localStorage.setItem("user_id", result.data.user_id);
          window.localStorage.setItem("count", result.data.count);
          window.localStorage.setItem("username", result.data.firstname);
          window.localStorage.setItem("lastname", result.data.userlastname);
          window.localStorage.setItem("email", result.data.email);
          window.localStorage.setItem("mobile", result.data.mobile);
          window.localStorage.setItem("dob", result.data.dob);
          window.localStorage.setItem("batch_id", result.data.batch_id);
          window.localStorage.setItem("term", result.data.term);
          navigate(`/Myaccount`);
          
        } else if (result.data.count == "1") {
          window.localStorage.setItem("user_id", result.data.user_id);
          window.localStorage.setItem("enrol_id", result.data.enrol_id);
          window.localStorage.setItem("category", result.data.category);
          window.localStorage.setItem("count", result.data.count);
          window.localStorage.setItem("username", result.data.firstname);
          window.localStorage.setItem("lastname", result.data.userlastname);
          window.localStorage.setItem("email", result.data.email);
          window.localStorage.setItem("mobile", result.data.mobile);
          window.localStorage.setItem("user_role", result.data.user_role);
          window.localStorage.setItem("program_id", result.data.program_id);
          window.localStorage.setItem("dob", result.data.dob);
          window.localStorage.setItem("batch_id", result.data.batch_id);
          window.localStorage.setItem("term", result.data.term);
          navigate(`/Dashboard`);
        


        } else if(result.data.status === 401) {
          toast.error("The password entered is incorrect. Please double-check your password or use the 'Lost Your Password' option to reset it.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
        else if(result.data.status === 404) {
          toast.error("Please check the email or sign up to create a new account.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          LoaderClose();
        }
       
      
      });
  };

  const LoaderOpen = () => {
    setOpen(true);
  };
  const LoaderClose = () => {
    setOpen(false);
  };
  // //console.log(sendData);
  return (
    <>
    <ToastContainer/>
      <Testingnavbar />
      <Box sx={{ pt: 15, pb: 5 }}>
        <Container>
          <Box
            sx={{
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              p: 5,
              borderRadius: 5,
              mx: { lg: 20 },
              width: { lg: "65%" },
            }}
          >
            <Grid container>
              <Grid lg={6} sx={{ display: { xs: "none", lg: "block" } }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Auth/login.webp"
                    alt="Goole"
                    sx={{ width: "auto" }}
                  />
                </Box>
              </Grid>
              <Grid lg={6} xs={12}>
                <Box>
                  <Typography
                    mb={2}
                    fontWeight="bold"
                    fontSize={25}
                    sx={{ textAlign: { xs: "center", lg: "left" } }}
                  >
                    Sign in
                  </Typography>
                  <form onSubmit={submitForm}>
                    <Box mb={2}>
                      {" "}
                      <TextField
                        id="email"
                        label="Email"
                        variant="standard"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={user.email}
                        fullWidth
                        required={true}
                        sx={{ mb: 2 }}
                      />
                    </Box>
                    <Box mb={2}>
                      {" "}
                      <TextField
                        id="password"
                        label="Password"
                        variant="standard"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        value={user.password}
                        fullWidth
                        required={true}
                        sx={{ mb: 2 }}
                      />
                    </Box>

                    <Box pt={2} display="flex">
                      <Box>
                        {" "}
                        <Button variant="contained" type="submit"> Login</Button>
                      </Box>
                      
                      <Box sx={{ml:15}}>
                        {" "}
                        <Link to="/Lost-password" style={{textDecoration:"none",color:"#000"}}>
                          {" "}
                          <Typography  pt={1} >
                          Lost your password?
                          </Typography>
                        </Link>{" "}
                      </Box>
                    </Box>
                    </form>
                    <Box sx={{mt:1}}>
                        {" "}
                        <Link to="/Signup" style={{textDecoration:"none",color:"#000"}}>
                          {" "}
                          <Typography ml={5} pt={1}>
                            Don't have account? Sign up here
                          </Typography>
                        </Link>{" "}
                      </Box>
                 
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open} 
              >
              <CircularProgress color="inherit" />
              </Backdrop>
      <Footer />
    </>
  );
}
