import React from 'react'
import "../../Assets/css/Home/ProgramCard.css";
import useStyles from '../../Assets/js/Home/ProgramCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button} from "@mui/material";
import { Link, useNavigate, Redirect } from "react-router-dom";
const ProgramCard = ({img, tag, title, duration, mode, deadline, hexcolor,pagelink}) => {
  const style = {
    background: `linear-gradient(to right ,${hexcolor}cc, ${hexcolor}66), url(${img})`,
  }
  const classes = useStyles();
  return (
    <>
        <div className={classes.programcard}>
            <div style={style} className='img-prog'>
                {/* <img src={img} alt="" /> */}
            </div>
            <div className='prog-details'>
                <span className="prog-tag">{tag}</span>
                <span className={classes.progtitle}>{title}</span>
                <hr width="300"></hr>
                <span className="prog-duration">{duration} | {mode}</span>
                <span className="prog-deadline">Admission Deadline: {deadline}</span>
               <Link to={pagelink}>
                <Button  
                className='btn-explore'   
              
                sx={{textTransform:"capitalize",fontSize:"1.3rem",fontWeight:"bold",'&:hover': {
                  textTransform:"capitalize",fontSize:"1.3rem",fontWeight:"bold",background:"transparent"
            
              }, }}
                >
                  Explore Program Now<ChevronRightIcon/>
                  </Button>
                  </Link>
              
            </div>
        </div>
    </>
  )
}

export default ProgramCard