import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import "react-phone-number-input/style.css";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  CardMedia,
  Card,
  CardContent,
  Backdrop,
  DialogContent,
} from "@mui/material";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProgramSectionOne from "./IitProgramSectionOne";
import ProgramSectionTwo from "./IitProgramSectionTwo";
import ProgramSectionThree from "./IitProgramSectionThree";
import ProgramSectionFive from "./IitProgramSectionFive";
import useStyles from "../../Assets/js/ProgramPage/style";
import Testingnavbar from "../../Components/Navbar/testinnav";
import Footer from "../../Components/Footer/Footer";
import DownloadIcon from "@mui/icons-material/Download";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "react-moment";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import CircularProgress from "@mui/material/CircularProgress";
// import { Iitprogram } from "../../data/Iitprogram";
import { Iitprogramnew } from "../../data/Iitprogramnew";

import Swal from "sweetalert2";
import axios from "axios";
import ApplyNow from "../../Components/Signup/ApplyNow";
const currencies = [
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Product Management",
    label: "Product Management",
  },
  {
    value: "General Management",
    label: "General Management",
  },
  // {
  //   value: "HR Analytics",
  //   label: "HR Analytics",
  // },
  // {
  //   value: "Sales and Marketing",
  //   label: "Sales and Marketing",
  // },
  {
    value: "Digital Transformation",
    label: "Digital Transformation",
  },
  {
    value: "Business Management",
    label: "Business Management",
  },
  // {
  //   value: "Operations & Supply Chain Management",
  //   label: "Operations & Supply Chain Management",
  // },
];
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ff9800" : "#fff176",
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "733px",
    background:""
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function RcbDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

RcbDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CcbDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1033px",
    width:"100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop:"-40px",
  },
}));
function CcDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ mb:2, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 1,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

CcDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function YourComponent() {
  const date = moment().day("Sunday").add(7, "days");
  // const date = moment();
  const app = localStorage.getItem("user_id");
  const [isFetching, setIsFetching] = useState(true);
  const { id } = useParams();
  const [rcbprogram, setProgramrcb] = useState("");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [program, setProgram] = useState("");
  const [source, setSource] = useState("");
  const [pdf, setPdf] = useState("");
  const [currency, setCurrency] = React.useState("");
  const [applynow, setApplynow] = useState(false);
  const [insaidtvpop, setInsaidtvpop] = useState(false);
  const [maxWidth, setMaxWidth] = useState('md');
  const handleyClose = () => {
    setInsaidtvpop(false);
  };
  const handleyClickOpen = () => {
    setInsaidtvpop(true);
 
  };
  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const [Rcbopen, RcbsetOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const progamhandleChange = (event) => {
    setProgramrcb(event.target.value);
  };

  useEffect(() => {
    let ans = Iitprogramnew.filter((obj) => {
      return obj.id == id;
    });

    //console.log(ans,"first");
    setData(ans);
    setProgram(ans[0].programname);
    setSource(ans[0].ctasource);
    setPdf(ans[0].ctapdf);
    document.title = `${ans[0].title} | Accredian`;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, [id]);

  const classes = useStyles();
  const sliderRef = useRef();
  const ptsliderRef = useRef();
  const deskgotoNext = () => {
    sliderRef.current.slickNext();
  };
  const deskgotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const ptgotoNext = () => {
    ptsliderRef.current.slickNext();
  };
  const ptgotoPrev = () => {
    ptsliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1124,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenRcb = () => {
    RcbsetOpen(true);
  };
  const RcbhandleClose = () => {
    RcbsetOpen(false);
  };
  const LoaderClose = () => {
    setLoader(false);
  };
  const LoaderOpen = () => {
    setLoader(true);
  };

  const [cta, setCta] = useState({
    mobile: "",
    name: "",
    newemail: "",

    ctapage: "",

    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });

  const ctahandleChange = (e) => {
    setCta({ ...cta, [e.target.name]: e.target.value });
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  var ctapage = location.pathname.replace("/", "");
  var utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  var utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  var utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");
  const submitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: cta.mobile,
      name: cta.name,
      email: cta.newemail,
      program: program,
      source: source,
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "brochure",
      type:"CTA"
    };
    //console.log(sendRcbctaData);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        handleClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((conf) => {
            if (conf.isConfirmed) {
              window.open(pdf, "_blank");
            }
          });

          setCta("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const [rcb, setRcb] = useState({
    mobile: "",
    name: "",
    newemail: "",
    program: "",
    ctaname: "",
    ctapage: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const rcbhandleChange = (e) => {
    setRcb({ ...rcb, [e.target.name]: e.target.value });
  };
  const rcbsubmitForm = (e) => {
    e.preventDefault();
    LoaderOpen();
    const sendRcbctaData = {
      phone: rcb.mobile,
      name: rcb.name,
      email: rcb.newemail,
      program: rcbprogram,
      source: "Request Callback ",
      page: ctapage,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      form_id: "request_callback",
      type:"CTA"
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/common_publish/commonPublish`,
        JSON.stringify(sendRcbctaData),
        options
      )
      .then((result) => {
        // //console.log(result.data.status);
        LoaderClose();
        RcbhandleClose();

        if (result.data.status == "200") {
          Swal.fire({
            title: "Success",
            text: "Your request submitted successfully. Our expert will call you soon.",
            icon: "success",
            confirmButtonText: "OK",
          });

          setRcb("");
        } else {
          Swal.fire({
            title: "Warning",
            text: "Something went wrong. Please try again!",
            icon: "Warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const handleApplyOpen = () => {
    setApplynow(true);
  };
  const handleApplyclose = () => {
    setApplynow(false);
  };
  return (
    <>

       <Testingnavbar />
       {data &&
        data.map((Iitprogram) => (
          <Box
          sx={{
            pt: app
              ? { lg: 8.7, sm: 0, md: 0, xs: 0 }
              : { lg:12.5, sm: 5, md: 4, xs: 0 },
            mt: app
              ? { lg: 0, sm: -3.2, md: -3.2, xs: 0 }
              : { lg: 0, sm: 0, md: 0, xs: 0 },
          }}
          key={Iitprogram.id}
        >

      <Box sx={{ backgroundImage:{lg:"linear-gradient(477deg,#f4f5f7 50% , rgba(54, 74, 114, 0.6) 40% ), url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp)",xs:"none"},backgroundPosition:'top'}}>
        {/* mobile fixed bar of apply now */}
        {/* background:"#fff",padding:"5px 0px" */}
        <Box
                    sx={{ display: {lg:"none",xs:"flex"},position:"fixed",bottom:"0px",justifyContent:"center",zIndex:100,width:"100%",flexDirection:"row",background:"#fff",padding:"5px 0px" }}
                  >
                      <Box>
                      <Button
                        variant="outlined"
                        sx={{ mb: 1,textTransform:"none",padding:"11px 0px",width:'155px',height:"40px",fontSize:'13px',mt:1,background:"#fff",border:"1px solid rgba(0, 41, 255, 1)"}}
                   
                      >
                        Apply Now
                      </Button>
                    </Box>
                    <Box sx={{ml:2}}>
                      <Button
                        variant="contained"
                        sx={{textTransform:"none",background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",padding:"11px 0px",width:'155px',fontSize:'13px',mt:1,height:"40px",mb:1}}
                     
                        // onClick={handleClickOpen}
                      >
                        Download Brochure
                      </Button>
                    </Box>
                  
                  </Box>



              {/* end of  mobile fixed bar of apply now */}
        <Container sx={{display:{lg:"block",xs:"none"}}} fixed>
          <Box sx={{py:3}}>
          <Box
            sx={{
              // background:
              //   "radial-gradient(100% 200% at left,#f4f5f7 60%,#013A6B 40.1%)",
         
              p:2,
            }}
          >
            <Box sx={{ display: {lg:"flex",xs:"block"}}}>
              <Box sx={{width:{lg:"681px",xs:"100%"}}}>
                <Typography
                  variant="h5"
                  component="div"
                  color="#000"
                  sx={{
                    fontWeight: "bold",
                    fontSize:{lg:"1.5rem",xs:"16px"},
                    width: {lg:"608px",xs:"95%"},
                    pb: {lg:2,xs:1},
                  }}
                >
                  Executive Program in Data Science & Artificial Intelligence
                  with E&ICT Academy, IIT Guwahati
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt: {lg:2,xs:0}, fontSize:{lg:"15px",xs:"11px"},background:"-webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  // background: '-webkit-linear-gradient(#ee0979, #ff6a00)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  display:{xs:"block",lg:"none"}
                
                }}
                >
                  12 Months | Live Instructor led | Weekend Online
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt: 2, fontSize: "15px",
                  display:{xs:"none",lg:"block"}
                
                }}
                >
                  12 Months | Live Instructor led | Weekend Online
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{
                    pt: 1,
                    borderBottom: "1px solid #dee2e6",
                    width: {lg:"452px",xs:"100%"},
                  }}
                ></Typography>
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt: {lg:2,xs:1}, fontSize: {lg:"15px",xs:"11px"}, width: {lg:"80%",xs:"100%"} }}
                >
                  Embark on a 12-term learning journey in Data Science, Machine
                  Learning, & End-to-End AI Product Development.
                </Typography>

                <Box sx={{ mt:{lg:3,xs:2} }}>
                  <Box
                    sx={{ display: {lg:"flex",xs:"none"} }}
                  >
                      <Box>
                      <Button
                        variant="outlined"
                        sx={{ mb: 2,textTransform:"none",color:{lg:"#000",xs:"#fff"},padding:"11px 15px",width:'195px',fontSize:'15px',background:{lg:"none",xs:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)"}}}
                   
                      >
                        Apply Now
                      </Button>
                    </Box>
                    <Box sx={{ml:2}}>
                      <Button
                        variant="contained"
                        sx={{textTransform:"none",background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",padding:"11px 15px",width:'195px',fontSize:'15px',display:{lg:"block",xs:"none"} }}
                     
                        // onClick={handleClickOpen}
                      >
                        Download Brochure
                      </Button>
                    </Box>
                  
                  </Box>
                </Box>

                 {/* mobile view */}
                 <Box sx={{display:{lg:"none",xs:"flex"},justifyContent:'center',alignItems:'center',mt:2,mb:2,backgroundImage:{xs:"linear-gradient(#f4f5f7 0% , rgba(54, 74, 114, 0.6) 0% ),url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp)",lg:"none"},borderRadius:"15px",height:"175px",}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/playn.gif"
                        alt="iit"
                        sx={{
                          objectFit: "cover",
                         width:{lg:"80px",xs:"70px"},
                         cursor:'pointer',
                       
                        }}
                        onClick={handleyClickOpen}
                      />
                      <Box>
                        {/* <Box>
                          <Typography sx={{ mb: "0.2rem !important",color:"#fff"}}>
                          Watch Program
                          </Typography>
                          <Typography sx={{ mb: "0.5rem !important",color:"#fff"}}>
                          Intro video
                          </Typography>
                        </Box> */}
                         <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitlogo.webp"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                       width:{lg:"!20px",xs:"100px"},
                       background:"#000",
                       borderRadius:"10px",
                      // display:{lg:"block",xs:"none"}
                        }}
                      />
                </Box>
                      </Box>
                </Box>
                {/* end of mobile  */}
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt:{lg:4,xs:0}, fontSize: {lg:"17px",xs:"13px"}, width: "80%",
                 fontWeight:{lg:"normal",xs:"bold"}
                }}
                >
                  Get dual certification from
                </Typography>
                <Grid item lg={12} container sx={{ pt: {lg:3,xs:1.5},mb:{lg:0,xs:3} }}>
                  <Grid item lg={2} xs={2}>
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/EICTIITG.webp"
                      alt="Goole"
                    sx={{   objectFit: "unset",
                    width:{lg:"60px",xs:"35px"}}}
                    />
                  </Grid>
                  <Grid item lg={2} xs={4}>
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                      alt="Goole"
                      sx={{ mx: {lg:3,xs:1}, my: {lg:2,xs:1},   objectFit: "unset",
                      width:{lg:"150px",xs:"90px"} }}
                   
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ml:{lg:10,xs:0} }}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitlogo.webp"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                       width:"150px",
                       background:"#000",
                       borderRadius:"10px",
                      display:{lg:"block",xs:"none"}
                        }}
                      />
                </Box>
                <Box sx={{display:{lg:"flex",xs:"none"},justifyContent:'center',alignItems:'center',mt:8,mb:8}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/playn.gif"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                         width:"150px",
                         cursor:'pointer',
                        }}
                        onClick={handleyClickOpen}
                      />
                      <Box>
                        <Box>
                          <Typography sx={{ mb: "0.2rem !important",color:"#fff"}}>
                          Watch Program
                          </Typography>
                          <Typography sx={{ mb: "0.5rem !important",color:"#fff"}}>
                          Intro video
                          </Typography>
                        </Box>
                      </Box>
                </Box>

               
                
                <Card sx={{ maxWidth: "570px" }}>
                        <CardContent sx={{ px:1,pb:"0px !important" }}>
                          <Box sx={{display:"flex"}}>
                            <Box>
                            <Typography
                            align="left"
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0.5rem !important",
                            }}
                          >
                            Talk to Learning Advisor
                          </Typography>
                          <Typography
                            align="center"
                            variant="h5"
                            component="div"
                            sx={{ fontSize: "15px",fontWeight:600 }}
                          >
                      70421-48123
                          </Typography>
                          <Typography
                            align="center"
                            sx={{ fontSize: "13px", mb: "0.5rem !important", pb:1 }}
                            color="text.secondary"
                          >
                            9 AM - 9 PM IST
                          </Typography>
                            </Box>
                            <Box sx={{ml:2}}>
                            <Typography
                            align="left"
                       
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0.5rem !important",
                              color: "#BD3381",
                              fontWeight: "bold",
                            }}
                          >
                           Scholarship Seats left - 2
                          </Typography>

                          <Typography
                            align="left"
                     
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0rem !important",
                            }}
                            color="text.secondary"
                          >
                          Non-Scholarship Seats left - 4
                          </Typography>
                          <Typography
                            align="left"
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0rem !important",
                            }}
                            color="text.secondary"
                          >
                        Admission Deadline : 31 Dec 2023
                          </Typography>
                            </Box>
                          </Box>
                    
                     
                     
                        </CardContent>
                      </Card>
                {/* <Box
                  sx={{
                    display: "flex",
                    background: "#013A6B",
                    borderRadius: "8px",
                    px: 3,
                  }}
                >
                  <Box>
                    <Typography
                      align="center"
                      // display={Iitprogram.setsction}
                      sx={{
                        fontSize: ".775rem",
                        pb: 0,
                        mb: "0rem !important",
                        color: "#fff",
                        // fontWeight: "bold",
                      }}
                    >
                      Scholarship Seats left - 2
                    </Typography>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Typography
                      align="center"
                      // display={Iitprogram.setsction}
                      sx={{
                        color: "#fff",
                        fontSize: ".775rem",
                        pb: 0,
                        mb: "0rem !important",
                      }}
                    >
                      Non-Scholarship Seats left - 4
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        align="center"
                        // display={Iitprogram.setsction}
                        sx={{
                          color: "#fff",
                          fontSize: ".775rem",
                          pb: 0,
                          mb: "0rem !important",
                        }}
                      >
                        Admission Deadline : 31 Dec 2023
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
             
              </Box>
            </Box>
          </Box>
          </Box>
        </Container>

       
        
       
      </Box>
       {/* mobile view */}
       <Box sx={{ display:{lg:"none",xs:"block"},background:{lg:"none",xs:"linear-gradient(145deg,#f3f4f7 11.98%,#f3f4f7 64%,#f3f4f7)"},p:"5px 0px 15px 0px"}}>
          <Container sx={{mb:2}} fixed>
          <Card sx={{mt:4}} >
                        <CardContent sx={{ px:1,pb:"0px !important",pt:"0px !important" }}>
                        {/* <Container  fixed> */}
          <Box sx={{py:1}}>
          <Box
            sx={{
              // background:
              //   "radial-gradient(100% 200% at left,#f4f5f7 60%,#013A6B 40.1%)",
         
              p:2,
              pb:0
            }}
          >
            <Box sx={{ display: {lg:"flex",xs:"block"}}}>
              <Box sx={{width:{lg:"681px",xs:"100%"}}}>
                <Typography
                  variant="h5"
                  component="div"
                  color="#000"
                  sx={{
                    fontWeight: "bold",
                    fontSize:{lg:"1.5rem",xs:"16px"},
                    width: {lg:"608px",xs:"95%"},
                    pb: {lg:2,xs:0},
                  }}
                >
                  Executive Program in Data Science & Artificial Intelligence
                  with E&ICT Academy, IIT Guwahati
                </Typography>
                
                {/* <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{
                    pt: 1,
                    borderBottom: "1px solid #dee2e6",
                    width: {lg:"452px",xs:"100%"},
                  }}
                ></Typography> */}
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt: {lg:2,xs:1}, fontSize: {lg:"15px",xs:"11px"}, width: {lg:"80%",xs:"100%"},color:"#68666a",fontWeight:400,mb:{lg:0,xs:1} }}
                >
                  Embark on a 12-term learning journey in Data Science, Machine
                  Learning, & End-to-End AI Product Development.
                </Typography>
                <Box
                sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",gap:"18px"}}
                >
                  <Box
                  sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}
                  >
                    <Typography sx={{marginBottom:"0px !important",fontSize:"12px",fontWeight:"bold"}}>Duration</Typography>
                    <Typography  sx={{marginBottom:"0px !important",fontSize:"12px",
                    background:"-webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                    // background: '-webkit-linear-gradient(#ee0979, #ff6a00)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight:500,
                  
                  }}>12 months</Typography>
                  </Box>
                  <Box
                  sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}
                  >
                    <Typography  sx={{marginBottom:"0px !important",fontSize:"12px",fontWeight:"bold"}}>Start Date</Typography>
                    <Typography  sx={{marginBottom:"0px !important",fontSize:"12px",
                  background:"-webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  // background: '-webkit-linear-gradient(#ee0979, #ff6a00)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight:500,
                  
                  }}>24th April 2024</Typography>
                  </Box>
                  <Box
                  sx={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}
                  >
                    <Typography  sx={{marginBottom:"0px !important",fontSize:"12px",fontWeight:"bold"}}>Medium</Typography>
                    <Typography  sx={{marginBottom:"0px !important",fontSize:"12px",
                  background:"-webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  // background: '-webkit-linear-gradient(#ee0979, #ff6a00)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight:500,
                  
                  }}>Weekend Online</Typography>
                  </Box>

                </Box>
                {/* <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt: {lg:2,xs:0}, fontSize:{lg:"15px",xs:"11px"},background:"-webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
                  // background: '-webkit-linear-gradient(#ee0979, #ff6a00)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight:500,
                  display:{xs:"block",lg:"none"}
                
                }}
                >
                  12 Months | Live Instructor led | Weekend Online
                </Typography> */}
                <Box sx={{ mt:{lg:3,xs:2} }}>
                  <Box
                    sx={{ display: {lg:"flex",xs:"none"} }}
                  >
                      <Box>
                      <Button
                        variant="outlined"
                        sx={{ mb: 2,textTransform:"none",color:{lg:"#000",xs:"#fff"},padding:"11px 15px",width:'195px',fontSize:'15px',background:{lg:"none",xs:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)"}}}
                   
                      >
                        Apply Now
                      </Button>
                    </Box>
                    <Box sx={{ml:2}}>
                      <Button
                        variant="contained"
                        sx={{textTransform:"none",background:"linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",padding:"11px 15px",width:'195px',fontSize:'15px',display:{lg:"block",xs:"none"} }}
                     
                        // onClick={handleClickOpen}
                      >
                        Download Brochure
                      </Button>
                    </Box>
                  
                  </Box>
                </Box>

                 {/* mobile view */}
                 {/* linear-gradient(#f4f5f7 0% , rgba(54, 74, 114, 0.6) 0% ), */}
                 <Box sx={{display:{lg:"none",xs:"flex"},justifyContent:'center',alignItems:'center',mt:2,mb:2,backgroundImage:{xs:"url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/iita.webp)",lg:"none"},borderRadius:"15px",height:"175px",backgroundSize:"cover"}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/playn.gif"
                        alt="iit"
                        sx={{
                          objectFit: "cover",
                         width:{lg:"80px",xs:"70px"},
                         cursor:'pointer',
                       
                        }}
                        onClick={handleyClickOpen}
                      />
                      <Box>
                        {/* <Box>
                          <Typography sx={{ mb: "0.2rem !important",color:"#fff"}}>
                          Watch Program
                          </Typography>
                          <Typography sx={{ mb: "0.5rem !important",color:"#fff"}}>
                          Intro video
                          </Typography>
                        </Box> */}
                         <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitlogo.webp"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                       width:{lg:"!20px",xs:"100px"},
                       background:"#000",
                       borderRadius:"10px",
                      // display:{lg:"block",xs:"none"}
                        }}
                      />
                </Box>
                      </Box>
                </Box>
                {/* end of mobile  */}
                <Typography
                  variant="h6"
                  component="div"
                  color="#000"
                  sx={{ pt:{lg:4,xs:0}, fontSize: {lg:"17px",xs:"13px"}, width: "80%",
                 fontWeight:{lg:"normal",xs:"bold"}
                }}
                >
                  Get dual certification from
                </Typography>
                <Grid item lg={12} container sx={{ pt: {lg:3,xs:1.5},mb:{lg:0,xs:1.5} }}>
                  <Grid item lg={2} xs={2}>
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/EICTIITG.webp"
                      alt="Goole"
                    sx={{   objectFit: "unset",
                    width:{lg:"60px",xs:"50px"}}}
                    />
                  </Grid>
                  <Grid item lg={2} xs={4}>
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredian.webp"
                      alt="Goole"
                      sx={{ mx: {lg:3,xs:3}, my: {lg:2,xs:1},   objectFit: "unset",
                      width:{lg:"150px",xs:"120px"} }}
                   
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ml:{lg:10,xs:0} }}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitlogo.webp"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                       width:"150px",
                       background:"#000",
                       borderRadius:"10px",
                      display:{lg:"block",xs:"none"}
                        }}
                      />
                </Box>
                <Box sx={{display:{lg:"flex",xs:"none"},justifyContent:'center',alignItems:'center',mt:8,mb:8}}>
                <CardMedia
                        component="img"
                        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/playn.gif"
                        alt="iit"
                        sx={{
                          objectFit: "unset",
                         width:"150px",
                         cursor:'pointer',
                        }}
                        onClick={handleyClickOpen}
                      />
                      <Box>
                        <Box>
                          <Typography sx={{ mb: "0.2rem !important",color:"#fff"}}>
                          Watch Program
                          </Typography>
                          <Typography sx={{ mb: "0.5rem !important",color:"#fff"}}>
                          Intro video
                          </Typography>
                        </Box>
                      </Box>
                </Box>

               
                
               
                {/* <Box
                  sx={{
                    display: "flex",
                    background: "#013A6B",
                    borderRadius: "8px",
                    px: 3,
                  }}
                >
                  <Box>
                    <Typography
                      align="center"
                      // display={Iitprogram.setsction}
                      sx={{
                        fontSize: ".775rem",
                        pb: 0,
                        mb: "0rem !important",
                        color: "#fff",
                        // fontWeight: "bold",
                      }}
                    >
                      Scholarship Seats left - 2
                    </Typography>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Typography
                      align="center"
                      // display={Iitprogram.setsction}
                      sx={{
                        color: "#fff",
                        fontSize: ".775rem",
                        pb: 0,
                        mb: "0rem !important",
                      }}
                    >
                      Non-Scholarship Seats left - 4
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        align="center"
                        // display={Iitprogram.setsction}
                        sx={{
                          color: "#fff",
                          fontSize: ".775rem",
                          pb: 0,
                          mb: "0rem !important",
                        }}
                      >
                        Admission Deadline : 31 Dec 2023
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
             
              </Box>
            </Box>
          </Box>
          </Box>
        {/* </Container> */}
                          </CardContent>
                          </Card>
          </Container>
               
                          <Container fixed>
                          <Card sx={{ maxWidth: "570px" }}>
                        <CardContent sx={{ px:1,pb:"0px !important" }}>
                          <Box sx={{display:"flex"}}>
                            <Box>
                            <Typography
                            align="left"
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0.5rem !important",
                            }}
                          >
                            Talk to Learning Advisor
                          </Typography>
                          <Typography
                            align="center"
                            variant="h5"
                            component="div"
                            sx={{ fontSize: "15px",fontWeight:600 }}
                          >
                      70421-48123
                          </Typography>
                          <Typography
                            align="center"
                            sx={{ fontSize: "13px", mb: "0.5rem !important", pb:1 }}
                            color="text.secondary"
                          >
                            9 AM - 9 PM IST
                          </Typography>
                            </Box>
                            <Box sx={{ml:2}}>
                            <Typography
                            align="left"
                       
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0.5rem !important",
                              color: "#BD3381",
                              fontWeight: "bold",
                            }}
                          >
                           Scholarship Seats left - 2
                          </Typography>

                          <Typography
                            align="left"
                     
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0rem !important",
                            }}
                            color="text.secondary"
                          >
                          Non-Scholarship Seats left - 4
                          </Typography>
                          <Typography
                            align="left"
                            sx={{
                              fontSize: "12px",
                              pb: 0,
                              mb: "0rem !important",
                            }}
                            color="text.secondary"
                          >
                        Admission Deadline : 31 Dec 2023
                          </Typography>
                            </Box>
                          </Box>
                    
                     
                     
                        </CardContent>
                      </Card>
                          </Container>
                          
        </Box>
        {/* end of complete mobile view first section */}
<Box sx={{ pt: { lg: 4, md: 7, sm: 4, xs: 2 } }}>
<Container fixed>
  <Grid
    lg={12}
    xs={12}
    sm={12}
    md={12}
    container
    sx={{ pb: { lg: 4, md: 4, sm: 4, xs: 4 } }}
  >
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        p: 2,
        borderRadius: "9px",
        maxWidth: {
          lg: "24.333333% !important ",
          md: "45% !important ",
          sm: "48% !important ",
          xs: "100% !important ",
        },
        height: "110px",
        mr: { lg: 1, md: 0, sm: 0, xs: 0 },
        ml: { lg: 0, md: 2, sm: 0, xs: 0 },
        mb: 3,
      }}
    >
      <Grid lg={12} xs={12} sm={12} md={12} container>
        <Grid lg={3} sm={3} xs={3} md={3}>
          <Box className={classes.cardoneimage}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/IIT-Xlri-iocn/1.webp"
              alt="Goole"
              sx={{ width: "48px" }}
            />
          </Box>
        </Grid>
        <Grid lg={9} xs={9} sm={9} md={9} sx={{ pl: 4, pt: 2 }}>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stacksone}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stacksonedescription}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        p: 2,
        borderRadius: "9px",
        maxWidth: {
          lg: "24.333333% !important ",
          md: "45% !important ",
          sm: "48% !important ",
          xs: "100% !important ",
        },
        height: "110px",
        mr: { lg: 1, md: 0, sm: 0, xs: 0 },
        ml: { lg: 0, md: 2, sm: 1, xs: 0 },
        mb: 3,
      }}
    >
      <Grid lg={12} xs={12} sm={12} md={12} container>
        <Grid lg={3} xs={3} sm={3} md={3}>
          <div className={classes.cardtwoimage}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/IIT-Xlri-iocn/2.webp"
              alt="Goole"
              sx={{ width: "48px" }}
            />
          </div>
        </Grid>
        <Grid lg={9} xs={9} sm={9} md={9} sx={{ pl: 4, pt: 2 }}>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stackstwo}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stackstwodescription}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        p: 2,
        borderRadius: "9px",
        maxWidth: {
          lg: "24.333333% !important ",
          md: "45% !important ",
          sm: "48% !important ",
          xs: "100% !important ",
        },
        height: "110px",
        mr: { lg: 1, md: 0, sm: 0, xs: 0 },
        ml: { lg: 0, md: 2, sm: 0, xs: 0 },
        mb: 3,
      }}
    >
      <Grid lg={12} xs={12} sm={12} md={12} container>
        <Grid lg={3} sm={3} md={3} xs={3}>
          <div className={classes.cardthreeimage}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/IIT-Xlri-iocn/3.webp"
              alt="Goole"
              sx={{ width: "48px" }}
            />
          </div>
        </Grid>
        <Grid lg={9} md={9} sm={9} xs={9} sx={{ pl: 4, pt: 2 }}>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stacksthree}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stacksthreedescription}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        p: 2,
        borderRadius: "9px",
        maxWidth: {
          lg: "24.333333% !important ",
          md: "45% !important ",
          sm: "48% !important ",
          xs: "100% !important ",
        },
        height: "110px",
        mr: { lg: 0, md: 0, sm: 0, xs: 0 },
        ml: { lg: 0, md: 2, sm: 1, xs: 0 },
        mb: 3,
      }}
    >
      <Grid lg={12} xs={12} sm={12} md={12} container>
        <Grid lg={3} xs={3} sm={3} md={3}>
          <div className={classes.cardfourimage}>
            <CardMedia
              component="img"
              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/IIT-Xlri-iocn/4.webp"
              alt="Goole"
              sx={{ width: "48px" }}
            />
          </div>
        </Grid>
        <Grid lg={9} xs={9} md={9} sm={9} sx={{ pl: 4, pt: 2 }}>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stackslast}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: "17px", fontWeight: "bold" }}
          >
            {Iitprogram.stackslastdescription}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</Container>
</Box>

<Box
sx={{
  display: { xs: "none", lg: "block", sm: "none", md: "none" },
  overflowX: "hidden",
}}
>
<Container fixed>
  <Typography
    variant="h4"
    component="div"
    sx={{ fontSize: "38px", fontWeight: "bold", pb: 6 }}
  >
    Program Overview
  </Typography>
</Container>
<Container fixed>
  <Grid item lg={12} container sx={{ pb: 6 }}>
    <Grid
      item
      lg={6}
      sx={{
        background:
          "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
        px: 8,
        py: 4,
        borderRadius: "9px",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", color: "#fff", pb: 5 }}
      >
        This program includes:
      </Typography>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/4.webp"
            alt="Goole"
            sx={{ width: "30px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.5,
            }}
          >
            {Iitprogram.progarminclud1}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/6.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
            }}
          >
            {Iitprogram.progarminclud10}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/2.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/8.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud11}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/9.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud3}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/10.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud4}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/15.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud5}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/11.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud6}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/12.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud7}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/13.webp"
            alt="Goole"
            sx={{ width: "32px", pt: 1 }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              pt: 0.8,
              marginBottom: "0rem !important",
            }}
          >
            {Iitprogram.progarminclud8}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#fff",
              marginBottom: "0rem!important",
            }}
          >
            (Subject to resource availability)
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item lg={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/14.webp"
            alt="Goole"
            sx={{ width: "32px" }}
          />
        </Grid>
        <Grid item lg={10} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
              width: "439px",
              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud9}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item lg={6} sx={{ pt: 8, pl: 15 }}>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "38px",
            fontWeight: "bold",
            pb: 2,
            px: 3,
          }}
        >
          Who should join?
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: "18px",
            lineHeight: "21px",
            pb: 6,
            px: 3,
          }}
        >
          {Iitprogram.whoshouldjoin}
        </Typography>

        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "38px",
            fontWeight: "bold",
            pb: 2,
            px: 3,
          }}
        >
          Job Opportunities
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: "18px",
            lineHeight: "21px",
            pb: 6,
            px: 3,
          }}
        >
          {Iitprogram.Jobopportunities}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          fontSize: "38px",
          fontWeight: "bold",
          pb: 3,
          px: 3,
        }}
      >
        Want to Know More?
      </Typography>
      <Box sx={{ ml: 3.5 }}>
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          className={classes.DownloadBrochureone}
          onClick={handleClickOpen}
        >
          Download Brochure <DownloadIcon fontSize="medium" />
        </Button>
      </Box>
    </Grid>
  </Grid>
</Container>
</Box>
{/*   Mobile version For Threed section */}
<Box
sx={{
  display: { xs: "block", lg: "none", sm: "block", md: "block" },
}}
>
<Container fixed>
  <Typography
    variant="h4"
    component="div"
    sx={{
      fontSize: { sm: "38px", md: "38px", xs: "35px" },
      fontWeight: "bold",
      pb: 6,
      textAlign: {
        lg: "left",
        md: "center",
        sm: "center",
        xs: "center",
      },
    }}
  >
    Program Overview
  </Typography>
</Container>
<Container fixed>
  <Grid item lg={12} container sx={{ pb: 6 }}>
    <Grid
      item
      xs={12}
      sx={{
        background:
          "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%)",
        px: 2,
        py: 4,
        borderRadius: "9px",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ fontWeight: "bold", color: "#fff", pb: 5 }}
      >
        This program includes:
      </Typography>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/4.webp"
            alt="Goole"
            sx={{ width: "30px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",
            }}
          >
            {Iitprogram.progarminclud1}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/6.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud10}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/2.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/8.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud11}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/9.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud3}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/10.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud4}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/15.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud5}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/11.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud6}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/12.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud7}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/13.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud8}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: "#fff" }}>
            (Depends on the availability of resources)
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={12} container sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <CardMedia
            component="img"
            image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/program-includes/14.webp"
            alt="Goole"
            sx={{ width: "35px" }}
          />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#fff",
              fontSize: "16px",

              py: 0.8,
            }}
          >
            {Iitprogram.progarminclud9}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid xs={12} sm={12} md={12} sx={{ pt: 4 }}>
      <Box
        sx={{ boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)", mb: 4 }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
            pt: 5,
            px: 3,
          }}
        >
          Who should join?
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: "16px",
            lineHeight: "21px",
            pb: 4,
            px: 3,
          }}
        >
          {Iitprogram.whoshouldjoin}
        </Typography>
      </Box>
      <Box
        sx={{ boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)", mb: 4 }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
            pt: 5,
            px: 3,
          }}
        >
          Job Opportunities
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: "16px",
            lineHeight: "21px",
            pb: 4,
            px: 3,
          }}
        >
          {Iitprogram.Jobopportunities}
        </Typography>
      </Box>
      <Box
        sx={{ boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)", mb: 2 }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
            pt: 5,
            px: 3,
          }}
        >
          Want to Know More?
        </Typography>
        <center>
          <Button
            variant="contained"
            sx={{ mb: 4 }}
            className={classes.DownloadBrochureone}
            onClick={handleClickOpen}
          >
            Download Brochure <DownloadIcon fontSize="medium" />
          </Button>
        </center>
      </Box>
    </Grid>
  </Grid>
</Container>
</Box>
{/*  End Mobile version For Threed section */}

<Box
sx={{
  backgroundColor: "#343a40",
  height: "304px",
  display: { xs: "none", lg: "block" },
}}
>
<Container fixed>
  <Grid item lg={12} container sx={{ pb: 6, mx: 3 }}>
    <Grid item lg={8}>
      <FormatQuoteIcon
        sx={{
          color: "#fff",
          transform: "rotate(180deg)",
          fontSize: "3em",
          mt: 3,
        }}
      />
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "25px",
            color: "#fff",
            pb: 4,
            pl: 5,
            pt: 4,
            width: "754px",
          }}
        >
          {Iitprogram.suchitquotes}
        </Typography>
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontSize: "25px",
            color: "#fff",
            fontStyle: "italic",
            px: 5,
          }}
        >
          {Iitprogram.suchitinfo}
        </Typography>
      </Box>
    </Grid>
    <Grid item lg={4}>
      <CardMedia
        component="img"
        image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/program-page-banner-image/newsuchit.webp"
        alt="Goole"
        sx={{ marginTop: "-20px" }}
      />
    </Grid>
  </Grid>
</Container>
</Box>
<ProgramSectionOne />
<Box>
<Container fixed>
  <Typography
    variant="h4"
    component="div"
    sx={{
      fontSize: {
        lg: "38px",
        sm: "38px",
        md: "38px",
        xs: "30px",
      },
      fontWeight: "bold",
      pb: 6,
      pl: 2,
      textAlign: {
        lg: "left",
        sm: "center",
        md: "center",
        xs: "center",
      },
    }}
  >
    {Iitprogram.programtoolhadding}
  </Typography>
</Container>
<Box sx={{ mb: 10, mt: 3 }}>
  <Slider
    ref={sliderRef}
    {...settings}
    style={{ overflowX: "hidden" }}
  >
    {Iitprogram.progaming &&
      Iitprogram.progaming.map((proimg) => (
        <Grid lg={2} xs={1} sm={1} md={1} sx={{ mb: 2, mt: 1 }}>
          <Box
            sx={{
              boxShadow: "0 4px 10px hsla(0,0%,54%,.24)",
              width: {
                lg: "180px",
                md: "250px",
                xs: "240px",
                sm: "346px",
              },
              height: "106px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: { lg: 3.4, sm: "225px", md: "140px", xs: 10 },
            }}
          >
            <CardMedia
              component="img"
              image={proimg}
              alt="us"
              sx={{
                width: { lg: "150px" },
                ml: { lg: 0, sm: 2, md: 2, xs: 0 },
                // height:{lg:"43px",xs:"80px"},
                objectFit: "unset",
              }}
            />
          </Box>
        </Grid>
      ))}
  </Slider>
  <Button
    onClick={() => deskgotoPrev()}
    sx={{
      mt: { lg: -27, md: -15, sm: -16, xs: -19 },
      ml: { lg: -7.5, md: 9, sm: 16, xs: 3 },
      display: { lg: "none" },
      "&:hover": { backgroundColor: "transparent" },
    }}
  >
    <ArrowBackIosIcon></ArrowBackIosIcon>
  </Button>
  <Button
    onClick={() => deskgotoNext()}
    sx={{
      float: "right",
      mt: { lg: -25, md: -9.5, sm: -9, xs: -10 },
      mr: { lg: -5.4, md: 2, sm: 14, xs: 1.5 },
      display: { lg: "none" },
      "&:hover": { backgroundColor: "transparent" },
    }}
  >
    <ArrowForwardIosIcon></ArrowForwardIosIcon>
  </Button>
</Box>
</Box>
{/*  Mobile version For tool section */}

<Box sx={{ mb: 3 }}>
<Container fixed>
  <Grid lg={12} xs={12} sm={12} md={12} container>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        borderRadius: "9px",
        maxWidth: {
          lg: "22% !important",
          xs: "100% !important",
          sm: "47% !important",
          md: "40% !important",
        },
        mb: 2,
        ml: { lg: 0, xs: 0, sm: 2, md: 7 },
      }}
    >
      <Box sx={{ mt: 3, mb: 5 }}>
        <center>
          {" "}
          <div className={classes.cardoneimagelarg}>
            <center>
              {" "}
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/ps1.webp"
                alt="Goole"
                sx={{ width: "64px" }}
              />
            </center>
          </div>
        </center>
      </Box>
      <Box>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{ fontSize: "23px", fontWeight: "bold", pb: 6 }}
        >
          Completion Certifications
        </Typography>
      </Box>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        borderRadius: "9px",
        maxWidth: {
          lg: "22% !important",
          xs: "100% !important",
          sm: "47% !important",
          md: "40% !important",
        },
        ml: { lg: 4, xs: 0, sm: 2, md: 7 },
        mb: 2,
      }}
    >
      <Box sx={{ mt: 3, mb: 5 }}>
        <center>
          {" "}
          <div className={classes.cardoneimagelarg}>
            <center>
              {" "}
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/ps2.webp"
                alt="Goole"
                sx={{ width: "64px" }}
              />
            </center>
          </div>
        </center>
      </Box>
      <Box>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{ fontSize: "23px", fontWeight: "bold", pb: 6 }}
        >
          Live Projects and Case studies
        </Typography>
      </Box>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        borderRadius: "9px",
        maxWidth: {
          lg: "22% !important",
          xs: "100% !important",
          sm: "47% !important",
          md: "40% !important",
        },
        ml: { lg: 4, xs: 0, sm: 2, md: 7 },
        mb: 2,
      }}
    >
      <Box sx={{ mt: 3, mb: 5 }}>
        <center>
          {" "}
          <div className={classes.cardoneimagelarg}>
            <center>
              {" "}
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/ps3.webp"
                alt="Goole"
                sx={{ width: "64px" }}
              />
            </center>
          </div>
        </center>
      </Box>
      <Box>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{ fontSize: "23px", fontWeight: "bold", pb: 6 }}
        >
          {Iitprogram.toolandassinment}
        </Typography>
      </Box>
    </Grid>
    <Grid
      lg={3}
      xs={12}
      sm={6}
      md={6}
      sx={{
        boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)",
        borderRadius: "9px",
        maxWidth: {
          lg: "22% !important",
          xs: "100% !important",
          sm: "47% !important",
          md: "40% !important",
        },
        ml: { lg: 4, xs: 0, sm: 2, md: 7 },
        mb: 2,
      }}
    >
      <Box sx={{ mt: 3, mb: 5 }}>
        <center>
          {" "}
          <div className={classes.cardoneimagelarg}>
            <center>
              {" "}
              <CardMedia
                component="img"
                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/icons/ps4.webp"
                alt="Goole"
                sx={{ width: "64px" }}
              />
            </center>
          </div>
        </center>
      </Box>
      <Box>
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{ fontSize: "23px", fontWeight: "bold", pb: 6 }}
        >
          {Iitprogram.toolandpackge}
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Container>
</Box>

<ProgramSectionTwo />
<ProgramSectionThree />
{/* <ProgramSectionFour /> */}
<ProgramSectionFive />

<BootstrapDialog
onClose={handleClose}
aria-labelledby="customized-dialog-title"
open={open}
>
<BootstrapDialogTitle
  id="customized-dialog-title"
  onClose={handleClose}
  sx={{
    backgroundColor: "#f8f9fa",
    color: "#6c757d",
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: "bold",
  }}
>
  50% Complete
  <BorderLinearProgress
    variant="determinate"
    value={50}
    sx={{ my: 2 }}
  />
</BootstrapDialogTitle>
<Backdrop
  sx={{
    color: "#fff",
    zIndex: (theme) => theme.zIndex.drawer + 1,
  }}
  open={loader}
>
  <CircularProgress color="inherit" />
</Backdrop>
<Box>
  <Grid lg={12} sm={12} md={12} xs={12} container>
    <Grid lg={4} sm={4} md={4}>
      <CardMedia
        component="img"
        image={Iitprogram.brochurectaimg}
        alt="Goole"
        sx={{
          width: "208px",
          mx: 3,
          py: 3,
          display: {
            xs: "none",
            lg: "block",
            sm: "block",
            md: "block",
          },
        }}
      />
    </Grid>
    <Grid lg={8} sm={8} md={8}>
      <Box sx={{ mx: 3, py: 3 }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontSize: "25px" }}
        >
          {Iitprogram.brochurectahadding}
        </Typography>
      </Box>
      <Box sx={{ mx: 3 }}>
        <form onSubmit={submitForm}>
          <Box sx={{ pb: 2 }}>
            <TextField
              id="standard-basic"
              label="First Name"
              variant="standard"
              name="name"
              onChange={ctahandleChange}
              value={cta.name}
              type="text"
              fullWidth
              required
              sx={{ pb: 1 }}
            />
            <TextField
              id="standard-basic"
              label="Email"
              variant="standard"
              type="email"
              name="newemail"
              onChange={ctahandleChange}
              value={cta.email}
              fullWidth
              required
              sx={{ pb: 1 }}
            />
            <TextField
              label="Phone Number"
              variant="standard"
              onChange={ctahandleChange}
              value={cta.mobile}
              type="number"
              name="mobile"
              fullWidth
              required
              sx={{ pb: 1 }}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <Button
              variant="contained"
              sx={{
                mb: 2,
                backgroundColor: "#0039e6",
                textTransform: "none",
                p: 1,
                "&:hover": {
                  mb: 2,
                  backgroundColor: "#0039e6",
                  textTransform: "none",
                  p: 1,
                },
              }}
              fullWidth
              type="submit"
            >
              Download Brochure here!
            </Button>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                color: "rgb(119, 119, 119)",
                mx: 1,
              }}
            >
              Privacy Policy: We hate spam and promise to keep
              your email address safe
            </Typography>
          </Box>
        </form>
      </Box>
    </Grid>
  </Grid>
</Box>
</BootstrapDialog>

<BootstrapDialog
onClose={RcbhandleClose}
aria-labelledby="customized-dialog-title"
open={Rcbopen}
>
<RcbDialogTitle
  id="customized-dialog-title"
  onClose={RcbhandleClose}
></RcbDialogTitle>
<Backdrop
  sx={{
    color: "#fff",
    zIndex: (theme) => theme.zIndex.drawer + 1,
  }}
  open={loader}
>
  <CircularProgress color="inherit" />
</Backdrop>
<Box>
  <Grid lg={12} sm={12} md={12} xs={12} container>
    <Grid
      lg={5}
      md={5}
      sm={5}
      sx={{
        display: {
          xs: "none",
          lg: "block",
          sm: "block",
          md: "block",
        },
      }}
    >
      <Box sx={{ mx: 3 }}>
        <Typography
          sx={{
            fontSize: "1rem",
            textAlign: "center",
            mx: 1,
            marginBottom: "0.7rem !important",
          }}
        >
          Call Our experts
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "#0049AE",
            textAlign: "center",
            mx: 1,
            marginBottom: "1rem !important",
          }}
        >
          Data Science: +91 7042148123
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "#0049AE",
            textAlign: "center",
            mx: 1,
          }}
        >
          Product Management: +91 9625811095
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "#0049AE",
            textAlign: "center",
            mx: 1,
          }}
        >
          General Management: +91 8130897175
        </Typography>
        <CardMedia
          component="img"
          image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/topbar.webp"
          alt="Goole"
          sx={{ mt: 5, width: "110%" }}
        />
      </Box>
    </Grid>

    <Divider orientation="vertical" flexItem sx={{ pl: 2 }}>
      <Typography
        sx={{
          display: { xs: "none", lg: "block" },
          marginBottom: "0rem!important",
        }}
      >
        OR
      </Typography>
    </Divider>

    <Grid lg={6} xs={12} sm={6} md={6}>
      <Box>
        <Typography
          sx={{
            fontSize: "1rem",
            textAlign: "center",
            mx: 1,
            marginBottom: "0.7rem !important",
          }}
        >
          Schedule a 1-on-1 consultation
        </Typography>
        <Typography
          sx={{
            fontSize: "1.5rem",
            textAlign: "center",
            color: "#0049AE",
            mx: 1,
          }}
        >
          Request a Call Back
        </Typography>
      </Box>
      <Box>
        <form onSubmit={rcbsubmitForm}>
          <TextField
            id="number"
            label="Mobile"
            variant="standard"
            textAlign="canter"
            sx={{ pb: 1, mx: 7, width: "65%" }}
            required={true}
            onChange={rcbhandleChange}
            value={rcb.mobile}
            type="number"
            name="mobile"
          />
          <TextField
            id="name"
            label="Name"
            variant="standard"
            textAlign="canter"
            sx={{ pb: 1, mx: 7, width: "65%" }}
            onChange={rcbhandleChange}
            value={rcb.name}
            type="text"
            name="name"
            required={true}
          />
          <TextField
            id="newemail"
            label="Email"
            variant="standard"
            textAlign="canter"
            sx={{ pb: 2, mx: 7, width: "65%" }}
            type="email"
            name="newemail"
            onChange={rcbhandleChange}
            value={rcb.newemail}
            required={true}
          />
          <TextField
            id="program"
            select
            value={rcbprogram}
            onChange={progamhandleChange}
            variant="standard"
            sx={{ pb: 3, mx: 7, width: "65%" }}
            name="program"
            label={rcbprogram === "" ? "Select Program" : ""}
            // label="Select Program"
            required={true}
            InputLabelProps={{ shrink: false }}
          >
            <MenuItem value="">
              <em>---- Select Program ----</em>
            </MenuItem>
            {currencies.map((rcb) => (
              <MenuItem
                key={rcb.value}
                value={rcb.value}
                name="program"
              >
                {rcb.label}
              </MenuItem>
            ))}
          </TextField>

          <center>
            {" "}
            <Button
              variant="contained"
              type="submit"
              sx={{
                mb: 2,
                backgroundColor: "#ff8c1a",
                textTransform: "none",
                p: 1,
                width: "70%",
                "&:hover": {
                  mb: 2,
                  backgroundColor: "#ff8c1a",
                  textTransform: "none",
                  p: 1,
                  width: "70%",
                },
              }}
            >
              Call Me Back
            </Button>
          </center>
        </form>
      </Box>
    </Grid>
  </Grid>
</Box>
</BootstrapDialog>
<Dialog
        open={insaidtvpop}
        onClose={handleyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{"& .MuiDialog-paper": {
          backgroundColor: "transparent",
      
        },}}
        fullWidth={true}
        maxWidth={maxWidth}
       
      >
        <Box>
        <CloseIcon sx={{color:"#fff",float:"right",mb:4,cursor:"pointer"}} onClick={handleyClose}/>

        </Box>
          
    
   
          <iframe
            width="100%"
            height="500"
            frameborder="0"
            allowfullscreen="1"
            allow="autoplay; encrypted-media;"
            src={
            'https://www.youtube.com/embed/cY6JXfIewHQ?autoplay=1&cc_load_policy=1rel=0&showinfo=0'
            }
          ></iframe>
    
      </Dialog>
<ApplyNow
open={applynow}
handleClose={handleApplyclose}
category={Iitprogram.category}
university={Iitprogram.university}
course={Iitprogram.title}
/>
</Box>

         ))}

    </>
  );
}

export default YourComponent;
